import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { userById } from '../../actions/userActions'
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import TrackingEmployee from '../../components/adminComponents/adminTrackingComponents/TrackingEmployee'
import TrackingUserDetails from '../../components/adminComponents/adminTrackingComponents/TrackingUserDetails'
const AdminTrackingofEmpIDPage = () => {
  const params = useParams();
  const dispatch = useDispatch()
  useEffect(() => {
    if (params.id) {
      dispatch(userById({userId: params.id, date: moment().format('YYYY-MM-DD')}))
    } 
  }, [params.id]);
  return (
    <>
      {/* <AdminHeader />
      <AdminSideBar /> */}
      <div className="main-wrapper">
          <div className="page-wrapper">
              <div className="content container-fluid">
                <div className="row">
                  <div className="col-lg-8 col-xl-9">
                    <TrackingEmployee />
                  </div>
                  <div className="col-lg-4 col-xl-3">
                    <TrackingUserDetails />
                  </div>
                </div>
              </div>
          </div>
      </div>
    </>
  )
}
export default AdminTrackingofEmpIDPage
import React from 'react'
import writtenNumber from 'written-number';
import { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import EditModalSalaryViewFromAdmin from './EditModalSalaryViewFromAdmin';
// import { useDispatch } from 'react-redux'
// import { getSalaryByUserId } from '../../../actions/salaryActions'; 
// import { useEffect } from 'react';
// import { useParams } from 'react-router-dom'
// import { NavLink, useNavigate } from 'react-router-dom'
const SalaryInfoViewFromAdmin = () => {
   const salary = useSelector((state) => state.salary);
   // const auth = useSelector((state) => state.auth);
   // const params = useParams()
   // const dispatch = useDispatch()
   // const salaryData = async (e) => {
   //    dispatch(getSalaryByUserId({userId: params.id}))
   // }
   // useEffect(() => {
   //    salaryData()
   // },[])
   const [showDropDown, setshowDropDown] = useState(false)
   const openDropDown = () => {
      setshowDropDown((showDropDown) => !showDropDown)
   }
   return (
      <>
         <div className="tab-content">
         <div className="" id="bank_statutory">
               <div className="card">
                  <div className="card-body">
                     <h3 className="card-title"> Basic Salary Information
                     <div className="pro-edit">
                        <NavLink to="#" className="edit-icon" data-toggle="modal" onClick={openDropDown} data-target="#profile_info"><i className="fa fa-pencil"></i></NavLink></div>
                     </h3>
                     <EditModalSalaryViewFromAdmin current={salary.salary} showDropDown={showDropDown} openDropDown={openDropDown} />
                     <div className="row">
                        <div className="col-sm-6">
                           <div>
                              <h4 className="m-b-10"><strong>Earnings</strong></h4>
                              <table className="table table-bordered">
                                 <tbody>
                                    {
                                       salary.salary &&
                                       <>
                                          <tr>
                                             <td><strong>Basic Salary</strong> <span className="float-right">${salary.salary.basicSalary}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>House Rent Allowance (H.R.A.)</strong> <span className="float-right">${salary.salary.houseRentAllowance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Conveyance</strong> <span className="float-right">${salary.salary.conveyance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Other Allowance</strong> <span className="float-right">${salary.salary.otherAllowance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Total Earnings</strong> <span className="float-right"><strong>${salary.salary.totalEarning}</strong></span></td>
                                          </tr>
                                       </>
                                    }
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div>
                              <h4 className="m-b-10"><strong>Deductions</strong></h4>
                              <table className="table table-bordered">
                                 <tbody>
                                 {
                                    salary.salary &&
                                    <>
                                       <tr>
                                          <td><strong>Tax Deducted at Source (T.D.S.)</strong> <span className="float-right">${salary.salary.taxDeductedAtSource}</span></td>
                                       </tr>
                                       <tr>
                                          <td><strong>Provident Fund</strong> <span className="float-right">${salary.salary.providentFund}</span></td>
                                       </tr>
                                       <tr>
                                          <td><strong>ESI</strong> <span className="float-right">${salary.salary.esi}</span></td>
                                       </tr>
                                       <tr>
                                          <td><strong>Loan</strong> <span className="float-right">${salary.salary.loan}</span></td>
                                       </tr>
                                       <tr>
                                          <td><strong>Total Deductions</strong> <span className="float-right"><strong>${salary.salary.totalDeduction}</strong></span></td>
                                       </tr>
                                    </>
                                 }
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div className="col-sm-12">
                        {
                           salary.salary &&
                              <p><strong>Net Salary: ${salary.salary.netSalary}</strong> ({writtenNumber(salary.salary.netSalary)})</p>
                        }      
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </> 
   )
}
export default SalaryInfoViewFromAdmin
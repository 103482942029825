import React from "react";
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from "../../components/adminComponents/AdminHeader";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import AdminEmployeeFandS from "../../components/adminComponents/adminAllEmployeeComponents/AdminEmployeeFandS";
import AdminEmployeeTable from "../../components/adminComponents/adminAllEmployeeComponents/AdminEmployeeTable";
import AdminLocationPositionCards from "../../components/adminComponents/adminAllEmployeeComponents/AdminLocationPositionCards";
import BreakRules from "../../components/adminComponents/adminAllEmployeeComponents/BreakRules";
import { useSelector } from "react-redux";

// import AdminEmployeeMenu from '../../components/adminComponents/adminAllEmployeeComponents/AdminEmployeeMenu'

const AdminAllEmployees = () => {
  const myState = useSelector((state) => state.changeTheTab);
  return (
    <>
      {/* <AdminHeader />
      <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Employee" employee />
            <AdminEmployeeFandS />
            {/* <AdminEmployeeTable /> */}
            {/* {myState.viewEmployeeMenu === "employee" ? (
              <>
                <AdminTittle title="Employee" employee />
                <br />
                <AdminEmployeeFandS />
                <AdminEmployeeTable />
              </>
            ) : myState.viewEmployeeMenu === "locationposition" ? (
              <>
                <AdminTittle title="Location Position" employee />
                <br />
                <AdminLocationPositionCards />
              </>
            ) : myState.viewEmployeeMenu === "breakrules" ? (
              <>
                <AdminTittle title="Break Rules" employee />
                <br />
                <BreakRules />
              </>
            ) : (
              <div>Loading</div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminAllEmployees;

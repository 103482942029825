import moment from "moment";
import React from "react";

const EditModalAttendanceInfo = ({ dayData, showDropDown, openDropDown }) => {
  // console.log(dayData)
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="attendance_info"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        {/* modal custom-modal fade */}
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Attendance Info</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {dayData && (
                  <>
                    <div className="col-md-6">
                      <div className="card punch-status">
                        <div className="card-body">
                          <h5 className="card-title">
                            Timesheet{" "}
                            <small className="text-muted">
                              {moment(dayData.date).format("MM/DD/YYYY")}
                            </small>
                          </h5>
                          <div className="punch-det">
                            <h6>Time In at</h6>
                            <p>
                              {moment(dayData.dayClockIn).format(
                                "ddd, MM/DD/YYYY h:mm a"
                              )}
                            </p>
                          </div>
                          <div className="punch-info">
                            <div className="punch-hours">
                              <span>{dayData.hoursUsed}</span>
                            </div>
                          </div>
                          <div className="punch-det">
                            <h6>Time Out at</h6>
                            <p>
                              {moment(dayData.dayClockOut).format(
                                "ddd, MM/DD/YYYY h:mm a"
                              )}
                            </p>
                          </div>
                          <div className="statistics">
                            <div className="row">
                              <div className="col-md-12 col-6 text-center">
                                <div className="stats-box">
                                  <p>Break</p>
                                  <h6>{dayData.breakUsed}</h6>
                                </div>
                              </div>
                              {/* <div className="col-md-6 col-6 text-center">
                                                        <div className="stats-box">
                                                            <p>Overtime</p>
                                                            <h6>3 hrs</h6>
                                                        </div>
                                                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card recent-activity">
                        <div className="card-body">
                          <h5 className="card-title">Activity</h5>
                          <ul className="res-activity-list">
                            {dayData.clockHistory &&
                              dayData.clockHistory.length > 0 &&
                              dayData.clockHistory.map((current, i) => {
                                return (
                                  <>
                                    <li key={i}>
                                      <p className="mb-0">Time In at</p>
                                      <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        &nbsp;{" "}
                                        {moment(current.clockIn).format(
                                          "h:mm a"
                                        )}
                                      </p>
                                    </li>
                                    {current.clockOut && (
                                      <li>
                                        <p className="mb-0">Time Out at</p>
                                        <p className="res-activity-time">
                                          <i className="fa fa-clock-o"></i>
                                          &nbsp;{" "}
                                          {moment(current.clockOut).format(
                                            "h:mm a"
                                          )}
                                        </p>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            {/* <li>
                                                    <p className="mb-0">Time In at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        10.00 AM.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="mb-0">Time Out at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        11.00 AM.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="mb-0">Time In at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        11.15 AM.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="mb-0">Time Out at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        1.30 PM.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="mb-0">Time In at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        2.00 PM.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className="mb-0">Time Out at</p>
                                                    <p className="res-activity-time">
                                                        <i className="fa fa-clock-o"></i>
                                                        7.30 PM.
                                                    </p>
                                                </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalAttendanceInfo;

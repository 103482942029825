import React from 'react'
import Footer from '../../components/Footer'
import TermsOfUse from '../../components/frontendComponents/websiteSignupForm/TermsOfUse'
import NavBar from '../../components/NavBar'

const TermsOfUseForm = () => {
  return (
    <>
      <NavBar />
      <TermsOfUse/>  
      <Footer />
    </>
  )
}
export default TermsOfUseForm
import React from 'react'
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminRecentClockIn from '../../components/adminComponents/adminTimeClock/AdminRecentClockIn'
import AdminTittle from '../../components/adminComponents/AdminTittle'
const AdminTrackingofEmployeePage = () => {
  return (
    <>
        {/* <AdminHeader />
        <AdminSideBar /> */}
          <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <AdminTittle title="Tracking" />
                    <AdminRecentClockIn tracking/>
                </div>
            </div>
        </div>
    </>
  )
}
export default AdminTrackingofEmployeePage
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { createMail } from "../../actions/mailActions";
// import { Editor } from 'react-draft-wysiwyg'
const ComposeMessage = () => {
  const mail = useSelector((state) => state.mail);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    companyId: "",
    subject: "",
    members: [auth.user.id],
    messages: [],
    sender: "",
    message: "",
    attachment: [],
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [membersObj, setMembersObj] = useState([]);
  const handleMembersChange = (fieldName) => (event) => {
    const abc = JSON.parse(event.target.value);
    // console.log(event.target.value, values.members.includes(abc.id) === false)
    if (abc !== null) {
      if (values.members.includes(abc.id) === false) {
        // console.log(abc)
        setMembersObj([...membersObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.members, abc.id],
        });
      }
    }
  };
  // console.log(values, "ssaaaaaaaaaaassasssasasasasasa", membersObj)
  const deleteHandler = (id) => {
    setMembersObj(membersObj.filter((x) => x.id !== id));
    const index = values.members.indexOf(id);
    let arr = values.members;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, members: arr });
    }
  };
  const dispatch = useDispatch();
  const mailData = {
    companyId: auth.user.companyId,
    subject: values.subject,
    members: values.members,
    messages: [
      {
        sender: auth.user.id,
        message: values.message,
        attachment: values.attachment,
      },
    ],
    userId: values.userId,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(createMail(mailData));
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  {/* <input type="email" 
                                    placeholder="To" className="form-control" 
                                    /> */}
                  <select
                    className="form-select"
                    style={{ height: "44px", fontSize: "14px" }}
                    autoComplete="false"
                    onChange={handleMembersChange("members")}
                  >
                    <option value="">To</option>
                    {user.allUsersWithoutPaginate &&
                      user.allUsersWithoutPaginate.length > 0 &&
                      user.allUsersWithoutPaginate.map((current, i) => {
                        return (
                          <option
                            value={JSON.stringify({
                              id: current.id,
                              name: current.email,
                            })}
                            key={i + 1}
                          >
                            {current.firstName + " " + current.lastName}
                          </option>
                        );
                      })}
                  </select>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <br />
                      <label className="col-form-label">To, </label>
                      <ul>
                        {membersObj.map((elem, ind) => {
                          return (
                            <li key={ind}>
                              {elem.name}
                              <i
                                style={{ float: "right" }}
                                className="fa fa-trash"
                                onClick={() => deleteHandler(elem.id)}
                              ></i>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Cc" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Bcc" className="form-control" />
                                        </div>
                                    </div>
                                </div> */}
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Subject"
                    className="form-control"
                    value={values.subject}
                    onChange={handleChange("subject")}
                  />
                </div>
                {/* message k liya */}
                {/* <div className="form-group"> 
                                <Editor
                                    placeholder="Enter your message here"
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName" />
                                </div> */}
                <div className="form-group">
                  <textarea
                    rows="4"
                    className="form-control"
                    placeholder="Type a message..."
                    autoComplete="false"
                    value={values.message}
                    onChange={handleChange("message")}
                  ></textarea>
                </div>
                <div className="form-group mb-0">
                  <div className="text-center">
                    <button className="btn btn-primary">
                      <span>Send</span> <i className="fa fa-send m-l-5"></i>
                    </button>
                    {/* <button className="btn btn-success m-l-5" type="button"><span>Delete</span> <i className="fa fa-trash-o m-l-5"></i></button> */}
                    {/* <button className="btn btn-success m-l-5" type="button"><span>Draft</span> <i className="fa fa-floppy-o m-l-5"></i></button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ComposeMessage;

import {
    SUPER_ADMIN_COMPANY_REQUEST,
    SUPER_ADMIN_COMPANY_SUCCESS,
    SUPER_ADMIN_COMPANY_FAIL,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    NEWLEAD_REQUEST,
    NEWLEAD_SUCCESS,
    NEWLEAD_FAIL,
    CREATE_NEWLEAD_REQUEST,
    CREATE_NEWLEAD_SUCCESS,
    CREATE_NEWLEAD_FAIL,
    CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAIL,
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAIL,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL,
    USER_LOGOUT_SUCCESS,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL,
    SUPER_ADMIN_DASHBOARD_CARD_REQUEST,
    SUPER_ADMIN_DASHBOARD_CARD_SUCCESS,
    SUPER_ADMIN_DASHBOARD_CARD_FAIL,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL,
    SUPER_ADMIN_SEARCH_COMPANY_REQUEST,
    SUPER_ADMIN_SEARCH_COMPANY_SUCCESS,
    SUPER_ADMIN_SEARCH_COMPANY_FAIL,
} from "../constants"

export const superAdminCompanyReducer = (
    state = {
        company : {
            superAdminCompanyCC: null,
            superAdminCompanyCPC: null,
            newLead: null,
            subscriptionData: null,
            StripeInovicesQueryAllCompanies: null,
            SAdminCardsDetails: null,
            adminLoginInfo: null,
            searchCompany: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                superAdminCompanyCC: null,
                superAdminCompanyCPC: null,
                newLead: null,
            };

        case SUPER_ADMIN_COMPANY_REQUEST : 
            return {
                ...state,
            };
        case SUPER_ADMIN_COMPANY_SUCCESS :
            return {
                ...state,
                superAdminCompanyCC : action.payload,
            };
        case SUPER_ADMIN_COMPANY_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST : 
            return {
                ...state,
            };
        case SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS :
            return {
                ...state,
                superAdminCompanyCPC : action.payload,
            };
        case SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL :
            return {
                ...state,
                error: action.payload,
            };


            case UPDATE_COMPANY_REQUEST :
                return {
                    ...state,
                };
            case UPDATE_COMPANY_SUCCESS :
                return {
                    ...state,
                    // superAdminCompanyCC: [...state.superAdminCompanyCC, action.payload],
                    // superAdminCompanyCC: state.superAdminCompanyCC.map((x) =>
                    // x.id === action.payload.id ? action.payload : x
                    // ),
                };
            case UPDATE_COMPANY_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

            // USER NEWLEAD TABLE
            case NEWLEAD_REQUEST : 
                return {
                    ...state,
                };
            case NEWLEAD_SUCCESS :
                return {
                    ...state,
                    newLead: action.payload,
                    // newRegisteration: action.payload.register ? true : false,
                };
            case NEWLEAD_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

            // COMPANY CREATE NEWLEAD
            case CREATE_NEWLEAD_REQUEST : 
                return {
                    ...state,
                };
            case CREATE_NEWLEAD_SUCCESS :
                return {
                    ...state,
                    // newLead: action.payload,
                    // newRegisteration: action.payload.register ? true : false,
                };
            case CREATE_NEWLEAD_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

            // SUPER ADMIN CREATE COMPANY AND ADMIN
            case CREATE_COMPANY_REQUEST :
                return {
                    ...state,
                };
            case CREATE_COMPANY_SUCCESS :
                return {
                    ...state,
                    newLead: state.newLead.map((x) =>
                    x.email === action.payload.email ? x.generateUser = true : x               
                    )
                };
            case CREATE_COMPANY_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

            // SUPER ADMIN ALL SUBSCRIPTION
            case SUBSCRIPTION_REQUEST :
                return {
                    ...state,
                };
            case SUBSCRIPTION_SUCCESS :
                return {
                    ...state,
                    subscriptionData: action.payload,
                };
            case SUBSCRIPTION_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

            // SUPER ADMIN UPDATE SUBSCRIPTION
            case SUBSCRIPTION_UPDATE_REQUEST :
                return {
                    ...state,
                };
            case SUBSCRIPTION_UPDATE_SUCCESS :
                return {
                    ...state,
                    subscriptionData: action.payload,
                };
            case SUBSCRIPTION_UPDATE_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };



            case SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST : 
                return {
                    ...state,
                };
            case SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS :
                return {
                    ...state,
                    StripeInovicesQueryAllCompanies : action.payload,
                };
            case SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL :
                return {
                    ...state,
                    error: action.payload,
                };

                
        
        case SUPER_ADMIN_DASHBOARD_CARD_REQUEST : 
            return {
                ...state,
            };
        case SUPER_ADMIN_DASHBOARD_CARD_SUCCESS :
            return {
                ...state,
                SAdminCardsDetails: action.payload,
            };
        case SUPER_ADMIN_DASHBOARD_CARD_FAIL :
            return {
                ...state,
                error: action.payload,
            };

                
        case SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST : 
            return {
                ...state,
            };
        case SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS :
            return {
                ...state,
                adminLoginInfo: action.payload,
            };
        case SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL :
            return {
                ...state,
                error: action.payload,
            };


// abbhhhhiiiiiiiiiiiiiiiiiiiii
        case SUPER_ADMIN_SEARCH_COMPANY_REQUEST : 
            return {
                ...state,
            };
        case SUPER_ADMIN_SEARCH_COMPANY_SUCCESS :
            return {
                ...state,
                searchCompany: action.payload,
            };
        case SUPER_ADMIN_SEARCH_COMPANY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminSSAddShiftModal from "./AdminSSAddShiftModal";
import EditModalSSEditScheduling from "./EditModalSSEditScheduling";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const MonthlyTableRow = ({ current, i, datesArrLength, date }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [iii, setIII] = useState();
  const [numberShift, setNumberShift] = useState();
  const openMoreVertDropDown = (num, userShift, currentShift) => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
    setIII(num);
  };
  const openDropDown = (num) => {
    setshowDropDown((showDropDown) => !showDropDown);
    setNumberShift(num);
  };
  return (
    <>
      <tr key={i}>
        <td>
          <h2 className="table-avatar">
            <NavLink to={`/profile/${current.user.id}`} className="avatar">
              {/* <img alt="" src="assetsAdmin/img/avatar-02.jpg" /> */}
              {current.user &&
              current.user.profilePic &&
              current.user.profilePic.fileUrl ? (
                <img
                  alt="profilePic"
                  src={BUCKET_BASE_URL + current.user.profilePic.fileUrl}
                  style={{ objectFit: "fill", height: "100%" }}
                />
              ) : (
                <img alt="profilePic" src={defaultImg} />
              )}
            </NavLink>
            <NavLink to={`/profile/${current.user.id}`}>
              {current.user.firstName + " " + current.user.lastName}{" "}
              <span>{current.user.jobTitle}</span>
            </NavLink>
          </h2>
        </td>
        {current.monthlyShift.length > 0
          ? current.monthlyShift.map((e, j) => {
              let shiftType;
              let shiftTypeCss = "miclsun";
              let shiftTypeIcon = "fa fa-sun-o";
              let empty = true;
              let temp;
              let start, end;
              let stAmPm = "am";
              let enAmPm = "am";
              let date;
              if (Object.keys(e).length != 0) {
                let stStart = parseInt(e.startTime.substring(11, 13));
                let stStartMin = parseInt(e.startTime.substring(14, 16));
                let enEnd = parseInt(e.endTime.substring(11, 13));
                let enEndMin = parseInt(e.endTime.substring(14, 16));
                if (stStartMin < 10) {
                  stStartMin = "0" + stStartMin;
                }
                if (enEndMin < 10) {
                  enEndMin = "0" + enEndMin;
                }
                if (stStart === 0) {
                  stStart = 12;
                }
                if (enEnd === 0) {
                  enEnd = 12;
                }
                if (stStart > 12) {
                  stStart = stStart - 12;
                  stAmPm = "pm";
                }
                if (enEnd > 12) {
                  enEnd = enEnd - 12;
                  enAmPm = "pm";
                }
                if ((stStart === 12 || stStart <= 5) && stAmPm == "am") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                } else if (stStart >= 6 && stAmPm == "am") {
                  shiftType = "morning";
                  shiftTypeCss = "miclsun";
                  shiftTypeIcon = "fa fa-sun-o";
                } else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm") {
                  shiftType = "evening";
                  shiftTypeCss = "miclscl";
                  shiftTypeIcon = "fa fa-cloud";
                } else if (stStart >= 8 && stAmPm == "pm") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                } else if (stStart >= 0 && stAmPm == "am") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                }
                start = stStart + ":" + stStartMin + " " + stAmPm;
                end = enEnd + ":" + enEndMin + " " + enAmPm;
              }
              return (
                <>
                  {Object.keys(e).length != 0 ? (
                    // <td>
                    // <div className={`user-add-shedule-list ${shiftTypeCss}`} >
                    //    <h2>
                    //          <a onClick={openMoreVertDropDown}>
                    //             {/* <div > */}
                    //                {/* style={{width: "100%", height: "3rem"}} yeh add karo toh class achi hogi */}
                    //             <span className="username-info " >{date} {e.duration}</span>
                    //             <span className="userrole-info">{e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span>
                    //             {/* </div> */}
                    //          </a>
                    //          <EditModalSSEditScheduling showMoreVertDropDown={showMoreVertDropDown} openMoreVertDropDown={openMoreVertDropDown} />
                    //    </h2>
                    // </div>
                    // </td>
                    <td style={{ borderCollapse: "separate" }}>
                      <div
                        className={`user-add-shedule-list ${shiftTypeCss}`}
                        style={{ width: "100%" }}
                      >
                        <h2>
                          <a
                            data-toggle="modal"
                            onClick={() => {
                              openMoreVertDropDown(j, current, e);
                            }}
                            data-target="#edit_schedule"
                          >
                            {e.location ? (
                              <span className="username-info ">
                                <i className="fa fa-moon-o"></i>
                                <br />
                                {e.location.name}
                                <br />
                                {start}
                                <br />
                                {end}
                              </span>
                            ) : (
                              <span className="username-info ">
                                <i className="fa fa-moon-o"></i>
                                <br />
                                <br />
                                {start}
                                <br />
                                {end}
                              </span>
                            )}
                          </a>
                        </h2>
                        {/* <EditModalSSEditScheduling current={current} valueIndex={j} typeStatus={'monthly'} showMoreVertDropDown={showMoreVertDropDown} openMoreVertDropDown={openMoreVertDropDown}/> */}
                      </div>
                    </td>
                  ) : (
                    <td className="text-center" style={{ minWidth: "6rem" }}>
                      <div className="user-add-shedule-list">
                        <NavLink
                          to="#"
                          data-toggle="modal"
                          data-target="#add_schedule"
                          onClick={openDropDown}
                        >
                          <span>
                            <i className="fa fa-plus"></i>
                          </span>
                        </NavLink>
                      </div>
                    </td>
                  )}
                </>
              );
            })
          : datesArrLength.length > 0 &&
            datesArrLength.map((g, p) => {
              return (
                <td className="text-center" style={{ minWidth: "6rem" }}>
                  <div className="user-add-shedule-list">
                    <NavLink
                      to="#"
                      data-toggle="modal"
                      data-target="#add_schedule"
                      onClick={() => {
                        openDropDown(p);
                      }}
                    >
                      <span>
                        <i className="fa fa-plus"></i>
                      </span>
                    </NavLink>
                  </div>
                </td>
              );
            })}
      </tr>
      <EditModalSSEditScheduling
        current={current}
        valueIndex={iii}
        typeStatus={"monthly"}
        showMoreVertDropDown={showMoreVertDropDown}
        openMoreVertDropDown={openMoreVertDropDown}
      />
      <AdminSSAddShiftModal
        current={current}
        number={numberShift}
        date={date}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};

export default MonthlyTableRow;

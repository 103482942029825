import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { salarySearchFilter } from "../../../actions/adminActions";
import { tabSalaryCalendarSearch } from "../../../actions/tabActions";
const PEmployeeSalariesFilterandSearch = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [fromDate, setFromDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [toDate, setToDate] = useState(new Date(moment().format("YYYY-MM-DD")));
  const From = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datetimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">From</label>
    </div>
  ));
  const To = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datetimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">To</label>
    </div>
  ));
  let searchData;
  searchData = {
    companyId: auth.user.companyId,
    name: values.name,
    date: moment().format("YYYY-MM-DD"),
    from: fromDate.toISOString().substring(0, 10),
    to: toDate.toISOString().substring(0, 10),
  };
  const handleClick = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(searchData)) {
      if (searchData[key] === "") {
        delete searchData[key];
      }
    }
    dispatch(tabSalaryCalendarSearch());
    dispatch(salarySearchFilter(searchData));
  };
  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="form-group form-focus select-focus">
            <input
              type="text"
              className="form-control floating"
              value={values.name}
              onChange={handleChange("name")}
            />
            <label className="focus-label">Employee Name</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="form-group form-focus select-focus">
            <DatePicker
              selected={fromDate}
              onChange={(date) => {
                setFromDate(date);
              }}
              customInput={<From />}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <div className="form-group form-focus select-focus">
            <DatePicker
              selected={toDate}
              minDate={fromDate}
              onChange={(date) => setToDate(date)}
              customInput={<To />}
            />
          </div>
        </div>
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
          <a onClick={handleClick} to="#" className="btn btn-success btn-block">
            {" "}
            Search{" "}
          </a>
        </div>
      </div>
    </>
  );
};
export default PEmployeeSalariesFilterandSearch;

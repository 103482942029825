import React from 'react'
import { useSelector } from 'react-redux'
import MyComponent from '../../adminComponents/Map/Map'
const LocationMap = () => {
    const location = useSelector((state) => state.location)
    return (
        <>
            <div className="card" >
                <div className="card-body">
                    <h5 className="card-title m-b-20">Map</h5>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        { 
                            location.locationById && 
                            location.locationById.latitude && 
                            location.locationById.longitude && 
                            <MyComponent currLat={location.locationById.latitude} currLng={location.locationById.longitude} searchNeeded/>
                        }   
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LocationMap
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9929.862863082239!2d-0.1543613!3d51.5230174!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26fdced3c04033a4!2sMadame%20Tussauds%20London!5e0!3m2!1sen!2s!4v1629553466971!5m2!1sen!2s"
width="100%" height="300" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe> */}
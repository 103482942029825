import React, { useEffect, useState } from "react";
import AdminSSWeeklyTable from "./AdminSSWeeklyTable";
import AdminSSDailyTable from "./AdminSSDailyTable";
import AdminSSMonthlyTable from "./AdminSSMonthlyTable";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { tabDaily, tabMonthly, tabWeekly } from "../../../actions/tabActions";
import { getShiftAllUsers } from "../../../actions/adminActions";
// import ShiftSearchTable from "./ShiftSearchTable";
// import AdminSSAddShiftModal from "./AdminSSAddShiftModal";
// import { Link } from "react-router-dom";
// import DailyTableRow from "./DailyTableRow";
// import WeeklyTableRow from "./WeeklyTableRow";
// import MonthlyTableRow from "./MonthlyTableRow";

const AdminSSchedulingViewBar = () => {
  const [today, setToday] = useState(moment());
  const admin = useSelector((state) => state.admin);

  let date = moment(today.format("YYYY-MM-DD"));
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState.viewTheShift)
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const [currentDaily, setCurrentDaily] = useState(0);
  const [currentWeekly, setCurrentWeekly] = useState(0);
  const [currentMonthly, setCurrentMonthly] = useState(0);
  const onClickNextDaily = async () => {
    // console.log(currentDaily)
    setCurrentDaily(currentDaily + 1);
    // console.log(currentDaily+1)
    setToday(moment().add(currentDaily + 1, "days"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentDaily + 1, "days")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const onClickPrevDaily = () => {
    // console.log(currentDaily)
    setCurrentDaily(currentDaily - 1);
    // console.log(currentDaily-1)
    setToday(moment().add(currentDaily - 1, "days"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentDaily - 1, "days")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const onClickNextWeekly = async () => {
    // console.log(currentWeekly)
    setCurrentWeekly(currentWeekly + 7);
    // console.log(currentWeekly+1)
    setToday(moment().add(currentWeekly + 7, "days"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly + 7, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentWeekly + 7, "days")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const onClickPrevWeekly = () => {
    setCurrentWeekly(currentWeekly - 7);
    setToday(moment().add(currentWeekly - 7, "days"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly - 7, 'days').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentWeekly - 7, "days")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const onClickNextMonthly = async () => {
    setCurrentMonthly(currentMonthly + 1);
    setToday(moment().add(currentMonthly + 1, "months"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly + 1, 'months').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentMonthly + 1, "months")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const onClickPrevMonthly = () => {
    setCurrentMonthly(currentMonthly - 1);
    setToday(moment().add(currentMonthly - 1, "months"));
    // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly - 1, 'months').format('YYYY-MM-DD')}))
    dispatch(
      getShiftAllUsers({
        companyId: auth.user.companyId,
        date: moment()
          .add(currentMonthly - 1, "months")
          .format("YYYY-MM-DD"),
        limit: 10,
        page: 1,
      })
    );
  };
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };

  let dateShift = moment(today.format("YYYY-MM-DD"));
  if (dateShift.format("dddd") !== "Sunday") {
    dateShift = dateShift.startOf("week").add(1, "days").format("YYYY-MM-DD");
  } else {
    dateShift = dateShift.startOf("week").add(-6, "days").format("YYYY-MM-DD");
  }

  // let endOfMonth = date.endOf("month").format("DD");
  // // let startOfMonth = date.startOf('month').format('DD')
  // let datesArrLength = [];
  // for (let i = 0; i < parseInt(endOfMonth); i++) {
  //   datesArrLength.push(i);
  // }
  // useEffect(() => {
  //   setToday(moment(date.format("YYYY-MM-DD")));
  // }, [date]);
  // let dateShift2 = date.startOf("month").format("YYYY-MM-DD");

  return (
    <>
      <div class="row">
        <div class="col-xl-12 col-sm-12 col-12">
          <div class="card rounded-3 card-table">
            <div class="card-header rounded-3 bg-success-dark">
              <div className="row">
                {myState.viewTheShift === "daily" ? (
                  <>
                    {/* <div className="row"> */}
                    <div className="col-md-4">
                      <div className="float-left">
                        <a
                          className="btn btn-white"
                          onClick={() => dispatch(tabDaily())}
                          href="#"
                        >
                          Daily
                        </a>
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabWeekly())}
                          href="#"
                        >
                          Weekly
                        </a>
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabMonthly())}
                          href="#"
                        >
                          Monthly
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-md-5"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        href="#"
                        className="btn btn-white"
                        onClick={onClickPrevDaily}
                      >
                        <i className="fa fa-chevron-circle-left"></i>
                      </a>
                      &nbsp;
                      <h5 className="card-title mt-2 text-center text-white">
                        {date.format("DD MMM YYYY")}
                      </h5>
                      &nbsp;
                      <a href="#" className="btn btn-white">
                        <i
                          className="fa fa-chevron-circle-right"
                          onClick={onClickNextDaily}
                        ></i>
                      </a>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="text-end">
                        <Link
                          to="#"
                          className="btn add-btn m-r-5 m-l-5"
                          data-toggle="modal"
                          onClick={openDropDown}
                          data-target="#add_schedule"
                        >
                          {" "}
                          Add Shifts
                        </Link>
                      </div>
                    </div> */}
                    {/* </div> */}
                  </>
                ) : myState.viewTheShift === "weekly" ? (
                  <>
                    {/* <div className="row"> */}
                    <div className="col-md-4">
                      <div className="float-left">
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabDaily())}
                          href="#"
                        >
                          Daily
                        </a>
                        <a
                          className="btn btn-white"
                          onClick={() => dispatch(tabWeekly())}
                          href="#"
                        >
                          Weekly
                        </a>
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabMonthly())}
                          href="#"
                        >
                          Monthly
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-md-5"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        href="#"
                        className="btn btn-white"
                        onClick={onClickPrevWeekly}
                      >
                        <i className="fa fa-chevron-circle-left"></i>
                      </a>
                      &nbsp;
                      <h5 className="card-title mt-2 text-center text-white">
                        {date.format("dddd") !== "Sunday"
                          ? date
                              .startOf("week")
                              .add(1, "days")
                              .format("DD MMMM YYYY")
                          : date
                              .startOf("week")
                              .add(-6, "days")
                              .format("DD MMMM YYYY")}
                        &nbsp; to &nbsp;
                        {date.format("dddd") !== "Sunday"
                          ? date
                              .startOf("week")
                              .add(7, "days")
                              .format("DD MMMM YYYY")
                          : date
                              .startOf("week")
                              .add(0, "days")
                              .format("DD MMMM YYYY")}
                      </h5>
                      &nbsp;
                      <a href="#" className="btn btn-white">
                        <i
                          className="fa fa-chevron-circle-right"
                          onClick={onClickNextWeekly}
                        ></i>
                      </a>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="text-end">
                        <Link
                          to="#"
                          className="btn add-btn m-r-5 m-l-5"
                          data-toggle="modal"
                          onClick={openDropDown}
                          data-target="#add_schedule"
                        >
                          {" "}
                          Add Shifts
                        </Link>
                      </div>
                    </div> */}
                    {/* </div> */}
                  </>
                ) : myState.viewTheShift === "monthly" ? (
                  <>
                    {/* <div className="row"> */}
                    <div className="col-md-4">
                      <div className="float-left">
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabDaily())}
                          href="#"
                        >
                          Daily
                        </a>
                        <a
                          className="btn text-white"
                          onClick={() => dispatch(tabWeekly())}
                          href="#"
                        >
                          Weekly
                        </a>
                        <a
                          className="btn btn-white"
                          onClick={() => dispatch(tabMonthly())}
                          href="#"
                        >
                          Monthly
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-md-5"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        href="#"
                        className="btn btn-white"
                        onClick={onClickPrevMonthly}
                      >
                        <i className="fa fa-chevron-circle-left"></i>
                      </a>
                      &nbsp;
                      <h5 className="card-title mt-2 text-center text-white">
                        {date.format("MMMM YYYY")}
                      </h5>
                      &nbsp;
                      <a href="#" className="btn btn-white">
                        <i
                          className="fa fa-chevron-circle-right"
                          onClick={onClickNextMonthly}
                        ></i>
                      </a>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="text-end">
                        <Link
                          to="#"
                          className="btn add-btn m-r-5 m-l-5"
                          data-toggle="modal"
                          onClick={openDropDown}
                          data-target="#add_schedule"
                        >
                          {" "}
                          Add Shifts
                        </Link>
                      </div>
                    </div> */}
                    {/* </div> */}
                  </>
                ) : (
                  <div>nothing</div>
                )}
              </div>
            </div>

            <div class="card-body">
              <div class="table-responsive">
                {myState.viewTheShift === "daily" ? (
                  <>
                    <AdminSSDailyTable dateForTableData={today} />

                    {/* <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Scheduled Shift</th>
                          <th>{date.format("ddd DD")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admin.usersShifts &&
                          admin.usersShifts.length > 0 &&
                          admin.usersShifts.map((current, i) => {
                            return (
                              i < admin.usersShifts.length - 5 && (
                                <>
                                  <DailyTableRow current={current} key={i} />
                                </>
                              )
                            );
                          })}
                      </tbody>
                    </table> */}
                  </>
                ) : myState.viewTheShift === "weekly" ? (
                  <>
                    <AdminSSWeeklyTable dateForTableData={today} />
                  </>
                ) : myState.viewTheShift === "monthly" ? (
                  <>
                    <AdminSSMonthlyTable dateForTableData={today} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSSchedulingViewBar;

// {
//   myState.viewTheShift === "weekly" ? (
//     <>
//       <div className="row mitblrow">
//         <div className="col-md-4">
//           <div className="float-left">
//             <button
//               onClick={() => dispatch(tabDaily())}
//               // to="/daily-scheduling"
//               className="btn"
//             >
//               Daily
//             </button>
//             <button
//               onClick={() => dispatch(tabWeekly())}
//               // to="/daily-scheduling"
//               className="btn"
//               // btn btn-primary
//               style={{ backgroundColor: "#9ac748", color: "white" }}
//             >
//               Weekly
//             </button>
//             <button
//               onClick={() => dispatch(tabMonthly())}
//               // to="/daily-scheduling"
//               className="btn"
//             >
//               Monthly
//             </button>
//           </div>
//         </div>
//         <div className="col-md-4">
//           <h3 className="card-title mt-2 text-center">
//             {date.format("dddd") !== "Sunday"
//               ? date.startOf("week").add(1, "days").format("DD MMMM YYYY")
//               : date.startOf("week").add(-6, "days").format("DD MMMM YYYY")}
//             &nbsp; to &nbsp;
//             {date.format("dddd") !== "Sunday"
//               ? date.startOf("week").add(7, "days").format("DD MMMM YYYY")
//               : date.startOf("week").add(0, "days").format("DD MMMM YYYY")}
//             &nbsp;
//           </h3>
//         </div>
//         <div className="col-md-4">
//           <div className="float-right">
//             <a to="#" onClick={onClickPrevWeekly} className="btn btn-primary">
//               <i className="fa fa-chevron-circle-left"></i>
//             </a>
//             <a to="#" onClick={onClickNextWeekly} className="btn btn-primary">
//               <i className="fa fa-chevron-circle-right"></i>
//             </a>
{
  /* <div className="col-auto float-right ml-auto">
            <Link
              to="#"
              className="btn add-btn m-r-5 m-l-5"
              data-toggle="modal"
              onClick={openDropDown}
              data-target="#add_schedule"
            >
              {" "}
              Add Shifts
            </Link>
          </div> */
}
{
  /* <AdminSSAddShiftModal
              showDropDown={showDropDown}
              openDropDown={openDropDown}
            />
          </div>
        </div>
      </div>
      <AdminSSWeeklyTable dateForTableData={today} />
    </>
  ) : myState.viewTheShift === "daily" ? (
    <>
      <div className="row mitblrow">
        <div className="col-md-4">
          <div className="float-left">
            <button
              onClick={() => dispatch(tabDaily())}
              className="btn"
              style={{ backgroundColor: "#9ac748", color: "white" }}
            >
              Daily
            </button>
            <button onClick={() => dispatch(tabWeekly())} className="btn">
              Weekly
            </button>
            <button onClick={() => dispatch(tabMonthly())} className="btn">
              Monthly
            </button>
          </div>
        </div>
        <div className="col-md-4">
          <h3 className="card-title mt-2 text-center">
            {date.format("DD MMM YYYY")}
          </h3>
        </div>
        <div className="col-md-4">
          <div className="float-right">
            <a to="#" onClick={onClickPrevDaily} className="btn btn-primary">
              <i className="fa fa-chevron-circle-left"></i>
            </a>
            <a to="#" onClick={onClickNextDaily} className="btn btn-primary">
              <i className="fa fa-chevron-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <AdminSSDailyTable dateForTableData={today} />
    </>
  ) : myState.viewTheShift === "monthly" ? (
    <>
      <div className="row mitblrow">
        <div className="col-md-4">
          <div className="float-left">
            <button onClick={() => dispatch(tabDaily())} className="btn">
              Daily
            </button>
            <button onClick={() => dispatch(tabWeekly())} className="btn">
              Weekly
            </button>
            <button
              onClick={() => dispatch(tabMonthly())}
              className="btn"
              style={{ backgroundColor: "#9ac748", color: "white" }}
            >
              Monthly
            </button>
          </div>
        </div>
        <div className="col-md-4">
          <h3 className="card-title mt-2 text-center">
            {date.format("MMMM YYYY")}
          </h3>
        </div>
        <div className="col-md-4">
          <div className="float-right">
            <a to="#" onClick={onClickPrevMonthly} className="btn btn-primary">
              <i className="fa fa-chevron-circle-left"></i>
            </a>
            <a to="#" onClick={onClickNextMonthly} className="btn btn-primary">
              <i className="fa fa-chevron-circle-right"></i>
            </a>
          </div>
        </div>
      </div>
      <AdminSSMonthlyTable dateForTableData={today} />
    </>
  ) : myState.viewTheShift === "thesearch" ? (
    <>
      <div className="row mitblrow">
        <div className="col-md-4">
          <div className="float-left">
            <button onClick={() => dispatch(tabDaily())} className="btn">
              Daily
            </button>
            <button onClick={() => dispatch(tabWeekly())} className="btn">
              Weekly
            </button>
            <button onClick={() => dispatch(tabMonthly())} className="btn">
              Monthly
            </button>
          </div>
        </div>
        <div className="col-md-4">
          <h3 className="card-title mt-2 text-center">Searched Data</h3>
        </div>
        <div className="col-md-4"></div>
      </div>
      <ShiftSearchTable />
    </>
  ) : (
    <div>nothing</div>
  );
} */
}

import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeScheduleSearch from '../../components/employeeComponents/employeeSchedule/EmployeeScheduleSearch'
import EmployeeScheduleViewBar from '../../components/employeeComponents/employeeSchedule/EmployeeScheduleViewBar'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeSchedule = () => {
  return (
    <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Shifts"/>
            <EmployeeScheduleSearch />
            <EmployeeScheduleViewBar />
          </div>
        </div>
      </div>
    </>
  )
}
export default EmployeeSchedule
// import React, { forwardRef, useState } from 'react'
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { addShift } from "../../../actions/adminActions";
import moment from "moment";
import { getShiftAllUsers } from "../../../actions/adminActions";
import { useEffect } from "react";
// import DateTimePicker from 'react-datetime-picker'

const AdminSSAddShiftModal = ({
  showDropDown,
  openDropDown,
  current,
  number,
  date,
}) => {
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [theEndDate, setTheEndDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  // console.log(theDate)
  // console.log(theEndDate)
  // console.log(date, number, "yeh date aarahi hai")

  // const StartTime = forwardRef(({ value, onClick }, ref) => (
  //    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //       <div className="cal-icon">
  //          <input className="form-control floating datatimepicker" type="text" value={value}/>
  //       </div>
  //       <label className="focus-label">Start Date</label>
  //    </div>
  // ))
  // const EndTime = forwardRef(({ value, onClick }, ref) => (
  //    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //       <div className="cal-icon">
  //          <input className="form-control floating datatimepicker" type="text" value={value}/>
  //       </div>
  //       <label className="focus-label">End Date</label>
  //    </div>
  // ))

  const locationTemp = useSelector((state) => state.location);
  let location = locationTemp.location;
  // location.forEach(element => {
  //       console.log(element.name)
  // });
  // const breakRulesTemp = useSelector((state) => state.breakrule);
  // let breakRules = breakRulesTemp.breakRules
  const user = useSelector((state) => state.user);
  //   breakRules.forEach(element => {
  //       console.log(element.name)
  //   });
  // const dateMoment = moment(theDate.toISOString().substring(0,19)).format()
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    companyId: "",
    startTime: "",
    endTime: "",
    location: "",
    // breakRules:'',
    userId: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)

  const dispatch = useDispatch();
  const ifCurrent = () => {
    if (current !== undefined) {
      setValues({
        ...values,
        userId: current.user.id,
      });
    }
  };
  const ifDate = () => {
    if (number !== undefined && date !== undefined) {
      setTheDate(
        new Date(
          date.substring(0, 4),
          parseInt(date.substring(5, 7)) - 1,
          parseInt(date.substring(8, 10)) + number
        )
      );
      setTheEndDate(
        new Date(
          date.substring(0, 4),
          parseInt(date.substring(5, 7)) - 1,
          parseInt(date.substring(8, 10)) + number
        )
      );
    }
  };
  useEffect(() => {
    if (current !== undefined) {
      ifCurrent();
    }
  }, [current]);

  useEffect(() => {
    if (number !== undefined && date !== undefined) {
      ifDate();
    }
  }, [number]);
  const shiftData = {
    companyId: auth.user.companyId,
    startTime: moment(theDate).format().substring(0, 19) + "+00:00",
    // startTime: theDate.toLocaleString().substring(0,16),
    endTime: moment(theEndDate).format().substring(0, 19) + "+00:00",
    location: values.location,
    // breakRules: values.breakRules,
    userId: values.userId,
  };
  // console.log(shiftData)
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    if (shiftData.location === "") {
      delete shiftData.location;
    }
    // if (number !== undefined && date !== undefined){
    // dispatch(addShift(shiftData, number))
    // }
    // else {
    dispatch(addShift(shiftData));
    // }
    openDropDown();
    setTimeout(
      () =>
        dispatch(
          getShiftAllUsers({
            companyId: auth.user.companyId,
            date: moment().format("YYYY-MM-DD"),
            limit: 10,
            page: 1,
          })
        ),
      2000
    );
    setValues({
      companyId: "",
      startTime: "",
      endTime: "",
      location: "",
      // breakRules:'',
      userId: "",
    });
  };

  useEffect(() => {
    const tempEndDate = new Date(theDate);
    //  console.log("date==>", theDate, tempEndDate);
    setTheEndDate(new Date(tempEndDate.setHours(tempEndDate.getHours() + 8)));
  }, [theDate]);

  // console.log("dates==>", theDate, theEndDate, number);
  //   console.log("disable before ", moment("Thu Jul 27 2023 00:00:00 GMT+0500 (Pakistan Standard Time)").add(8, "h").toString());
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="add_schedule"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Shifts</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {/* <h4>Create Shift on 22-August</h4> */}
                <div className="row">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="col-form-label">
                        Start Time <span className="text-danger">*</span>
                      </label>
                      <br />
                      <DatePicker
                        selected={theDate}
                        onChange={(date) => setTheDate(date)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        className="colo-icon form-control datetimepicker3"
                        minDate={moment().toDate()}
                      />
                      {/* <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div> */}
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="col-form-label">
                        End Time <span className="text-danger">*</span>
                      </label>
                      <br />
                      <DatePicker
                        selected={theEndDate}
                        onChange={(date) => setTheEndDate(date)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        className="colo-icon form-control datetimepicker3"
                        minDate={theDate}
                      />
                      {/* <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div> */}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <h4 className="mihour">
                      {(
                        (new Date(theEndDate) - new Date(theDate)) /
                        (1000 * 60 * 60)
                      )
                        .toString()
                        .substring(0, 3)}
                      h
                    </h4>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Employee <span className="text-danger">*</span>
                      </label>
                      {/* yahan aaway gah employeeewa ka code */}
                      {current ? (
                        // <input type="text" disabled value={values.}
                        <select
                          className="form-select"
                          style={{ height: "44px", fontSize: "14px" }}
                          autoComplete="false"
                          value={values.userId}
                          onChange={handleChange("userId")}
                          disabled
                        >
                          <option value="">Select</option>
                          {user.allUsersWithoutPaginate &&
                            user.allUsersWithoutPaginate.length > 0 &&
                            user.allUsersWithoutPaginate.map((current, i) => {
                              return (
                                <option value={current.id} key={i + 1}>
                                  {current.firstName + " " + current.lastName}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          style={{ height: "44px", fontSize: "14px" }}
                          autoComplete="false"
                          value={values.userId}
                          onChange={handleChange("userId")}
                        >
                          <option value="">Select</option>
                          {user.allUsersWithoutPaginate &&
                            user.allUsersWithoutPaginate.length > 0 &&
                            user.allUsersWithoutPaginate.map((current, i) => {
                              return (
                                <option value={current.id} key={i + 1}>
                                  {current.firstName + " " + current.lastName}
                                </option>
                              );
                            })}
                        </select>
                      )}
                    </div>
                  </div>
                  {current &&
                    current.user &&
                    current.user.locationRequired === true && (
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="col-form-label">
                            Location <span className="text-danger">*</span>
                          </label>
                          {current ? (
                            current.user &&
                            current.user.location && (
                              <select
                                className="form-select"
                                style={{ height: "44px", fontSize: "14px" }}
                                autoComplete="false"
                                value={values.location}
                                onChange={handleChange("location")}
                              >
                                <option value="">Select</option>
                                {current.user.location &&
                                  current.user.location.length > 0 &&
                                  current.user.location.map((c, i) => {
                                    return (
                                      <option value={c.id} key={i + 1}>
                                        {c.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            )
                          ) : (
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              autoComplete="false"
                              value={values.location}
                              onChange={handleChange("location")}
                            >
                              <option value="">Select</option>
                              {location &&
                                location.length > 0 &&
                                location.map((current, i) => {
                                  return (
                                    <option value={current.id} key={i + 1}>
                                      {current.name}
                                    </option>
                                  );
                                })}
                            </select>
                          )}
                        </div>
                      </div>
                    )}
                  {/* <div className="col-sm-6">
                              <div className="form-group">
                                 <label className="col-form-label">Break Rule <span className="text-danger">*</span></label> */}
                  {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                 autoComplete="false" value={values.breakRules}
                                 onChange={handleChange('breakRules')}>
                                    <option value="">Select</option>
                                    { breakRules && 
                                    breakRules.length > 0 &&
                                    breakRules.map((current, i) => {
                                    return (
                                        <option value={current.id} key={i+1}>{current.breakName}</option>
                                    )
                                    })}                    
                                 </select>
                              </div>
                           </div> */}
                  <div className="col-sm-12 mb-3">
                    <div className="submit-section mt-1">
                      <button className="btn btn-info submit-btn">
                        Create Shift
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSSAddShiftModal;

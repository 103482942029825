import axios from "axios"
import {
    // FILEMANAGER_CREATE_REQUEST,
    // FILEMANAGER_CREATE_SUCCESS,
    // FILEMANAGER_CREATE_FAIL,
    FILEMANAGER_TABLE_REQUEST,
    FILEMANAGER_TABLE_SUCCESS,
    FILEMANAGER_TABLE_FAIL,
    FILEMANAGER_UPDATE_REQUEST,
    FILEMANAGER_UPDATE_SUCCESS,
    FILEMANAGER_UPDATE_FAIL,
    REMOVE_FILEMANAGER_REQUEST,
    REMOVE_FILEMANAGER_SUCCESS,
    REMOVE_FILEMANAGER_FAIL,
    USER_RECENT_FILES_REQUEST,
    USER_RECENT_FILES_SUCCESS,
    USER_RECENT_FILES_FAIL,
    SERVERADDRESS,
} from "../constants"

// ADMIN ADD NEW FILEMANAGER
// export const addPolicy = (obj) => async ( dispatch ) => {
//     dispatch({
//         type: FILEMANAGER_CREATE_REQUEST,
//     })
//     try {
//         const { data } = await axios.post(`${SERVERADDRESS}/v1/filemanager/createPolicy`, obj)
//     dispatch({
//         type: FILEMANAGER_CREATE_SUCCESS,
//         payload: data,
//     })
// }
// catch (err) {
//     console.log(err)
//     dispatch({
//         type: FILEMANAGER_CREATE_FAIL,
//         payload: err,
//     })
// }
// }


// ADMIN GET FILEMANAGER TABLE
export const getFileCompany = (obj) => async (dispatch) => {
    dispatch({
        type: FILEMANAGER_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/filemanager/getFileCompany`, obj);
        localStorage.setItem("filemanagerTable", JSON.stringify(data));
        dispatch({
            type: FILEMANAGER_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: FILEMANAGER_TABLE_FAIL,
            payload: err,
        });
    }
};



// UPDATE FILEMANAGER
export const updateFile = (obj) => async (dispatch) => {
    dispatch({
        type: FILEMANAGER_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/filemanager/updateFile`, obj);
        dispatch({
            type: FILEMANAGER_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: FILEMANAGER_UPDATE_FAIL,
            payload: err,
        });
    }
};


// REMOVE FILE BY ADMIN
export const deleteFile = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_FILEMANAGER_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/filemanager/deleteFile`, obj);
        dispatch({
            type: REMOVE_FILEMANAGER_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_FILEMANAGER_FAIL,
            payload: err,
        });
    }
};


// EMPLOYEE GET RECENT FILES
export const userRecentFiles = (obj) => async (dispatch) => {
    dispatch({
        type: USER_RECENT_FILES_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/filemanager/userRecentFiles`, obj);
        localStorage.setItem("filemanagerTable", JSON.stringify(data));
        dispatch({
            type: USER_RECENT_FILES_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: USER_RECENT_FILES_FAIL,
            payload: err,
        });
    }
};

import { useEffect, useState } from "react";
import React from "react";
import { NavLink } from "react-router-dom";
// import { getCompanyLeaveCategory, getLeaveByCompanyId, getLeaveStatus, getLeaveStatusUsers } from '../../../actions/leaveActions'
import {
  getCompanyLeaveCategory,
  getLeaveByCompanyId,
  getLeaveStatusUsers,
} from "../../../actions/leaveActions";
import { useDispatch, useSelector } from "react-redux";
import AdminLeaveRow from "./AdminLeaveRow";
import moment from "moment";
// import moment from 'moment'
// import { findAllByTestId } from '@testing-library/react'

const AdminLeavesTable = () => {
  // const user = useSelector((state) => state.user)
  const auth = useSelector((state) => state.auth);
  const leave = useSelector((state) => state.leave);
  // let val = 1
  // let pageNumber = 1
  const [dropDownRow, setDropDownRow] = useState(null);
  const [closeDropDownRow, setCloseDropDownRow] = useState(null);

  const dispatch = useDispatch();
  const leaveDisplay = async (e) => {
    dispatch(
      getLeaveByCompanyId({
        companyId: auth.user.companyId,
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  // takay leave ksy type search krskhein
  const leaveSetting = async (e) => {
    dispatch(getCompanyLeaveCategory({ companyId: auth.user.companyId }));
  };
  // leaveReportUsers
  const leaveReportUsers = async (e) => {
    dispatch(
      getLeaveStatusUsers({
        companyId: auth.user.companyId,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  useEffect(() => {
    leaveDisplay();
    leaveSetting();
    leaveReportUsers();
  }, []);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getLeaveByCompanyId({
        companyId: auth.user.companyId,
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= leave.leaveDisplay.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getLeaveByCompanyId({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  // console.log(currentPageNumber)
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getLeaveByCompanyId({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getLeaveByCompanyId({
        companyId: auth.user.companyId,
        limit: limit,
        page: pageNo,
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              Show &nbsp;
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="select"
                autoComplete="false"
                onChange={handleLimit}
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return <option value={current}>{current}</option>;
                })}
              </select>{" "}
              entries
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th>
                  <div className="th-content">
                    <span>Employee</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Leave Type</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>From</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>To</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                {/* <th>Leave Type</th> */}
                {/* <th>From</th>
                <th>To</th> */}
                <th>No of Days</th>
                <th>Reason</th>
                {/* <th className="text-center">Status</th> */}
                <th>
                  <div className="th-content">
                    <span>Status</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {leave.leaveDisplay &&
                leave.leaveDisplay.results &&
                leave.leaveDisplay.results.length > 0 &&
                leave.leaveDisplay.results.map((current, i) => {
                  return (
                    <AdminLeaveRow
                      current={current}
                      i={i}
                      dropDownRow={dropDownRow}
                      setDropDownRow={setDropDownRow}
                      closeDropDownRow={closeDropDownRow}
                      setCloseDropDownRow={setCloseDropDownRow}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <div className="row">
        {leave.leaveDisplay && (
          <div className="col-sm-12 col-md-5">
            <label>
              {" "}
              Showing {1 + limit * (currentPageNumber - 1)} to{" "}
              {leave.leaveDisplay.displayingResults +
                limit * (currentPageNumber - 1)}{" "}
              of {leave.leaveDisplay.totalResults} entries{" "}
            </label>
          </div>
        )}
        <div className="col-sm-12 col-md-7">
          <div className="avatar-pagination">
            <ul className="pagination" style={{ justifyContent: "flex-end" }}>
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  onClick={onClickPrev}
                  style={{ cursor: "pointer" }}
                >
                  <span aria-hidden="true">Previous</span>
                  <span className="sr-only">Previous</span>
                </NavLink>
              </li>
              {leave.leaveDisplay &&
                leave.leaveDisplay.paginationArr &&
                leave.leaveDisplay.paginationArr.length > 0 &&
                leave.leaveDisplay.paginationArr.map((current, i) => {
                  return currentPageNumber === current ? (
                    <li className="page-item active" key={i}>
                      <a className="page-link" value={current}>
                        {current}
                      </a>
                    </li>
                  ) : (
                    <li className="page-item" key={i}>
                      <a
                        className="page-link"
                        value={current}
                        onClick={() => handleChangeData(current)}
                      >
                        {current}
                      </a>
                    </li>
                  );
                })}
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Next"
                  onClick={onClickNext}
                  style={{ cursor: "pointer" }}
                >
                  <span aria-hidden="true">Next</span>
                  <span className="sr-only">Next</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLeavesTable;

{
  /* {
        leave.leaveDisplay &&
        leave.leaveDisplay.length > 0 &&
        leave.leaveDisplay.map((current, i) => {
            return(
                <tr key={i}> 
                <td>
                    <h2 className="table-avatar">
                        <a href="profile.html" className="avatar"><img alt="" src="assetsAdmin/img/avatar-09.jpg" /></a>
                        <a href="#"> {current.userId.firstName + " " + current.userId.lastName} <span>{current.userId.jobTitle}</span></a>
                    </h2>
                </td>
                <td>{current.leaveType}</td>
                <td>{moment(current.from).format("DD MMMM YYYY")}</td>
                <td>{moment(current.to).format("DD MMMM YYYY")}</td>
                <td>{current.numberOfDays} days</td>
                <td>{current.leaveReason}</td>
                <td className="text-center">
                    <div className="dropdown action-label">
                        <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" onClick={openApproveDropDown} aria-expanded="false">
                            <i className="fa fa-dot-circle-o text-purple"></i> {current.status}
                        </a>
                        <div className={`dropdown-menu dropdown-menu-right ${showApproveDropDown ? 'show' : 'hide'}`}>
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-purple"></i> New</a>
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Pending</a>
                            <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#approve_leave"><i className="fa fa-dot-circle-o text-success"></i> Approved</a>
                            <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Declined</a>
                        </div>
                    </div>
                </td>
            
                <td className="text-right">
                    <div className="dropdown dropdown-action">
                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                            <a className="dropdown-item"  href="#" data-toggle="modal" onClick={openEditModal} data-target="#edit_leave"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                            <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                            < showEditModal={showEditModal} openEditModal={openEditModal}/>
                            < showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} />
                        </div>
                    </div>
                </td>
            </tr>
    )})} */
}

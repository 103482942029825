// frontend login


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { getUserByToken, userLogin } from '../../actions/authActions';

const LoginForm = () => {
  // const [isError, setisError] = useState(false)
  const auth = useSelector((state) => state.auth);
  let navigate = useNavigate()
    // const navigateUser = () => {
    //     navigate('/dashboard')
    // }
  const dispatch = useDispatch()
  const chkUser = async () => {
    dispatch(getUserByToken(auth.tokens.refresh.token, navigate))
  }
  useEffect(() => {
    if (localStorage.tokens === undefined){
      // console.log("empty")
    }
    else {
      chkUser()
    }
  },[])
  const [values, setValues] = useState({
    email: '',
    password: ''
  })
  // let role = ["admin", "employee", "superadmin"]
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values, [fieldName]:
        event.target.value
    })
  }
  const userData = {
    email: values.email,
    password: values.password
  }
  // console.log(userData)
  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(userLogin(userData, navigate))
  }
  return (
    <>
      <div className="form-section">
        <div className="wrapper-login">
          <div id="formContent-login">
            {/* <!-- Tabs Titles --> */}
            {/* <!-- Login Form --> */}
            <form onSubmit={handleSubmit}>
              <h2 className="text-center h2-tag-login"
              style={{
                textAlign: "center",
                fontSize:"25px",
                fontWeight: "600",
                textTransform: "uppercase",
                display:"inline-block",
                margin: "40px 8px 10px 8px",
                color:"#32895E"}}>Sign In</h2>
              
              <input type="email" id="login" className="testing-login-input" name="login" placeholder="Username" 
              autoComplete="false" value={values.email}
              onChange={handleChange('email')}/>
              
              <input type="password" id="password" className="testing-login-input" name="login" placeholder="Password" 
              value={values.password}
              onChange={handleChange('password')}
              autoComplete="false"/>

              <input type="submit" className="testing-login-input" value="Sign In" />
              {/* {
                auth.error &&
                <span className="text-danger" > *Invalid email or password*</span>
              } */}

              {/* <button className="testing-login-input" type="submit">Login</button> */}
            </form>
            {/* <!-- Remind Passowrd --> */}
            <div id="formFooter-login">
              <NavLink to="/identify" className="underlineHover-login a-tag-login"
              style={{
                color: "#92badd",
                display:"inline-block",
                textDecoration: "none",
                fontWeight: "400"
              }}>Forgot Password?</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LoginForm
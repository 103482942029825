import React, { useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../../actions/authActions'
import { tabLinkSendToUser } from '../../../actions/tabActions';
// import { NavLink } from 'react-router-dom'
// import { useEffect } from 'react'
const IdentifyUserEmailForm = () => {
    // const auth = useSelector((state) => state.auth);
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        email: '',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const userData = {
        email: values.email,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(forgotPassword(userData, navigate))
        dispatch(tabLinkSendToUser())
    }
    // loginform ka code liya hai but yeh use nh kiya abh.....
    // const chkUser = async () => {
    //   dispatch(getUserByToken(auth.tokens.refresh.token, navigate))
    // }
    // useEffect(() => {
    //   if (localStorage.tokens === undefined){
    //       console.log("empty")
    //   }
    //   else {
    //       chkUser()
    //   }
    // },[])
    return (
        <>
            <div className="form-section">
                <div className="wrapper-login">
                    <div id="formContent-login">
                        <form onSubmit={handleSubmit}>
                            <h2 className="text-center h2-tag-login"
                                style={{
                                    textAlign: "center",
                                    fontSize: "25px",
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                    display: "inline-block",
                                    margin: "40px 8px 10px 8px",
                                    color: "#32895E"
                                }}>Find Your Account</h2>
                            <h5>Enter your email to reset your password.</h5>
                            <br />
                            <input type="email" id="login" className="testing-login-input" name="login" placeholder="Username"
                            autoComplete="false" value={values.email}
                            onChange={handleChange('email')} 
                            />
                            <br />
                            <br />
                            {/* <NavLink to="#requestSend"> */}
                                <input type="submit" className="testing-login-input" value="Submit"/>
                            {/* </NavLink> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default IdentifyUserEmailForm
import axios from "axios"
import {
    SUPER_ADMIN_COMPANY_REQUEST,
    SUPER_ADMIN_COMPANY_SUCCESS,
    SUPER_ADMIN_COMPANY_FAIL,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS,
    SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    NEWLEAD_REQUEST,
    NEWLEAD_SUCCESS,
    NEWLEAD_FAIL,
    CREATE_NEWLEAD_REQUEST,
    CREATE_NEWLEAD_SUCCESS,
    CREATE_NEWLEAD_FAIL,
    CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAIL,
    SUBSCRIPTION_REQUEST,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAIL,
    SUBSCRIPTION_UPDATE_REQUEST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS,
    SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL,
    SUPER_ADMIN_DASHBOARD_CARD_REQUEST,
    SUPER_ADMIN_DASHBOARD_CARD_SUCCESS,
    SUPER_ADMIN_DASHBOARD_CARD_FAIL,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS,
    SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL,
    SUPER_ADMIN_SEARCH_COMPANY_REQUEST,
    SUPER_ADMIN_SEARCH_COMPANY_SUCCESS,
    SUPER_ADMIN_SEARCH_COMPANY_FAIL,
    // REMOVE_COMPANY_REQUEST,
    // REMOVE_COMPANY_SUCCESS,
    // REMOVE_COMPANY_FAIL,
    SERVERADDRESS,
} from "../constants"

export const getSuperAdminCompany = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: SUPER_ADMIN_COMPANY_REQUEST,
    });
    try {
        const { data } = await axios.get(`${SERVERADDRESS}/v1/company/getCompany`, obj);
        localStorage.setItem("superAdminCompanyCC", JSON.stringify(data));
        dispatch({
            type: SUPER_ADMIN_COMPANY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SUPER_ADMIN_COMPANY_FAIL,
            payload: err,
        });
    }
};

export const getSAdminCompanyById = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/company/companyById`, obj);
        localStorage.setItem("superAdminClientProfileCover", JSON.stringify(data));
        dispatch({
            type: SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL,
            payload: err,
        });
    }
};


// UPDATE COMPANY 
export const updateCompany = (obj) => async (dispatch) => {
    dispatch({
        type: UPDATE_COMPANY_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/company/updateCompany`, obj);
        dispatch({
            type: UPDATE_COMPANY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: UPDATE_COMPANY_FAIL,
            payload: err,
        });
    }
};


// COMPANY NEWLEAD
export const getAllNewLead = (obj) => async (dispatch) => {
    dispatch({
        type: NEWLEAD_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/newLead/getAllNewLead`, obj);
        localStorage.setItem("newLead", JSON.stringify(data));
        dispatch({
            type: NEWLEAD_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: NEWLEAD_FAIL,
            payload: err,
        });
    }
};

// COMPANY CREATE NEWLEAD
export const createNewLead = (obj, navigateUser) => async (dispatch) => {
    dispatch({
        type: CREATE_NEWLEAD_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/newLead/createNewLead`, obj);
        dispatch({
            type: CREATE_NEWLEAD_SUCCESS,
            payload: data,
        });
        console.log(data)
        try {
            await axios.post(`${SERVERADDRESS}/v1/auth/send-verification-email-newlead`, {_id:data.id, email: data.email});
        } catch (error) {
            console.log(error)
        }
        navigateUser();
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: CREATE_NEWLEAD_FAIL,
            payload: err,
        });
    }
};

// SUPER ADMIN CREATE COMPANY AND ADMIN
export const createCompanyAndAdmin = (obj) => async (dispatch) => {
    dispatch({
        type: CREATE_COMPANY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/company/admin`, obj);
        // localStorage.setItem("newLead", JSON.stringify(data));
        dispatch({
            type: CREATE_COMPANY_SUCCESS,
            payload: data,
        });
        // console.log(data, "yeh ho raha hai")
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: CREATE_COMPANY_FAIL,
            payload: err,
        });
    }
};


// SUPER ADMIN ALL SUBSCRIPTION
export const getStripeSubscriptionsAllCompanies = () => async (dispatch) => {
    dispatch({
        type: SUBSCRIPTION_REQUEST,
    });
    try {
        const { data } = await axios.get(`${SERVERADDRESS}/v1/userPaymentInfo/getStripeSubscriptionsAllCompanies`,);
        localStorage.setItem("subscriptionData", JSON.stringify(data));
        dispatch({
            type: SUBSCRIPTION_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: SUBSCRIPTION_FAIL,
            payload: err,
        });
    }
};

// SUPER ADMIN UPDATE SUBSCRIPTION
export const updateStripeSubscription = (obj) => async (dispatch) => {
    dispatch({
        type: SUBSCRIPTION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/userPaymentInfo/updateStripeSubscription`, obj);
        // localStorage.setItem("subscriptionData", JSON.stringify(data));
        dispatch({
            type: SUBSCRIPTION_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: SUBSCRIPTION_UPDATE_FAIL,
            payload: err,
        });
    }
};


export const getStripeInovicesQueryAllCompanies = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST,
    });
    try {
        const { data } = await axios.get(`${SERVERADDRESS}/v1/userPaymentInfo/getStripeInovicesQueryAllCompanies`, obj);
        localStorage.setItem("StripeInovicesQueryAllCompanies", JSON.stringify(data));
        dispatch({
            type: SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL,
            payload: err,
        });
    }
};


// COMPANY NEWLEAD
export const cardsDetails = (obj) => async (dispatch) => {
    dispatch({
        type: SUPER_ADMIN_DASHBOARD_CARD_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/superkpi/cardsDetails`, obj);
        localStorage.setItem("SAdminCardsDetails", JSON.stringify(data));
        dispatch({
            type: SUPER_ADMIN_DASHBOARD_CARD_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: SUPER_ADMIN_DASHBOARD_CARD_FAIL,
            payload: err,
        });
    }
};

// COMPANY ADMIN LOGIN INFO
export const getAllAdminLoginInfo = (obj) => async (dispatch) => {
    dispatch({
        type: SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST,
    });
    try {
        const { data } = await axios.get(`${SERVERADDRESS}/v1/userLoginInfo/getAllAdminLoginInfo`, obj);
        localStorage.setItem("adminLoginInfo", JSON.stringify(data));
        dispatch({
            type: SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL,
            payload: err,
        });
    }
};


// SUPER ADMIN SEARCH COMPANY
export const SAdminCompanySearchFilter = (obj) => async ( dispatch ) => {
    dispatch({
        type: SUPER_ADMIN_SEARCH_COMPANY_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/superkpi/companySearchFilter`, obj)
        localStorage.setItem("searchCompany", JSON.stringify(data));
    dispatch({
        type: SUPER_ADMIN_SEARCH_COMPANY_SUCCESS,
        payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: SUPER_ADMIN_SEARCH_COMPANY_FAIL,
        payload: err,
    })
}
}

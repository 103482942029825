import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { BUCKET_BASE_URL } from "../../../constants";
import cake from "../../../assetsAdmin/img/cakeundraw_Birthday_cake_re_bsw5.png";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";

const AllLateComersModal = ({
  showLateDropDown,
  openLateDropDown,
  current,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString.substring(11, 13));
    const minutes = timeString.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showLateDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${
          showLateDropDown ? "show" : "hide"
        }`}
        id="delete_warn"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openLateDropDown(); // Call your close modal function
          }
        }}
      >
        {/* modal custom-modal fade */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Late Comers</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => openLateDropDown()}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  {admin.LateComing && admin.LateComing.length > 0 ? (
                    admin.LateComing.map((current, i) => {
                      return (
                        <div key={i} className="leave-info-box">
                          <div className="media align-items-center">
                            <NavLink to="/profile" className="avatar">
                              {current.user &&
                              current.user.userId &&
                              current.user.userId.profilePic &&
                              current.user.userId.profilePic.fileUrl ? (
                                <img
                                  alt="profilePic"
                                  src={
                                    BUCKET_BASE_URL +
                                    current.user.userId.profilePic.fileUrl
                                  }
                                  style={{
                                    objectFit: "fill",
                                    height: "100%",
                                  }}
                                />
                              ) : (
                                <img alt="profilePic" src={defaultImg} />
                              )}
                            </NavLink>
                            <div className="media-body">
                              <div className="text-sm my-0">
                                {current.user.userId.firstName}{" "}
                                {current.user.userId.lastName}
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mt-3">
                            <div className="col-6">
                              <h6 className="mb-0">
                                {current.user
                                  ? current.user?.location?.name
                                  : ""}
                              </h6>
                              <span className="text-sm text-muted">
                                Timings:{" "}
                                {current.user
                                  ? formatTime(current.user.startTime) +
                                    " - " +
                                    formatTime(current.user.endTime)
                                  : "without shift"}
                              </span>
                            </div>
                            <div className="col-6 text-right">
                              <span className="badge bg-danger text-white">
                                {current.late} late
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="dash-stats-list text-center">
                      <img
                        alt=""
                        src={cake}
                        style={{ width: "110px", opacity: "0.8" }}
                      />
                      <br />
                      <p style={{ opacity: "0.5" }}>No late comers</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllLateComersModal;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import EditModalEmployeeSalaryTable from "./EditModalEmployeeSalaryTable";
import moment from "moment/moment";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const SearchPEmployeeSalariesRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
    setshowMoreVertDropDown(false);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
    setshowMoreVertDropDown(false);
  };
  return (
    <>
      <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
        <td>
          <h2 className="table-avatar">
            <NavLink to={`/profile/${current.userId.id}`} className="avatar">
              {current.userId.profilePic &&
              current.userId.profilePic.fileUrl ? (
                <img
                  alt="profilePic"
                  src={BUCKET_BASE_URL + current.userId.profilePic.fileUrl}
                  style={{ objectFit: "fill", height: "100%" }}
                />
              ) : (
                <img alt="profilePic" src={defaultImg} />
              )}
            </NavLink>
            <NavLink to={`/profile/${current.userId.id}`}>
              {current.userId.firstName + " " + current.userId.lastName}
              <span>{current.userId.jobTitle}</span>
            </NavLink>
          </h2>
        </td>
        <td>{current.userId.employeeId}</td>
        <td>{current.userId.phone}</td>
        <td>{moment(current.userId.startingDate).format("MM/DD/YYYY")}</td>
        <td>${current.salaryThisMonth}</td>
        <td>
          <NavLink
            className="btn btn-sm btn-primary"
            to={`/salary-view/${current.id}`}
          >
            Generate Slip
          </NavLink>
        </td>
        <td className="text-right">
          <div className="dropdown dropdown-action">
            <NavLink
              to="#"
              className="action-icon dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={() => {
                openMoreVertDropDown();
                setDropDownRow(i);
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="material-icons">more_vert</i>
            </NavLink>
            {dropDownRow === i && (
              <>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    showMoreVertDropDown ? "show" : "hide"
                  }`}
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    onClick={openDropDown}
                    data-target="#edit_salary"
                  >
                    <i className="fa fa-pencil m-r-5"></i> Edit
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    onClick={openDeleteDropDown}
                    data-target="#delete_approve"
                  >
                    <i className="fa fa-trash-o m-r-5"></i> Delete
                  </a>
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
      <EditModalEmployeeSalaryTable
        current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default SearchPEmployeeSalariesRow;

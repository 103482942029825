import React, { useEffect } from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import InboxMessages from '../../components/adminComponents/InboxMessages'
import { getMailByUserId } from '../../actions/mailActions'
import { useDispatch, useSelector } from 'react-redux'
const ViewInbox = () => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const mailData = async () => {
        dispatch(getMailByUserId({userId: auth.user.id}))
    }
    useEffect(() => {
        mailData()
    },[])
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Inbox" ComposeMessage/>
                        <InboxMessages />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewInbox
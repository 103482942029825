import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getAllMonthlySalaryByCompanyId } from "../../../actions/payrollAction";
import { useDispatch, useSelector } from "react-redux";
import PEmployeeSalariesRow from "./PEmployeeSalariesRow";
const PEmployeeSalariesTable = () => {
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const payroll = useSelector((state) => state.payroll);
  const [dropDownRow, setDropDownRow] = useState(null);
  const dispatch = useDispatch();

  const salaryCalender = async (e) => {
    dispatch(
      getAllMonthlySalaryByCompanyId({
        companyId: auth.user.companyId,
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  useEffect(() => {
    salaryCalender();
  }, []);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getAllMonthlySalaryByCompanyId({
        companyId: auth.user.companyId,
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };

  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= payroll.salaryCalender.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllMonthlySalaryByCompanyId({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  // console.log(currentPageNumber)
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllMonthlySalaryByCompanyId({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllMonthlySalaryByCompanyId({
        companyId: auth.user.companyId,
        limit: limit,
        page: pageNo,
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              Show &nbsp;
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="select"
                autoComplete="false"
                onChange={handleLimit}
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return <option value={current}>{current}</option>;
                })}
              </select>{" "}
              entries
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table datatable">
            <thead>
              <tr>
                {/* <th>Employee</th>
                <th>Employee ID</th> */}
                <th>
                  <div className="th-content">
                    <span>Employee</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Employee ID</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>Phone</th>
                {/* <th>Join Date</th> */}
                <th>
                  <div className="th-content">
                    <span>Join Date</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Salary</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                {/* <th>Salary</th> */}
                <th>Payslip</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {payroll.salaryCalender &&
                payroll.salaryCalender.results &&
                payroll.salaryCalender.results.length > 0 &&
                payroll.salaryCalender.results.map((current, i) => {
                  return (
                    current.delete === false && (
                      <PEmployeeSalariesRow
                        current={current}
                        i={i}
                        dropDownRow={dropDownRow}
                        setDropDownRow={setDropDownRow}
                      />
                    )
                  );
                })}
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </div>
      <div className="row">
        {payroll.salaryCalender && (
          <div className="col-sm-12 col-md-5">
            <label>
              {" "}
              Showing {1 + limit * (currentPageNumber - 1)} to{" "}
              {payroll.salaryCalender.displayingResults +
                limit * (currentPageNumber - 1)}{" "}
              of {payroll.salaryCalender.totalResults} entries{" "}
            </label>
          </div>
        )}
        <div className="col-sm-12 col-md-7">
          <div className="avatar-pagination">
            <ul className="pagination" style={{ justifyContent: "flex-end" }}>
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  onClick={onClickPrev}
                >
                  <span aria-hidden="true">Previous</span>
                  <span className="sr-only">Previous</span>
                </NavLink>
              </li>
              {payroll.salaryCalender &&
                payroll.salaryCalender.paginationArr &&
                payroll.salaryCalender.paginationArr.length > 0 &&
                payroll.salaryCalender.paginationArr.map((current, i) => {
                  return currentPageNumber === current ? (
                    <li className="page-item active" key={i}>
                      <a className="page-link" value={current}>
                        {current}
                      </a>
                    </li>
                  ) : (
                    <li className="page-item" key={i}>
                      <a
                        className="page-link"
                        value={current}
                        onClick={() => handleChangeData(current)}
                      >
                        {current}
                      </a>
                    </li>
                  );
                })}
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Next"
                  onClick={onClickNext}
                >
                  <span aria-hidden="true">Next</span>
                  <span className="sr-only">Next</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default PEmployeeSalariesTable;

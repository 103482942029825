import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContactUs } from '../../../actions/contactusActions'
import SuperAdminContactUsQueriesTableRow from './SuperAdminContactUsQueriesTableRow'

const SuperAdminContactUsQueriesTable = () => {
  const auth = useSelector((state) => state.auth)
  const contactus = useSelector((state) => state.contactus)
  const [ dropDownRow, setDropDownRow ] = useState(null)
  const dispatch = useDispatch()
  const contactusData = async (e) => {
    dispatch(getContactUs({ companyId: auth.user.companyId }))
  }
  useEffect(()=>{
    contactusData()
  }, [])
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th style={{ width: "30px" }}></th>
                <th>Name </th>
                <th>Email </th>
                <th>Contact </th>
                <th>Subject </th>
                <th>Message </th>
                <th>Date </th>
                <th className="text-right">Details</th>
              </tr>
            </thead>
            <tbody>
              {
            contactus.contactusTable &&
            contactus.contactusTable.results &&
            contactus.contactusTable.results.length > 0 &&
            contactus.contactusTable.results.map((current, i) => {
              return (
                <SuperAdminContactUsQueriesTableRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default SuperAdminContactUsQueriesTable
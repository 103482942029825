import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMailByUserId } from "../../actions/mailActions";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminInboxComponent from "../../components/superAdminCompoents/superAdminMessageCompoents/SuperAdminInboxComponent";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
const SuperAdminInbox = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const mailData = async () => {
    dispatch(getMailByUserId({ userId: auth.user.id }));
  };
  useEffect(() => {
    mailData();
  }, []);
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="mail-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle
              // title="Inbox"
              SuperAdminComposeMessage
              SAdminComposeMsgAllAdmin
              SAdminComposeMsgAllUsers
            />
            <SuperAdminInboxComponent />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminInbox;

import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAdminLoginInfo } from '../../../actions/superAdminCompanyActions'
import AdminLoginInfoTable from './AdminLoginInfoTable'
// import AdminLoginInfoAccordion from './AdminLoginInfoAccordion'
// import moment from 'moment'
const AdminLoginInfoComponent = () => {
    const dispatch = useDispatch()
    const adminLoginInfoData = async () => {
        dispatch(getAllAdminLoginInfo())
    }
    useEffect(() => {
        adminLoginInfoData()
    }, [])    
    const company = useSelector((state) => state.company)
    return (
        <>
            <div className="row">
                <div className="col-md-12 d-flex">
                    <div className="card card-table flex-fill">
                        <div className="card-header">
                            <h3 className="card-title mb-0">Logged-in Admin Information</h3>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-nowrap custom-table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Company</th>
                                            <th>Admin Name</th>
                                            <th>User Type</th>
                                            <th>Country</th>
                                            <th>Ip Address</th>
                                            <th>Date & Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        company.adminLoginInfo &&
                                        company.adminLoginInfo.length > 0 &&
                                        company.adminLoginInfo.map((current, i) => {
                                            return (
                                                <AdminLoginInfoTable current={current} key={i} />
                                                // <AdminLoginInfoAccordion current={current} key={i}/>
                                            )
                                        }) 
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminLoginInfoComponent

{/* {
        company.adminLoginInfo &&
        company.adminLoginInfo.length > 0 &&
        company.adminLoginInfo.map((current, i) => {
            return (
                <tr>
                    <td>{current.companyId.companyName}</td>
                    <td>{current.userId.firstName + " " + current.userId.lastName}</td>
                    <td>{current.userId.role}</td>
                    <td>{current.country}</td>
                    <td>{current.ipAddress}</td>
                    <td>{moment(current.date.toString().substring(0,10)).format('MMMM Do YYYY') +", "} {current.date.substring(11, 16)} {parseInt(current.date.substring(11, 13)) > 12 ? "PM" : "AM"}</td>
                </tr>
            )
        }) 
    } */}
// import { NavLink } from 'react-router-dom'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSalaryByUserId } from '../../../actions/salaryActions'
import ProfileAllCards from './ProfileAllCards'
import ProfileLocation from './ProfileLocation'
import SalaryInfo from './SalaryInfo'
import SalaryInfoViewFromAdmin from './SalaryInfoViewFromAdmin'
import SalaryStructure from './SalaryStructure'

const ProfileBar = () => {
    const salary = useSelector((state) => state.salary)
    const [ viewBarItems, setViewBarItems ] = useState("profile")
    const handleProfile = (e) => {
        setViewBarItems("profile")
    }
    const handleLocations = (e) => {
        setViewBarItems("locations")
    }
    // const handleBank = (e) => {
    //     setViewBarItems("bank")
    // }
    const handleSalaryStructure = (e) => {
        setViewBarItems("salarystructure")
    }
    const params = useParams()
    const dispatch = useDispatch()
    const salaryData = async (e) => {
        dispatch(getSalaryByUserId({userId: params.id}))
    }
    useEffect(() => {
       salaryData()
    },[])

    // console.log(typeof(salary.salary), salary.salary === "", "ssssssssssssssssssssssssss")
    return (
        <>
        { 
        viewBarItems === "profile" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link active">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link">Locations</a >
                        </li>
                        {/* <li className="nav-item">
                            <a to="#bank_statutory" 
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</a >
                        </li> */}
                        <li className="nav-item">
                            <a to="#salary_structure" 
                            style={{cursor: "pointer"}}
                            onClick={handleSalaryStructure}
                            className="nav-link">Salary Structure </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ProfileAllCards />
        </>
        :
        viewBarItems === "locations" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" 
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link active">Locations</a >
                        </li>
                        {/* <li className="nav-item">
                            <a to="#bank_statutory" 
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</a >
                        </li> */}
                        <li className="nav-item">
                            <a to="#salary_structure" 
                            style={{cursor: "pointer"}}
                            onClick={handleSalaryStructure}
                            className="nav-link">Salary Structure </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ProfileLocation />
        </>
        :
        viewBarItems === "bank" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            // data-toggle="tab" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link">Locations</a >
                        </li>
                        {/* <li className="nav-item">
                            <a to="#bank_statutory" 
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link  active">Bank &amp; Statutory</a >
                        </li> */}
                        <li className="nav-item">
                            <a to="#salary_structure" 
                            style={{cursor: "pointer"}}
                            onClick={handleSalaryStructure}
                            className="nav-link">Salary Structure </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <SalaryInfo />
        </>
        :
        viewBarItems === "salarystructure" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link">Profile</a>
                        </li>
                        <li className="nav-item"><a to="#emp_Locations" 
                            // data-toggle="tab" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link">Locations</a >
                        </li>
                        {/* <li className="nav-item">
                            <a to="#bank_statutory" 
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</a >
                            </li> */}
                        <li className="nav-item">
                            <a to="#salary_structure" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleSalaryStructure}
                            className="nav-link active">Salary Structure </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {
            salary.salary === "" ?

            <SalaryStructure />
            :
            <SalaryInfoViewFromAdmin />
        }
        </>
        :
        <div></div>
        }
        </>
    )
}
export default ProfileBar
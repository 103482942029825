import React from 'react'
import plan from '../../assetsgeneral/images/plans.png'
const OurWorkBanner = () => {
    return (
        <>
            <div className="slider-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="work-schedule work-about">
                                <h2>Our Plans</h2>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="work-schedule-img">
                                <img src={plan} id="sli-img-se" alt='banner'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurWorkBanner
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tabOurPlans } from '../actions/tabActions'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import Compare from '../components/ourWorkComponents/Compare'
import OurWorkBanner from '../components/ourWorkComponents/OurWorkBanner'
import PageTitlePricing from '../components/ourWorkComponents/PageTitlePricing'
const OurPlans = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabOurPlans())
  }, [])
  return (
    <>
      <NavBar />
      <OurWorkBanner />
      <PageTitlePricing />
      <Compare />
      <Footer />
    </>
  )
}
export default OurPlans
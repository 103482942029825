import React from 'react'
// import { useSelector } from 'react-redux'
// import moment from 'moment'
// import { NavLink } from 'react-router-dom'
const SuperAdminPlanTransaction = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-table mb-0">
            <div className="card-header">
              <h4 className="card-title mb-0">Plan Details</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Plan</th>
                      <th>Plan Type</th>
                      <th>Create Date</th>
                      <th>Modified Date</th>
                      <th>Amount</th>
                      <th>Subscribed Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Small Business</td>
                      <td>Monthly</td>
                      <td>9 Nov 2019</td>
                      <td>8 Dec 2019</td>
                      <td>$5</td>
                      <td><a className="btn btn-info btn-sm" href="subscribed-companies.html">30 Users</a></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SuperAdminPlanTransaction
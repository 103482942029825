import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import { useSelector } from 'react-redux'
import PageMenu from '../../components/adminComponents/adminPayrollItems/PageMenu'
import PEmployeeSalariesFilterandSearch from '../../components/adminComponents/adminPayrollItems/PEmployeeSalariesFilterandSearch'
import PEmployeeSalariesTable from '../../components/adminComponents/adminPayrollItems/PEmployeeSalariesTable'
import SearchPEmployeeSalariesTable from '../../components/adminComponents/adminPayrollItems/SearchPEmployeeSalariesTable'

const AdminPIView = () => {
    const myState = useSelector((state => state.changeTheTab))

    return (
        <>
        {/* <AdminHeader />
        <AdminSideBar /> */}
        <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content container-fluid">
                    {
                    myState.viewPayrollMenu === "payrollitem" ?
                    <>
                    <AdminTittle title="Payroll" payroll/>
                    <PageMenu />                    
                    </> 
                    : myState.viewPayrollMenu === "employeesalaries" ?
                    <>
                    <AdminTittle title="Employee Salaries" payroll/>
                    <PEmployeeSalariesFilterandSearch />
                    <PEmployeeSalariesTable />
                    </>
                    : myState.viewPayrollMenu === "search" ?
                    <>
                    <AdminTittle title="Employee Salaries" payroll/>
                    <PEmployeeSalariesFilterandSearch />
                    <SearchPEmployeeSalariesTable />
                    </>
                    :
                    <div>Loading</div>
                    }
          </div>
        </div>
      </div> 
    </>
    )
}
export default AdminPIView
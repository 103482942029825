import React from "react";
import ChatComponent from "../../../components/chatComponent";

function AdminChatPage() {
  return (
    <>
      {/* <AdminHeader />
      <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper chat-page " style={{ height: "100vh" }}>
            <div class="container ">
            <br />
              <ChatComponent />
            </div> 
        </div>
      </div>
    </>
  );
}

export default AdminChatPage;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getPackage } from '../../../actions/packageActions'
import { tabCompany, tabNewRequest, tabSComp, tabSubscription } from '../../../actions/tabActions'
import AddSubscription from '../superAdminSubscription/AddSubscription'
import CompaniesAddModal from './CompaniesAddModal'

const CompAndSubsNavItem = () => {
    const myState = useSelector((state => state.changeTheTab))
    // console.log("***********", myState.viewLeaveNavItem)
    const dispatch = useDispatch()
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    useEffect(() => {
        dispatch(getPackage())
    }, [])
    // console.log(showDropDown)

  return (
    <>
        {
            myState.viewCompanyAndSubscription === "company" ?
            <>
            <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item active">
                        <NavLink to="#companies" onClick={() => dispatch(tabCompany())} className="">Company</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscriptions" onClick={() => dispatch(tabSubscription())} className="">Manage Subscriptions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscribed-companies" onClick={() => dispatch(tabSComp())} className="">Subscribed Companies</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#new-request" onClick={() => dispatch(tabNewRequest())} className="">New Request</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-2 float-right ml-auto">
                <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_leave" onClick={openDropDown}><i className="fa fa-plus"></i>Add Companies</NavLink>
            </div>
            <CompaniesAddModal showDropDown={showDropDown} openDropDown={openDropDown}/>
            </>
                                        
            : myState.viewCompanyAndSubscription === "subscription" ?
            <>
            <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                        <NavLink to="#companies" onClick={() => dispatch(tabCompany())} className="">Company</NavLink>
                    </li>
                    <li className="nav-item active">
                        <NavLink to="#subscriptions" onClick={() => dispatch(tabSubscription())} className="">Manage Subscriptions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscribed-companies" onClick={() => dispatch(tabSComp())} className="">Subscribed Companies</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#new-request" onClick={() => dispatch(tabNewRequest())} className="">New Request</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-2 float-right ml-auto">
                <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_leave" onClick={openDropDown}><i className="fa fa-plus"></i>Add Subscription</NavLink>
            </div>
            <AddSubscription showDropDown={showDropDown} openDropDown={openDropDown}/>
            </>

            : myState.viewCompanyAndSubscription === "scomp" ?
            <>
            <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                        <NavLink to="#companies" onClick={() => dispatch(tabCompany())} className="">Company</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscriptions" onClick={() => dispatch(tabSubscription())} className="">Manage Subscriptions</NavLink>
                    </li>
                    <li className="nav-item active">
                        <NavLink to="#subscribed-companies" onClick={() => dispatch(tabSComp())} className="">Subscribed Companies</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#new-request" onClick={() => dispatch(tabNewRequest())} className="">New Request</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-2 float-right ml-auto">
            </div>
                {/* <ModalCreateLeave showDropDown={showDropDown} openDropDown={openDropDown}/> */}
            </>
            : myState.viewCompanyAndSubscription === "newrequest" ?
            <>
            <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item">
                        <NavLink to="#companies" onClick={() => dispatch(tabCompany())} className="">Company</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscriptions" onClick={() => dispatch(tabSubscription())} className="">Manage Subscriptions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscribed-companies" onClick={() => dispatch(tabSComp())} className="">Subscribed Companies</NavLink>
                    </li>
                    <li className="nav-item active">
                        <NavLink to="#new-request" onClick={() => dispatch(tabNewRequest())} className="">New Request</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-2 float-right ml-auto">
            </div>
            </>
            : myState.viewCompanyAndSubscription === "search" ?
            <>
            <div className="col-7 mitbsnav">
                <ul className="nav nav-pills nav-justified">
                    <li className="nav-item active">
                        <NavLink to="#companies" onClick={() => dispatch(tabCompany())} className="">Company</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscriptions" onClick={() => dispatch(tabSubscription())} className="">Manage Subscriptions</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#subscribed-companies" onClick={() => dispatch(tabSComp())} className="">Subscribed Companies</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="#new-request" onClick={() => dispatch(tabNewRequest())} className="">New Request</NavLink>
                    </li>
                </ul>
            </div>
            <div className="col-2 float-right ml-auto">
            </div>
            </>
           :
           <div>Loading Module</div>            
        }
    </>
  )
}
export default CompAndSubsNavItem
// import React, { forwardRef, useEffect, useState } from 'react'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addEmployee, lastId } from "../../../actions/userActions";
import AddLPModal from "./AddLPModal";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import MyComponent from "../Map/Map";
// import { NavLink } from 'react-router-dom'

const AddEmployeeForm = () => {
  const [showDropDown, setshowDropDown] = useState(false);
  const lastIdData = async () => {
    dispatch(lastId({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    lastIdData();
  }, []);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [theNewDate, setTheNewDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const location = useSelector((state) => state.location);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  let employeeIdInt = parseInt(user.lastEmployeeId);
  employeeIdInt += 1;
  let employeeIdStr = employeeIdInt.toString();
  if (employeeIdStr.length === 1) {
    employeeIdStr = "00" + employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr")
  } else if (employeeIdStr.length === 2) {
    employeeIdStr = "0" + employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr2")
  } else {
    employeeIdStr = employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr3")
  }
  const generatedPassword = Math.random().toString(36).substring(2, 9) + 1;

  // console.log(employeeIdStr,employeeIdStr.length,theDate,"theDate", generatedPassword)

  // const DOB = forwardRef(({ value, onClick }, ref) => (
  //     <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //         <div className="cal-icon">
  //             <input className="form-control floating datatimepicker" type="text" value={value}/>
  //         </div>
  //         <label className="focus-label">Date</label>
  //     </div>
  // ))

  // const SDate = forwardRef(({ value, onClick }, ref) => (
  //     <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //         <div className="cal-icon">
  //             <input className="form-control floating datatimepicker" type="text" value={value}/>
  //         </div>
  //         <label className="focus-label">Date</label>
  //     </div>
  // ))
  const [values, setValues] = useState({
    companyId: "",
    firstName: "",
    lastName: "",
    employeeId: employeeIdStr,
    email: "",
    password: generatedPassword,
    phone: "",
    jobTitle: "",
    dateOfBirth: "",
    gender: "",
    hourlyWage: "",
    startingDate: "",
    employeeType: "",
    payType: "",
    address: "",
    state: "",
    // country:'',
    pinCode: "",
    timeZone: "",
    // primaryLocation: '',
    location: [],
    role: "",
    locationRequired: "true",
    // availability:{
    //     monday:{
    //         morningShift:''
    //     }
    // }
    availability: {
      monday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      tuesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      wednesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      thursday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      friday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      saturday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      sunday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
    },
  });
  const dispatch = useDispatch();

  // const handleChange = (fieldName) => (event) => {
  //   setValues({
  //     ...values,
  //     [fieldName]: event.target.value,
  //   });
  // };
  const handleChange = (fieldName) => (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.value
        : event.target.value;

    setValues({
      ...values,
      [fieldName]: value,
    });
  };

  const [locObj, setLocObj] = useState([]);
  const handleLocationChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.location.includes(abc.id) === false) {
        // console.log(abc)
        setLocObj([...locObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.location, abc.id],
        });
      }
    }
  };
  const deleteHandler = (id) => {
    setLocObj(locObj.filter((x) => x.id !== id));
    const index = values.location.indexOf(id);
    let arr = values.location;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, location: arr });
    }
  };

  const emptyLocationHandler = () => {
    setLocObj([]);
    setValues({ ...values, location: [] });
  };
  // console.log("moreLocationmoreLocationmoreLocationmoreLocationmoreLocationmoreLocation",locObj)
  // console.log(values)
  let [moreLocation, setMoreLocation] = useState([0]);
  const moreLocationFunc = () => {
    let abc = 0;
    setMoreLocation([...moreLocation, abc]);
  };

  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  // console.log("moreLocationmoreLocationmoreLocationmoreLocationmoreLocationmoreLocation", moreLocation)
  const employeeData = {
    companyId: auth.user.companyId,
    firstName: values.firstName,
    lastName: values.lastName,
    employeeId: values.employeeId,
    email: values.email,
    password: values.password,
    phone: values.phone,
    jobTitle: values.jobTitle,
    dateOfBirth: theDate.toISOString().substring(0, 10),
    gender: values.gender,
    hourlyWage: values.hourlyWage,
    startingDate: theNewDate.toISOString().substring(0, 10),
    employeeType: values.employeeType,
    payType: values.payType,
    address: values.address,
    state: values.state,
    // country: values.country,
    pinCode: (values.pinCode = values.zip),
    locationRequired: values.locationRequired === "true" ? true : false,
    location: values.location,
    // timeZone: values.timeZone,
    // primaryLocation: values.primaryLocation,
    role: "user",
    availability: values.availability,
    // {
    //     monday:{
    //         morningShift: false
    //     }
    // }
  };
  // console.log(employeeData)

  let navigate = useNavigate();
  // const [ errArr, setErrArr] = useState([])
  const navigateUser = () => {
    navigate("/employees");
  };
  const [errEmail, setErrEmail] = useState();
  const [errPhone, setErrPhone] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (employeeData.hourlyWage === "") {
      delete employeeData.hourlyWage;
    }
    if (employeeData.payType === "") {
      delete employeeData.payType;
    }
    if (employeeData.employeeType === "") {
      delete employeeData.employeeType;
    }
    for (let key of Object.keys(employeeData)) {
      // console.log(key,"54121421521", employeeData[key])
      if (employeeData[key] === "" || employeeData[key] === "+") {
        delete employeeData[key];
      }
    }

    dispatch(addEmployee(employeeData, navigateUser, setErrEmail, setErrPhone));
    // setValues({
    // companyId:'',
    // firstName: '',
    // lastName: '',
    // employeeId: '',
    // email: '',
    // password: '',
    // phone:'',
    // jobTitle:'',
    // dateOfBirth:'',
    // gender:'',
    // hourlyWage:'',
    // startingDate:'',
    // employeeType:'',
    // payType: '',
    // address:'',
    // state:'',
    // // country:'',
    // pinCode:0,
    // timeZone: '',
    // primaryLocation: '',
    // role: '',
    // availability:{
    //     monday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       tuesday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       wednesday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       thursday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       friday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       saturday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    //       sunday: {
    //         morningShift: false,
    //         eveningShift: false,
    //         nightShift: false,
    //       },
    // }
    // })
  };
  console.log(values, " employee from");

  const [requireLocation, setRequireLocation] = useState(null);

  const handleCheckboxChange2 = (e) => {
    const value = e.target.value;
    if (value === "yes") {
      setRequireLocation("yes");
    } else if (value === "no") {
      setRequireLocation("no");
    }
  };
  return (
    <>
      <div class="row">
        <div class="col-xl-12 col-sm-12 col-12">
          <div class="card rounded-3">
            <div class="card-header rounded-3 bg-success-dark">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-titl text-white">Employee Info</h5>
                <div>
                  <Link to="/employees" class="btn btn-white">
                    {" "}
                    <i class="fa fa-eye"></i> Employees List
                  </Link>
                </div>
              </div>
            </div>
            <div class="card-body">
              <form class="row" onSubmit={handleSubmit}>
                <div class="col-md-6 mb-3">
                  <label for="fname" class="form-label">
                    First Name <span class="text-denger">*</span>
                    {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"firstName"') &&
                    values.firstName === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="fname"
                    required
                    // aria-required="true"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    maxLength={35}
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="lname" class="form-label">
                    Last Name <span class="text-denger">*</span>
                  </label>
                  {user.error &&
                  user.error
                    .replaceAll(" is required", "")
                    .split(", ")
                    .includes('"lastName"') &&
                  values.lastName === "" ? (
                    <span className="text-danger"> This Field is Required</span>
                  ) : (
                    <></>
                  )}
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="lname"
                    required
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    maxLength={35}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="Employeeid" class="form-label">
                    Employee ID <span class="text-denger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="Employeeid"
                    value={values.employeeId}
                    onChange={handleChange("employeeId")}
                    disabled
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="emailAddress" class="form-label">
                    Email <span class="text-denger">*</span>
                    {(user.error && user.error === "EP-404") ||
                    user.error === "E-404" ? (
                      errEmail === values.email && (
                        <span className="text-danger">Email Exists</span>
                      )
                    ) : user.error &&
                      user.error
                        .replaceAll(" is required", "")
                        .split(", ")
                        .includes('"email"') &&
                      values.email === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : user.error &&
                      user.error === '"email" must be a valid email' ? (
                      <span className="text-danger">
                        {" "}
                        Email must be a valid email
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="email"
                    class="form-control bg-light"
                    placeholder=""
                    id="emailAddress"
                    required
                    value={values.email}
                    onChange={handleChange("email")}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="Phone" class="form-label">
                    Phone <span class="text-denger">*</span>
                    {(user.error && user.error === "EP-404") ||
                    user.error === "P-404" ? (
                      errPhone === values.phone && (
                        <span className="text-danger">Phone Exists</span>
                      )
                    ) : user.error &&
                      // console.log(user.error.replaceAll(" is required","").split(", "), user.error.replaceAll(" is required","").split(", ").includes('"phone"') && values.phone === '') &&
                      user.error
                        .replaceAll(" is required", "")
                        .split(", ")
                        .includes('"phone"') &&
                      values.phone === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="Phone"
                    minLength="14"
                    maxLength="14"
                    required
                    // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                    onKeyDown={(e) => {
                      if (alphabets.includes(e.key)) {
                        e.preventDefault();
                      }
                      if (e.code === "Backspace" && values.phone.length === 6) {
                        setValues({
                          ...values,
                          phone: values.phone.substring(0, 4),
                        });
                      }
                      if (e.code === "Backspace" && values.phone.length === 2) {
                        setValues({
                          ...values,
                          phone: "",
                        });
                      }
                      if (
                        e.code === "Backspace" &&
                        values.phone.length === 10
                      ) {
                        setValues({
                          ...values,
                          phone: values.phone.substring(0, 9),
                        });
                      }
                    }}
                    value={values.phone}
                    onChange={handlePhone("phone")}
                    placeholder="(123) 456-7890"
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="Password" class="form-label">
                    Password<span class="text-denger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    id="Password"
                    placeholder="Must contain a number and an alphabet. Minimum 8 characters"
                    pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                    value={values.password}
                    onChange={handleChange("password")}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="jobTitle" class="form-label">
                    Job Title <span class="text-denger">*</span>
                    {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"jobTitle"') &&
                    values.jobTitle === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="jobTitle"
                    required
                    value={values.jobTitle}
                    onChange={handleChange("jobTitle")}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="dateofbirth" class="form-label">
                      Date of Birth <span class="text-denger">*</span>
                    </label>
                    <div class="cal-icon">
                      <DatePicker
                        selected={theDate}
                        onChange={(date) => setTheDate(date)}
                        className="form-control bg-light datetimepicker"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="gender" className="form-label">
                    Gender<span className="text-danger">*</span>
                    {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"gender"') &&
                    values.gender === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Male"
                        checked={values.gender === "Male"}
                        onChange={handleChange("gender")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value="Female"
                        checked={values.gender === "Female"}
                        onChange={handleChange("gender")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Female
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <label for="hourlywage" class="form-label">
                    Hourly Wage
                    {/* <span class="text-denger">*</span> */}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="hourlywage"
                    value={values.hourlyWage}
                    onChange={handleChange("hourlyWage")}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="dateofbirth" class="form-label">
                      Starting Date <span class="text-denger">*</span>
                    </label>
                    <div class="cal-icon">
                      <DatePicker
                        selected={theNewDate}
                        onChange={(date) => setTheNewDate(date)}
                        className="form-control bg-light datetimepicker"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="" class="form-label">
                      Employee Type<span class="text-denger">*</span>
                    </label>
                    <select
                      class="form-select"
                      autoComplete="false"
                      value={values.employeeType}
                      onChange={handleChange("employeeType")}
                    >
                      <option value="null">None</option>
                      <option value="Full time">Full time</option>
                      <option value="Part time">Part time</option>
                      <option value="Seasonal">Seasonal</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Contractor">Contractor</option>
                      <option value="Volunteer">Volunteer</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="paytype" class="form-label">
                      Pay Type<span class="text-denger">*</span>
                    </label>
                    <select
                      class="form-select"
                      autoComplete="false"
                      value={values.payType}
                      onChange={handleChange("payType")}
                    >
                      <option value="null">None</option>
                      <option value="Hourly">Hourly</option>
                      <option value="Salary">Salary</option>
                      <option value="Commission">Commission</option>
                      <option value="Daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Contract">Contract</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="tc" class="form-label">
                    Does User Required Location to Clock In?
                    {/* <span class="text-denger">*</span> */}
                    {/* {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"gender"') &&
                    values.gender === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )} */}
                  </label>
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="Yes"
                        checked={values.locationRequired === "Yes"}
                        onChange={handleChange("locationRequired")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value="No"
                        checked={values.locationRequired === "No"}
                        onChange={handleChange("locationRequired")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="Address" class="form-label">
                    Address<span class="text-denger">*</span>
                    {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"address"') &&
                    values.address === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  {/* <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="Address"
                    value=""
                  /> */}
                  <MyComponent
                    // isRequired
                    values={values}
                    setValues={setValues}
                  />
                </div>
                {/* <div class="col-md-12 mb-3">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90325.48960836412!2d-93.34387893941545!3d44.97066737377247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b333909377bbbd%3A0x939fc9842f7aee07!2sMinneapolis%2C%20MN%2C%20USA!5e0!3m2!1sen!2s!4v1728385959238!5m2!1sen!2s"
                    width="100%"
                    height="300"
                    style={{ borderRadius: "10px" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div> */}
                <div class="col-md-4 mb-3">
                  <label for="State" class="form-label">
                    State<span class="text-denger">*</span>
                    {user.error &&
                    user.error
                      .replaceAll(" is required", "")
                      .split(", ")
                      .includes('"state"') &&
                    values.state === "" ? (
                      <span className="text-danger">
                        {" "}
                        This Field is Required
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="State"
                    value={values.state}
                    onChange={handleChange("state")}
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="zipcode" class="form-label">
                    Postal/Zip Code
                    {/* <span class="text-denger">*</span> */}
                  </label>
                  <input
                    type="text"
                    class="form-control bg-light"
                    placeholder=""
                    id="zipcode"
                    onKeyDown={(e) => {
                      if (alphabets.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    autoComplete="false"
                    value={values.pinCode}
                    onChange={handleChange("pinCode")}
                  />
                </div>
                {/* <Select ismultiple ka code dalna ha later */}
                <div class="col-md-4 mb-3">
                  <div class="form-group">
                    <label for="paytype" class="form-label">
                      Location Primary<span class="text-denger">*</span>
                    </label>
                    <a
                      onClick={openDropDown}
                      className="micoloc btn"
                      data-toggle="modal"
                      data-target="#add_designation"
                    >
                      <i className="fa fa-plus" onClick={moreLocationFunc}></i>
                    </a>
                    {values.locationRequired === "true" ? (
                      <select
                        className="form-select"
                        autoComplete="false"
                        onChange={handleLocationChange("location")}
                      >
                        <option value="null">Select</option>
                        {location.location &&
                          location.location.length > 0 &&
                          location.location.map((current, i) => {
                            return (
                              <option
                                value={JSON.stringify({
                                  id: current.id,
                                  name: current.name,
                                })}
                                key={i + 1}
                              >
                                {current.name}
                              </option>
                            );
                          })}
                      </select>
                    ) : (
                      <select
                        className="form-select"
                        autoComplete="false"
                        disabled
                        onChange={handleLocationChange("location")}
                      >
                        <option value="null">Select</option>
                        {location.location &&
                          location.location.length > 0 &&
                          location.location.map((current, i) => {
                            return (
                              <option
                                value={JSON.stringify({
                                  id: current.id,
                                  name: current.name,
                                })}
                                key={i + 1}
                              >
                                {current.name}
                              </option>
                            );
                          })}
                      </select>
                    )}
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="Primarylocation" className="form-label">
                    Selected location(s)
                  </label>
                  <ul className="mt-2">
                    {locObj.map((elem, ind) => {
                      return (
                        <li
                          key={ind}
                          className="d-flex justify-content-between align-items-center"
                        >
                          {elem.name}
                          <i
                            style={{ cursor: "pointer" }}
                            className="fa fa-trash"
                            onClick={() => deleteHandler(elem.id)}
                          ></i>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div class="col-md-12 mb-3">
                  <div class="bg-table p-3">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead class="border-bottom-newest">
                          <tr>
                            <th>Available Shifts</th>
                            <th class="text-center">Mon</th>
                            <th class="text-center">Tue</th>
                            <th class="text-center">Wed</th>
                            <th class="text-center">Thu</th>
                            <th class="text-center">Fri</th>
                            <th class="text-center">Sat</th>
                            <th class="text-center">Sun</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Morning Shift</td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  // setValues({...values, ...{...values.availability, ...{...values.availability.monday, ...{morningShift: (!values.availability.monday.morningShift)}}}})
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      monday: {
                                        ...values.availability.monday,
                                        morningShift:
                                          !values.availability.monday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      tuesday: {
                                        ...values.availability.tuesday,
                                        morningShift:
                                          !values.availability.tuesday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      wednesday: {
                                        ...values.availability.wednesday,
                                        morningShift:
                                          !values.availability.wednesday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      thursday: {
                                        ...values.availability.thursday,
                                        morningShift:
                                          !values.availability.thursday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      friday: {
                                        ...values.availability.friday,
                                        morningShift:
                                          !values.availability.friday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      saturday: {
                                        ...values.availability.saturday,
                                        morningShift:
                                          !values.availability.saturday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      sunday: {
                                        ...values.availability.sunday,
                                        morningShift:
                                          !values.availability.sunday
                                            .morningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Evening Shift</td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      monday: {
                                        ...values.availability.monday,
                                        eveningShift:
                                          !values.availability.monday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      tuesday: {
                                        ...values.availability.tuesday,
                                        eveningShift:
                                          !values.availability.tuesday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      wednesday: {
                                        ...values.availability.wednesday,
                                        eveningShift:
                                          !values.availability.wednesday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      thursday: {
                                        ...values.availability.thursday,
                                        eveningShift:
                                          !values.availability.thursday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      friday: {
                                        ...values.availability.friday,
                                        eveningShift:
                                          !values.availability.friday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      saturday: {
                                        ...values.availability.saturday,
                                        eveningShift:
                                          !values.availability.saturday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      sunday: {
                                        ...values.availability.sunday,
                                        eveningShift:
                                          !values.availability.sunday
                                            .eveningShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Night Shift</td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      monday: {
                                        ...values.availability.monday,
                                        nightShift:
                                          !values.availability.monday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      tuesday: {
                                        ...values.availability.tuesday,
                                        nightShift:
                                          !values.availability.tuesday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      wednesday: {
                                        ...values.availability.wednesday,
                                        nightShift:
                                          !values.availability.wednesday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      thursday: {
                                        ...values.availability.thursday,
                                        nightShift:
                                          !values.availability.thursday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      friday: {
                                        ...values.availability.friday,
                                        nightShift:
                                          !values.availability.friday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      saturday: {
                                        ...values.availability.saturday,
                                        nightShift:
                                          !values.availability.saturday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                            <td class="text-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                onClick={() =>
                                  setValues({
                                    ...values,
                                    availability: {
                                      ...values.availability,
                                      sunday: {
                                        ...values.availability.sunday,
                                        nightShift:
                                          !values.availability.sunday
                                            .nightShift,
                                      },
                                    },
                                  })
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-success btn-lg">Submit</button>
                </div>
              </form>
              <AddLPModal
                showDropDown={showDropDown}
                openDropDown={openDropDown}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEmployeeForm;

// <div className="row">
// <div className="col-lg-12">
//   <div className="card mb-0">
//     <div className="card-header">
//       <h4 className="card-title mb-0">Employee Info</h4>
//     </div>
//     <div className="card-body">
//       <form onSubmit={handleSubmit}>
//         <div className="row">
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 First Name <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"firstName"') &&
//                 values.firstName === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 type="text"
//                 autoComplete="false"
//                 required
//                 // aria-required="true"
//                 value={values.firstName}
//                 onChange={handleChange("firstName")}
//                 maxLength={35}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Last Name <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"lastName"') &&
//                 values.lastName === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 type="text"
//                 autoComplete="false"
//                 required
//                 value={values.lastName}
//                 onChange={handleChange("lastName")}
//                 maxLength={35}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Employee ID <span className="text-danger">*</span>
//               </label>
//               <input
//                 type="text"
//                 className="form-control"
//                 autoComplete="false"
//                 value={values.employeeId}
//                 onChange={handleChange("employeeId")}
//                 disabled
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Email <span className="text-danger">*</span>
//                 {(user.error && user.error === "EP-404") ||
//                 user.error === "E-404" ? (
//                   errEmail === values.email && (
//                     <span className="text-danger">Email Exists</span>
//                   )
//                 ) : user.error &&
//                   user.error
//                     .replaceAll(" is required", "")
//                     .split(", ")
//                     .includes('"email"') &&
//                   values.email === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : user.error &&
//                   user.error === '"email" must be a valid email' ? (
//                   <span className="text-danger">
//                     {" "}
//                     Email must be a valid email
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 type="email"
//                 autoComplete="false"
//                 required
//                 value={values.email}
//                 onChange={handleChange("email")}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Phone <span className="text-danger">*</span>
//                 {(user.error && user.error === "EP-404") ||
//                 user.error === "P-404" ? (
//                   errPhone === values.phone && (
//                     <span className="text-danger">Phone Exists</span>
//                   )
//                 ) : user.error &&
//                   // console.log(user.error.replaceAll(" is required","").split(", "), user.error.replaceAll(" is required","").split(", ").includes('"phone"') && values.phone === '') &&
//                   user.error
//                     .replaceAll(" is required", "")
//                     .split(", ")
//                     .includes('"phone"') &&
//                   values.phone === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 minLength="14"
//                 maxLength="14"
//                 required
//                 // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
//                 onKeyDown={(e) => {
//                   if (alphabets.includes(e.key)) {
//                     e.preventDefault();
//                   }
//                   if (
//                     e.code === "Backspace" &&
//                     values.phone.length === 6
//                   ) {
//                     setValues({
//                       ...values,
//                       phone: values.phone.substring(0, 4),
//                     });
//                   }
//                   if (
//                     e.code === "Backspace" &&
//                     values.phone.length === 2
//                   ) {
//                     setValues({
//                       ...values,
//                       phone: "",
//                     });
//                   }
//                   if (
//                     e.code === "Backspace" &&
//                     values.phone.length === 10
//                   ) {
//                     setValues({
//                       ...values,
//                       phone: values.phone.substring(0, 9),
//                     });
//                   }
//                 }}
//                 name="phone"
//                 value={values.phone}
//                 onChange={handlePhone("phone")}
//                 placeholder="(123) 456-7890"
//                 autocomplete="new-mobileNunmber"
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Password <span className="text-danger">*</span>
//               </label>
//               <input
//                 className="form-control"
//                 type="type"
//                 autoComplete="false"
//                 // required
//                 placeholder="Must contain a number and an alphabet. Minimum 8 characters"
//                 pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
//                 value={values.password}
//                 onChange={handleChange("password")}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Job Title <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"jobTitle"') &&
//                 values.jobTitle === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 type="text"
//                 autoComplete="false"
//                 required
//                 value={values.jobTitle}
//                 onChange={handleChange("jobTitle")}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">Date of Birth</label>
//               <DatePicker
//                 selected={theDate}
//                 onChange={(date) => setTheDate(date)}
//                 className="colo-icon form-control datetimepicker3"
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Gender <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"gender"') &&
//                 values.gender === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <select
//                 className="form-select"
//                 style={{ height: "44px", fontSize: "14px" }}
//                 autoComplete="false"
//                 // required
//                 value={values.gender}
//                 onChange={handleChange("gender")}
//               >
//                 <option value="">Select</option>
//                 <option value="Male">Male</option>
//                 <option value="Female">Female</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">Hourly Wage</label>
//               <input
//                 className="form-control"
//                 type="text"
//                 autoComplete="false"
//                 value={values.hourlyWage}
//                 onChange={handleChange("hourlyWage")}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Starting Date <span className="text-danger">*</span>
//               </label>

//               <DatePicker
//                 selected={theNewDate}
//                 onChange={(date) => setTheNewDate(date)}
//                 className="colo-icon form-control datetimepicker3"
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">Employee Type </label>
//               <select
//                 className="form-select"
//                 style={{ height: "44px", fontSize: "14px" }}
//                 autoComplete="false"
//                 value={values.employeeType}
//                 onChange={handleChange("employeeType")}
//               >
//                 <option value="null">None</option>
//                 <option value="Full time">Full time</option>
//                 <option value="Part time">Part time</option>
//                 <option value="Seasonal">Seasonal</option>
//                 <option value="Temporary">Temporary</option>
//                 <option value="Contractor">Contractor</option>
//                 <option value="Volunteer">Volunteer</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">Pay Type </label>
//               <select
//                 className="form-select"
//                 style={{ height: "44px", fontSize: "14px" }}
//                 autoComplete="false"
//                 value={values.payType}
//                 onChange={handleChange("payType")}
//               >
//                 <option value="null">None</option>
//                 <option value="Hourly">Hourly</option>
//                 <option value="Salary">Salary</option>
//                 <option value="Commission">Commission</option>
//                 <option value="Daily">Daily</option>
//                 <option value="Weekly">Weekly</option>
//                 <option value="Monthly">Monthly</option>
//                 <option value="Contract">Contract</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Does User Required Location to Clock In?{" "}
//                 <span className="text-danger">*</span>
//               </label>
//               <select
//                 className="form-select"
//                 style={{ height: "44px", fontSize: "14px" }}
//                 // autoComplete='false'
//                 defaultValue="true"
//                 // required
//                 value={values.locationRequired}
//                 onChange={handleChange("locationRequired")}
//                 onClick={() =>
//                   values.locationRequired === "false"
//                     ? emptyLocationHandler()
//                     : console.log("already no location added")
//                 }
//               >
//                 <option value="true">Yes</option>
//                 <option value="false">No</option>
//               </select>
//             </div>
//           </div>
//           <div className="col-sm-12">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Address <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"address"') &&
//                 values.address === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>

//               <MyComponent
//                 // isRequired
//                 values={values}
//                 setValues={setValues}
//               />
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 State <span className="text-danger">*</span>
//                 {user.error &&
//                 user.error
//                   .replaceAll(" is required", "")
//                   .split(", ")
//                   .includes('"state"') &&
//                 values.state === "" ? (
//                   <span className="text-danger">
//                     {" "}
//                     This Field is Required
//                   </span>
//                 ) : (
//                   <></>
//                 )}
//               </label>
//               <input
//                 className="form-control"
//                 type="text"
//                 autoComplete="false"
//                 // required
//                 value={values.state}
//                 onChange={handleChange("state")}
//               />
//             </div>
//           </div>

//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Postal/Zip Code

//               </label>
//               <input
//                 className="form-control"
//                 type="number"
//                 // required
//                 onKeyDown={(e) => {
//                   if (alphabets.includes(e.key)) {
//                     e.preventDefault();
//                   }
//                 }}
//                 autoComplete="false"
//                 value={values.pinCode}
//                 onChange={handleChange("pinCode")}
//               />
//             </div>
//           </div>

//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Primary Location{" "}
//               </label>
//               <a
//                 onClick={openDropDown}
//                 className="micoloc btn"
//                 data-toggle="modal"
//                 data-target="#add_designation"
//               >
//                 <i
//                   className="fa fa-plus"
//                   onClick={moreLocationFunc}
//                 ></i>
//               </a>

//               {values.locationRequired === "true" ? (
//                 <select
//                   className="form-select"
//                   style={{ height: "44px", fontSize: "14px" }}
//                   autoComplete="false"
//                   onChange={handleLocationChange("location")}
//                 >
//                   <option value="null">Select</option>
//                   {location.location &&
//                     location.location.length > 0 &&
//                     location.location.map((current, i) => {
//                       return (
//                         <option
//                           value={JSON.stringify({
//                             id: current.id,
//                             name: current.name,
//                           })}
//                           key={i + 1}
//                         >
//                           {current.name}
//                         </option>
//                       );
//                     })}
//                 </select>
//               ) : (
//                 <select
//                   className="form-select"
//                   style={{ height: "44px", fontSize: "14px" }}
//                   autoComplete="false"
//                   disabled
//                   onChange={handleLocationChange("location")}
//                 >
//                   <option value="null">Select</option>
//                   {location.location &&
//                     location.location.length > 0 &&
//                     location.location.map((current, i) => {
//                       return (
//                         <option
//                           value={JSON.stringify({
//                             id: current.id,
//                             name: current.name,
//                           })}
//                           key={i + 1}
//                         >
//                           {current.name}
//                         </option>
//                       );
//                     })}
//                 </select>
//               )}
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="form-group">
//               <label className="col-form-label">
//                 Selected Location
//               </label>
//               <ul>
//                 {locObj.map((elem, ind) => {
//                   return (
//                     <li key={ind}>
//                       {elem.name}
//                       <i
//                         style={{ float: "right" }}
//                         className="fa fa-trash"
//                         onClick={() => deleteHandler(elem.id)}
//                       ></i>
//                     </li>
//                   );
//                 })}
//               </ul>
//             </div>
//           </div>
//         </div>
//         <div className="table-responsive m-t-15">
//           <table className="table table-striped custom-table">
//             <thead>
//               <tr>
//                 <th>Available Shifts</th>
//                 <th className="text-center">Mon</th>
//                 <th className="text-center">Tue</th>
//                 <th className="text-center">Wed</th>
//                 <th className="text-center">Thu</th>
//                 <th className="text-center">Fri</th>
//                 <th className="text-center">Sat</th>
//                 <th className="text-center">Sun</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>Morning Shift</td>

//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       // setValues({...values, ...{...values.availability, ...{...values.availability.monday, ...{morningShift: (!values.availability.monday.morningShift)}}}})
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           monday: {
//                             ...values.availability.monday,
//                             morningShift:
//                               !values.availability.monday.morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           tuesday: {
//                             ...values.availability.tuesday,
//                             morningShift:
//                               !values.availability.tuesday.morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           wednesday: {
//                             ...values.availability.wednesday,
//                             morningShift:
//                               !values.availability.wednesday
//                                 .morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           thursday: {
//                             ...values.availability.thursday,
//                             morningShift:
//                               !values.availability.thursday
//                                 .morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           friday: {
//                             ...values.availability.friday,
//                             morningShift:
//                               !values.availability.friday.morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           saturday: {
//                             ...values.availability.saturday,
//                             morningShift:
//                               !values.availability.saturday
//                                 .morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           sunday: {
//                             ...values.availability.sunday,
//                             morningShift:
//                               !values.availability.sunday.morningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td>Evening Shift</td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           monday: {
//                             ...values.availability.monday,
//                             eveningShift:
//                               !values.availability.monday.eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           tuesday: {
//                             ...values.availability.tuesday,
//                             eveningShift:
//                               !values.availability.tuesday.eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           wednesday: {
//                             ...values.availability.wednesday,
//                             eveningShift:
//                               !values.availability.wednesday
//                                 .eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           thursday: {
//                             ...values.availability.thursday,
//                             eveningShift:
//                               !values.availability.thursday
//                                 .eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           friday: {
//                             ...values.availability.friday,
//                             eveningShift:
//                               !values.availability.friday.eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           saturday: {
//                             ...values.availability.saturday,
//                             eveningShift:
//                               !values.availability.saturday
//                                 .eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           sunday: {
//                             ...values.availability.sunday,
//                             eveningShift:
//                               !values.availability.sunday.eveningShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td>Night Shift</td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           monday: {
//                             ...values.availability.monday,
//                             nightShift:
//                               !values.availability.monday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           tuesday: {
//                             ...values.availability.tuesday,
//                             nightShift:
//                               !values.availability.tuesday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           wednesday: {
//                             ...values.availability.wednesday,
//                             nightShift:
//                               !values.availability.wednesday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           thursday: {
//                             ...values.availability.thursday,
//                             nightShift:
//                               !values.availability.thursday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           friday: {
//                             ...values.availability.friday,
//                             nightShift:
//                               !values.availability.friday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           saturday: {
//                             ...values.availability.saturday,
//                             nightShift:
//                               !values.availability.saturday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//                 <td className="text-center">
//                   <input
//                     type="checkbox"
//                     onClick={() =>
//                       setValues({
//                         ...values,
//                         availability: {
//                           ...values.availability,
//                           sunday: {
//                             ...values.availability.sunday,
//                             nightShift:
//                               !values.availability.sunday.nightShift,
//                           },
//                         },
//                       })
//                     }
//                   />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//         <div className="submit-section">
//           {
//             user.error === "ACC-400-LIMIT-REACHED" && (
//               <span
//                 className="text-danger"
//                 style={{ fontSize: "12px" }}
//               >
//                 Account Limit Reached. Upgrade Your Plan
//               </span>
//             )
//             // user.error === "EP-404" ||
//             // user.error === "E-404"
//             // ?
//             // <span className="text-danger" style={{fontSize:"12px"}}>Fill All Required Fields</span>
//             // :
//             // <></>
//           }
//           <br />
//           <button className="btn btn-primary submit-btn">Submit</button>
//         </div>
//       </form>
//       <AddLPModal
//         showDropDown={showDropDown}
//         openDropDown={openDropDown}
//       />
//     </div>
//   </div>
// </div>
// </div>

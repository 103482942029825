import axios from "axios";
import {
    TRAINING_REQUEST,
    TRAINING_SUCCESS,
    TRAINING_FAIL,
    TRAINING_CREATE_REQUEST,
    TRAINING_CREATE_SUCCESS,
    TRAINING_CREATE_FAIL,
    TRAINING_UPDATE_REQUEST,
    TRAINING_UPDATE_SUCCESS,
    TRAINING_UPDATE_FAIL,
    REMOVE_TRAINING_REQUEST,
    REMOVE_TRAINING_SUCCESS,
    REMOVE_TRAINING_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY TRAINING
export const getAllTraining = (obj) => async (dispatch) => {
    dispatch({
        type: TRAINING_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/training/getAllTraining`, obj);
        localStorage.setItem("training", JSON.stringify(data));
        dispatch({
            type: TRAINING_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: TRAINING_FAIL,
            payload: err,
        });
    }
};

// COMPANY ADD NEW TRAINING
export const addTraining = (obj) => async ( dispatch ) => {
    dispatch({
        type: TRAINING_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/training/createTraining`, obj)
    dispatch({
        type: TRAINING_CREATE_SUCCESS,
        payload: data,
    })
    // navigateUser()
}
catch (err) {
    console.log(err)
    dispatch({
        type: TRAINING_CREATE_FAIL,
        payload: err,
    })
}
}


// // UPDATE TRAINING BY ADMIN
export const updateTraining = (obj) => async (dispatch) => {
    dispatch({
        type: TRAINING_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/training/updateTraining`, obj);
        dispatch({
            type: TRAINING_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: TRAINING_UPDATE_FAIL,
            payload: err,
        });
    }
};

// // REMOVE TRAINING BY ADMIN
export const removeTraining = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_TRAINING_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/training/updateTraining`, obj);
        dispatch({
            type: REMOVE_TRAINING_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_TRAINING_FAIL,
            payload: err,
        });
    }
};

import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminCompanySendMessage from "../../components/superAdminCompoents/superAdminMessageCompoents/SuperAdminCompanySendMessage";
import { allCompanyAdmins } from "../../actions/userActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const SuperAdminComposePage = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const getallCompanyAdmins = async () => {
    dispatch(allCompanyAdmins({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    getallCompanyAdmins();
  }, []);

  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Compose" />
            <SuperAdminCompanySendMessage />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminComposePage;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { useParams } from 'react-router-dom';
import { updateAdditionType } from "../../../actions/payrollAction";
const EditModalAdditionsPayroll = ({ showDropDown, openDropDown, current }) => {
  const [values, setValues] = useState({
    // companyId: '',
    id: "",
    name: "",
    category: "",
    amount: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // const params = useParams()
  const currentAddition = async () => {
    try {
      setValues({
        id: "",
        name: "",
        category: "",
        amount: "",
      });
      setValues({
        id: current.id,
        name: current.name,
        category: current.category,
        amount: current.amount,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    currentAddition();
  }, []);
  const dispatch = useDispatch();
  const updateAdditionData = {
    _id: values.id,
    name: values.name,
    category: values.category,
    amount: values.amount,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateAdditionType(updateAdditionData));
    setValues({
      id: "",
      name: "",
      category: "",
      amount: "",
    });
  };
  // console.log(values, "addition values tak chal rha hai")
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_addition"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Addition</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div className="form-group">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Category <span className="text-danger">*</span>
                  </label>
                  <br />
                  <select
                    className="form-select"
                    style={{ height: "44px", fontSize: "14px" }}
                    autoComplete="false"
                    value={values.category}
                    onChange={handleChange("category")}
                  >
                    <option>Select a category</option>
                    <option>Monthly remuneration</option>
                    <option>Additional remuneration</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Unit Amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={values.amount}
                      onChange={handleChange("amount")}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">.00</span>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalAdditionsPayroll;

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tabContactUs } from '../actions/tabActions'
import BannerContactUs from '../components/contactUsComponents/BannerContactUs'
import ContactUsMessage from '../components/contactUsComponents/ContactUsMessage'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
const ContactUs = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabContactUs())
  }, [])
  return (
    <>
      <NavBar />
      <BannerContactUs />
      <ContactUsMessage />
      <Footer />
    </>
  )
}
export default ContactUs
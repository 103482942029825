import React from 'react'
import { useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom'

const ProfileLocation = () => {
    const user = useSelector((state) => state.user);

    return (
        <>
            <div className="" id="emp_Locations">
                {/* "pro-overview tab-pane fade show active"
        remove this class  */}
        {
            user.userById.user &&
            user.userById.user.location &&
            user.userById.user.location.length > 0 &&
            user.userById.user.location.map((current, i) => {
                return (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content milocpmn">
                            <div className="card card-table mb-0">
                                <div className="card-header">
                                    <h4 className="card-title mb-0"><strong>Location: </strong> {current.name}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })
        }
            </div>
        </>
    )
}

export default ProfileLocation



//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="tab-content milocpmn">
//                             <div className="card card-table mb-0">
//                                 <div className="card-header">
//                                     <h4 className="card-title mb-0"><strong>Position: </strong>United Protective Agency</h4>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="table-responsive">
//                                         <table className="table table-hover table-center mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th>Designation</th>
//                                                     <th>Position</th>
//                                                     <th className="text-right">Actions</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="grnclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>

//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="redclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="cynclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="ylwclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-lg-12">
//                         <div className="tab-content milocpmn">
//                             <div className="card card-table mb-0">
//                                 <div className="card-header">
//                                     <h4 className="card-title mb-0"><strong>Position: </strong>United Protective Agency</h4>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className="table-responsive">
//                                         <table className="table table-hover table-center mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th>Designation</th>
//                                                     <th>Position</th>
//                                                     <th className="text-right">Actions</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="grnclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="redclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="cynclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td className="sorting_1">
//                                                         <h2 className="table-avatar">
//                                                             <NavLink to="#" className="avatar">
//                                                                 <div className="ylwclr"></div>
//                                                             </NavLink>
//                                                             <NavLink to="#">Un-Armed Officer</NavLink>
//                                                         </h2>
//                                                     </td>
//                                                     <td>
//                                                         abc
//                                                     </td>
//                                                     <td className="text-right">
//                                                         {/* <div className="dropdown dropdown-action">
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></NavLink>
// <NavLink className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></NavLink>
// </div> */}
//                                                         <div className="dropdown dropdown-action">
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-map-marker m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-pencil m-r-5"></i></NavLink>
//                                                             <span>&nbsp;&nbsp;&nbsp;</span>
//                                                             <NavLink
//                                                                 to="#"
//                                                             ><i className="fa fa-trash-o m-r-5"></i></NavLink>
//                                                         </div>
//                                                     </td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SuperAdminEditClientProfileCover from "./SuperAdminEditClientProfileCover";
import moment from "moment";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { NavLink } from "react-router-dom";
import { BUCKET_BASE_URL } from "../../../constants";
const SuperAdminClientProfileCover = () => {
  // const auth = useSelector((state) => state.auth)
  const company = useSelector((state) => state.company);
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // console.log(showDropDown)
  return (
    <>
      <div className="card mb-0">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-view">
                <div className="profile-img-wrap">
                  <div className="profile-img">
                    <NavLink to="#">
                      {/* <img alt="" src="assets/img/avatar-02.jpg" /> */}
                      {company.superAdminCompanyCPC[0].admin.profilePic &&
                      // company.superAdminCompanyCPC[0].profilePic &&
                      company.superAdminCompanyCPC[0].admin.profilePic
                        .fileUrl ? (
                        // company.superAdminCompanyCPC[0].profilePic.fileUrl &&
                        <img
                          alt="Profile Pic"
                          src={
                            BUCKET_BASE_URL +
                            company.superAdminCompanyCPC[0].admin.profilePic
                              .fileUrl
                          }
                          style={{ objectFit: "fill", height: "100%" }}
                        />
                      ) : (
                        <img alt="Profile Pic" src={defaultImg} />
                      )}
                    </NavLink>
                  </div>
                </div>
                <div className="profile-basic">
                  {company.superAdminCompanyCPC && (
                    <div className="row">
                      <div className="col-md-5">
                        <div className="profile-info-left">
                          <h4 className="user-name m-t-0 mb-0">
                            <strong>Company Name:</strong>{" "}
                            {
                              company.superAdminCompanyCPC[0].company
                                .companyName
                            }
                          </h4>
                          {/* <h6 className="staff-id"><strong>User Name:</strong> stephaniepatterson</h6> */}
                          <div className="staff-id">
                            <strong>Company ID :</strong>{" "}
                            {company.superAdminCompanyCPC[0].company.clientId}
                          </div>
                          <div className="staff-id">
                            <strong>Plan:</strong>&nbsp;
                            {
                              company.superAdminCompanyCPC[0]
                                .currentSubscription.currentSubscriptionPackage
                                .planName
                            }
                          </div>
                          {/* <div className="staff-id"><strong>Joining Date :</strong> 24th July 2021</div> */}
                          <div className="staff-msg">
                            <a className="btn btn-custom" href="compose.html">
                              Send Message
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Phone:</div>
                            <div className="text">
                              <a href="">
                                {company.superAdminCompanyCPC[0].company.phone}
                              </a>
                              {/* <span className="text-success miphcn">  Confirmed <i className="fa fa-check-circle text-success"></i></span> */}
                            </div>
                          </li>
                          <li>
                            <div className="title">Email:</div>
                            <div className="text">
                              <a href="">
                                <span>
                                  {
                                    company.superAdminCompanyCPC[0].company
                                      .email
                                  }
                                </span>
                              </a>
                            </div>
                          </li>
                          {/* <br /> */}
                          <li>
                            <div className="title">Joining Date:</div>
                            <div className="text">
                              {moment(
                                company.superAdminCompanyCPC[0].company.date
                              ).format("MM/DD/YYYY")}
                            </div>
                          </li>
                          <li>
                            <div className="title">Address:</div>
                            <div className="text">
                              {company.superAdminCompanyCPC[0].company.address
                                ? company.superAdminCompanyCPC[0].company
                                    .address
                                : " "}
                            </div>
                          </li>
                          <br />
                          <li>
                            <div className="title">Total Users:</div>
                            <div className="text milocpmn">
                              <div className="miprflps">
                                <a>
                                  {company.superAdminCompanyCPC[0].totalUsers}{" "}
                                  Employees
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div className="pro-edit">
                  <a
                    data-target="#profile_info"
                    data-toggle="modal"
                    onClick={openDropDown}
                    className="edit-icon"
                    href="#"
                  >
                    <i className="fa fa-pencil"></i>
                  </a>
                </div>
              </div>
              <SuperAdminEditClientProfileCover
                showDropDown={showDropDown}
                openDropDown={openDropDown}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminClientProfileCover;

import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
// import AdminTittle from '../../components/adminComponents/AdminTittle'
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import SuperAdminSettingView from "../../components/superAdminCompoents/superAdminSettingView/SuperAdminSettingView";
const SuperAdminSettingPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <SuperAdminSettingView />
    </>
  );
};
export default SuperAdminSettingPage;

import axios from "axios";
import {
    SALARY_REQUEST,
    SALARY_SUCCESS,
    SALARY_FAIL,
    SALARY_CREATE_REQUEST,
    SALARY_CREATE_SUCCESS,
    SALARY_CREATE_FAIL,
    SALARY_STRUCTURE_UPDATE_REQUEST,
    SALARY_STRUCTURE_UPDATE_SUCCESS,
    SALARY_STRUCTURE_UPDATE_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER SALARY
export const getSalaryByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: SALARY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salary/getSalaryByUserId`, obj);
        localStorage.setItem("salary", JSON.stringify(data));
        dispatch({
            type: SALARY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SALARY_FAIL,
            payload: err,
        });
    }
};


// ADMIN ADD NEW SALARY
export const addTheSalary = (obj) => async ( dispatch ) => {
    dispatch({
        type: SALARY_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salary/createSalary`, obj)
        dispatch({
            type: SALARY_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SALARY_CREATE_FAIL,
            payload: err,
        });
    }
};


// UPDATE SALARY STRUCTURE
export const updateSalaryStructure = (obj) => async (dispatch) => {
    dispatch({
        type: SALARY_STRUCTURE_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/salary/updateSalary`, obj);
        dispatch({
            type: SALARY_STRUCTURE_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SALARY_STRUCTURE_UPDATE_FAIL,
            payload: err,
        });
    }
};
import moment from "moment";
import React, { useState } from "react";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { NavLink } from "react-router-dom";
import ChangePlanModel from "./ChangePlanModel";
import { BUCKET_BASE_URL } from "../../../constants";
// import { getStripeSubscriptionsAllCompanies } from '../../../actions/superAdminCompanyActions'
const SubsTableRow = ({ current, sNo, i }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      {current &&
        current.company &&
        current.company.companyId &&
        current.subscriptions && (
          <tr>
            <td>{sNo}</td>
            <td>
              <h2 className="table-avatar">
                <NavLink
                  to={`/client-profile/${current.company.companyId.id}`}
                  className="avatar"
                >
                  {current.company.userId.profilePic &&
                  current.company.userId.profilePic.fileUrl ? (
                    <img
                      alt="profilePic"
                      src={
                        BUCKET_BASE_URL +
                        current.company.userId.profilePic.fileUrl
                      }
                      style={{ objectFit: "fill", height: "100%" }}
                    />
                  ) : (
                    <img alt="profilePic" src={defaultImg} />
                  )}
                </NavLink>
                <NavLink to={`/client-profile/${current.company.companyId.id}`}>
                  {" "}
                  {current.company.companyId.companyName}{" "}
                </NavLink>
              </h2>
            </td>
            <td>{current.company.currentSubscriptionPackage.planName}</td>
            <td>{current.company.currentSubscriptionPackage.users}</td>
            <td>
              {(current.subscriptions.current_period_end -
                current.subscriptions.current_period_start) /
                (60 * 60) /
                24 +
                " days"}
            </td>
            <td>
              {moment
                .unix(current.subscriptions.current_period_start)
                .format("MM/DD/YYYY")}
            </td>
            <td>
              {moment
                .unix(current.subscriptions.current_period_end)
                .format("MM/DD/YYYY")}
            </td>
            <td>
              {current.subscriptions.trial_start !== null
                ? "Free"
                : "$" +
                  current.subscriptions.items.data[0].price.unit_amount / 100}
            </td>
            <td>
              {current.subscriptions.status === "active" ? (
                <span className="badge bg-inverse-success">
                  {current.subscriptions.status}
                </span>
              ) : (
                <span className="badge bg-inverse-danger">
                  {current.subscriptions.status}
                </span>
              )}
            </td>
            <td>
              <a
                className="btn btn-primary btn-sm"
                href="#"
                data-toggle="modal"
                onClick={openDropDown}
                data-target="#upgrade_plan"
              >
                Change Plan
              </a>
            </td>
            <td>
              {/* <div className="status-toggle">
                            <input type="checkbox" id="company_status_1" className="check" checked/>
                            <label for="company_status_1" className="checktoggle"></label>
                        </div> */}
            </td>
          </tr>
        )}
      <ChangePlanModel
        current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default SubsTableRow;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { reportTimeClockWeekly } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const TimeClockReportWeekly = ({ today }) => {
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);
  // const [ today, setToday ] = useState(moment())
  let date = moment(today.format("YYYY-MM-DD"));
  let dateForJS = moment(today.format("YYYY-MM-DD"));
  let compArr = [];
  let dateDay = [0, 1, 2, 3, 4, 5, 6];
  for (let e of dateDay)
    compArr[e] = dateForJS.startOf("week").add(++e, "days").format("DD");
  // console.log(compArr)
  const dispatch = useDispatch();
  const weeklyTimeClockReportData = () => {
    dispatch(
      reportTimeClockWeekly({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Weekly",
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  useEffect(() => {
    weeklyTimeClockReportData();
  }, [today]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      reportTimeClockWeekly({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Weekly",
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (
      i <= admin.timeClockReportWeekly[admin.timeClockReportWeekly.length - 5]
    ) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        reportTimeClockWeekly({
          companyId: auth.user.companyId,
          date: moment(today).format("YYYY-MM-DD"),
          type: "Weekly",
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  // console.log(currentPageNumber)
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        reportTimeClockWeekly({
          companyId: auth.user.companyId,
          date: moment(today).format("YYYY-MM-DD"),
          type: "Weekly",
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      reportTimeClockWeekly({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Weekly",
        limit: limit,
        page: pageNo,
      })
    );
  };
  return (
    <>
      <div className="row miordr2">
        <div className="col-md-12">
          <div className="table-responsive misftsch">
            <table className="table table-striped custom-table datatable schedul-rptbl">
              <thead>
                <tr>
                  <th colSpan="2">All Employees / Positions</th>
                  {date.format("dddd") !== "Sunday" ? (
                    <>
                      <th className="report-tbhd2 report-tbhd1st" colSpan="3">
                        {date
                          .startOf("week")
                          .add(1, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(2, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(3, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(4, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(5, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(6, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(7, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                    </>
                  ) : (
                    <>
                      <th className="report-tbhd2 report-tbhd1st" colSpan="3">
                        {date
                          .startOf("week")
                          .add(-6, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(2, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(3, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(4, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(5, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(6, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                      <th className="report-tbhd2" colSpan="3">
                        {date
                          .startOf("week")
                          .add(7, "days")
                          .format("MMM DD, YYYY")}
                      </th>
                    </>
                  )}
                  {/* <th className="report-tbhd2 report-tbhd1st" colSpan="3">May 14, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 15, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 16, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 17, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 18, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 19, 2022</th>
                                    <th className="report-tbhd2" colSpan="3">May 20, 2022</th> */}
                </tr>
                <tr className="report-tbhdrw2">
                  {/* <th>Name</th> */}
                  <th>
                    <div className="th-content">
                      <span>Employee</span>
                      {/* <span className="sort-icons">
                        <i className="fas fa-long-arrow-alt-up"></i>
                        <i className="fas fa-long-arrow-alt-down"></i>
                      </span> */}
                    </div>
                  </th>
                  <th className="report-tbhdtt1">Total</th>
                  <th className="report-tbhdst1 report-tbhdfst">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                  <th className="report-tbhdst1">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                </tr>
              </thead>
              <tbody>
                {admin.timeClockReportWeekly &&
                  admin.timeClockReportWeekly.length > 0 &&
                  admin.timeClockReportWeekly.map((current, i) => {
                    return (
                      i < admin.timeClockReportWeekly.length - 5 && (
                        <tr key={i}>
                          <td>
                            <h2 className="table-avatar">
                              <NavLink
                                to={`/profile/${current.e.id}`}
                                className="avatar"
                              >
                                {current.e &&
                                current.e.profilePic &&
                                current.e.profilePic.fileUrl ? (
                                  <img
                                    alt="profilePic"
                                    src={
                                      BUCKET_BASE_URL +
                                      current.e.profilePic.fileUrl
                                    }
                                    style={{
                                      objectFit: "fill",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <img alt="profilePic" src={defaultImg} />
                                )}
                              </NavLink>
                              <NavLink to={`/profile/${current.e.id}`}>
                                {current.e.firstName +
                                  ", " +
                                  current.e.lastName}
                                <span>{current.e.jobTitle}</span>
                              </NavLink>
                            </h2>
                          </td>
                          <td className="report-tbhdtt1">
                            {Math.round(current.totalHours * 100) / 100}
                          </td>

                          {current.weekData && current.weekData.length > 0
                            ? current.weekData.map((e, j) => {
                                return (
                                  <>
                                    {Object.keys(e).length != 0 ? (
                                      <>
                                        <td className="report-tbhdst1 report-tbhdfst">
                                          {e.dayClockIn
                                            .toString()
                                            .substring(11, 16)}
                                        </td>
                                        <td className="report-tbhden1">
                                          {e.dayClockOut
                                            .toString()
                                            .substring(11, 16)}
                                        </td>
                                        <td className="report-tbhdhr1">
                                          {e.hoursUsed}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="report-tbhdst1 report-tbhdfst"></td>
                                        <td className="report-tbhden1"></td>
                                        <td className="report-tbhdhr1"></td>
                                      </>
                                    )}
                                  </>
                                );
                              })
                            : [0, 0, 0, 0, 0, 0, 0].map((g) => {
                                return (
                                  <>
                                    <td className="report-tbhdst1 report-tbhdfst"></td>
                                    <td className="report-tbhden1"></td>
                                    <td className="report-tbhdhr1"></td>
                                  </>
                                );
                              })}
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
            <div className="row">
              {admin.timeClockReportWeekly &&
                admin.timeClockReportWeekly.length > 0 && (
                  <div className="col-sm-12 col-md-5">
                    <label>
                      {" "}
                      Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                      {admin.timeClockReportWeekly[
                        admin.timeClockReportWeekly.length - 4
                      ] +
                        limit * (currentPageNumber - 1)}{" "}
                      of{" "}
                      {
                        admin.timeClockReportWeekly[
                          admin.timeClockReportWeekly.length - 2
                        ]
                      }{" "}
                      entries{" "}
                    </label>
                  </div>
                )}
              <div className="col-sm-12 col-md-7">
                {admin.timeClockReportWeekly && (
                  <div className="avatar-pagination">
                    <ul
                      className="pagination"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={onClickPrev}
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {admin.timeClockReportWeekly &&
                        admin.timeClockReportWeekly.length > 0 &&
                        admin.timeClockReportWeekly[
                          admin.timeClockReportWeekly.length - 1
                        ].map((current, i) => {
                          return currentPageNumber === current ? (
                            <li className="page-item active" key={i}>
                              <a className="page-link" value={current}>
                                {current}
                              </a>
                            </li>
                          ) : (
                            <li className="page-item" key={i}>
                              <a
                                className="page-link"
                                value={current}
                                onClick={() => handleChangeData(current)}
                              >
                                {current}
                              </a>
                            </li>
                          );
                        })}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={onClickNext}
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div
                  className="dataTables_length"
                  id="DataTables_Table_0_length"
                >
                  <label>
                    Show &nbsp;
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="select"
                      autoComplete="false"
                      onChange={handleLimit}
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return <option value={current}>{current}</option>;
                      })}
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimeClockReportWeekly;

import { 
    OVERTIME_REQUEST,
    OVERTIME_SUCCESS,
    OVERTIME_FAIL,
    OVERTIME_TABLE_REQUEST,
    OVERTIME_TABLE_SUCCESS,
    OVERTIME_TABLE_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const overtimeReducer = (
    state = {
        overtime: {
            overtime: null,
            overtimeTable: null,
        }
    },

    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                overtime: null,
                overtimeTable: null,
            };


        case OVERTIME_REQUEST : 
            return {
                ...state,
            };
        case OVERTIME_SUCCESS :
            return {
                ...state,
                overtime: action.payload,
            };
        case OVERTIME_FAIL :
            return {
                ...state,
                error: action.payload,
            };



            case   OVERTIME_TABLE_REQUEST : 
            return {
                ...state,
            };
        case OVERTIME_TABLE_SUCCESS :
            return {
                ...state,
                overtimeTable: action.payload,
                // changes yeh dkhioooooooooooooo
            };
        case OVERTIME_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}
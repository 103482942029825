import React from 'react'

const SalaryInfo = () => {
    return (
        <>
            <div className="tab-content">
                <div className="" id="bank_statutory">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title"> Basic Salary Information</h3>
                            <form>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Salary basis <span className="text-danger">*</span></label>
                                            <select className="form-select" style={{ height: "44px", fontSize: "14px" }}>
                                                <option>Select salary basis type</option>
                                                <option>Hourly</option>
                                                <option>Daily</option>
                                                <option>Weekly</option>
                                                <option>Monthly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Salary amount <small className="text-muted">per month</small></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Type your salary amount" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Payment type</label>
                                            <select className="form-select" style={{ height: "44px", fontSize: "14px" }}>
                                                <option>Select payment type</option>
                                                <option>Bank transfer</option>
                                                <option>Check</option>
                                                <option>Cash</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SalaryInfo
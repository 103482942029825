import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
// import SearchByEmployee from '../../components/adminComponents/adminSchedulingReport/SearchByEmployee'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminReportTimeClkReport from '../../components/adminComponents/adminTimeClockReport/AdminReportTimeClkReport'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import SearchTimeClockReport from '../../components/adminComponents/adminTimeClockReport/SearchTimeClockReport'

const AdminTimeClockReport = () => {
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Time Clock Reports"/>
                        {/* <SearchByEmployee /> */}
                        <SearchTimeClockReport />
                        <AdminReportTimeClkReport />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminTimeClockReport
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyComponent from "../Map/Map";
// import { addLocation, getAllLocationsWithUsers } from '../../../actions/locationActions'
import { addLocation } from "../../../actions/locationActions";
// import MyComponent from '../../../pages/Test'

const AddLPModal = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);
  const [values, setValues] = useState({
    name: "",
    address: "",
    description: "",
    // phoneNumber: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const dispatch = useDispatch();
  const breakruleData = {
    companyId: auth.user.companyId,
    name: values.name,
    address: values.address,
    // description: values.description,
    // phoneNumber: values.phoneNumber,
    map: location.map,
    latitude: location.lat,
    longitude: location.lng,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    for (let key of Object.keys(breakruleData)) {
      if (breakruleData[key] === "") {
        delete breakruleData[key];
      }
    }
    dispatch(addLocation(breakruleData));
    // dispatch(getAllLocationsWithUsers({companyId: auth.user.companyId}))
    // openDropDown()
    setValues({
      companyId: "",
      name: "",
      address: "",
      description: "",
      // phoneNumber: '',
      map: "",
      latitude: "",
      longitude: "",
    });
    openDropDown();
  };

  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_plan"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Location Position</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    Location Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    autoComplete="false"
                    value={values.name}
                    onChange={handleChange("name")}
                    maxLength={40}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Description
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <textarea
                    rows="4"
                    className="form-control"
                    autoComplete="false"
                    value={values.description}
                    onChange={handleChange("description")}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>
                    Address<span className="text-danger">*</span>
                  </label>
                  {/* <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.address}
                    onChange={handleChange("address")}
                  /> */}
                  <MyComponent
                    // isRequired
                    values={values}
                    setValues={setValues}
                  />
                </div>

                {/* <div className="form-group">
                                    <label>Phone<span className="text-danger">*</span></label>
                                    <input className="form-control" type="text"
                                    autoComplete="false" value={values.phoneNumber}
                                    onChange={handleChange('phoneNumber')}/>
                                </div> */}

                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddLPModal;

{
  /* <div className="form-group"> */
}
{
  /* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90325.47666039692!2d-93.33151828200064!3d44.970675595414185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b333909377bbbd%3A0x939fc9842f7aee07!2sMinneapolis%2C%20MN%2C%20USA!5e0!3m2!1sen!2s!4v1648644861491!5m2!1sen!2s" width="100%" height="250" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */
}
{
  /* <MyComponent values={values} setValues={setValues} />
</div>; */
}

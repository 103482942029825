import {
    BREAKRULE_CREATE_REQUEST,
    BREAKRULE_CREATE_SUCCESS,
    BREAKRULE_CREATE_FAIL,
    BREAKRULE_REQUEST,
    BREAKRULE_SUCCESS,
    BREAKRULE_FAIL,
    BREAKRULE_UPDATE_REQUEST,
    BREAKRULE_UPDATE_SUCCESS,
    BREAKRULE_UPDATE_FAIL,
    BREAKRULE_REMOVE_REQUEST,
    BREAKRULE_REMOVE_SUCCESS,
    BREAKRULE_REMOVE_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const breakruleReducer = (
    state = {
        breakrule : {
            breakRules: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                breakRules: null,
            };
    
        case BREAKRULE_CREATE_REQUEST :
            return {
                ...state,
            }
        case BREAKRULE_CREATE_SUCCESS :
            return {
                ...state,
                breakRules: [...state.breakRules, action.payload],
            }
        case BREAKRULE_CREATE_FAIL :
            return {
                ...state,

                error: action.payload,
            }


        case BREAKRULE_REQUEST :
            return {
                ...state,
            };
        case BREAKRULE_SUCCESS :
            return {
                ...state,
                breakRules: action.payload
            };
        case BREAKRULE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        
        // UPDATE BREAKRULE
        case BREAKRULE_UPDATE_REQUEST :
            return {
                ...state,
            };
        case BREAKRULE_UPDATE_SUCCESS :
            return {
                ...state,
                breakRules: state.breakRules.map((x) =>
                x.id === action.payload.id ? action.payload : x
              ),
            };
        case BREAKRULE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // REMOVE BREAKRULE
        case BREAKRULE_REMOVE_REQUEST :
            return {
                ...state,
            };
        case BREAKRULE_REMOVE_SUCCESS :
            return {
                ...state,
            //     breakRules: state.breakRules.map((x) =>
            //     x.id == action.payload.id ? action.payload : x
            //   ),
            };
        case BREAKRULE_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        default:
            return state;
    }
}
import React, { useState } from 'react'
import SuperAdminClientInvoicesTable from './SuperAdminClientInvoicesTable'
import SuperAdminClientRepresentative from './SuperAdminClientRepresentative'
import SuperAdminClientSubsriptionTable from './SuperAdminClientSubsriptionTable'

const SuperAdminProfileNavBar = () => {
    const [ viewBarItems, setViewBarItems ] = useState("Subscription")
    const handleSubscription = (e) => {
        setViewBarItems("Subscription")
    }
    const handleCompanyRepresentative = (e) => {
        setViewBarItems("Representative")
    }
    const handleInvoices = (e) => {
        setViewBarItems("Invoices")
    }

  return (
    <>
    
    { 
        viewBarItems === "Subscription" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" style={{cursor: "pointer"}} onClick={handleSubscription} className="nav-link active">Subscription</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Representative" style={{cursor: "pointer"}} onClick={handleCompanyRepresentative} className="nav-link">Representative</a >
                        </li>
                        <li className="nav-item">
                            <a to="#salary_structure" style={{cursor: "pointer"}} onClick={handleInvoices} className="nav-link">Invoices </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <SuperAdminClientSubsriptionTable />
        </>
        :
        viewBarItems === "Representative" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" style={{cursor: "pointer"}} onClick={handleSubscription} className="nav-link">Subscription</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Representative" style={{cursor: "pointer"}} onClick={handleCompanyRepresentative} className="nav-link active">Representative</a >
                        </li>
                        <li className="nav-item">
                            <a to="#salary_structure" style={{cursor: "pointer"}} onClick={handleInvoices} className="nav-link">Invoices </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <SuperAdminClientRepresentative />
        </>
        :
        viewBarItems === "Invoices" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item ">
                            <a to="#emp_profile" style={{cursor: "pointer"}} onClick={handleSubscription} className="nav-link">Subscription</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Representative" style={{cursor: "pointer"}} onClick={handleCompanyRepresentative} className="nav-link">Representative</a >
                        </li>
                        <li className="nav-item">
                            <a to="#salary_structure" style={{cursor: "pointer"}} onClick={handleInvoices} className="nav-link active">Invoices </a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <SuperAdminClientInvoicesTable />
        </>
        :
        <div></div>
        }
    </>
  )
}
export default SuperAdminProfileNavBar
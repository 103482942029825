import React from 'react'
import { useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants';

const TrackingUserDetails = () => {
    const user = useSelector((state) => state.user); 
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h6 className="card-title m-b-15">Employee details</h6>
                    <div className="new-cover-img" style={{alignContent: "center", alignItems:"center"}}>
                        {
                            user.userById &&
                            user.userById.user &&
                            user.userById.user.profilePic &&
                            user.userById.user.profilePic.fileUrl ?
                            <img alt="profilePic" src={BUCKET_BASE_URL + user.userById.user.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                            :
                            <img alt="profilePic" src={defaultImg} />
                        }
                    </div>
                    <br />
                    <table className="table table-striped table-border">
                        <tbody>
                            {
                                user.userById &&
                                user.userById.user &&
                                <>
                                    <tr>
                                        <td>Name:</td>
                                        <td className="text-right">{user.userById.user.firstName + " " + user.userById.user.lastName}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone:</td>
                                        <td className="text-right">{user.userById.user.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Position:</td>
                                        <td className="text-right">{user.userById.user.jobTitle}</td>
                                    </tr>
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TrackingUserDetails

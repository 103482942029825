import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { BUCKET_BASE_URL } from "../../../constants";
import cake from "../../../assetsAdmin/img/cakeundraw_Birthday_cake_re_bsw5.png";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";

const AllAnnievesaryModal = ({
  showAnnieversaryDropDown,
  openAnnieversaryDropDown,
  current,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);

  return (
    <>
      <div
        className={`modal-backdrop fade ${
          showAnnieversaryDropDown ? "show" : "hide"
        }`}
      ></div>
      <div
        className={`modal custom-modal fade ${
          showAnnieversaryDropDown ? "show" : "hide"
        }`}
        id="delete_warn"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openAnnieversaryDropDown(); // Call your close modal function
          }
        }}
      >
        {/* modal custom-modal fade */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Annual Annieversaries</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => openAnnieversaryDropDown()}
                >
                  <i className="fa fa-close"></i>
                </button>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  {admin.AnnualAnniversary &&
                  admin.AnnualAnniversary.length > 0 ? (
                    admin.AnnualAnniversary.map((current, i) => {
                      return (
                        current.user.length > 0 && (
                          <>
                            {current.user.map((e, index) => {
                              return (
                                <div className="leave-info-box py-2">
                                  <div className="media align-items-center">
                                    <NavLink
                                      to={`/profile/${e.id}`}
                                      className="avatar"
                                    >
                                      {e.profilePic && e.profilePic.fileUrl ? (
                                        <img
                                          alt="profilePic"
                                          src={
                                            BUCKET_BASE_URL +
                                            e.profilePic.fileUrl
                                          }
                                          style={{
                                            objectFit: "fill",
                                            height: "100%",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          alt="profilePic"
                                          src={defaultImg}
                                        />
                                      )}
                                    </NavLink>
                                    <div className="media-body">
                                      <div className="text-sm my-0">
                                        {e.firstName}, {e.lastName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center mt-2">
                                    <div className="col-6">
                                      <h6 className="mb-0">
                                        {parseInt(
                                          e.startingDate.substring(0, 4)
                                        ) ===
                                        parseInt(
                                          moment().format().substring(0, 4)
                                        )
                                          ? "Recently Joined"
                                          : parseInt(
                                              moment().format().substring(0, 4)
                                            ) -
                                            parseInt(
                                              e.startingDate.substring(0, 4)
                                            ) +
                                            " Year Completed"}
                                      </h6>
                                      <span className="text-sm text-muted"></span>
                                    </div>
                                    <div className="col-6 text-right">
                                      <span className="badge bg-primary text-white">
                                        {i === 0
                                          ? "Today"
                                          : i === 1
                                          ? " Tomorrow"
                                          : " In " + (i + 1) + " days"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )
                      );
                    })
                  ) : (
                    <>
                      <br />
                      <br />
                      <div className="dash-stats-list">
                        <img
                          alt=""
                          src={cake}
                          style={{ width: "110px", opacity: "0.8" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Annual Anniversary</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllAnnievesaryModal;

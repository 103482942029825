import moment from "moment";
// import React, { useState } from 'react'
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import shift3 from "../../../assetsAdmin/img/undraw_Booking_re_gw4j.png";
// import EditModalRecentShift from './EditModalRecentShift'
const ProfileCardRecentShift = () => {
  const user = useSelector((state) => state.user);
  // const [showDropDown, setshowDropDown] = useState(false)
  // const openDropDown = () => {
  //     setshowDropDown((showDropDown) => !showDropDown)
  // }
  // console.log(showDropDown)
  return (
    <>
      <div className="col-md-6 d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">
              Recent Shifts
              {/* <a href="#" className="edit-icon" data-toggle="modal" onClick={openDropDown} data-target="#edit_schedule"><i className="fa fa-pencil"></i></a> */}
            </h3>
            {/* <EditModalRecentShift showDropDown={showDropDown} openDropDown={openDropDown}/> */}
            <div className="experience-box miprcrl">
              <ul className="experience-list ">
                {user.userById &&
                user.userById.shift &&
                user.userById.shift.length > 0 &&
                user.userById.shift[0].recent &&
                user.userById.shift[0].recent.length > 0 ? (
                  user.userById.shift[0].recent.map((current, i) => {
                    return (
                      <li key={i}>
                        <div className="experience-user">
                          <div className="before-circle"></div>
                        </div>
                        <div className="experience-content">
                          <div className="timeline-content">
                            <div>
                              {moment(
                                current.startTime.substring(0, 10)
                              ).format("MM/DD/YYYY")}
                            </div>
                            {current.location ? (
                              <NavLink
                                to={`/location-view/${current.location.id}`}
                                className="name"
                              >
                                {current.location.name} -{" "}
                                <strong>{user.userById.user.jobTitle}</strong>
                              </NavLink>
                            ) : (
                              <NavLink
                                to="#"
                                className="name"
                                style={{ cursor: "default" }}
                              >
                                <strong>{user.userById.user.jobTitle}</strong>
                              </NavLink>
                            )}
                            <span className="time">
                              {current.startTime.substring(11, 16)} -{" "}
                              {current.endTime.substring(11, 16)}
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="dash-stats-list">
                      <img
                        alt=""
                        src={shift3}
                        style={{ width: "110px", opacity: "0.8" }}
                      />
                      <br />
                      <p style={{ opacity: "0.5" }}>No Recent Shifts</p>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileCardRecentShift;

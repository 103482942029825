import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const SuperAdminClientInvoicesTable = () => {
  const company = useSelector((state) => state.company);
  // const auth = useSelector((state) => state.auth)
  // const dispatch = useDispatch()
  // const superAdminCompanyCPC = async (e) => {
  //     dispatch(getSuperAdminCompany({companyId: auth.user.companyId }))
  // }
  // useEffect(() => {
  //     superAdminCompanyCPC()
  //  }, [])

  return (
    <>
      <div className="tab-content">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title"> Recent Invoices</h3>
            <table className="table table-nowrap custom-table mb-0">
              <thead>
                <tr>
                  <th>Invoice ID</th>
                  <th>Plan</th>
                  <th>Issue Date</th>
                  <th>Due Date</th>
                  <th>Total</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {company.superAdminCompanyCPC[0] &&
                  company.superAdminCompanyCPC[0].allInvoices.data &&
                  company.superAdminCompanyCPC[0].allInvoices.data.length > 0 &&
                  company.superAdminCompanyCPC[0].allInvoices.data.map(
                    (current, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <a href="#">{current.id}</a>
                          </td>
                          <td>
                            <h2>
                              <a href="#">100 User Premium</a>
                            </h2>
                          </td>
                          <td>
                            {moment
                              .unix(current.lines.data[0].period.start)
                              .format("MM/DD/YYYY")}
                          </td>
                          <td>
                            {moment
                              .unix(current.lines.data[0].period.end)
                              .format("MM/DD/YYYY")}
                          </td>
                          <td>
                            ${current.lines.data[0].price.unit_amount / 100}
                          </td>
                          <td>
                            {current.status === "paid" ? (
                              <span className="badge bg-success text-white">
                                {current.status}
                              </span>
                            ) : (
                              <span className="badge bg-danger text-white">
                                {current.status}
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminClientInvoicesTable;

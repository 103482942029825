import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/AdminHeader";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";

import "./App.css";

import "../../assetsAdmin/css/bootstrap.min.css";
import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css";
import "../../assetsAdmin/css/dataTables.bootstrap4.min.css";
import "../../assetsAdmin/css/font-awesome.min.css";
import "../../assetsAdmin/css/fullcalendar.min.css";
import "../../assetsAdmin/css/jquery.circliful.css";
import "../../assetsAdmin/css/line-awesome.min.css";
import "../../assetsAdmin/css/morris.css";
import "../../assetsAdmin/css/select2.min.css";
import "../../assetsAdmin/css/style.css";
import "../../assetsAdmin/css/tagsinput.css";

import { useDispatch, useSelector } from "react-redux";
import {
  MESSAGE_RECEIVE_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  SOCKETADDRESS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECT,
} from "../../constants";
import { useState } from "react";
import io from "socket.io-client";

import { useRef } from "react";
import { getNotificationsAction } from "../../actions/notificationActions";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";

let socketInstance;
const SuperAdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    dispatch(getNotificationsAction(auth.user.id));

    socketInstance = io(SOCKETADDRESS);

    socketInstance.emit("setup", auth.user.id);
    socketInstance.on("connected", () => {
      // setSocketConnected(true);
      dispatch({
        type: SOCKET_CONNECTED,
        payload: { socketInstance, socketConnected: true },
      });
      // console.log()
    });

    socketInstance.on("error", function (err) {
      socketInstance.reconnect();
    });
    socketInstance.on("reconnect", () => {
      // ...
      dispatch({
        type: SOCKET_CONNECTED,
        payload: { socketInstance, socketConnected: true },
      });
    });
    return () => {
      socketInstance.disconnect();
      dispatch({
        type: SOCKET_DISCONNECT,
        payload: { socketInstance: null, socketConnected: false },
      }); // socket.off("login");
    };
  }, []);

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("message recieved", (newMessageRecieved) => {
        console.log("message received==>", newMessageRecieved);

        dispatch({
          type: MESSAGE_RECEIVE_SUCCESS,
          payload: newMessageRecieved,
        });

        // const lastChildElement = scrollRef.current?.lastElementChild;
        // lastChildElement?.scrollIntoView({ behavior: "smooth" });
        // if (
        //   (!selectedChatCompare || selectedChatCompare._id) !==
        //   newMessageRecieved.chatId._id
        // ) {
        //   if (!notifications.includes(newMessageRecieved)) {
        //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
        //   }
        // } else {
        //   setMessages([...messages, newMessageRecieved]);
        // }
        // dispatch(fetchChats());
      });

      socketInstance.on("notification recieved", (notificationReceived) => {
        console.log("notification received==>", notificationReceived);

        dispatch({
          type: NOTIFICATION_RECEIVE_SUCCESS,
          payload: notificationReceived,
        });
        notificationRef.current.play();
      });
    }
  }, [socketInstance]);
  const notificationRef = useRef();

  return (
    <>
      <SuperAdminHeader />
      <SuperAdminSidebar />
      {/* <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid"> */}
      {children}
      {/* <AdminTittle admin/>
                        <AdminDashboardCard />
                        <ShiftTime/>
                        <EmployeeDetails/> */}
      {/* </div>
        </div>
      </div> */}
      <audio src="/notification.wav" ref={notificationRef}></audio>
    </>
  );
};
export default SuperAdminLayout;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AddSalaryModal from "./AddSalaryModal";
import { useSelector, useDispatch } from "react-redux";
import { tabPayroll, tabEmployeeSalaries } from "../../../actions/tabActions";

const AdminPayrollandEmployeesSal = () => {
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState.viewPayrollMenu)
  const dispatch = useDispatch();

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // console.log(showDropDown)
  return (
    <>
      {myState.viewPayrollMenu === "payrollitem" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item active">
                <NavLink
                  to="#payroll-items"
                  onClick={() => dispatch(tabPayroll())}
                  className=""
                >
                  Payroll
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#salary"
                  onClick={() => dispatch(tabEmployeeSalaries())}
                  className=""
                >
                  Employee Salaries
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto"></div>
        </>
      ) : myState.viewPayrollMenu === "employeesalaries" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#payroll-items"
                  onClick={() => dispatch(tabPayroll())}
                  className=""
                >
                  Payroll
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#salary"
                  onClick={() => dispatch(tabEmployeeSalaries())}
                  className=""
                >
                  Employee Salaries
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_salary"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Salary
            </NavLink>
          </div>
          <AddSalaryModal
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        </>
      ) : myState.viewPayrollMenu === "search" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#payroll-items"
                  onClick={() => dispatch(tabPayroll())}
                  className=""
                >
                  Payroll
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#salary"
                  onClick={() => dispatch(tabEmployeeSalaries())}
                  className=""
                >
                  Employee Salaries
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_salary"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Salary
            </NavLink>
          </div>
          <AddSalaryModal
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        </>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};
export default AdminPayrollandEmployeesSal;

import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import img1 from '../../assetsgeneral/images/img1.png'
import img2 from '../../assetsgeneral/images/img2.png'
import img3 from '../../assetsgeneral/images/img3.png'
import img4 from '../../assetsgeneral/images/img4.png'

const OurSolutions = () => {
    return (
        <>
            <div className="our-solutin-section">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="oursolut">
                                <h2>Our Solutions For All </h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                        <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-icons">
                                <img src={img1} id="img1" alt='solutions'/>
                            </div>
                            <div className="ousoltext">
                                <h2>Retail </h2>
                            </div>
                        </AnimationOnScroll>
                        </div>

                        <div className="col-md-3">
                        <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true" delay="300">
                            <div className="oursolut-icons">
                                <img src={img2} id="img1" alt='solutions'/>
                            </div>
                            <div className="ousoltext">
                                <h2>Health Care </h2>
                            </div>
                        </AnimationOnScroll>
                        </div>

                        <div className="col-md-3">
                        <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true" delay="600">
                            <div className="oursolut-icons">
                                <img src={img3} id="img1" alt='solutions'/>
                            </div>
                            <div className="ousoltext">
                                <h2>Call Center </h2>
                            </div>
                        </AnimationOnScroll>
                        </div>
                        <div className="col-md-3">
                        <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true" delay="900">
                            <div className="oursolut-icons">
                                <img src={img4} id="img1" alt='solutions'/>
                            </div>
                            <div className="ousoltext">
                                <h2>Universities </h2>
                            </div>
                        </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurSolutions
// import React, { useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
// import EditModalTotalLeave from './EditModalTotalLeave'
const ProfileCardTotalLeave = () => {
    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log(showDropDown)
    const user = useSelector((state) => state.user);
    return (
        <>
            <div className="col-md-6 d-flex">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title">Leaves 
                            {/* <a href="#" className="edit-icon" data-toggle="modal" onClick={openDropDown} data-target="#edit_leave"><i className="fa fa-pencil"></i></a> */}
                        </h3>
                        {/* <EditModalTotalLeave showDropDown={showDropDown} openDropDown={openDropDown}/> */}
                        <div className="table-responsive milevtb">
                            <table className="table table-nowrap">
                                <thead>
                                    <tr>
                                        <th>Leave Types</th>
                                        <th className="text-center">Total Leave</th>
                                        <th className="text-center">Remaining Leave</th>
                                        {/* <th className="text-center">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    user.userById &&
                                    user.userById.shift &&
                                    user.userById.shift.length > 0 &&
                                    user.userById.shift[0].annualLeave &&
                                    user.userById.shift[0].annualLeave.length > 0 &&
                                    user.userById.shift[0].annualLeave.map((current, i) => {
                                        return(
                                            <tr key={i}>
                                                <td>
                                                    <strong>{current.leaveCategory.name}</strong>
                                                </td>
                                                <td className="text-center">{current.annualLeavesDays}</td>
                                                <td className="text-center">{current.remainingLeavesDays}</td>
                                            </tr>
                                        )})}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfileCardTotalLeave
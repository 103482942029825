import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";
const SuperAdminSubscriptionPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Subscribed Companies" />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminSubscriptionPage;

import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/Footer'
import EmailSentToUser from '../components/frontendComponents/identifyUserEmail/EmailSentToUser'
// import IdentifyUserEmailBanner from '../components/frontendComponents/identifyUserEmail/IdentifyUserEmailBanner'
import IdentifyUserEmailForm from '../components/frontendComponents/identifyUserEmail/IdentifyUserEmailForm'
import NavBar from '../components/NavBar'
// import { useState } from 'react'
// import { useDispatch } from 'react-redux'
// import ViewIdentifyUserComponent from '../components/frontendComponents/identifyUserEmail/ViewIdentifyUserComponent'
// import { tabIdentifyUser, tabLinkSendToUser } from '../actions/tabActions' 
const EnterEmailPage = () => {
  const myState = useSelector((state => state.changeTheTab))
  // const [showDropDown, setshowDropDown] = useState(false)
  // const dispatch = useDispatch()
  // const openDropDown = () => {
  //   setshowDropDown((showDropDown) => !showDropDown)
  // }
  return (
    <>
      <NavBar />
      {/* <IdentifyUserEmailBanner /> */}
      {/* <IdentifyUserEmailForm /> */}
      {
        myState.viewOfIdentifyUser === "identify" ?
        <>
          <IdentifyUserEmailForm/>
        </>
        : 
        myState.viewOfIdentifyUser === "requestSend" ?
        <>
          <EmailSentToUser />
        </>
        : 
        <div>Loading...</div>            
      }
      {/* <ViewIdentifyUserComponent /> */}
      {/* <EmailSentToUser /> */}
      <Footer />
    </>
  )
}
export default EnterEmailPage
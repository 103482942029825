import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import aboutusnew from '../../assetsgeneral/images/aboutusnew-removebg-preview.png'
// import aboutus from '../../assetsgeneral/images/aboutus.png'
// import aboutusnew from '../../assetsgeneral/images/aboutusnew.png'
// import aboutus from '../../assetsgeneral/images/advertiser-thumb.png'

const AboutUsInfo = () => {
    return (
        <>
            <div className="about-us-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-uss-text">
                                <h2> About Us </h2>
                                <p>RiyoWork is the leading cloud-based employee scheduling platform that accelerates schedule creation by up to 80 percent. Organizations of all sizes receive unprecedented insight into their operations, and actionable data to optimize staffing based on employee skill set, time-off/leave management, and staff availability.</p>
                                <p>Riyo-Work is offered as SaaS and can be deployed as a stand-alone solution in the cloud or integrated quickly and easily with today’s leading HCM platforms and payroll solutions.Riyo-work simplifies day-to-day employee management processes.With Riyo-work, your organization can easily and accurately create error-free, dynamic schedules for your staff by location, department, position, and/or skills—with real-time availability conflict checking </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <AnimationOnScroll animateIn='fadeInRight' duration="2.5" animateOnce="true" >
                            <div className="about-uss-img">
                                <img src={aboutusnew} id="about-img" />
                            </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUsInfo
import React from "react";
import ChatComponent from "../../components/chatComponent";

function SuperAdminChatPage() {
  return (
    <>
      {/* <AdminHeader />
      <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper chat-page " style={{ height: "100vh" }}>
          {/* <div
            className="content container-fluid"
        
          > */}
          {/* <AdminTittle admin /> */}
          {/* <section style={{ backgroundColor: "#eee" }}> */}
          {/* <div class="container ">
                <div class="row" style={{}}> */}
          <ChatComponent />
          {/* </div>
              </div> */}
          {/* </section> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default SuperAdminChatPage;

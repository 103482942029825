import React, { useEffect, useState } from 'react'
import { getSuperAdminCompany } from '../../../actions/superAdminCompanyActions'
import { useDispatch, useSelector } from 'react-redux'
import CompaniesCardsRow from './CompaniesCardsRow'
import CompanySearchedFilterCards from './CompanySearchedFilterCards'

const CompaniesCards = () => {
    const myState = useSelector((state => state.changeTheTab))
    const [ dropDownRow, setDropDownRow ] = useState(null)
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const company = useSelector((state) => state.company)
    const dispatch = useDispatch()
    const superAdminCompanyCC = async (e) => {
        dispatch(getSuperAdminCompany({companyId: auth.user.companyId }))
    }
    useEffect(() => {
        superAdminCompanyCC()
    }, [])
    return (
        <>
            {
                myState.viewIfCompanySearch === "company" ?
                <div className="row staff-grid-row">
                {
                    company.superAdminCompanyCC &&
                    company.superAdminCompanyCC.length > 0 &&
                    company.superAdminCompanyCC.map((current, i) => {
                        return (
                            <CompaniesCardsRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                        )}
                    )
                }
                </div>
                : 
                myState.viewIfCompanySearch === "searchFilter" ?
                <div className="row staff-grid-row">
                {
                    company.searchCompany &&
                    company.searchCompany.length > 0 &&
                    company.searchCompany.map((current, i) => {
                        return (
                            <CompanySearchedFilterCards current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                        )}
                    )
                }
                </div>
                : 
                    <div>Loading</div>
            
            }
        </>
    )
}
export default CompaniesCards
import React from "react";
import AdminSettingView from "../../components/adminComponents/adminSettingView/AdminSettingView";

const AdminAdministrationSettingView = () => {
  return (
    <>
      {/* <AdminHeader />
            <AdminSideBar /> */}
      <AdminSettingView />
    </>
  );
};
export default AdminAdministrationSettingView;

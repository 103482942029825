import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import SAdminUsersSearchAndFilter from "../../components/superAdminCompoents/superAdminUsersAdministration/SAdminUsersSearchAndFilter";
import SAdminUsersTable from "../../components/superAdminCompoents/superAdminUsersAdministration/SAdminUsersTable";
const SuperAdminUsersPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Users" />
            <SAdminUsersSearchAndFilter />
            <SAdminUsersTable />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminUsersPage;

import {
    MESSAGE_WALL_CREATE_REQUEST,
    MESSAGE_WALL_CREATE_SUCCESS,
    MESSAGE_WALL_CREATE_FAIL,
    MESSAGE_WALL_TABLE_REQUEST,
    MESSAGE_WALL_TABLE_SUCCESS,
    MESSAGE_WALL_TABLE_FAIL,
    MESSAGE_WALL_UPDATE_REQUEST,
    MESSAGE_WALL_UPDATE_SUCCESS,
    MESSAGE_WALL_UPDATE_FAIL,
    REMOVE_MESSAGE_WALL_REQUEST,
    REMOVE_MESSAGE_WALL_SUCCESS,
    REMOVE_MESSAGE_WALL_FAIL,
    COMMENT_CREATE_REQUEST,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_FAIL,
    COMMENT_TABLE_REQUEST,
    COMMENT_TABLE_SUCCESS,
    COMMENT_TABLE_FAIL,
    COMMENT_UPDATE_REQUEST,
    COMMENT_UPDATE_SUCCESS,
    COMMENT_UPDATE_FAIL,
    REMOVE_COMMENT_REQUEST,
    REMOVE_COMMENT_SUCCESS,
    REMOVE_COMMENT_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const messageWallReducer = (
    state = {
        messageWall : {
            getMessageWallById: null,
            getCommentById: null,
            }
        },
        action
    ) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                getMessageWallById: null,
            };

        // MESSAGE_WALL CREATE
        case MESSAGE_WALL_CREATE_REQUEST :
            return {
                ...state,
            };
        case MESSAGE_WALL_CREATE_SUCCESS :
            // console.log('MESSAGE_WALL_CREATE_SUCCESS:', action.payload);
            return {
                ...state,  // Spread the current state to retain other properties.
                getMessageWallById: [ action.payload, ...state.getMessageWallById, ]
            };
        case MESSAGE_WALL_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case MESSAGE_WALL_TABLE_REQUEST : 
            return {
                ...state,
            };
        case MESSAGE_WALL_TABLE_SUCCESS :
            return {
                ...state,
                getMessageWallById : action.payload,
            };
        case MESSAGE_WALL_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case MESSAGE_WALL_UPDATE_REQUEST :
            return {
                ...state,
            };
        case MESSAGE_WALL_UPDATE_SUCCESS :
            return {
                ...state,
                // policyTable: [...state.policyTable, action.payload],
                // getMessageWallById: {...state.getMessageWallById, getMessageWallById:state.getMessageWallById.map((x) =>
                // x.id === action.payload.id ? action.payload : x
                // )},
                // getMessageWallById: state.getMessageWallById.map((x) =>
                //     x.id === action.payload.id ? action.payload : x
                //   ),
                // getMessageWallById: state.getMessageWallById.map((x) =>
                //     x.isPin === action.payload.isPin === true ? action.payload.isPin === true : action.payload.isPin === false
                //   ),
                getMessageWallById: state.getMessageWallById.map((x) =>
                    x.id === action.payload.id 
                      ? { ...x, ...action.payload, isPin: true }  // Set isPin true for the selected message
                      : { ...x, isPin: false }  // Set isPin false for all others
                  ),
                  
            };
        case MESSAGE_WALL_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case REMOVE_MESSAGE_WALL_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_MESSAGE_WALL_SUCCESS :
            return {
                ...state,
            getMessageWallById: state.getMessageWallById.filter((x) => x.id !== action.payload.id),
            // getMessageWallById: state.getMessageWallById.filter((x) =>
            //     x.id === action.payload.id 
            //       ? { ...x, ...action.payload, isPin: true }  // Set isPin true for the selected message
            //       : { ...x, isPin: false }  // Set isPin false for all others
            //   ),
            };
        case REMOVE_MESSAGE_WALL_FAIL :
            return {
                ...state,
                error: action.payload,
            };

                
    // COMMENT CREATE
    case COMMENT_CREATE_REQUEST :
        return {
            ...state,
        };
    case COMMENT_CREATE_SUCCESS :
        console.log('COMMENT_CREATE_SUCCESS:', action.payload);
        return {
            ...state,  // Spread the current state to retain other properties.
            getCommentById: [ ...state.getCommentById, action.payload,]
        };
    case COMMENT_CREATE_FAIL :
        return {
            ...state,
            error: action.payload,
        };


    case COMMENT_TABLE_REQUEST : 
        return {
            ...state,
        };
    case COMMENT_TABLE_SUCCESS :
        return {
            ...state,
            getCommentById : action.payload,
        };
    case COMMENT_TABLE_FAIL :
        return {
            ...state,
            error: action.payload,
        };


    case COMMENT_UPDATE_REQUEST :
        return {
            ...state,
        };
    case COMMENT_UPDATE_SUCCESS :
        return {
            ...state,
            
            getCommentById: state.getCommentById.map((x) =>
                x.id === action.payload.id ? action.payload : x
              ),
        };
    case COMMENT_UPDATE_FAIL :
        return {
            ...state,
            error: action.payload,
        };

    case REMOVE_COMMENT_REQUEST : 
        return {
            ...state,
        };
    case REMOVE_COMMENT_SUCCESS :
        
        console.log('REMOVE_COMMENT_SUCCESS payload:', action.payload); // Debugging
        console.log('Success payload:', action.payload);
        console.log('Before update, policyTable:', state.getCommentById)
        return {
            ...state,
        
        getCommentById: state.getCommentById.filter((x) => x.id !== action.payload.id),
        };
    case REMOVE_COMMENT_FAIL :
        return {
            ...state,
            error: action.payload,
        };

    
        default:
            return state;
    }
}
import React from 'react'
import AllActivities from '../../components/employeeComponents/employeeActivitiesComponents/AllActivities'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeActivities = () => {
  return (
    <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Activities" />
            <AllActivities />
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeActivities
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBreakrule } from "../../actions/breakruleAction";
import { createChatAction } from "../../actions/chatActions";

const CreateChatModal = ({ openModal, setOpenModal }) => {
  // const auth = useSelector((state) => state.auth);
  // const [values, setValues] = useState({
  //   companyId: "",
  //   breakName: "",
  //   breakType: "",
  //   minimumShiftDuration: "",
  //   breakDuration: "",
  // });
  // const handleChange = (fieldName) => (event) => {
  //   setValues({
  //     ...values,
  //     [fieldName]: event.target.value,
  //   });
  // };
  // console.log(values)
  const dispatch = useDispatch();
  // const breakruleData = {
  //   companyId: auth.user.companyId,
  //   breakName: values.breakName,
  //   breakType: values.breakType,
  //   minimumShiftDuration: parseInt(values.minimumShiftDuration),
  //   breakDuration: parseInt(values.breakDuration),
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // console.log("first")
  //   dispatch(addBreakrule(breakruleData));
  //   // openDropDown()
  //   setValues({
  //     companyId: "",
  //     breakName: "",
  //     breakType: "",
  //     minimumShiftDuration: "",
  //     breakDuration: "",
  //   });
  // };

  const mail = useSelector((state) => state.mail);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    receiverId: "",

    user: auth.user,
    company: auth.user.companyId,
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [memberObj, setMemberObj] = useState(null);
  const handleMembersChange = (fieldName) => (event) => {
    const abc = JSON.parse(event.target.value);
    // console.log(event.target.value, values.members.includes(abc.id) === false)
    console.log("abc==", abc);
    if (abc) {
      setValues({
        ...values,
        [fieldName]: abc.id,
      });
      setMemberObj(abc);
    }
  };
  // console.log(values, "ssaaaaaaaaaaassasssasasasasasa", membersObj)
  const deleteHandler = (id) => {
    setMemberObj(null);

    setValues({ ...values, receiverId: "" });
  };
  const groupData = {
    ...values,
    receiverId: values.receiverId,
  };
  // console.log("mail data==>", mailData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(createChatAction(groupData, successHandler));
  };

  const successHandler = (obj) => {
    setOpenModal(false);
    // socket.emit("new message", {
    //   sender: {
    //     firstName: auth.user.firstName,
    //     lastName: auth.user.lastName,
    //     id: auth.user.id,
    //     profilePic: {
    //       fileUrl: auth.user.profilePic.fileUrl,
    //     },
    //   },
    //   chatId: chats.selectedChat,
    //   message: message,
    //   dateTime,
    // });
  };

  console.log(values, memberObj);
  return (
    <>
      <div
        className={`modal-backdrop fade ${openModal ? "show" : "hide"}`}
      ></div>
      <div
        id="add_plan"
        className={`modal custom-modal fade ${openModal ? "show" : "hide"}`}
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            setOpenModal(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setOpenModal(false)}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="modal-body">
              <h5 className="modal-title text-center mb-3">Create Chat</h5>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          {/* <input type="email" 
                                    placeholder="To" className="form-control" 
                                    /> */}
                          <label className="col-form-label">
                            Select User:{" "}
                          </label>

                          <select
                            className="form-select"
                            style={{ height: "44px", fontSize: "14px" }}
                            autoComplete="false"
                            onChange={handleMembersChange("receiverId")}
                          >
                            <option value="">To</option>
                            {user.allUsersWithoutPaginate &&
                              user.allUsersWithoutPaginate.length > 0 &&
                              user.allUsersWithoutPaginate.map((current, i) => {
                                return (
                                  <option
                                    value={JSON.stringify({
                                      id: current.id,
                                      name:
                                        current.firstName +
                                        " " +
                                        current.lastName,
                                      email: current.email,
                                      jobTitle: current.jobTitle.toUpperCase(),
                                    })}
                                    key={i + 1}
                                  >
                                    {current.firstName + " " + current.lastName}{" "}
                                    {` ( ${current.jobTitle.toUpperCase()} )`}
                                  </option>
                                );
                              })}
                          </select>
                          <div className="form-group">
                            <br />
                            <label className="col-form-label">
                              Selected User:{" "}
                            </label>
                            <ul className="group-members-list list-unstyled bg-gray p-2">
                              {!memberObj ? (
                                <p className="text-center mb-0">
                                  User is not selected!
                                </p>
                              ) : (
                                <>
                                  {/* {membersObj.map((elem, ind) => {
                                    return ( */}
                                  <li className="p-2 border my-1 bg-white">
                                    {memberObj.name}{" "}
                                    {`( ${memberObj.jobTitle} )`}
                                    <span role="button">
                                      <i
                                        style={{ float: "right" }}
                                        className="fa fa-trash"
                                        onClick={() =>
                                          deleteHandler(memberObj.id)
                                        }
                                      ></i>
                                    </span>
                                  </li>
                                </>
                              )}
                            </ul>
                          </div>
                        </div>
                        {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Cc" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Bcc" className="form-control" />
                                        </div>
                                    </div>
                                </div> */}

                        {/* message k liya */}
                        {/* <div className="form-group"> 
                                <Editor
                                    placeholder="Enter your message here"
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName" />
                                </div> */}
                        <div className="form-group">
                          {" "}
                          <label className="col-form-label">Message: </label>
                          <textarea
                            rows="4"
                            className="form-control"
                            placeholder="Type a message..."
                            autoComplete="false"
                            value={values.message}
                            onChange={handleChange("message")}
                          ></textarea>
                        </div>
                        <div className="form-group mb-0">
                          <div className="text-center">
                            <button className="btn btn-primary">
                              <span>Send</span>{" "}
                              <i className="fa fa-send m-l-5"></i>
                            </button>
                            {/* <button className="btn btn-success m-l-5" type="button"><span>Delete</span> <i className="fa fa-trash-o m-l-5"></i></button> */}
                            {/* <button className="btn btn-success m-l-5" type="button"><span>Draft</span> <i className="fa fa-floppy-o m-l-5"></i></button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateChatModal;

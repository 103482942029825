import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getPackage } from '../actions/packageActions'
import { tabHome } from '../actions/tabActions'
import Banner from '../components/Banner'
import Features from '../components/Features'
import Footer from '../components/Footer'
import Advertiser from '../components/homeComponents/Advertiser'
import OurSolutions from '../components/homeComponents/OurSolutions'
// import Review from '../components/homeComponents/Review'
import NavBar from '../components/NavBar'
import Pricing from '../components/Pricing'
// import '../../src/assetsgeneral/css/22bootstrap.min.css'
// import Banner from '../components/Banner'
// import Features from '../components/Features'
// import Pricing from '../components/Pricing'
// import Footer from '../components/Footer'
// import banner from "../assets/images/home.jpg"
// import home66 from "../assets/images/home66.png"
// import Advertiser from '../components/homeComponents/Advertiser'
// import OurSolutions from '../components/homeComponents/OurSolutions'
// import Languages from '../components/homeComponents/Languages'
// import Review from '../components/homeComponents/Review'
const Home = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabHome())
    dispatch(getPackage())
  }, [])
  return (
    <>
      <section>
        <NavBar />
        <Banner />
        {/* <Banner image="" title="WORK SCHEDULING" title2="Work Better. Together."
          text="Riyowork is an employee scheduling app that does more than just save you time on scheduling."
          image2="" /> */}
        <Advertiser />
        <Features />
        {/* <Advertiser initialVisible/> */}
        {/* <Features bgcolorclass="features-color-default" /> */}
        <OurSolutions />
        <Pricing />
        {/* <Languages /> */}
        {/* <Review /> */}
        <Footer /> 
      </section>
      {/* <LearnRiyoWork /> */}
    </>
  )
}
export default Home
import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
// import { USER_LOGOUT_SUCCESS } from "./constants";

import { authReducer } from "./reducers/authReducer";
import { shiftReducer } from "./reducers/shiftReducer";
import { locationReducer } from "./reducers/locationReducer";
import { holidayReducer } from "./reducers/holidayReducer";
import { trainingReducer } from "./reducers/trainingReducer";
import { clockReducer } from "./reducers/clockReducer";
import { userReducer } from "./reducers/userReducer";
import { salaryReducer } from "./reducers/salaryReducer";
import { overtimeReducer } from "./reducers/overtimeReducer";
import { leaveReducer } from "./reducers/leaveReducer";
import { mailReducer } from "./reducers/mailReducer";
import { policyReducer } from "./reducers/policyReducer";
import { breakruleReducer } from "./reducers/breakruleReducer";
import { payrollReducer } from "./reducers/payrollReducer";
import { changeTheTab } from "./reducers/tabReducers";
import { adminReducer } from "./reducers/adminReducer";
import { superAdminCompanyReducer } from "./reducers/superAdminCompanyReducer";
import { packageReducer } from "./reducers/packageReducer";
import { filemanagerReducer } from "./reducers/fileManagerReducer";
import { contactusReducer } from "./reducers/contactusReducer";
import { chatReducer } from "./reducers/chatReducer";
import { socketReducer } from "./reducers/socketReducer";
import { notificationReducer } from "./reducers/notificationReducer";
import { messageWallReducer } from "./reducers/messageWallReducer";

const initialState = {
  auth: {
    tokens: localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens"))
      : null,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    company: localStorage.getItem("company")
      ? JSON.parse(localStorage.getItem("company"))
      : null,
    activeUserCountry: localStorage.getItem("activeUserCountry")
      ? JSON.parse(localStorage.getItem("activeUserCountry"))
      : null,
    loading: false,
  },
  shift: {
    dailyShift: localStorage.getItem("dailyShift")
      ? JSON.parse(localStorage.getItem("dailyShift"))
      : null,
    weeklyShift: localStorage.getItem("weeklyShift")
      ? JSON.parse(localStorage.getItem("weeklyShift"))
      : null,
    monthlyShift: localStorage.getItem("monthlyShift")
      ? JSON.parse(localStorage.getItem("monthlyShift"))
      : null,
    thisWeek: localStorage.getItem("thisWeek")
      ? JSON.parse(localStorage.getItem("thisWeek"))
      : null,
    tomorrowShift: localStorage.getItem("tomorrowShift")
      ? JSON.parse(localStorage.getItem("tomorrowShift"))
      : null,
    activeLocationThisWeek: localStorage.getItem("activeLocationThisWeek")
      ? JSON.parse(localStorage.getItem("activeLocationThisWeek"))
      : null,
    activeLocationThisMonth: localStorage.getItem("activeLocationThisMonth")
      ? JSON.parse(localStorage.getItem("activeLocationThisMonth"))
      : null,
    upcomingShift: localStorage.getItem("upcomingShift")
      ? JSON.parse(localStorage.getItem("upcomingShift"))
      : null,
    recentShift: localStorage.getItem("recentShift")
      ? JSON.parse(localStorage.getItem("recentShift"))
      : null,
    loading: false,
  },
  location: {
    location: localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location"))
      : null,
    locationWithUsers: localStorage.getItem("locationWithUsers")
      ? JSON.parse(localStorage.getItem("locationWithUsers"))
      : null,
    locationById: localStorage.getItem("locationById")
      ? JSON.parse(localStorage.getItem("locationById"))
      : null,
  },
  holiday: {
    holiday: localStorage.getItem("holiday")
      ? JSON.parse(localStorage.getItem("holiday"))
      : null,
    holidayTable: localStorage.getItem("holidayTable")
      ? JSON.parse(localStorage.getItem("holidayTable"))
      : null,
  },
  training: {
    training: localStorage.getItem("training")
      ? JSON.parse(localStorage.getItem("training"))
      : null,
  },
  clock: {
    clockData: localStorage.getItem("clockData")
      ? JSON.parse(localStorage.getItem("clockData"))
      : null,
    clockInButton: localStorage.getItem("clockInButton")
      ? JSON.parse(localStorage.getItem("clockInButton"))
      : true,
    breakButton: localStorage.getItem("breakButton")
      ? JSON.parse(localStorage.getItem("breakButton"))
      : null,
    clockStatusData: localStorage.getItem("clockStatusData")
      ? JSON.parse(localStorage.getItem("clockStatusData"))
      : null,
    clockMonthlyData: localStorage.getItem("clockMonthlyData")
      ? JSON.parse(localStorage.getItem("clockMonthlyData"))
      : null,
    clockAttendance: localStorage.getItem("clockAttendance")
      ? JSON.parse(localStorage.getItem("clockAttendance"))
      : null,
  },
  user: {
    annivarsary: localStorage.getItem("annivarsary")
      ? JSON.parse(localStorage.getItem("annivarsary"))
      : null,
    allUsers: localStorage.getItem("allUsers")
      ? JSON.parse(localStorage.getItem("allUsers"))
      : null,
    nonActive: localStorage.getItem("nonActive")
      ? JSON.parse(localStorage.getItem("nonActive"))
      : null,
    active: localStorage.getItem("active")
      ? JSON.parse(localStorage.getItem("active"))
      : null,
    allUsersWithoutPaginate: localStorage.getItem("allUsersWithoutPaginate")
      ? JSON.parse(localStorage.getItem("allUsersWithoutPaginate"))
      : null,
    lastEmployeeId: localStorage.getItem("lastEmployeeId")
      ? JSON.parse(localStorage.getItem("lastEmployeeId"))
      : null,
    // userById: null,
  },
  salary: {
    salary: localStorage.getItem("salary")
      ? JSON.parse(localStorage.getItem("salary"))
      : null,
  },
  overtime: {
    overtime: localStorage.getItem("overtime")
      ? JSON.parse(localStorage.getItem("overtime"))
      : null,
    overtimeTable: localStorage.getItem("overtimeTable")
      ? JSON.parse(localStorage.getItem("overtimeTable"))
      : null,
  },
  leave: {
    leaveAnnualStatus: localStorage.getItem("leaveAnnualStatus")
      ? JSON.parse(localStorage.getItem("leaveAnnualStatus"))
      : null,
    leaveList: localStorage.getItem("leaveList")
      ? JSON.parse(localStorage.getItem("leaveList"))
      : null,
    leaveDisplay: localStorage.getItem("leaveDisplay")
      ? JSON.parse(localStorage.getItem("leaveDisplay"))
      : null,
    leaveReport: localStorage.getItem("leaveReport")
      ? JSON.parse(localStorage.getItem("leaveReport"))
      : null,
    leaveSetting: localStorage.getItem("leaveSetting")
      ? JSON.parse(localStorage.getItem("leaveSetting"))
      : null,
    leaveCard: localStorage.getItem("leaveCard")
      ? JSON.parse(localStorage.getItem("leaveCard"))
      : null,
    leaveReportUsers: localStorage.getItem("leaveReportUsers")
      ? JSON.parse(localStorage.getItem("leaveReportUsers"))
      : null,
  },
  mail: {
    mailData: localStorage.getItem("mailData")
      ? JSON.parse(localStorage.getItem("mailData"))
      : null,
  },
  breakrule: {
    breakRules: localStorage.getItem("breakRules")
      ? JSON.parse(localStorage.getItem("breakRules"))
      : null,
  },
  admin: {
    LocationCount: localStorage.getItem("LocationCount")
      ? JSON.parse(localStorage.getItem("LocationCount"))
      : null,
    UserCount: localStorage.getItem("UserCount")
      ? JSON.parse(localStorage.getItem("UserCount"))
      : null,
    TrainingCount: localStorage.getItem("TrainingCount")
      ? JSON.parse(localStorage.getItem("TrainingCount"))
      : null,
    CurrTimeIn: localStorage.getItem("CurrTimeIn")
      ? JSON.parse(localStorage.getItem("CurrTimeIn"))
      : null,
    ActiveUser: localStorage.getItem("ActiveUser")
      ? JSON.parse(localStorage.getItem("ActiveUser"))
      : null,
    UpcomingShift: localStorage.getItem("UpcomingShift")
      ? JSON.parse(localStorage.getItem("UpcomingShift"))
      : null,
    AnnualAnnieversary: localStorage.getItem("AnnualAnnieversary")
      ? JSON.parse(localStorage.getItem("AnnualAnnieversary"))
      : null,
    LateComing: localStorage.getItem("LateComing")
      ? JSON.parse(localStorage.getItem("LateComing"))
      : null,
    AbsUsers: localStorage.getItem("AbsUsers")
      ? JSON.parse(localStorage.getItem("AbsUsers"))
      : null,
    shiftReportWeekly: localStorage.getItem("shiftReportWeekly")
      ? JSON.parse(localStorage.getItem("shiftReportWeekly"))
      : null,
    shiftReportDaily: localStorage.getItem("shiftReportDaily")
      ? JSON.parse(localStorage.getItem("shiftReportDaily"))
      : null,
    timeClockReportWeekly: localStorage.getItem("timeClockReportWeekly")
      ? JSON.parse(localStorage.getItem("timeClockReportWeekly"))
      : null,
    timeClockReportDaily: localStorage.getItem("timeClockReportDaily")
      ? JSON.parse(localStorage.getItem("timeClockReportDaily"))
      : null,
    paymentInvoice: localStorage.getItem("paymentInvoice")
      ? JSON.parse(localStorage.getItem("paymentInvoice"))
      : null,
    usersShifts: localStorage.getItem("usersShifts")
      ? JSON.parse(localStorage.getItem("usersShifts"))
      : null,
  },
  payroll: {
    payrollAdditionTable: localStorage.getItem("payrollAdditionTable")
      ? JSON.parse(localStorage.getItem("payrollAdditionTable"))
      : null,
    payrollOvertimeTable: localStorage.getItem("payrollOvertimeTable")
      ? JSON.parse(localStorage.getItem("payrollOvertimeTable"))
      : null,
    payrollDeductionTable: localStorage.getItem("payrollDeductionTable")
      ? JSON.parse(localStorage.getItem("payrollDeductionTable"))
      : null,
    salaryCalender: localStorage.getItem("salaryCalender")
      ? JSON.parse(localStorage.getItem("salaryCalender"))
      : null,
  },
  policy: {
    policyTable: localStorage.getItem("policyTable")
      ? JSON.parse(localStorage.getItem("policyTable"))
      : null,
  },
  messageWall: {
    getMessageWallById: localStorage.getItem("getMessageWallById")
      ? JSON.parse(localStorage.getItem("getMessageWallById"))
      : null,
      getCommentById: localStorage.getItem("getCommentById")
      ? JSON.parse(localStorage.getItem("getCommentById"))
      : null,
  },
  company: {
    superAdminCompanyCC: localStorage.getItem("superAdminCompanyCC")
      ? JSON.parse(localStorage.getItem("superAdminCompanyCC"))
      : null,
    superAdminCompanyCPC: localStorage.getItem("superAdminCompanyCPC")
      ? JSON.parse(localStorage.getItem("superAdminCompanyCPC"))
      : null,
    newLead: localStorage.getItem("newLead")
      ? JSON.parse(localStorage.getItem("newLead"))
      : null,
    StripeInovicesQueryAllCompanies: localStorage.getItem(
      "StripeInovicesQueryAllCompanies"
    )
      ? JSON.parse(localStorage.getItem("StripeInovicesQueryAllCompanies"))
      : null,
    SAdminCardsDetails: localStorage.getItem("SAdminCardsDetails")
      ? JSON.parse(localStorage.getItem("SAdminCardsDetails"))
      : null,
  },
  package: {
    package: localStorage.getItem("package")
      ? JSON.parse(localStorage.getItem("package"))
      : null,
  },
  filemanager: {
    filemanagerTable: localStorage.getItem("filemanagerTable")
      ? JSON.parse(localStorage.getItem("filemanagerTable"))
      : null,
  },
  contactus: {
    contactusTable: localStorage.getItem("contactusTable")
      ? JSON.parse(localStorage.getItem("contactusTable"))
      : null,
    contactusTableModal: localStorage.getItem("contactusTableModal")
      ? JSON.parse(localStorage.getItem("contactusTableModal"))
      : null,
  },
};
// console.log("initialState", initialState)
const reducer = combineReducers({
  auth: authReducer,
  shift: shiftReducer,
  location: locationReducer,
  holiday: holidayReducer,
  training: trainingReducer,
  clock: clockReducer,
  user: userReducer,
  salary: salaryReducer,
  overtime: overtimeReducer,
  leave: leaveReducer,
  mail: mailReducer,
  changeTheTab: changeTheTab,
  admin: adminReducer,
  policy: policyReducer,
  messageWall: messageWallReducer,
  breakrule: breakruleReducer,
  payroll: payrollReducer,
  company: superAdminCompanyReducer,
  package: packageReducer,
  filemanager: filemanagerReducer,
  contactus: contactusReducer,
  chats: chatReducer,
  sockets: socketReducer,
  notifications: notificationReducer,
});

// const rootReducer = (state, action) => {
//     if (action.type === 'USER_LOGOUT_SUCCESS') {
//         state = undefined;
//     }
//     return reducer(state, action);
// }

// const middleware = [thunk]

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserByAdmin } from "../../../actions/userActions";
import { toast } from "react-hot-toast";
// import { updateUserById } from '../../../actions/authActions'

const CompaniesEditModal = ({ current, showDropDown, openDropDown }) => {
  const dispatch = useDispatch();
  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(")
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    clientId: "",
    phone: "",
    password: "",
    passwordConfirm: "",
    userId: "",
  });
  useEffect(() => {
    if (current) {
      // const no = current.admin.phone
      // let noArr = no.split(" ")
      setValues({
        userId: current.admin.id,
        firstName: current.admin.firstName,
        lastName: current.admin.lastName,
        companyName: current.company.companyName,
        email: current.admin.email,
        clientId: current.company.clientId,
        phone: current.admin.phone,
      });
    }
  }, [current]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
    // passwordChk()
  };
  const userData = {
    userId: values.userId,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    password: values.password,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.password === values.passwordConfirm) {
      for (let key of Object.keys(userData)) {
        if (userData[key] === "" || userData[key] === "+") {
          delete userData[key];
        }
      }
      dispatch(updateUserByAdmin(userData));
    } else {
      toast.error("Password, don't match");
      // alert("Password, don't match");
    }
  };

  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_modal"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Client</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">First Name </label>
                      <input
                        className="form-control"
                        value={values.firstName}
                        type="text"
                        onChange={handleChange("firstName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Last Name</label>
                      <input
                        className="form-control"
                        value={values.lastName}
                        type="text"
                        onChange={handleChange("lastName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Email </label>
                      <input
                        className="form-control"
                        value={values.email}
                        type="email"
                        onChange={handleChange("email")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Phone </label>
                      <input
                        className="form-control"
                        minLength="14"
                        maxLength="14"
                        required
                        // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 6
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 2
                          ) {
                            setValues({
                              ...values,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 10
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 9),
                            });
                          }
                        }}
                        name="phone"
                        value={values.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123) 456-7890"
                        autocomplete="new-mobileNunmber"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Password</label>
                      <input
                        className="form-control"
                        value={values.password}
                        type="password"
                        onChange={handleChange("password")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Confirm Password</label>
                      <input
                        className="form-control"
                        value={values.passwordConfirm}
                        type="password"
                        onChange={handleChange("passwordConfirm")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Client ID </label>
                      <input
                        className="form-control"
                        value={values.clientId}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Company Name</label>
                      <input
                        className="form-control"
                        value={values.companyName}
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive m-t-15"></div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompaniesEditModal;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { shiftProfile } from "../../actions/shiftActions";
import shift2 from "../../assetsAdmin/img/undraw_Site_stats_re_ejgy.png";
import shift3 from "../../assetsAdmin/img/undraw_Booking_re_gw4j.png";
const Shifts = () => {
  const auth = useSelector((state) => state.auth);
  const shift = useSelector((state) => state.shift);
  const dispatch = useDispatch();
  const shiftProfileData = async (e) => {
    const currDate = moment().format();
    // const arr = currDate.split("+")
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0]+".000+00:00"
    const newCurrDate = arr + ".000+00:00";

    // console.log("shiftProfileData",auth.user.id)
    dispatch(shiftProfile({ userId: auth.user.id, date: newCurrDate }));
  };
  useEffect(() => {
    shiftProfileData();
  }, []);
  // console.log(moment().format().substring(0,10))
  return (
    <>
      <div className="row">
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">Upcoming Shifts</h3>
              <div className="experience-box miprcrl">
                <ul className="experience-list ">
                  {shift.upcomingShift && shift.upcomingShift.length > 0 ? (
                    shift.upcomingShift.map((current, i) => {
                      return (
                        <li className="misftact">
                          <div className="experience-user">
                            <div className="before-circle"></div>
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <div>
                                {moment(
                                  current.startTime.substring(0, 10)
                                ).format("MM/DD/YYYY")}
                              </div>
                              {
                                // <NavLink to={`/location-view/${shift.id}`}></NavLink>
                                current.location ? (
                                  <NavLink
                                    to={
                                      current.location
                                        ? `/location-view/${current.location.id}`
                                        : "#"
                                    }
                                    className="name"
                                  >
                                    {current.location.name} -{" "}
                                    <strong>{auth.user.jobTitle}</strong>
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    to={
                                      current.location
                                        ? `/location-view/${current.location.id}`
                                        : "#"
                                    }
                                    className="name"
                                    style={{ cursor: "default" }}
                                  >
                                    <strong>{auth.user.jobTitle}</strong>
                                  </NavLink>
                                )
                              }
                              <span className="time">
                                {current.startTime.toString().substring(11, 16)}{" "}
                                - {current.endTime.substring(11, 16)}
                              </span>
                              {current.startTime.substring(0, 10) ===
                              moment().format().substring(0, 10) ? (
                                <span className="btn add-btn">Ongoing</span>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="dash-stats-list">
                        <img
                          alt=""
                          src={shift2}
                          style={{ width: "110px", opacity: "0.8" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Upcoming Shifts</p>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">Recent Shifts</h3>
              <div className="experience-box miprcrl">
                <ul className="experience-list ">
                  {shift.recentShift && shift.recentShift.length > 0 ? (
                    shift.recentShift.map((current, i) => {
                      return (
                        <li key={i}>
                          <div className="experience-user">
                            <div className="before-circle"></div>
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <div>
                                {moment(
                                  current.startTime.substring(0, 10)
                                ).format("MM/DD/YYYY")}
                              </div>
                              {current.location ? (
                                <NavLink
                                  to={
                                    current.location
                                      ? `/location-view/${current.location.id}`
                                      : "#"
                                  }
                                  className="name"
                                >
                                  {current.location.name} -{" "}
                                  <strong>{auth.user.jobTitle}</strong>
                                </NavLink>
                              ) : (
                                <NavLink
                                  to={
                                    current.location
                                      ? `/location-view/${current.location.id}`
                                      : "#"
                                  }
                                  className="name"
                                  style={{ cursor: "default" }}
                                >
                                  <strong>{auth.user.jobTitle}</strong>
                                </NavLink>
                              )}
                              <span className="time">
                                {current.startTime.substring(11, 16)} -{" "}
                                {current.endTime.substring(11, 16)}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <div className="dash-stats-list">
                        <img
                          alt=""
                          src={shift3}
                          style={{ width: "110px", opacity: "0.8" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Upcoming Shifts</p>
                      </div>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Shifts;

import React from "react";
import EmployeeHeader from "../../components/employeeComponents/EmployeeHeader";
// import AddLeaveModal from '../../components/employeeComponents/employeeLeavesComponents/AddLeaveModal'
import LeavesCard from "../../components/employeeComponents/employeeLeavesComponents/LeavesCard";
import LeavesTable from "../../components/employeeComponents/employeeLeavesComponents/LeavesTable";
import LeavesTypes from "../../components/employeeComponents/employeeLeavesComponents/LeavesTypes";
import EmployeeSidebar from "../../components/employeeComponents/EmployeeSidebar";
import EmployeeTitle from "../../components/employeeComponents/EmployeeTitle";

const EmployeeLeaves = () => {
  return (
    <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Leaves" leaves />
            <LeavesCard />
            <LeavesTable />
            <LeavesTypes />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLeaves;

import {
    ADDITION_CREATE_REQUEST,
    ADDITION_CREATE_SUCCESS,
    ADDITION_CREATE_FAIL,
    OVERTIME_CREATE_REQUEST,
    OVERTIME_CREATE_SUCCESS,
    OVERTIME_CREATE_FAIL,
    DEDUCTION_CREATE_REQUEST,
    DEDUCTION_CREATE_SUCCESS,
    DEDUCTION_CREATE_FAIL,
    PAYROLL_ADDITION_TABLE_REQUEST,
    PAYROLL_ADDITION_TABLE_SUCCESS,
    PAYROLL_ADDITION_TABLE_FAIL,
    PAYROLL_OVERTIME_TABLE_REQUEST,
    PAYROLL_OVERTIME_TABLE_SUCCESS,
    PAYROLL_OVERTIME_TABLE_FAIL,
    PAYROLL_DEDUCTION_TABLE_REQUEST,
    PAYROLL_DEDUCTION_TABLE_SUCCESS,
    PAYROLL_DEDUCTION_TABLE_FAIL,
    PAYROLL_ADDITION_UPDATE_REQUEST,
    PAYROLL_ADDITION_UPDATE_SUCCESS,
    PAYROLL_ADDITION_UPDATE_FAIL,
    PAYROLL_OVERTIME_UPDATE_REQUEST,
    PAYROLL_OVERTIME_UPDATE_SUCCESS,
    PAYROLL_OVERTIME_UPDATE_FAIL,
    PAYROLL_DEDUCTION_UPDATE_REQUEST,
    PAYROLL_DEDUCTION_UPDATE_SUCCESS,
    PAYROLL_DEDUCTION_UPDATE_FAIL,
    PAYROLL_ADDITION_REMOVE_REQUEST,
    PAYROLL_ADDITION_REMOVE_SUCCESS,
    PAYROLL_ADDITION_REMOVE_FAIL,
    PAYROLL_OVERTIME_REMOVE_REQUEST,
    PAYROLL_OVERTIME_REMOVE_SUCCESS,
    PAYROLL_OVERTIME_REMOVE_FAIL,
    PAYROLL_DEDUCTION_REMOVE_REQUEST,
    PAYROLL_DEDUCTION_REMOVE_SUCCESS,
    PAYROLL_DEDUCTION_REMOVE_FAIL,
    SALARY_CALENDER_REQUEST,
    SALARY_CALENDER_SUCCESS,
    SALARY_CALENDER_FAIL,
    MONTHLY_SALARY_CREATE_REQUEST,
    MONTHLY_SALARY_CREATE_SUCCESS,
    MONTHLY_SALARY_CREATE_FAIL,
    MONTHLY_SALARY_DATA_CREATE_REQUEST,
    MONTHLY_SALARY_DATA_CREATE_SUCCESS,
    MONTHLY_SALARY_DATA_CREATE_FAIL,
    EMPLOYEE_SALARY_UPDATE_REQUEST,
    EMPLOYEE_SALARY_UPDATE_SUCCESS,
    EMPLOYEE_SALARY_UPDATE_FAIL,
    EMPLOYEE_SALARY_REMOVE_REQUEST,
    EMPLOYEE_SALARY_REMOVE_SUCCESS,
    EMPLOYEE_SALARY_REMOVE_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const payrollReducer = (
    state = {
        payroll: {
            // addition: null,
            // overtime: null,
            // deduction: null,
            payrollAdditionTable: null,
            payrollOvertimeTable: null,
            payrollDeductionTable: null,
            salaryCalender: null,
            // monthlySalary: null,
            // monthlySalary: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                payrollAdditionTable: null,
                payrollOvertimeTable: null,
                payrollDeductionTable: null,
                salaryCalender: null,
            };

        case ADDITION_CREATE_REQUEST : 
            return {
                ...state,
            };
        case ADDITION_CREATE_SUCCESS :
            return {
                ...state,
                payrollAdditionTable: [...state.payrollAdditionTable, action.payload],
            };
        case ADDITION_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case OVERTIME_CREATE_REQUEST : 
            return {
                ...state,
            };
        case OVERTIME_CREATE_SUCCESS :
            return {
                ...state,
                payrollOvertimeTable: [...state.payrollOvertimeTable, action.payload],
            };
        case OVERTIME_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case DEDUCTION_CREATE_REQUEST : 
            return {
                ...state,
            };
        case DEDUCTION_CREATE_SUCCESS :
            return {
                ...state,
                payrollDeductionTable: [...state.payrollDeductionTable, action.payload],
            };
        case DEDUCTION_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case PAYROLL_ADDITION_TABLE_REQUEST : 
            return {
                ...state,
            };
        case PAYROLL_ADDITION_TABLE_SUCCESS :
            return {
                ...state,
                payrollAdditionTable : action.payload,
            };
        case PAYROLL_ADDITION_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        case PAYROLL_OVERTIME_TABLE_REQUEST : 
            return {
                ...state,
            };
        case PAYROLL_OVERTIME_TABLE_SUCCESS :
            return {
                ...state,
                payrollOvertimeTable : action.payload,
            };
        case PAYROLL_OVERTIME_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case PAYROLL_DEDUCTION_TABLE_REQUEST : 
            return {
                ...state,
            };
        case PAYROLL_DEDUCTION_TABLE_SUCCESS :
            return {
                ...state,
                payrollDeductionTable : action.payload,
            };
        case PAYROLL_DEDUCTION_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case SALARY_CALENDER_REQUEST : 
            return {
                ...state,
            };
        case SALARY_CALENDER_SUCCESS :
            return {
                ...state,
                salaryCalender : action.payload,
            };
        case SALARY_CALENDER_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case MONTHLY_SALARY_CREATE_REQUEST : 
            return {
                ...state,
            };
        case MONTHLY_SALARY_CREATE_SUCCESS :
            return {
                ...state,
                // salaryCalender: [...state.salaryCalender, action.payload]
                // : action.payload,
                salaryCalender: action.payload,
            };
        case MONTHLY_SALARY_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // SALARY BY SALARY MONTHLY ID
        case MONTHLY_SALARY_DATA_CREATE_REQUEST : 
            return {
                ...state,
                monthlySalary: null,
            };
        case MONTHLY_SALARY_DATA_CREATE_SUCCESS :
            return {
                ...state,
                monthlySalary: action.payload
            };
        case MONTHLY_SALARY_DATA_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // UPDATE ADDITION EDIT MODAL
        case PAYROLL_ADDITION_UPDATE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_ADDITION_UPDATE_SUCCESS :
            return {
                ...state,
                payrollAdditionTable: state.payrollAdditionTable.map((x) =>
                x.id === action.payload.id ? action.payload : x
                // payroll: [...state.payroll, action.payload],
              ),
            };
        case PAYROLL_ADDITION_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // UPDATE OVERTIME   
        case PAYROLL_OVERTIME_UPDATE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_OVERTIME_UPDATE_SUCCESS :
            return {
                ...state,
                payrollOvertimeTable: state.payrollOvertimeTable.map((x) =>
                x.id === action.payload.id ? action.payload : x
              ),
            };
        case PAYROLL_OVERTIME_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // UPDATE DEDUCTION
        case PAYROLL_DEDUCTION_UPDATE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_DEDUCTION_UPDATE_SUCCESS :
            return {
                ...state,
                payrollDeductionTable: state.payrollDeductionTable.map((x) =>
                x.id === action.payload.id ? action.payload : x
              ),
            };
        case PAYROLL_DEDUCTION_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // UPDATE EMPLOYEE SALARY
        case EMPLOYEE_SALARY_UPDATE_REQUEST :
            return {
                ...state,
            };
        case EMPLOYEE_SALARY_UPDATE_SUCCESS :
            return {
                ...state,
                salaryCalender: {...state.salaryCalender, results:state.salaryCalender.results.map((x) =>
                x.id === action.payload.id ? action.payload : x
              )},
            };
        case EMPLOYEE_SALARY_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // REMOVE ADDITION
        case PAYROLL_ADDITION_REMOVE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_ADDITION_REMOVE_SUCCESS :
            return {
                ...state,
            };
        case PAYROLL_ADDITION_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // REMOVE OVERTIME
        case PAYROLL_OVERTIME_REMOVE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_OVERTIME_REMOVE_SUCCESS :
            return {
                ...state,
            };
        case PAYROLL_OVERTIME_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // REMOVE DEDUCTION 
        case PAYROLL_DEDUCTION_REMOVE_REQUEST :
            return {
                ...state,
            };
        case PAYROLL_DEDUCTION_REMOVE_SUCCESS :
            return {
                ...state,
            };
        case PAYROLL_DEDUCTION_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // REMOVE SALARY EMPLOYEE
        case EMPLOYEE_SALARY_REMOVE_REQUEST :
            return {
                ...state,
            };
        case EMPLOYEE_SALARY_REMOVE_SUCCESS :
            return {
                ...state,
                salaryCalender: state.salaryCalender.filter((x) => x.id !== action.payload.id),
            };
        case EMPLOYEE_SALARY_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
            
        default:
            return state;
    }
}
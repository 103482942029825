import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import AutoComplete from '../components/adminComponents/Map/AutoComplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import io from "socket.io-client"
import { SERVERADDRESS, SOCKETADDRESS } from '../constants';
import { useParams } from 'react-router-dom';
const containerStyle = {
  width: '100%',
  height: '80vh'
};

const Test2 = () => {
  // const socket = io('api.riyowork.com')
    let socket;
    useEffect(() => {
      socket = io(SOCKETADDRESS)
    }, [])
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
    })
    const params = useParams()
    const [mapApiLoaded, setMapApiLoaded] = useState(true);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapApi, setMapApi] = useState(null);
    const [center, setCenter] = useState({
      lat: 0,
      lng: 0,
    });
    const [address, setAddress] = useState("");
    const [places, setPlaces] = useState([]);
    const [map, setMap] = React.useState(null)
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [cc, setCC] = useState(0)
    useEffect(() => {
        // socket.on("hello", (arg) => {
        //   console.log(arg); // world
        // });
        socket.emit("user-location", { userId: params.id }, (response) => {
            setCoordinates({
                lat: response.status.latitude,
                lng: response.status.longitude,
            });
            setCenter({
              lat: response.status.latitude,
              lng: response.status.longitude,
            })
            // console.log(response.status); // ok
        })
        // return () => {
        //   socket.disconnect();
        //   socket.off("user-location")
        // }
    // }, [coordinates.lat, coordinates.lng])
    }, [cc, params.id, coordinates.lat, coordinates.lng,])
    useEffect(() => {
        setCurrentLocation()
    }, [params.id])
    let bb = 0
    useEffect(()=>{
      setInterval(() => 
          setCC(bb++)
      , 2000);
    },[])
    // console.log("cccccc",cc)
    // const addPlace = (place) => {
    //     setPlaces([place]);
    //     setCoordinates({
    //       lat: place.geometry.location.lat(),
    //       lng: place.geometry.location.lng(),
    //     });
    // };
    const setCurrentLocation = async () => {
      const res = await axios.post('http://localhost:5000/api/v1/track/trackData', 
            {
                userId: params.id
            }
        )
        if (res.data.latitude !== null || res.data.longitude !== null){
          setCenter({
            lat: res.data.latitude,
            lng: res.data.longitude,
          })
          setCoordinates({
              lat: res.data.latitude,
              lng: res.data.longitude,
          });
        }
        else {
          if ("geolocation" in navigator) {
              navigator.geolocation.getCurrentPosition((position) => {
                  // setCenter([position.coords.latitude, position.coords.longitude])
                  setCenter({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                  })
                  setCoordinates({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                      // lat: 24.9180271,
                      // lng: 67.0970916,
                  });
              });
          }
        }
    }
    // const handleSelect = async (value) => {
    //     const results = await geocodeByAddress(value);
    //     const latLng = await getLatLng(results[0]);
    //     setAddress(value);
    //     // addPlace(value)
    //     setCoordinates(latLng);
    //     setCenter(latLng)
    // };
    // useEffect(() => {
    //     setTimeout(() => 
    //         setCoordinates({
    //             lat: coordinates.lat+0.000022,
    //             lng: coordinates.lng+0.000022,
    //         })    
    //     , 1000);
    // },[coordinates.lat, coordinates.lng])
    // const _onClick = (value) => {
    //     console.log(value.latLng.lat)
    //     console.log(value.latLng.lat(),"S")
    //     setCoordinates({
    //         lat: value.latLng.lat(),
    //         lng: value.latLng.lng(),
    //     });
    // };
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
  return isLoaded ? (
    <>
      {/* {
        mapApiLoaded && (
        <div> 
          <AutoComplete
            map={mapInstance}
            mapApi={mapApi}
            addplace={addPlace}
            handleSelect={handleSelect}
            coordinates={coordinates}
            address={address}
            setAddress={setAddress}
          />
        </div>
        )
      } */}
      <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onUnmount={onUnmount}
      // onClick={_onClick}
      >
      <Marker
        position={coordinates}
      />

</GoogleMap>
        </>
  ) : <></>
}

export default Test2


// import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
// import { SOCKETADDRESS } from '../constants'
// import io from "socket.io-client"

// const Test2 = () => {
//   const socket = io(SOCKETADDRESS)
//   const params = useParams()
//   console.log("params=>",params)
//   useEffect(() => {
//     socket.emit("user-location", { userId: "62e2bf7c9ffba739f9e8d3ed" }, (response) => {
//       console.log(response.status); // ok
//     });
//   }, [])
//   return (
//     <div>Test2</div>
//   )
// }

// export default Test2
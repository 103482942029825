import React, { useEffect } from 'react'
import { getAllTraining } from "../../../actions/trainingActions"
import { useSelector, useDispatch } from 'react-redux'
import TrainingTableRow from './TrainingTableRow'
// import { NavLink } from 'react-router-dom'
const TrainingTable = () => {
    const auth = useSelector((state) => state.auth);
    const training = useSelector((state) => state.training);
    const dispatch = useDispatch()
    const trainingData = async (e) => {
        dispatch(getAllTraining({companyId: auth.user.companyId}))
    }
    useEffect(() => {
        trainingData()
    },[])
    // let i=0
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th style={{ width: "30px" }}>#</th>
                                    <th>Name</th>
                                    <th>Training Type</th>
                                    <th></th>
                                    <th>Time Duration</th>
                                    <th>Description </th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Status </th>
                                    {/* <th> Status </th> */}
                                    <th className="text-right"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                            training.training &&
                            training.training.results &&
                            training.training.results.length > 0 &&
                            training.training.results.map((current, i) => {
                                if (current.status === true) {
                                    return(
                                    <TrainingTableRow current={current} i={i}/>
                                    )
                                }
                            })}
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
export default TrainingTable
import React from "react";
import { getLeaveCard } from "../../../actions/leaveActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { useState } from 'react'
const AdminLeavesCard = () => {
  const auth = useSelector((state) => state.auth);
  const leave = useSelector((state) => state.leave);
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const leaveCard = async () => {
    dispatch(
      getLeaveCard({
        companyId: auth.user.companyId,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  useEffect(() => {
    leaveCard();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <div className="stats-info">
            <h6>Today Presents</h6>
            {leave.leaveCard && leave.leaveCard.length > 0 && (
              <h4>{leave.leaveCard[0].employeePresentTotal}</h4>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="stats-info">
            <h6>Planned Leaves</h6>
            {leave.leaveCard && leave.leaveCard.length > 0 && (
              <h4>
                {leave.leaveCard[0].leaves.length}
                <span></span>
              </h4>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="stats-info">
            <h6>Unplanned Leaves</h6>
            {leave.leaveCard && leave.leaveCard.length > 0 && (
              <h4>
                {leave.leaveCard[0].unplannedLeaves.length}
                <span></span>
              </h4>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="stats-info">
            <h6>Pending Requests</h6>
            {
              leave.leaveCard && leave.leaveCard.length > 0 && (
                <h4>{leave.leaveCard[0].pendingLeaves.length}</h4>
              )
              // <h4>{leave.leaveCard[0].pendingLeaves}</h4>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLeavesCard;

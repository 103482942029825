import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { tabPackageId, tabPackagePrice } from "../actions/tabActions";

const MonthPr = () => {
  const allPackage = useSelector((state) => state.package);
  const dispatch = useDispatch();
  const packageValues = (packageId, users, amount) => {
    dispatch(tabPackageId(packageId));
    dispatch(tabPackagePrice(users * amount));
  };
  return (
    <>
      <div className="ourplans-inner-section">
        <div className="container">
          <div className="row">
            {allPackage.package &&
              allPackage.package.length > 0 &&
              allPackage.package.map((current, i) => {
                return (
                  current.toBeDisplay === true && (
                    <>
                      <div className="col-md-4">
                        <div className="columns">
                          <ul className="price">
                            <li className="header-general">
                              {current.planName}
                            </li>
                            {/* <!--   <li className="grey "> <span> $5.99 </span> / Month</li> --> */}
                            <li className="simple"> {current.description}</li>
                            {/* <li className="fivedo"> ${current.amountMonthly}</li> */}
                            {/* <li className="simplee"> Per user per month billed monthly</li> */}
                            {/* <li className="usdbol">USD (local taxes may apply) </li> */}
                            {/* <li className="includes"> Includes <b> {current.users} </b> users</li> */}
                            <li className="fivedo"> </li>
                            <li className="simplee">
                              {" "}
                              Custom pricing
                              <br />
                              for {current.planName.toLowerCase()} clients
                            </li>
                            <li className="usdbol"> </li>
                            <li className="includes"> </li>
                            <li className="grey">
                              <NavLink
                                to={`/sign-up/plan=${current.planName}`}
                                onClick={() =>
                                  packageValues(
                                    current.id,
                                    current.users,
                                    current.amountMonthly
                                  )
                                }
                                className="button"
                              >
                                Get Started
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )
                );
              })}
            {/* <div className="col-md-4">
                            <div className="columns">
                                <ul className="price">
                                    <li className="header-general">Starter</li> */}
            {/* <!--   <li className="grey "> <span> $5.99 </span> / Month</li> --> */}

            {/* <li className="simple"> Simple workforce management for <br />small and medium-sized<br /> businesses</li>
                                    <li className="fivedo"> $5.99</li>

                                    <li className="simplee"> Per user per month billed monthly</li>
                                    <li className="usdbol">USD (local taxes may apply) </li>
                                    <li className="includes"> Includes <b> 20 </b> users</li>
                                    <li className="grey">
                                        <NavLink to="/get-quote" className="button">Get Started</NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="columns">
                                <ul className="price">
                                    <li className="header-general">Premium</li> */}
            {/* <!--   <li className="grey "> <span> $5.99 </span> / Month</li> --> */}

            {/* <li className="simple"> Professional scheduling for larger <br />or complex businesses</li>
                                    <li className="fivedo"> $7.99</li>
                                    <li className="simplee"> Per user per month billed monthly</li>
                                    <li className="usdbol">USD (local taxes may apply) </li>
                                    <li className="includes"> Includes <b> 50 </b> users</li>
                                    <li className="grey">
                                        <NavLink to="/get-quote" className="button">Get Started</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
            <div className="col-md-4">
              <div className="columns">
                <ul className="price">
                  <li className="header-general">Enterprise</li>
                  {/* <!--   <li className="grey "> <span> $5.99 </span> / Month</li> --> */}
                  <li className="simple">
                    {" "}
                    Fully customized scheduling
                    <br /> platform for very large businesses
                  </li>
                  <li className="fivedo"> </li>
                  <li className="simplee">
                    {" "}
                    Custom pricing
                    <br />
                    for enterprise clients
                  </li>
                  <li className="usdbol"> </li>
                  <li className="includes"> </li>
                  <li className="grey">
                    <NavLink to="/" className="button">
                      Call Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MonthPr;

import React, { useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addHoliday } from "../../../actions/holidayActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const ModalAddHoliday = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [values, setValues] = useState({
    companyId: "",
    holidayName: "",
    date: "",
  });
  const HDate = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datatimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">Date</label>
    </div>
  ));
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const dispatch = useDispatch();
  const holidayData = {
    companyId: auth.user.companyId,
    holidayName: values.holidayName,
    date: theDate.toISOString().substring(0, 10),
  };
  // console.log(theDate)
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("first");
    dispatch(addHoliday(holidayData));
    openDropDown();
    setValues({
      companyId: "",
      holidayName: "",
      date: "",
    });
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_holiday"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Holiday</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    Holiday Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.holidayName}
                    onChange={handleChange("holidayName")}
                  />
                </div>
                <div className="form-group form-focus select-focus">
                  <label>
                    Holiday Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={theDate}
                    onChange={(date) => setTheDate(date)}
                    customInput={<HDate />}
                  />
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalAddHoliday;

import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_BASE_URL,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_REGION,
  BUCKET_SECRET_KEY,
  SERVERADDRESS,
} from "../../../constants";
import AWS from "aws-sdk";
import { useEffect } from "react";
import { getFileCompany } from "../../../actions/fileManagerActions";
import SAdminFileManager from "./SAdminFileManager";
import { toast } from "react-hot-toast";
// import { NavLink } from 'react-router-dom'
// import moment from 'moment'

// const S3_BUCKET = 'riyoworkportal';
// // const REGION ='YOUR_DESIRED_REGION_HERE';
// AWS.config.update({
//     accessKeyId: 'AKIAWVZVUJ2JJRJGBNPC',
//     secretAccessKey: 'FYAbfPHSd6pJJQ9Rnxe5+gLihOJx8lxHs6gzDwkl'
// })
// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET},
//     // region: REGION,
// })

const s3 = new AWS.S3({
  // accessKeyId: "42517f1906a1e312810ee9ed7a0307ab",
  // secretAccessKey: "5dcddf9ff5cb2705f6ac2fbf65712c825b9969278e4a1efb551bd8f98e79f2ee",
  region: BUCKET_REGION,
  endpoint: BUCKET_ENDPOINT,
  credentials: {
    accessKeyId: BUCKET_ACCESS_KEY_ID,
    secretAccessKey: BUCKET_SECRET_KEY,
  },
});

const SAdminFileManagerMain = () => {
  const auth = useSelector((state) => state.auth);
  const filemanager = useSelector((state) => state.filemanager);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const filemanagerData = async () => {
    dispatch(getFileCompany({ superAdminFile: true }));
  };

  useEffect(() => {
    filemanagerData();
  }, []);

  const handleChangePic = async (event) => {
    const res = await axios.post(
      `${SERVERADDRESS}/v1/filemanager/getFileByIdentifier`,
      {
        identifier: "RiyoWorkSuperAdminData" + event.target.files[0].name,
      }
    );
    if (res.data === "Exists") {
      toast.error("File exist with the name");
      // alert("File exist with the name");
    } else {
      //   const today = new Date();
      //   const tsForUnique = today.getTime();
      //   const params = {
      //     Bucket: S3_BUCKET,
      //     Key:
      //       "data/" +
      //       "RiyoWorkSuperAdminData" +
      //       "/files/" +
      //       tsForUnique +
      //       "/" +
      //       event.target.files[0].name,
      //     Body: event.target.files[0],
      //     ACL: "public-read",
      //   };
      //   var upload = myBucket
      //     .upload(params)
      //     .on("httpUploadProgress", (evt) => {
      //       setProgress(Math.round((evt.loaded / evt.total) * 100));
      //     })
      //     .promise();
      //   let url;
      //   await upload.then(function (data, err) {
      //     try {
      //       url = data.Location;
      //       // setAwsURL(url)
      //       console.log(url);
      //     } catch (error) {
      //       console.log(error, err, "ee");
      //     }
      //   });

      const today = new Date();
      const tsForUnique = today.getTime();
      //   const { name } = req.body;
      const filePath =
        "data/" +
        "RiyoWorkSuperAdminData" +
        "/files/" +
        tsForUnique +
        "/" +
        event.target.files[0].name;

      const params = {
        Bucket: BUCKET_NAME,
        Key: filePath,
        Body: event.target.files[0],

        // ACL: 'public-read',
      };

      await s3
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          //   console.log("pro==>", Math.round((evt.loaded / evt.total) * 100));
        })
        .promise();
      // let url="";
      // await multipartUpload();
      const url = filePath;

      setProgress(0);
      // console.log(event.target.files[0], event.target)
      // const { data } = await axios.post(`${SERVERADDRESS}/v1/filemanager/createFile`,
      await axios.post(`${SERVERADDRESS}/v1/filemanager/createFile`, {
        url: url,
        file: event.target.files[0].name,
        type: event.target.files[0].type,
        filesize: event.target.files[0].size,
        tStamp: tsForUnique,
        userId: auth.user.id,
        companyId: auth.user.companyId,
      });
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="file-wrap">
            <div className="file-cont-wrap" style={{ width: "100%" }}>
              <div className="file-cont-inner">
                <div className="file-cont-header">
                  <div className="file-options"></div>
                  {progress !== 0 ? (
                    <span>Uploading {progress}%</span>
                  ) : (
                    <span>File Manager</span>
                  )}

                  <div className="file-options">
                    <span className="btn-file">
                      <input
                        type="file"
                        className="upload"
                        onChange={handleChangePic}
                      />
                      <i className="fa fa-upload"></i>
                    </span>
                  </div>
                </div>
                <div className="file-content">
                  {/* <form className="file-search">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search" style={{borderTopLeftRadius:"50px", borderBottomLeftRadius:"50px"}}/>
                                    </div>
                                </form> */}
                  {filemanager.filemanagerTable &&
                    filemanager.filemanagerTable.allFiles &&
                    filemanager.filemanagerTable.allFiles.length > 0 && (
                      <div className="file-body">
                        <div className="file-scroll">
                          <div className="file-content-inner">
                            <h4>Recent Files</h4>
                            <div className="row row-sm">
                              {filemanager.filemanagerTable &&
                                filemanager.filemanagerTable.recent &&
                                filemanager.filemanagerTable.recent.length >
                                  0 &&
                                filemanager.filemanagerTable.recent.map(
                                  (current, i) => {
                                    let fileIcon = "fa fa-file-word-o";
                                    if (
                                      current.mimeTypeCategory === "image" ||
                                      current.mimeTypeCategory === "video" ||
                                      current.mimeTypeCategory === "audio"
                                    ) {
                                      if (
                                        current.mimeTypeCategory === "image"
                                      ) {
                                        fileIcon = "fa fa-file-image-o";
                                      } else if (
                                        current.mimeTypeCategory === "video"
                                      ) {
                                        fileIcon = "fa fa-file-video-o";
                                      } else {
                                        fileIcon = "fa fa-file-audio-o";
                                      }
                                    } else {
                                      if (
                                        current.ext === "docx" ||
                                        current.ext === "doc"
                                      ) {
                                        fileIcon = "fa fa-file-word-o";
                                      } else if (current.ext === "pdf") {
                                        fileIcon = "fa fa-file-pdf-o";
                                      } else if (current.ext === "html") {
                                        fileIcon = "fa fa-file-code-o";
                                      } else if (current.ext === "txt") {
                                        fileIcon = "fa fa-file-text-o";
                                      } else if (
                                        current.ext === "xls" ||
                                        current.ext === "xlsx" ||
                                        current.ext === "csv" ||
                                        current.ext === "csvx"
                                      ) {
                                        fileIcon = "fa fa-file-excel-o";
                                      } else if (
                                        current.ext === "ppt" ||
                                        current.ext === "pptx"
                                      ) {
                                        fileIcon = "fa fa-file-powerpoint-o";
                                      } else {
                                        fileIcon = "fa fa-file-o";
                                      }
                                    }
                                    return (
                                      <SAdminFileManager
                                        current={current}
                                        i={i}
                                        fileIcon={fileIcon}
                                      />
                                    );
                                  }
                                )}
                            </div>
                            <h4>Files</h4>
                            <div className="row row-sm">
                              {filemanager.filemanagerTable &&
                                filemanager.filemanagerTable.allFiles &&
                                filemanager.filemanagerTable.allFiles.length >
                                  0 &&
                                filemanager.filemanagerTable.allFiles.map(
                                  (current, i) => {
                                    let fileIcon = "fa fa-file-word-o";
                                    if (
                                      current.mimeTypeCategory === "image" ||
                                      current.mimeTypeCategory === "video" ||
                                      current.mimeTypeCategory === "audio"
                                    ) {
                                      if (
                                        current.mimeTypeCategory === "image"
                                      ) {
                                        fileIcon = "fa fa-file-image-o";
                                      } else if (
                                        current.mimeTypeCategory === "video"
                                      ) {
                                        fileIcon = "fa fa-file-video-o";
                                      } else {
                                        fileIcon = "fa fa-file-audio-o";
                                      }
                                    } else {
                                      if (
                                        current.ext === "docx" ||
                                        current.ext === "doc"
                                      ) {
                                        fileIcon = "fa fa-file-word-o";
                                      } else if (current.ext === "pdf") {
                                        fileIcon = "fa fa-file-pdf-o";
                                      } else if (current.ext === "html") {
                                        fileIcon = "fa fa-file-code-o";
                                      } else if (current.ext === "txt") {
                                        fileIcon = "fa fa-file-text-o";
                                      } else if (
                                        current.ext === "xls" ||
                                        current.ext === "xlsx" ||
                                        current.ext === "csv" ||
                                        current.ext === "csvx"
                                      ) {
                                        fileIcon = "fa fa-file-excel-o";
                                      } else if (
                                        current.ext === "ppt" ||
                                        current.ext === "pptx"
                                      ) {
                                        fileIcon = "fa fa-file-powerpoint-o";
                                      } else {
                                        fileIcon = "fa fa-file-o";
                                      }
                                    }
                                    return (
                                      <SAdminFileManager
                                        current={current}
                                        i={i}
                                        fileIcon={fileIcon}
                                      />
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SAdminFileManagerMain;

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tabAboutUs } from '../actions/tabActions'
import AboutUsInfo from '../components/aboutUsComponents/AboutUsInfo'
import BannerAboutUs from '../components/aboutUsComponents/BannerAboutUs'
import Facts from '../components/aboutUsComponents/Facts'
import OurUsers from '../components/aboutUsComponents/OurUsers'
// import Testimonials from '../components/aboutUsComponents/Testimonials'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
// import '../../src/assetsgeneral/css/22bootstrap.min.css'
// import '../../src/assetsgeneral/css/style.css'
// import '../../src/customCss.css'
const AboutUs = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabAboutUs())
  }, [])
  return (
    <>
      <NavBar />
      <BannerAboutUs />
      <AboutUsInfo />
      {/* <PageTitle title="About Us" /> */}
      {/* <AboutUsInfo /> */}
      <Facts />
      {/* <Testimonials /> */}
      <OurUsers />
      <Footer />
    </>
  )
}
export default AboutUs
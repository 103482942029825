// import { type } from "@testing-library/user-event/dist/type"

export const tabAllEmployee = () => {
    return { 
    type: "TAB_ALLEMPLOYEE"
    }
}
export const tabLocationsPosition = () => {
    return {
        type: "TAB_LOCATIONSPOSITION"
    }
}
export const tabBreakRules = () => {
    return {
        type: "TAB_BREAKRULES"
    }
}


export const tabPayroll = () => {
    return {
        type: "TAB_PAYROLL"
    }
}
export const tabEmployeeSalaries = () => {
    return {
        type: "TAB_EMPLOYEESALARIES"
    }
}


export const tabTimeClock = () => {
    return {
        type: "TAB_TIMECLOCK"
    }
}
export const tabRecentClockin = () => {
    return {
        type: "TAB_RECENTCLOCKIN"
    }
}
export const tabAttendance = () => {
    return {
        type: "TAB_ATTENDANCE"
    }
}
export const tabTimeSheet = () => {
    return {
        type: "TAB_TIMESHEET"
    }
}
export const tabOvertime = () => {
    return {
        type: "TAB_OVERTIME"
    }
}


export const tabLeave = () => {
    return {
        type: "TAB_LEAVE"
    }
}
export const tabLeaveReport = () => {
    return {
        type: "TAB_LEAVEREPORT"
    }
}
export const tabLeaveSetting = () => {
    return {
        type: "TAB_LEAVESETTING"
    }
}
export const tabHolidays = () => {
    return {
        type: "TAB_HOLIDAYS"
    }
}
export const tabLeaveSearch = () => {
    return {
        type: "TAB_LEAVESEARCH"
    }
}



export const tabLocation = () => {
    return {
        type: "TAB_LOCATION"
    }
}
export const tabFileManager = () => {
    return {
        type: "TAB_FILEMANAGER"
    }
}


export const tabAllStaff = () => {
    return {
        type: "TAB_ALLSTAFF"
    }
}
export const tabActive = () => {
    return {
        type: "TAB_ACTIVE"
    }
}
export const tabNonActive = () => {
    return {
        type: "TAB_NONACTIVE"
    }
}

export const tabSearch = () => {
    return {
        type: "TAB_SEARCH"
    }
}


export const tabWeekly = () => {
    return {
        type: "TAB_WEEKLY"
    }
}
export const tabDaily = () => {
    return {
        type: "TAB_DAILY"
    }
}
export const tabMonthly = () => {
    return {
        type: "TAB_MONHTLY"
    }
}

export const tabShiftSearch = () => {
    return {
        type: "TAB_SHIFTSEARCH"
    }
}

export const tabAttendanceSearch = () => {
    return {
        type: "TAB_ATTENDANCESEARCH"
    }
}

export const tabCheckMonthAndYearAttendance = (obj) => {
    return {
        type: "TAB_CHECKMONTHANDYEARATTENDANCE",
        value: obj
    }
}

export const tabReportTimeClkWeek = () => {
    return {
        type: "TAB_REPORTTIMECLKWEEK",
    }
}

export const tabReportTimeClkDaily = () => {
    return {
        type: "TAB_REPORTTIMECLKDAILY",
    }
}

export const tabReportTimeClkSearch = () => {
    return {
        type: "TAB_REPORTTIMECLKSEARCH",
    }
}

export const tabSalaryCalendarSearch = () => {
    return {
        type: "TAB_SALARYCALENDARSEARCH",
    }
}


export const tabReportShiftWeek = () => {
    return {
        type: "TAB_REPORTSHIFTWEEK",
    }
}

export const tabReportShiftDaily = () => {
    return {
        type: "TAB_REPORTSHIFTDAILY",
    }
}
export const tabReportShiftSearch = () => {
    return {
        type: "TAB_REPORTSHIFTSEARCH",
    }
}



// superadmin redux tab action
export const tabCompany = () => {
    return {
        type: "TAB_COMPANY"
    }
}
export const tabSubscription = () => {
    return {
        type: "TAB_SUBSCRIPTION"
    }
}
export const tabSComp = () => {
    return {
        type: "TAB_SCOMP"
    }
}
export const tabNewRequest = () => {
    return {
        type: "TAB_NEWREQUEST"
    }
}
export const tabSearchCompany = () => {
    return {
        type: "TAB_SEARCHCOMPANY"
    }
}


export const tabCompanySearchFilter = () => {
    return {
        type: "TAB_COMPANY_SEARCH_FILTER"
    }
}
export const tabCompanyMainPage = () => {
    return {
        type: "TAB_COMPANY_MAIN_PAGE"
    }
}



// general website tab actions
export const tabHome = () => {
    return {
        type: "TAB_HOME"
    }
}
export const tabAboutUs = () => {
    return {
        type: "TAB_ABOUTUS"
    }
}
export const tabOurPlans = () => {
    return {
        type: "TAB_OURPLANS"
    }
}
export const tabWeWork = () => {
    return {
        type: "TAB_WEWORK"
    }
}
export const tabContactUs = () => {
    return {
        type: "TAB_CONTACTUS"
    }
}

export const tabPackageMonthly = () => {
    return {
        type: "TAB_PACKAGEMONTHLY"
    }
}

export const tabPackageYearly = () => {
    return {
        type: "TAB_PACKAGEYEARLY"
    }
}

export const tabPackageId = (objId) => {
    return {
        type: "TAB_PACKAGEID",
        value: objId
    }
}

export const tabPackagePrice = (price) => {
    return {
        type: "TAB_PACKAGEPRICE",
        value: price
    }
}

export const tabCustomerEmail = (string) => {
    return {
        type: "TAB_CUSTOMEREMAIL",
        value: string
    }
}

export const tabIdentifyUser = () => {
    return {
        type: "TAB_IDENTIFYUSER",
    }
}
export const tabLinkSendToUser = () => {
    return {
        type: "TAB_LINKSENDTOUSER",
    }
}












// for table
// export const tableRowNumber = (n) => {
//     return {
//         type: "TAB_TABLEROWNUMBER",
//         value: n
//     }
// }




// redux ka tarika
// pehly action main action
// phr reducer mein 2 file hain reducer ki index ki
// reducer edit and index ek bar ready
// phr orignal index
// phir store update 
// phr page pay sara date and switch state 
// aur uspe jo file ha uspa useselector and dispatch



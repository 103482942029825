import React, { useState } from 'react'
import SAdminEditModal from './SAdminEditModal'
import SAdminPolicyDeleteModal from './SAdminPolicyDeleteModal'

const SAdminPoliciesTable = () => {
    const [showDropDown, setshowDropDown] = useState(false)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
        setshowMoreVertDropDown(false)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
        setshowMoreVertDropDown(false)
    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th style={{width: '30px'}}>#</th>
                                    <th>Policy Name </th>
                                    <th> </th>
                                    <th>Description </th>
                                    <th>Created </th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Leave Policy</td>
                                    <td></td>
                                    <td>Lorem ipsum dollar</td>
                                    <td>19 Feb 2019</td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                                {/* <a className="dropdown-item" href="#"><i className="fa fa-download m-r-5"></i> Download</a> */}
                                                <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_policy"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_policy"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <SAdminEditModal showDropDown={showDropDown} openDropDown={openDropDown}/>
                                <SAdminPolicyDeleteModal showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/>
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
export default SAdminPoliciesTable
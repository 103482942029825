import React from 'react'
import { NavLink } from 'react-router-dom'

const AllActivities = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="activity">
                        <div className="activity-box">
                            <ul className="activity-list">
                                <li>
                                    <div className="activity-user">
                                        {/* user profile ka link hona chaiya apne apne */}
                                        <NavLink to="/profile" title="Lesley Grauer" data-toggle="tooltip" className="avatar">
                                            <img src="assets/img/profiles/avatar-01.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Lesley Grauer</NavLink> added new task <NavLink to="#">Hospital Administration</NavLink>
                                            <span className="time">4 mins ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="activity-user">
                                        <NavLink to="/profile" className="avatar" title="Jeffery Lalor" data-toggle="tooltip">
                                            <img src="assets/img/profiles/avatar-16.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Jeffery Lalor</NavLink> added <NavLink to="/profile" className="name">Loren Gatlin</NavLink> and <NavLink to="/profile" className="name">Tarah Shropshire</NavLink> to project <NavLink to="#">Patient appointment booking</NavLink>
                                            <span className="time">6 mins ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="activity-user">
                                        <NavLink to="/profile" title="Catherine Manseau" data-toggle="tooltip" className="avatar">
                                            <img src="assets/img/profiles/avatar-08.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Catherine Manseau</NavLink> completed task <NavLink to="#">Appointment booking with payment gateway</NavLink>
                                            <span className="time">12 mins ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="activity-user">
                                        <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip" className="avatar">
                                            <img src="assets/img/profiles/avatar-13.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Bernardo Galaviz</NavLink> changed the task name <NavLink to="#">Doctor available module</NavLink>
                                            <span className="time">1 day ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="activity-user">
                                        <NavLink to="/profile" title="Mike Litorus" data-toggle="tooltip" className="avatar">
                                            <img src="assets/img/profiles/avatar-05.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Mike Litorus</NavLink> added new task <NavLink to="#">Patient and Doctor video conferencing</NavLink>
                                            <span className="time">2 days ago</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="activity-user">
                                        <NavLink to="/profile" title="Jeffery Lalor" data-toggle="tooltip" className="avatar">
                                            <img src="assets/img/profiles/avatar-16.jpg" alt="" />
                                        </NavLink>
                                    </div>
                                    <div className="activity-content">
                                        <div className="timeline-content">
                                            <NavLink to="/profile" className="name">Jeffery Lalor</NavLink> added <NavLink to="/profile" className="name">Jeffrey Warden</NavLink> and <NavLink to="/profile" className="name">Bernardo Galaviz</NavLink> to the task of <NavLink to="#">Private chat module</NavLink>
                                            <span className="time">7 days ago</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllActivities
import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMail } from '../../../actions/mailActions'
const SAdminSendMsgToUser = () => {
    // const mail = useSelector((state) => state.mail)
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const [ values, setValues ] = useState({
        companyId:'',
        condition: '',
        subject: '',
        messages: [],
        sender: '',
        message: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const dispatch = useDispatch()
    const mailData = {
        companyId: auth.user.companyId,
        condition: "All Users",
        subject: values.subject,
        messages: [{
            sender: auth.user.id,
            message: values.message,
        }],
        userId: values.userId,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(createMail(mailData))
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                    disabled
                                    autoComplete="false">
                                    <option value="">To All Service Users</option>
                                </select>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <br />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" placeholder="Subject" className="form-control"
                                    value={values.subject}
                                    onChange={handleChange('subject')}/>
                                </div>
                                <div className="form-group">
                                    <textarea rows="4" className="form-control"
                                    placeholder="Type a message..."
                                    autoComplete="false" value={values.message}
                                    onChange={handleChange('message')}></textarea>
                                </div>
                                <div className="form-group mb-0">
                                    <div className="text-center">
                                        <button className="btn btn-primary"><span>Send</span> <i className="fa fa-send m-l-5"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SAdminSendMsgToUser
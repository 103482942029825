import React from "react";
import { NavLink } from "react-router-dom";
const SuperAdminExtraCards = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-4 d-flex">
          <div className="card flex-fill dash-statistics">
            <div className="card-body">
              <h5 className="card-title">Statistics</h5>
              <div className="stats-list">
                <div className="stats-info">
                  <p>
                    Today Leave{" "}
                    <strong>
                      4 <small>/ 65</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      style={{ width: "31%" }}
                      aria-valuenow="31"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    Locations Active{" "}
                    <strong>
                      15 <small>/ 20</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "70%" }}
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    Employees Active{" "}
                    <strong>
                      105 <small>/ 112</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "90%" }}
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    Todays late{" "}
                    <strong>
                      5 <small>/ 100</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: "5%" }}
                      aria-valuenow="5"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="stats-info">
                  <p>
                    Closed Tickets{" "}
                    <strong>
                      22 <small>/ 212</small>
                    </strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "22%" }}
                      aria-valuenow="22"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Late Coming{" "}
                <span className="badge bg-inverse-danger ml-2">2</span>
              </h4>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="profile.html" className="avatar">
                    <img alt="" src="assets/img/avatar-19.jpg" />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">Madame tussauds</h6>
                    <span className="text-sm text-muted">
                      Timings : 16:30 - 21:00
                    </span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-danger text-white">
                      30 min late
                    </span>
                  </div>
                </div>
              </div>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="profile.html" className="avatar">
                    <img alt="" src="assets/img/avatar-19.jpg" />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">Madame tussauds</h6>
                    <span className="text-sm text-muted">
                      Timings : 16:30 - 21:00
                    </span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-danger text-white">
                      30 min late
                    </span>
                  </div>
                </div>
              </div>
              <div className="load-more text-center">
                <NavLink className="text-dark" to="">
                  Take Action
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Today Absent{" "}
                <span className="badge bg-inverse-danger ml-2">5</span>
              </h4>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="profile.html" className="avatar">
                    <img alt="" src="assets/img/user.jpg" />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">9/22/2021</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-inverse-danger">Pending</span>
                  </div>
                </div>
              </div>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="profile.html" className="avatar">
                    <img alt="" src="assets/img/user.jpg" />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">9/22/2021</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-inverse-success">Approved</span>
                  </div>
                </div>
              </div>
              <div className="load-more text-center">
                <NavLink className="text-dark" to="">
                  Load More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminExtraCards;

import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import SAdminPoliciesTable from "../../components/superAdminCompoents/superAdminPoliciesAdministration/SAdminPoliciesTable";
const SuperAdminPoliciesPage = () => (
  <>
    {/* <SuperAdminHeader />
        <SuperAdminSidebar /> */}
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <AdminTittle title="Policies" SAdminAddPolicy /> */}
          <SAdminPoliciesTable />
        </div>
      </div>
    </div>
  </>
);
export default SuperAdminPoliciesPage;

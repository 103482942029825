import React, { useState } from 'react'
// import { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
// import { getPackage } from '../../../actions/packageActions'
import SuperAdminAnnualPlan from './SuperAdminAnnualPlan'
import SuperAdminMonthlyPlan from './SuperAdminMonthlyPlan'
import SuperAdminPlanTransaction from './SuperAdminPlanTransaction'

const SuperAdminMenu = () => {
   const [ viewSubscriptionMenu, setViewSubscriptionMenu ] = useState("monthlyplantab")
   const handleMonthlyPlanTab = (e) => {
      setViewSubscriptionMenu("monthlyplantab")
   }
   const handleAnnuallyPlanTab = (e) => {
      setViewSubscriptionMenu("annuallyplantab")
   }
   // const dispatch = useDispatch()
   // useEffect(() => {
   //    dispatch(getPackage())
   // }, [])
    return (
    <>
    {
         viewSubscriptionMenu === "monthlyplantab" ?
         <>
         <div className="row">
            <div className="col-lg-10 mx-auto">
               <div className="row justify-content-center mb-4">
                  <div className="col-auto">
                     <nav className="nav btn-group">
                        <a href="#monthly" className="btn btn-outline-secondary active show" onClick={handleMonthlyPlanTab} data-toggle="tab">Monthly Plan</a>
                        <a href="#annual" className="btn btn-outline-secondary" onClick={handleAnnuallyPlanTab} data-toggle="tab">Annual Plan</a>
                     </nav>
                  </div>
               </div>
               <SuperAdminMonthlyPlan /> 
               <SuperAdminPlanTransaction />
            </div>
         </div>
         </>
         : viewSubscriptionMenu === "annuallyplantab" ?
         <>
         <div className="row">
            <div className="col-lg-10 mx-auto">
               <div className="row justify-content-center mb-4">
                  <div className="col-auto">
                     <nav className="nav btn-group">
                        <a href="#monthly" className="btn btn-outline-secondary" onClick={handleMonthlyPlanTab} data-toggle="tab">Monthly Plan</a>
                        <a href="#annual" className="btn btn-outline-secondary active show" onClick={handleAnnuallyPlanTab} data-toggle="tab">Annual Plan</a>
                     </nav>
                  </div>
               </div>
               <SuperAdminAnnualPlan />
               <SuperAdminPlanTransaction />
            </div>
         </div>
         </>
         :
         <div>loading</div>
         }
    </>
   )
}
export default SuperAdminMenu
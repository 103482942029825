import React from 'react'
import { NavLink } from 'react-router-dom'
import sliderremovedbg from '../../src/assetsgeneral/images/sliderremovedbg.png'
// import slider from '../../src/assetsgeneral/images/slider.png'

const Banner = () => {
    return (
        <>
            <div className="slider-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="work-schedule">
                                <h2>WORK SCHEDULING </h2>
                                <h3> WORK BETTER, TOGETHER</h3>
                                <p> Riyowork is an employee scheduling app that does more than just save you time on scheduling.</p>
                                <NavLink to='/our-plan'>
                                    <button className="get-started">Get Started</button>
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="work-schedule-img">
                                <img src={sliderremovedbg} id="sli-img-se" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner
import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import paymentImg from "../../assetsgeneral/images/payment-method.png";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { createNewLead } from "../../actions/superAdminCompanyActions";
import { tabCustomerEmail } from "../../actions/tabActions";
import axios from "axios";
import { SERVERADDRESS } from "../../constants";
// import MailConfirm from '../confirmMailComponents/MailConfirm';
// import stripe from "../../assetsgeneral/images/stripe-img.png"

const ValuesForm = ({ current }) => {
  // const [toggle, setToggle] = useState(false)
  const myState = useSelector((state) => state.changeTheTab);
  const company = useSelector((state) => state.company);
  const [step, setStep] = useState(1);
  // const handleChange = () => {
  //     setToggle(toggle => !toggle)
  // }
  const [isErr, setIsErr] = useState(false);
  const [isErrCheck, setIsErrCheck] = useState(false);
  // const [isErrCheck2, setIsErrCheck2] = useState(false)
  const handleNext = () => {
    if (step === 1) {
      if (values.check === false) {
        setIsErrCheck(true);
      } else {
        setIsErrCheck(false);
      }
      // if ( values.check2 === false ) {
      //     setIsErrCheck2(true)
      // }else {
      //     setIsErrCheck2(false)
      // }
      if (
        values.companyName === "" ||
        // values.companyEmail === "" ||
        // values.companyPhone === "" ||
        values.industry === "" ||
        values.numberOfEmployees === "" ||
        values.firstName === "" ||
        values.lastName === "" ||
        values.email === "" ||
        values.phone === ""
      ) {
        setIsErr(true);
      } else if (values.phone.length !== 14) {
        setIsErr(true);
      }
      // else if (values.phone.length === 14 ){
      //     setIsErr(false)
      // }
      else {
        setIsErr(false);
        if (
          values.check === true &&
          values.check2 === true &&
          isEmail === false &&
          isPhone === false
        ) {
          setStep(step + 1);
        }
      }
    }
    // if (step === 2){
    //     if (
    //         values.firstName === "" ||
    //         values.lastName === "" ||
    //         values.email === "" ||
    //         values.phone === ""
    //     ){
    //         setIsErr(true)
    //     }
    //     else {
    //         setIsErr(false)
    //         setStep(step+1)
    //     }
    // }
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };
  // const [ confirmMailComponent, setConfirmMailComponent]=useState(false)
  const navigate = useNavigate();
  const navigateUser = () => {
    navigate("/verify-details");
  };
  const [values, setValues] = useState({
    companyName: "",
    // companyEmail: "",
    // companyPhone: "",
    industry: "",
    numberOfEmployees: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    cardNumber: "",
    expiryMonth: "",
    expiryYear: "",
    cvc: "",
    packageId: "",
    currentSubscriptionPackageType: "",
    date: "",
    check: false,
    check2: false,
  });
  // let name, value;
  const leadData = {
    companyName: values.companyName,
    companyEmail: values.email,
    companyPhone: values.phone,
    industry: values.industry,
    numberOfEmployees: values.numberOfEmployees,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phone: values.phone,
    cardNumber: parseInt(values.cardNumber),
    expMonthYear: values.expiryMonth + "/" + values.expiryYear,
    cvc: parseInt(values.cvc),
    packageId: myState.packageIdForProceed,
    currentSubscriptionPackageType: myState.packageType,
    date: moment().format("YYYY-MM-DD"),
  };
  // console.log(leadData)
  // const handleInputs = (e) => {
  //     console.log(e);
  //     name = e.target.name;
  //     value = e.target.value;
  //     setValues({...values,[name]:value})
  // }
  const dispatch = useDispatch();
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
    // if (values.phone.substring(3,4))
  };
  // const postValues = async (e) => {
  //     e.preventDefault();
  //     const {fullName, email ,mobileNumber, industry, companyName, numberOfEmployees} = values

  //     const res = await fetch("/postValues", {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json"
  //         },
  //         body: JSON.stringify({
  //             fullName, email ,mobileNumber, industry, companyName, numberOfEmployees
  //         })
  //     });
  //     const data = await res.json();
  //    navigate("/confirm-mail")
  // }
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      values.cardNumber === "" ||
      values.cvc === "" ||
      values.expiryMonth === "" ||
      values.expiryYear === ""
    ) {
      setIsErr(true);
    } else {
      if (
        parseInt(values.expiryMonth) < 13 &&
        parseInt(values.expiryMonth) > 0
      ) {
        dispatch(tabCustomerEmail(leadData.email));
        dispatch(createNewLead(leadData, navigateUser));
      }
    }
  };
  const params = useParams();
  const openTerms = () => {
    window.open("https://riyowork.com/terms-of-use", "_blank");
  };
  const openPrivacy = () => {
    // window.open('http://localhost:3000/privacy-policy', '_blank',)
    window.open("https://riyowork.com/privacy-policy", "_blank");
  };
  // console.log(values, "laaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaayyyyyyyyyyyy")
  const [isEmail, setIsEmail] = useState(false);
  const checkIfEmail = async () => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/newLead/getNewLeadByEmail`,
      {
        email: values.email,
      }
    );
    if (data === "Email already exists!") {
      setIsEmail(true);
    } else {
      setIsEmail(false);
    }
  };
  const [isPhone, setIsPhone] = useState(false);
  const checkIfPhone = async () => {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/newLead/getNewLeadByPhone`,
      {
        phone: values.phone,
      }
    );
    if (data === "Phone already exists!") {
      setIsPhone(true);
    } else {
      setIsPhone(false);
    }
    // console.log(data)
  };
  return (
    <>
      <section className="pricing-6-area pt-62 new-bg-color" id="kkk">
        <div className="container">
          <div className="row contact-area justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
              <div className="pricing-6-item mt-30">
                <div className="section-title-6 text-center">
                  <h3 className="title">
                    Let's get the schedule set up for your workplace.
                  </h3>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="progress progBarStyle">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "100%",
                          backgroundColor: "#8ABE4B",
                          borderRadius: "0",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#8ABE4B",
                        height: "20px",
                        width: "20px",
                        color: "white",
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      1
                    </div>
                    {step === 1 ? (
                      <>
                        <div className="progress progBarStyle2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "100%",
                              backgroundColor: "#e9ecef",
                              borderRadius: "0",
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#e9ecef",
                            height: "20px",
                            width: "20px",
                            color: "white",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        >
                          2
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="progress progBarStyle"
                          style={{
                            height: "10px",
                            width: "47%",
                            display: "inline-block",
                            backgroundColor: "#8ABE4B",
                            borderRadius: "0",
                          }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: "100%",
                              backgroundColor: "#8ABE4B",
                              borderRadius: "0",
                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#8ABE4B",
                            height: "20px",
                            width: "20px",
                            color: "white",
                            textAlign: "center",
                            display: "inline-block",
                          }}
                        >
                          2
                        </div>
                      </>
                    )}
                    <br />
                    <br />
                    <div className="row">
                      {step === 1 ? (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ fontSize: "18px", marginTop: "1rem" }}
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                required
                                value={values.firstName}
                                onChange={handleChange("firstName")}
                                placeholder="First Name"
                                autocomplete="new-firstName"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ fontSize: "18px", marginTop: "1rem" }}
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                required
                                value={values.lastName}
                                onChange={handleChange("lastName")}
                                placeholder="Last Name"
                                autocomplete="new-lastName"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              >
                                Email
                              </label>
                              <input
                                type="email"
                                name="email"
                                required
                                value={values.email}
                                onChange={handleChange("email")}
                                onBlur={checkIfEmail}
                                placeholder="email@address.com"
                                autocomplete="new-email"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              />
                            </div>
                            {isEmail === true && (
                              <span className="text-danger">
                                &nbsp;
                                <i className="fa fa-exclamation-circle">
                                  {" "}
                                  Email already exists!
                                </i>
                              </span>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              >
                                Mobile Number
                              </label>
                              <input
                                minLength="14"
                                maxLength="14"
                                required
                                // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                                onKeyDown={(e) => {
                                  if (alphabets.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                  // console.log(e.code, e.key, values.phone.length, values.phone, values.phone.substring(0,3))
                                  if (
                                    e.code === "Backspace" &&
                                    values.phone.length === 6
                                  ) {
                                    setValues({
                                      ...values,
                                      phone: values.phone.substring(0, 4),
                                    });
                                  }
                                  if (
                                    e.code === "Backspace" &&
                                    values.phone.length === 2
                                  ) {
                                    setValues({
                                      ...values,
                                      phone: "",
                                    });
                                  }
                                  if (
                                    e.code === "Backspace" &&
                                    values.phone.length === 10
                                  ) {
                                    setValues({
                                      ...values,
                                      phone: values.phone.substring(0, 9),
                                    });
                                  }
                                }}
                                name="phone"
                                value={values.phone}
                                onChange={handlePhone("phone")}
                                placeholder="(123) 456-7890"
                                autocomplete="new-mobileNunmber"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              />
                            </div>
                            {isPhone === true && (
                              <span className="text-danger">
                                &nbsp;
                                <i className="fa fa-exclamation-circle">
                                  {" "}
                                  Phone already exists!
                                </i>
                              </span>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              >
                                Company Name
                              </label>
                              <input
                                type="text"
                                name="companyName"
                                required
                                value={values.companyName}
                                onChange={handleChange("companyName")}
                                onBlur={checkIfPhone}
                                placeholder="(e.g. ACME Inc.)"
                                autocomplete="new-companyName"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              >
                                Industry
                              </label>
                              <select
                                name="industry"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                                required
                                value={values.industry}
                                onChange={handleChange("industry")}
                              >
                                <option value="">
                                  What best describes your industry
                                </option>
                                <option value="Call Center / Customer Support">
                                  Call Center / Customer Support
                                </option>
                                <option value="Education">Education</option>
                                <option value="Food Services">
                                  Food Services
                                </option>
                                <option value="Government">Government</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Hospitality / Entertainment">
                                  Hospitality / Entertainment
                                </option>
                                <option value="Logistics">Logistics</option>
                                <option value="Manufacturing">
                                  Manufacturing
                                </option>
                                <option value="On-demand / Gig">
                                  On-demand / Gig
                                </option>
                                <option value="Retail">Retail</option>
                                <option value="Services">Services</option>
                                <option value="Technology">Technology</option>
                                <option value="Something Else">
                                  Something Else
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-box mt-30">
                              <label
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                              >
                                Number Of Employees
                              </label>
                              <select
                                name="numberOfEmployees"
                                style={{ marginTop: "1rem", fontSize: "18px" }}
                                required
                                value={values.numberOfEmployees}
                                onChange={handleChange("numberOfEmployees")}
                              >
                                <option value="">
                                  How many people do you schedule?
                                </option>
                                <option value="20">1 - 20</option>
                                <option value="50">21 - 50</option>
                                <option value="200">51 - 200</option>
                                <option value="500">201 - 500</option>
                                <option value="3000">501 - 3,000</option>
                                <option value="3001">3,001+</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            {/* <p className="text-center mt-5"> */}
                            <br />
                            <table>
                              <tr>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <td style={{ paddingRight: "10px" }}>
                                  <input
                                    type="checkbox"
                                    required
                                    checked={values.check}
                                    onClick={() =>
                                      setValues({
                                        ...values,
                                        check: !values.check,
                                      })
                                    }
                                  />
                                  {/* <input type='checkbox' required value={values.check}
                                                                onChange={handleChange('check')} 
                                                                /> */}
                                </td>
                                <td className="res-table-accept">
                                  I read, understand and agree to the&nbsp;{" "}
                                </td>
                                <button
                                  type="button"
                                  onClick={openTerms}
                                  className="checkbox"
                                >
                                  Terms Of Use
                                </button>
                                {/* <button type='button'onClick={openPrivacy} className='checkbox'> Privacy Policy</button> */}
                              </tr>

                              <tr>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <td style={{ paddingRight: "10px" }}>
                                  <input
                                    type="checkbox"
                                    required
                                    checked={values.check2}
                                    onClick={() =>
                                      setValues({
                                        ...values,
                                        check2: !values.check2,
                                      })
                                    }
                                  />
                                </td>
                                <td className="res-table-accept">
                                  I read, understand and agree to the&nbsp;{" "}
                                </td>
                                <button
                                  type="button"
                                  onClick={openPrivacy}
                                  className="checkbox"
                                >
                                  {" "}
                                  Privacy Policy
                                </button>
                              </tr>
                            </table>
                            {/* </p> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mprchdr mipymnt">
                            <div className="mprcmn1">
                              <h4 className="title">Plan Type</h4>
                              <span>
                                <i className="fa fa-users"></i> {params.id}{" "}
                                Business ($
                                {myState.packagePrice
                                  .toString()
                                  .substring(0, 6)}{" "}
                                / {myState.packageType.split("ly")[0]})
                              </span>
                            </div>
                            <div className="mprcmn2">
                              <NavLink
                                className="main-btn"
                                to="/get-qoute"
                                style={{ borderRadius: "0" }}
                              >
                                Total Amount : $
                                {myState.packagePrice
                                  .toString()
                                  .substring(0, 6)}
                              </NavLink>
                            </div>
                          </div>
                          <div className="payment-option-box-inner">
                            <div className="payment-top-box">
                              <input
                                type="hidden"
                                name="payment_type"
                                value="1"
                              />
                              <div className="paypal-box">
                                <div className="paypal-top">
                                  {/* <img src={stripe} alt="payment gateways" /> */}
                                </div>
                                <br />
                                <div className="paypal-img">
                                  <img
                                    src={paymentImg}
                                    alt="payment gateways"
                                  />
                                </div>
                                <br />
                              </div>
                            </div>
                            {/* <p style={{fontSize: "18px"}}>If you Don't have Stripe Account, it doesn,t matter. You can also pay via Stripe with your credit card or bank debit card<br />Payment can be submitted in any currency.</p> */}
                          </div>
                          <div id="paymentForm" style={{ display: "block" }}>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="input-box">
                                  <div className="input_field">
                                    <label style={{ fontSize: "18px" }}>
                                      Card Number
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      minLength="16"
                                      maxLength="16"
                                      required
                                      onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      name="cardNumber"
                                      value={values.cardNumber}
                                      onChange={handleChange("cardNumber")}
                                      placeholder="Valid card number..."
                                      autocomplete="new-cardNumber"
                                      maxlength="16"
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="input-box">
                                  <div className="input_field">
                                    <label
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Expiry Month
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      minLength="2"
                                      maxLength="2"
                                      required
                                      onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      name="expiryMonth"
                                      value={values.expiryMonth}
                                      onChange={handleChange("expiryMonth")}
                                      placeholder="MM..."
                                      max-length="2"
                                      autocomplete="new-expiryMonth"
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="input-box">
                                  <div className="input_field">
                                    <label
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    >
                                      Expiry Year
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      minLength="4"
                                      maxLength="4"
                                      required
                                      onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      name="expiryYear"
                                      value={values.expiryYear}
                                      onChange={handleChange("expiryYear")}
                                      placeholder="YYYY..."
                                      max-length="4"
                                      autocomplete="new-expiryYear"
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="input-box">
                                  <div className="input_field">
                                    <label
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    >
                                      CV code
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      minLength="3"
                                      maxLength="3"
                                      required
                                      onKeyDown={(e) => {
                                        if (alphabets.includes(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      name="cvc"
                                      value={values.cvc}
                                      onChange={handleChange("cvc")}
                                      placeholder="3 digit CVC..."
                                      max-length="3"
                                      autocomplete="new-cvc"
                                      style={{
                                        marginTop: "1rem",
                                        fontSize: "18px",
                                      }}
                                    />
                                    <div id="card-element-cvc"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {/* <input required></input> */}
                    </div>
                    <div className="col-lg-12">
                      <p></p>
                      <div className="input-box mt-30 d-flex align-items-center justify-content-center">
                        {step === 1 ? (
                          <>
                            <button
                              type="submit"
                              onClick={handleNext}
                              className="btn-for-quote"
                            >
                              Next
                            </button>
                            {/* <NavLink 
                                                    // type="submit" 
                                                    onClick={handleNext}
                                                    to="#"
                                                    className="main-btn">Next</NavLink> */}
                            {/* <button onClick={handleNext}> next</button> */}
                          </>
                        ) : // step === 2 ?
                        // <>
                        // <NavLink
                        // // type="submit"
                        // onClick={handlePrevious}
                        // to="#"
                        // className="main-btn">Previous</NavLink>
                        // &nbsp;
                        // <NavLink
                        // // type="submit"
                        // onClick={handleNext}
                        // to="#"
                        // className="main-btn">Next</NavLink>
                        // </>
                        // :
                        step === 2 ? (
                          <>
                            <NavLink
                              // type="submit"
                              onClick={handlePrevious}
                              to="#"
                              className="main-btn"
                            >
                              Previous
                            </NavLink>
                            &nbsp;
                            {/* <NavLink 
                                                    // type="submit" 
                                                    onClick={handleSubmit}
                                                    to="#"
                                                    className="main-btn">Sign Up</NavLink> */}
                            <button type="submit" className="btn-for-quote">
                              Sign Up
                            </button>
                            {/* <button type="submit" className="main-btn">Start Scheduling Free</button>  */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <MailConfirm /> */}
    </>
  );
};
export default ValuesForm;

// <form onSubmit={handleSubmit}
// // action="/confirm-mail"
// >
// {/* <div className='row'> */}

// <div className="progress" style={{height: "10px", width: "47%", display: "inline-block", backgroundColor: "#8ABE4B", borderRadius: "0"}}>
//     <div className="progress-bar" role="progressbar" style={{width: "100%", backgroundColor: "#8ABE4B", borderRadius: "0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
// </div>
// <div style={{backgroundColor: "#8ABE4B", height: "20px", width:"20px", color: "white", textAlign: "center", display: "inline-block"}}>
// 1
// </div>
// {step === 1 ?
//     <>
//         <div className="progress" style={{height: "10px", width: "47%", display: "inline-block", backgroundColor: "#e9ecef", borderRadius: "0"}}>
//             <div className="progress-bar" role="progressbar" style={{width: "100%", backgroundColor: "#e9ecef", borderRadius: "0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//         </div>
//         <div style={{backgroundColor: "#e9ecef", height: "20px", width:"20px", color: "white", textAlign: "center", display: "inline-block"}}>
//         2
//         </div>
//     </>
//     :
//     <>
//         <div className="progress" style={{height: "10px", width: "47%", display: "inline-block", backgroundColor: "#8ABE4B", borderRadius: "0"}}>
//             <div className="progress-bar" role="progressbar" style={{width: "100%", backgroundColor: "#8ABE4B", borderRadius: "0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//         </div>
//         <div style={{backgroundColor: "#8ABE4B", height: "20px", width:"20px", color: "white", textAlign: "center", display: "inline-block"}}>
//         2
//         </div>
//     </>
// }
// {/* {step === 1 || step === 2 ?
//     <>
//         <div className="progress" style={{height: "10px", width: "30%", display: "inline-block", backgroundColor: "#e9ecef", borderRadius: "0"}}>
//             <div className="progress-bar" role="progressbar" style={{width: "100%", backgroundColor: "#e9ecef", borderRadius: "0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//         </div>
//         <div style={{backgroundColor: "#e9ecef", height: "20px", width:"20px", color: "white", textAlign: "center", display: "inline-block"}}>
//         3
//         </div>
//     </>
//     :
//     <>
//         <div className="progress" style={{height: "10px", width: "30%", display: "inline-block", backgroundColor: "#8ABE4B", borderRadius: "0"}}>
//             <div className="progress-bar" role="progressbar" style={{width: "100%", backgroundColor: "#8ABE4B", borderRadius: "0"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
//         </div>
//         <div style={{backgroundColor: "#8ABE4B", height: "20px", width:"20px", color: "white", textAlign: "center", display: "inline-block"}}>
//         3
//         </div>
//     </>
// } */}
// <br/>
// <br/>
//     <div className="row">
//         {
//         step === 1 ?
//         <>
//             <div className="col-lg-6 col-md-6">
//                 <div className="input-box mt-30">
//                     <label style={{fontSize: "18px", marginTop:"1rem"}}>First Name</label>
//                     <input type="text" name="firstName"
//                     required
//                     value={values.firstName}
//                     onChange={handleChange('firstName')}
//                     placeholder="First Name" autocomplete="new-firstName" style={{marginTop:"1rem", fontSize: "18px"}}/>
//                 </div>
//             </div>
//             <div className="col-lg-6 col-md-6">
//                 <div className="input-box mt-30">
//                     <label style={{fontSize: "18px", marginTop:"1rem"}}>Last Name</label>
//                     <input type="text" name="lastName"
//                     value={values.lastName}
//                     onChange={handleChange('lastName')}
//                     placeholder="Last Name" autocomplete="new-lastName" style={{marginTop:"1rem", fontSize: "18px"}}/>
//                 </div>
//             </div>
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Email</label>
//                 <input type="email" name="email"
//                 value={values.email}
//                 onChange={handleChange('email')}
//                 placeholder="email@address.com" autocomplete="new-email" style={{marginTop:"1rem", fontSize: "18px"}}/>
//             </div>
//         </div>
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Mobile Number</label>
//                 <input
//                 minLength="14"
//                 maxLength="14"
//                 // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
//                 onKeyDown={(e) => {
//                     if (alphabets.includes(e.key)) {
//                         e.preventDefault();
//                     }
//                     console.log(e.code, e.key, values.phone.length, values.phone, values.phone.substring(0,3))
//                     if (e.code === "Backspace" && values.phone.length === 6){
//                         setValues({
//                         ...values, phone:
//                         values.phone.substring(0,4)
//                         })
//                     }
//                     if (e.code === "Backspace" && values.phone.length === 2){
//                         setValues({
//                         ...values, phone:
//                         ''
//                         })
//                     }
//                     if (e.code === "Backspace" && values.phone.length === 10){
//                         setValues({
//                         ...values, phone:
//                         values.phone.substring(0,9)
//                         })
//                     }
//                 }} name="phone"
//                 value={values.phone}
//                 onChange={handlePhone('phone')}
//                 placeholder="(123)-456-7890" autocomplete="new-mobileNunmber" style={{marginTop:"1rem", fontSize: "18px"}}/>
//             </div>
//         </div>
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Company Name</label>
//                 <input type="text" name="companyName"
//                 value={values.companyName}
//                 onChange={handleChange('companyName')}
//                 placeholder="(e.g. ACME Inc.)" autocomplete="new-companyName" style={{marginTop:"1rem", fontSize: "18px"}}/>
//             </div>
//         </div>
//         {/* <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Company Email</label>
//                 <input type="email" name="companyEmail"
//                 value={values.companyEmail}
//                 onChange={handleChange('companyEmail')}
//                 placeholder="What is your Company Email?" style={{marginTop:"1rem", fontSize: "18px"}}/>
//             </div>
//         </div>
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30"> */}
//                 {/* <label style={{marginTop:"1rem", fontSize: "18px"}}>Company Phone</label> */}
//                 {/* <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required placeholder="___ - ___ - ____ "></input> */}
//                 {/* <input type="tel"
//                 id="phone"
//                 name="companyPhone"
//                 pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
//                 minLength="10"
//                 maxLength="10"
//                 onKeyDown={(e) => {
//                     if (alphabets.includes(e.key)) {
//                         e.preventDefault();
//                     }
//                 }}
//                 value={values.companyPhone}
//                 onChange={handleChange('companyPhone')}
//                 required placeholder="(123) - 456 - 7890"
//                 style={{marginTop:"1rem", fontSize: "18px"}}/>
//             </div>
//         </div> */}
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Industry</label>
//                 <select name="industry" style={{marginTop:"1rem", fontSize: "18px"}}
//                 value={values.industry}
//                 onChange={handleChange('industry')}>
//                     <option value="" selected>What best describes your industry</option>
//                     <option value="Call Center / Customer Support">Call Center / Customer Support</option>
//                     <option value="Education">Education</option>
//                     <option value="Food Services">Food Services</option>
//                     <option value="Government">Government</option>
//                     <option value="Healthcare">Healthcare</option>
//                     <option value="Hospitality / Entertainment">Hospitality / Entertainment</option>
//                     <option value="Logistics">Logistics</option>
//                     <option value="Manufacturing">Manufacturing</option>
//                     <option value="On-demand / Gig">On-demand / Gig</option>
//                     <option value="Retail">Retail</option>
//                     <option value="Services">Services</option>
//                     <option value="Technology">Technology</option>
//                     <option value="Something Else">Something Else</option>
//                 </select>
//             </div>
//         </div>
//         <div className="col-lg-6 col-md-6">
//             <div className="input-box mt-30">
//                 <label style={{marginTop:"1rem", fontSize: "18px"}}>Number Of Employees</label>
//                 <select name="numberOfEmployees" style={{marginTop:"1rem", fontSize: "18px"}}
//                 value={values.numberOfEmployees}
//                 onChange={handleChange('numberOfEmployees')}>
//                     <option value="" selected>How many people do you schedule?</option>
//                     <option value="20">1 - 20</option>
//                     <option value="50">21 - 50</option>
//                     <option value="200">51 - 200</option>
//                     <option value="500">201 - 500</option>
//                     <option value="3000">501 - 3,000</option>
//                     <option value="3001">3,001+</option>
//                 </select>
//             </div>
//         </div>
//         </>
//         :
//         // step === 2 ?
//         // <>
//         // </>
//         // :
//         <>
//         <div className="mprchdr mipymnt">
//             <div className="mprcmn1">
//                 <h4 className="title">Plan Type</h4>
//                 <span><i className="fa fa-users"></i> {params.id} Business (${myState.packagePrice.toString().substring(0,6)} / {myState.packageType.split('ly')[0]})</span>
//             </div>
//             <div className="mprcmn2">
//                 <NavLink className="main-btn" to="/get-qoute" style={{borderRadius:"0"}}>Total Amount : ${myState.packagePrice.toString().substring(0,6)}</NavLink>
//             </div>
//         </div>
//         <div className="payment-option-box-inner">
//             <div className="payment-top-box">
//                 <input type="hidden" name="payment_type" value="1" />
//                 <div className="paypal-box">
//                     <div className="paypal-top">
//                         {/* <img src={stripe} alt="payment gateways" /> */}
//                     </div>
//                     <br />
//                     <div className="paypal-img">
//                         <img src={paymentImg} alt="payment gateways" />
//                     </div>
//                     <br />
//                 </div>
//             </div>
//             {/* <p style={{fontSize: "18px"}}>If you Don't have Stripe Account, it doesn,t matter. You can also pay via Stripe with your credit card or bank debit card<br />Payment can be submitted in any currency.</p> */}
//         </div>
//         <div id="paymentForm" style={{ display: 'block' }}>
//             <div className="row">
//                 <div className="col-lg-12 col-md-12 col-sm-12">
//                     <div className="input-box">
//                         <div className="input_field">
//                             <label style={{fontSize: "18px"}}>Card Number</label>
//                             <input className="form-control" type="text"
//                             minLength="16"
//                             maxLength="16"
//                             onKeyDown={(e) => {
//                                 if (alphabets.includes(e.key)) {
//                                     e.preventDefault();
//                                 }
//                             }} name="cardNumber"
//                             value={values.cardNumber}
//                             onChange={handleChange('cardNumber')}
//                             placeholder="Valid card number..." autocomplete="new-cardNumber" maxlength="16"style={{marginTop:"1rem", fontSize: "18px"}} />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="row">
//                 <div className="col-lg-4 col-md-4 col-sm-12">
//                     <div className="input-box">
//                         <div className="input_field">
//                             <label style={{marginTop:"1rem", fontSize: "18px"}}>Expiry Month</label>
//                             <input className="form-control" type="text"
//                             minLength="2"
//                             maxLength="2"
//                             onKeyDown={(e) => {
//                                 if (alphabets.includes(e.key)) {
//                                     e.preventDefault();
//                                 }
//                             }} name="expiryMonth"
//                             value={values.expiryMonth}
//                             onChange={handleChange('expiryMonth')}
//                             placeholder="MM..." max-length="2" autocomplete="new-expiryMonth"
//                             style={{marginTop:"1rem", fontSize: "18px"}}/>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-4 col-md-4 col-sm-12">
//                     <div className="input-box">
//                         <div className="input_field">
//                             <label style={{marginTop:"1rem", fontSize: "18px"}}>Expiry Year</label>
//                             <input className="form-control" type="text"
//                             minLength="4"
//                             maxLength="4"
//                             onKeyDown={(e) => {
//                                 if (alphabets.includes(e.key)) {
//                                     e.preventDefault();
//                                 }
//                             }} name="expiryYear"
//                             value={values.expiryYear}
//                             onChange={handleChange('expiryYear')}
//                             placeholder="YYYY..." max-length="4" autocomplete="new-expiryYear"
//                             style={{marginTop:"1rem", fontSize: "18px"}}
//                             />
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-lg-4 col-md-4 col-sm-12">
//                     <div className="input-box">
//                         <div className="input_field">
//                             <label style={{marginTop:"1rem", fontSize: "18px"}}>CV code</label>
//                             <input className="form-control" type="text"
//                             minLength="3"
//                             maxLength="3"
//                             onKeyDown={(e) => {
//                                 if (alphabets.includes(e.key)) {
//                                     e.preventDefault();
//                                 }
//                             }} name="cvc"
//                             value={values.cvc}
//                             onChange={handleChange('cvc')}
//                             placeholder="3 digit CVC..." max-length="3" autocomplete="new-cvc" style={{marginTop:"1rem", fontSize: "18px"}}/>
//                             <div id="card-element-cvc"></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         </>
//         }
//         <div className="col-lg-12">
//             <p className="text-center mt-5">
//                 <table><tr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<td><input type='checkbox'
//                 onClick={ () => setValues({...values, check: !values.check})}

//                 /></td>&nbsp;&nbsp;&nbsp;&nbsp;I read, understand and agree to the &nbsp;
//                 <td>
//                 {/* <NavLink to='https://riyowork.com/terms-of-use' className='checkbox'>Terms Of Use</NavLink> and <NavLink to='https://riyowork.com/privacy-policy' className='checkbox'> Privacy Policy</NavLink> */}
//                 </td>
//                 <button onClick={openTerms} className='checkbox'>Terms Of Use</button> and &nbsp;
//                 <button onClick={openPrivacy} className='checkbox'> Privacy Policy</button>
//                 </tr></table>
//             </p>
//             { isErrCheck === true &&
//             values.check === false &&
//             <span className='text-danger text-center'>You must accept our terms and conditions policies </span>
//             }
//             {
//                 isErr === true
//                 &&
//                 (
//                 (
//                     values.companyName === "" ||
//                     // values.companyEmail === "" ||
//                     // values.companyPhone === "" ||
//                     values.industry === "" ||
//                     values.numberOfEmployees === "" ||
//                     values.firstName === "" ||
//                     values.lastName === "" ||
//                     values.email === "" ||
//                     values.phone === "" ||
//                     values.cardNumber === "" ||
//                     values.cvc === "" ||
//                     values.expiryMonth === "" ||
//                     values.expiryYear === ""
//                 ) &&
//                 <span className='text-danger text-center'>Please fill all the fields</span>
//                 ||
//                 values.phone.length !== 14 &&
//                 <span className='text-danger text-center'>Please provide accurate Number</span>
//                 )
//             }
//             {
//                 company.error &&
//                 <span className='text-danger text-center'>Email must be a valid email</span>
//             }
//             <div className="input-box mt-30 d-flex align-items-center justify-content-center">
//                 {/* <button type="submit" onClick={postValues}  to="/sign-in" className="main-btn">Start Scheduling Free</button> */}
//                 {
//                     step === 1 ?

//                     <>
//                     {/* <button type='submit' onClick={handleNext}> next</button> */}
//                     <NavLink
//                     // type="submit"
//                     onClick={handleNext}
//                     to="#"
//                     className="main-btn">Next</NavLink>
//                     {/* <button onClick={handleNext}> next</button> */}
//                     </>
//                     :
//                     // step === 2 ?
//                     // <>
//                     // <NavLink
//                     // // type="submit"
//                     // onClick={handlePrevious}
//                     // to="#"
//                     // className="main-btn">Previous</NavLink>
//                     // &nbsp;
//                     // <NavLink
//                     // // type="submit"
//                     // onClick={handleNext}
//                     // to="#"
//                     // className="main-btn">Next</NavLink>
//                     // </>
//                     // :
//                     step === 2 ?
//                     <>
//                     <NavLink
//                     // type="submit"
//                     onClick={handlePrevious}
//                     to="#"
//                     className="main-btn">Previous</NavLink>
//                     &nbsp;
//                     <NavLink
//                     // type="submit"
//                     onClick={handleSubmit}
//                     to="#"
//                     className="main-btn">Sign Up</NavLink>
//                     {/* <button type="submit" className="main-btn">Start Scheduling Free</button>  */}
//                     </>
//                     :
//                     <></>
//                 }
//                 {/* <button type="submit" onClick={<MailConfirm emailValue={values.email} />}   className="main-btn">Start Scheduling Free</button> */}
//             </div>
//         </div>
//     </div>
// </form>

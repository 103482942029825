import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { useParams } from 'react-router-dom'
import { updateSalaryStructure } from "../../../actions/salaryActions";

const EditModalSalaryViewFromAdmin = ({
  showDropDown,
  openDropDown,
  current,
}) => {
  const [values, setValues] = useState({
    id: "",
    basicSalary: "",
    houseRentAllowance: "",
    conveyance: "",
    otherAllowance: "",
    taxDeductedAtSource: "",
    providentFund: "",
    esi: "",
    loan: "",
  });
  // console.log(current, "maaaaaaaaaaaaaaaaaaaaaaai currreernnnnnnnnnttt hooon")
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];

  // const params = useParams()
  const currentSalary = async () => {
    try {
      setValues({
        id: "",
        basicSalary: "",
        houseRentAllowance: "",
        conveyance: "",
        otherAllowance: "",
        taxDeductedAtSource: "",
        providentFund: "",
        esi: "",
        loan: "",
      });
      setValues({
        id: current.id,
        basicSalary: current.basicSalary,
        houseRentAllowance: current.houseRentAllowance,
        conveyance: current.conveyance,
        otherAllowance: current.otherAllowance,
        taxDeductedAtSource: current.taxDeductedAtSource,
        providentFund: current.providentFund,
        esi: current.esi,
        loan: current.loan,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    currentSalary();
  }, [current]);
  const dispatch = useDispatch();
  const updateSalaryData = {
    _id: values.id,
    basicSalary: parseInt(values.basicSalary),
    houseRentAllowance: parseInt(values.houseRentAllowance),
    conveyance: parseInt(values.conveyance),
    otherAllowance: parseInt(values.otherAllowance),
    taxDeductedAtSource: parseInt(values.taxDeductedAtSource),
    providentFund: parseInt(values.providentFund),
    esi: parseInt(values.esi),
    loan: parseInt(values.loan),
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateSalaryStructure(updateSalaryData));
    setValues({
      id: "",
      basicSalary: "",
      houseRentAllowance: "",
      conveyance: "",
      otherAllowance: "",
      taxDeductedAtSource: "",
      providentFund: "",
      esi: "",
      loan: "",
    });
  };
  // console.log(values, "addition values tak chal rha hai")

  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_schedule"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Salary Structure</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleSubmit}>
                {/* <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Net Salary</label>
                                            <input class="form-control" type="text" value="$4000" />
                                        </div>
                                    </div>
                                </div> */}
                {/* <br /> */}
                <div class="row">
                  <div class="col-sm-6">
                    <h4 class="text-primary">Earnings</h4>
                    <div class="form-group">
                      <label>Basic Salary</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.basicSalary}
                        onChange={handleChange("basicSalary")}
                      />
                    </div>
                    <div class="form-group">
                      <label>House Rent Allowance (H.R.A.)</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.houseRentAllowance}
                        onChange={handleChange("houseRentAllowance")}
                      />
                    </div>
                    <div class="form-group">
                      <label>Conveyance</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.conveyance}
                        onChange={handleChange("conveyance")}
                      />
                    </div>
                    <div class="form-group">
                      <label>Other Allowance</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.otherAllowance}
                        onChange={handleChange("otherAllowance")}
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <h4 class="text-primary">Deductions</h4>
                    <div class="form-group">
                      <label>Tax Deducted at Source (T.D.S.)</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.taxDeductedAtSource}
                        onChange={handleChange("taxDeductedAtSource")}
                      />
                    </div>
                    <div class="form-group">
                      <label>Provident Fund</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.providentFund}
                        onChange={handleChange("providentFund")}
                      />
                    </div>
                    <div class="form-group">
                      <label>ESI</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.esi}
                        onChange={handleChange("esi")}
                      />
                    </div>
                    <div class="form-group">
                      <label>Loans</label>
                      <input
                        class="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.loan}
                        onChange={handleChange("loan")}
                      />
                    </div>
                  </div>
                </div>
                <div class="submit-section">
                  <button class="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalSalaryViewFromAdmin;

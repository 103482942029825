import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
// import AddLocationModal from './AddLocationModal'
import { useSelector, useDispatch } from 'react-redux'
import { tabLocation, tabFileManager } from '../../../actions/tabActions'
import AddLPModal from '../adminAllEmployeeComponents/AddLPModal'

const AdminLandFMTab = () => {
    const myState = useSelector((state => state.changeTheTab))
    // console.log("***********", myState.viewLocationAdministrationMenu)
    const dispatch = useDispatch()

    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // console.log(showDropDown)
    
    return (
        <>
            {
                myState.viewLocationAdministrationMenu === "location" ?
                <>
                    <div className="col-4 mitbsnav">
                        <ul className="nav nav-pills nav-justified">
                            <li className="nav-item active">
                                <NavLink to="#locations" onClick={ () => dispatch(tabLocation()) } className="">Locations</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="#file-manager" onClick={ () => dispatch(tabFileManager()) } className="">My Files</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-4 float-right ml-auto">
                        <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_designation" onClick={openDropDown}><i className="fa fa-plus"></i> Add Location</NavLink>
                    </div>
                    <AddLPModal showDropDown={showDropDown} openDropDown={openDropDown}/>
                </>
                        
                : myState.viewLocationAdministrationMenu === "filemanager" ?
                <>
                    <div className="col-4 mitbsnav">
                        <ul className="nav nav-pills nav-justified">
                            <li className="nav-item">
                                <NavLink to="#locations" onClick={ () => dispatch(tabLocation()) } className="">Locations</NavLink>
                            </li>
                            <li className="nav-item active">
                                <NavLink to="#file-manager" onClick={ () => dispatch(tabFileManager()) }className="">My Files</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-4 float-right ml-auto">
                    </div>
                </>
                : <div>Loading</div>
            }
        </>
    )
}
export default AdminLandFMTab
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PayrollAdditionButton from "./PayrollAdditionButton";
import PayrollDeductionButton from "./PayrollDeductionButton";
import PayrollOvertimeButton from "./PayrollOvertimeButton";
import AddAddittionModal from "./AddAddittionModal";
import AddDeductionModal from "./AddDeductionModal";
import AddOvertimeModal from "./AddOvertimeModal";

const PageMenu = () => {
  const [viewPayrollitems, setViewPayrollitems] = useState("addition");
  const handleAddition = () => {
    setViewPayrollitems("addition");
  };
  const handleOvertime = () => {
    setViewPayrollitems("overtime");
  };
  const handleDeduction = () => {
    setViewPayrollitems("deduction");
  };
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // console.log(showDropDown)
  return (
    <>
      {viewPayrollitems === "addition" ? (
        <>
          <div className="page-menu">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link active"
                      onClick={handleAddition}
                      data-toggle="tab"
                      to="#tab_additions"
                    >
                      Additions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleOvertime}
                      data-toggle="tab"
                      to="#tab_overtime"
                    >
                      Overtime
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleDeduction}
                      data-toggle="tab"
                      to="#tab_deductions"
                    >
                      Deductions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div className="text-right mb-4 clearfix">
            <button
              className="btn btn-primary add-btn"
              type="button"
              data-toggle="modal"
              data-target="#add_addition"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Addition
            </button>
          </div>
          <AddAddittionModal
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
          <PayrollAdditionButton />
        </>
      ) : viewPayrollitems === "overtime" ? (
        <>
          <div className="page-menu">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleAddition}
                      data-toggle="tab"
                      to="#tab_additions"
                    >
                      Additions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link active"
                      onClick={handleOvertime}
                      data-toggle="tab"
                      to="#tab_overtime"
                    >
                      Overtime
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleDeduction}
                      data-toggle="tab"
                      to="#tab_deductions"
                    >
                      Deductions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div className="text-right mb-4 clearfix">
            <button
              className="btn btn-primary add-btn"
              type="button"
              data-toggle="modal"
              data-target="#add_overtime"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Overtime
            </button>
          </div>
          <AddOvertimeModal
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
          <PayrollOvertimeButton />
        </>
      ) : viewPayrollitems === "deduction" ? (
        <>
          <div className="page-menu">
            <div className="row">
              <div className="col-sm-12">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleAddition}
                      data-toggle="tab"
                      to="#tab_additions"
                    >
                      Additions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={handleOvertime}
                      data-toggle="tab"
                      to="#tab_overtime"
                    >
                      Overtime
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link active"
                      onClick={handleDeduction}
                      data-toggle="tab"
                      to="#tab_deductions"
                    >
                      Deductions
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div className="text-right mb-4 clearfix">
            <button
              className="btn btn-primary add-btn"
              type="button"
              data-toggle="modal"
              data-target="#add_deduction"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Deduction
            </button>
          </div>
          <AddDeductionModal
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
          <PayrollDeductionButton />
        </>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
};
export default PageMenu;

import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
// import { getMailByMailId } from '../../../actions/mailActions';
import { NavLink } from 'react-router-dom'
import moment from 'moment';
const SuperAdminInboxComRow = ({item, i, }) => {
  const auth = useSelector((state) => state.auth);
  // const mail = useSelector((state) => state.mail);
  // const [ dropDownRow, setDropDownRow ] = useState(null)
  // const openDropDown = () => {
      // setshowDropDown((showDropDown) => !showDropDown)
  // }
  const [windowWidth, setWindowWidth] = useState(0)
  // console.log(windowWidth==576,"windowWidth==576");
  
  useEffect(() => {
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth);
    });
  }, [window.innerWidth]);
  
  // const dispatch = useDispatch()
  // const mailIndividual = async (idMail) => {
  //   dispatch(getMailByMailId({mailId: idMail}))
  // }
  let arr =[]

  let [seenTrueStatus, setSeenTrueStatus] = useState(false)
  
  useEffect(() => {
    if (item) {
      if (item.messages) {
        if (item.messages.length > 0) {
          let seenTrue = item.messages[item.messages.length-1].view
          seenTrue.forEach(e => { 
            if(e.receiver == auth.user.id && e.messageSeen === false){
              setSeenTrueStatus(true)
            }
            else {
              setSeenTrueStatus(false)
            }
          });
        }
      }
    }
  }, [item]);
  return (
    <>
      <tr className={`${seenTrueStatus === true ? ' unread clickable-row ' : 'clickable-row '}`} data-href="mail-view.html" key={i}>
        {/* <td>
            <input type="checkbox" className="checkmail" />
        </td> */}
        {/* <td><span className="mail-important"><i className="fa fa-star starred"></i></span></td> */}
        {/* <td><span className="mail-important"><i className="fa fa-star-o"></i></span></td> */}
        
        <td className="name">
          {
            item.condition ?
            <NavLink to={`/mail-view/${item.id}`} style={{color : "#000000"}}>
              {item.condition}
            </NavLink>
            :
            <NavLink to={`/mail-view/${item.id}`} style={{color : "#000000"}}>
              {
                item.members.forEach(e => {
                  // console.log("typeof=>", typeof (e.id), typeof (auth.user.id), e.id, auth.user.id, (e.id !== auth.user.id))
                  if (e.id !== auth.user.id) {
                    arr.push(e.firstName, ", ")
                  }
                })
              }{arr.pop() ? arr : ""}
            </NavLink>
          }
        </td>

        <td className="subject">
          <NavLink to={`/mail-view/${item.id}`} style={{color : "#000000"}}>
            {item.messages[item.messages.length-1].message}
          </NavLink>
        </td>
        <td className="mail-date">{moment().format('DD-MM-YYYY') === moment(item.messages[item.messages.length-1].timestamps).format('DD-MM-YYYY') ? moment(item.messages[item.messages.length-1].timestamps).format('LT') : moment(item.messages[item.messages.length-1].timestamps).format('DD MMM')}</td>
      </tr>
    </>
  )
}
export default SuperAdminInboxComRow
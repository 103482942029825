import {
    // FILEMANAGER_CREATE_REQUEST,
    // FILEMANAGER_CREATE_SUCCESS,
    // FILEMANAGER_CREATE_FAIL,
    FILEMANAGER_TABLE_REQUEST,
    FILEMANAGER_TABLE_SUCCESS,
    FILEMANAGER_TABLE_FAIL,
    FILEMANAGER_UPDATE_REQUEST,
    FILEMANAGER_UPDATE_SUCCESS,
    FILEMANAGER_UPDATE_FAIL,
    REMOVE_FILEMANAGER_REQUEST,
    REMOVE_FILEMANAGER_SUCCESS,
    REMOVE_FILEMANAGER_FAIL,
    USER_RECENT_FILES_REQUEST,
    USER_RECENT_FILES_SUCCESS,
    USER_RECENT_FILES_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const filemanagerReducer = (
    state = {
        filemanager : {
            // filemanager: null,
            filemanagerTable: null,
            recentFiles: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                filemanagerTable: null,
                recentFiles: null,
            };

        // USER ANNUAL LEAVE STATUS
        // case FILEMANAGER_CREATE_REQUEST :
        //     return {
        //         ...state,
        //     };
        // case FILEMANAGER_CREATE_SUCCESS :
        //     return {
        //         ...state,
        //         filemanagerTable: [...state.filemanagerTable, action.payload],
        //     };
        // case FILEMANAGER_CREATE_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };


        case FILEMANAGER_TABLE_REQUEST : 
            return {
                ...state,
            };
        case FILEMANAGER_TABLE_SUCCESS :
            return {
                ...state,
                filemanagerTable : action.payload,
            };
        case FILEMANAGER_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case FILEMANAGER_UPDATE_REQUEST :
            return {
                ...state,
            };
        case FILEMANAGER_UPDATE_SUCCESS :
            return {
                ...state,
                filemanagerTable: {
                    recent: state.filemanagerTable.recent.map((x) =>
                    x.id === action.payload.id ? action.payload : x
                    ),
                    allFiles: state.filemanagerTable.allFiles.map((x) =>
                    x.id === action.payload.id ? action.payload : x
                    ),
                }
            };
        case FILEMANAGER_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case REMOVE_FILEMANAGER_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_FILEMANAGER_SUCCESS :
            return {
                ...state,
                filemanagerTable: {
                    recent: state.filemanagerTable.recent.filter((x) => x.id !== action.payload.id),
                    allFiles: state.filemanagerTable.allFiles.filter((x) => x.id !== action.payload.id),
                }
            };
        case REMOVE_FILEMANAGER_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        // EMPLOYEE GET RECENT FILES
        case USER_RECENT_FILES_REQUEST :
            return {
                ...state,
            };
        case USER_RECENT_FILES_SUCCESS :
            return {
                ...state,
                recentFiles: action.payload,
            };
        case USER_RECENT_FILES_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BUCKET_BASE_URL } from "../../constants";
import defaultImg from "../../assetsAdmin/img/avatarProfile.png";

function ChatItem({ chat, receiver, selectChat, isTyping }) {
  const chats = useSelector((state) => state.chats);

  const timeNow = new Date();
  const messageTime = new Date(chat.latestMessage?.dateTime);
  const secondsDiff = Math.round((timeNow - messageTime) / 1000);
  const hoursDiff = Math.round(secondsDiff / 60 / 60);

  let time = "";
  if (secondsDiff < 60) {
    time = "Just Now";
  } else {
    if (secondsDiff < 600) {
      time = Math.round(secondsDiff / 60) + " mins ago";
    } else {
      if (hoursDiff > 24) {
        time = moment(messageTime).format("ddd, hh:mm a");
      } else {
        // console.log("messageTIme==>", messageTime);
        time = moment(messageTime).format("hh:mm a");
      }
    }
  }
  //   console.log("first==>", timeNow, "second==>", messageTime, secondsDiff);

  return (
    <li
      key={chat.id}
      class="p-2 border-bottom w-100"
      style={{
        backgroundColor: chats.selectedChat?.id === chat.id ? "#eee" : "",
      }}
    >
      <Link
        to=""
        onClick={() => selectChat(chat)}
        class="d-flex justify-content-between w-100"
      >
        <div class="d-flex flex-row w-100">
          <img
            src={
              chat.isGroup
                ? "/group-icon.png"
                : receiver?.profilePic
                ? BUCKET_BASE_URL + receiver?.profilePic?.fileUrl
                : defaultImg
            }
            // src={chat.isGroup ? "" : receiver.image}

            alt="avatar"
            class="rounded-circle d-flex align-self-center me-3 shadow-1-strong object-fit-cover"
            width="40"
            height="40"
          />
          <div class="pt-1">
            <p class="fw-bold mb-0">
              {chat.isGroup
                ? chat.chatName
                : `${receiver.firstName} ${receiver.lastName}`}
            </p>
            <p class="small text-muted">
              {isTyping && chat.id === isTyping.room ? (
                <span className="text-success fw-bold">
                  {isTyping.user.name} is typing...
                </span>
              ) : chat.latestMessage ? (
                chat?.latestMessage?.message?.length > 40 ? (
                  chat.latestMessage.message.slice(0, 40) + "...."
                ) : (
                  chat.latestMessage.message
                )
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
        <div class="pt-1">
          <p class="small text-muted mb-1">{chat.latestMessage ? time : ""}</p>
          {/* <span class="badge bg-danger float-end">
            {" "}
            {chats.list.length -
              notifications.list.filter((x) =>
                x.readBy.find((y) => y.readerId == auth.user.id)
              ).length}
          </span> */}
        </div>
      </Link>
    </li>
  );
}

export default ChatItem;

import React, { useState } from "react";
import EditModalPolicies from "./EditModalPolicies";
import moment from "moment";
import { NavLink } from "react-router-dom";
import DeletePolicy from "./DeletePolicy";
import { useDispatch } from "react-redux";

// import ModalDeleteWarning from '../ModalDeleteWarning'
// import { NavLink } from 'react-router-dom'
const AdminPoliciesRow = ({ current, i, dropDownRow, setDropDownRow }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const dispatch = useDispatch();
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
    setshowMoreVertDropDown(false);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
    setshowMoreVertDropDown(false);
  };
  return (
    <>
      {current.delete === false && (
        <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
          <td>{++i}</td>
          <td>{current.name}</td>
          {/* <td>All Departments  ???</td> */}
          <td>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            {current.description.length <= 20
              ? current.description
              : current.description.substring(0, 20) + "..."}
          </td>
          <td>
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
            {current.createdDate &&
              moment(current.createdDate).format("MM/DD/YYYY")}
          </td>
          <td className="text-right">
            <div className="dropdown dropdown-action">
              {/* <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a> */}
              <NavLink
                to="#"
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  openMoreVertDropDown();
                  setDropDownRow(i);
                }}
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons">more_vert</i>
              </NavLink>
              {dropDownRow === i && (
                <>
                  <div
                    className={`dropdown-menu dropdown-menu-right ${
                      showMoreVertDropDown ? "show" : "hide"
                    }`}
                  >
                    {/* <a className="dropdown-item" to="#"><i className="fa fa-download m-r-5"></i> Download</a> */}
                    <a
                      className="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      onClick={openDropDown}
                      data-target="#edit_policy"
                    >
                      <i className="fa fa-pencil m-r-5"></i> Edit
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      onClick={openDeleteDropDown}
                      data-target="#delete_approve"
                    >
                      <i className="fa fa-trash-o m-r-5"></i> Delete
                    </a>
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
      <EditModalPolicies
        current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
      <DeletePolicy
        current={current}
        showDeleteDropDown={showDeleteDropDown}
        openDeleteDropDown={openDeleteDropDown}
      />
    </>
  );
};
export default AdminPoliciesRow;

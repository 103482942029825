import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import EditSubscription from './EditSubscription'

const SuperAdminAnnualPlanRow = ({current, i, dropDownRow, setDropDownRow }) => {
  const [showDropDown, setshowDropDown] = useState(false)
  // const openDropDown = () => {
  //     setshowDropDown((showDropDown) => !showDropDown)
  // }
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown)
    setshowMoreVertDropDown(false)
  }
  // const openDeleteDropDown = () => {
  //   setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  //   setshowMoreVertDropDown(false)
  // }
  return (
    <>
      <div className="col-md-4" key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
        <div className="card pricing-box text-center">
          <div className="card-body d-flex flex-column">
            <h3 className="title">{current.planName}</h3>
            <p className="mx-auto mt-3 mb-4"> {current.description}</p>
            <span className="display-4 mb-1"> ${current.amountYearly}</span>
            <small className="mx-auto">Per user per month billed monthly <br /><span className="text-dark">USD (local taxes may apply)</span></small>
            <ul className="mt-4 mx-auto">
              <li>Includes <strong className="mifs-1">{current.users}</strong> users</li>
            </ul>
            <a href="#" className="btn btn-lg btn-secondary mt-auto" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan">Edit</a>
            <div className="dropdown profile-action">
              <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={() => {
              openMoreVertDropDown()
              setDropDownRow(i)}} style={{cursor: 'pointer'}} aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
              {
              dropDownRow === i &&
              <>
                <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                  <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_addition"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                </div>
              </>
              }
            </div>
          </div>
        </div>
      </div>
      <EditSubscription current={current} showDropDown={showDropDown} openDropDown={openDropDown} />
    </>
  )
}
export default SuperAdminAnnualPlanRow
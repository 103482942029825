import React from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import { useEffect } from 'react';
import { getSalaryByUserId } from '../../actions/salaryActions';
import writtenNumber from 'written-number';
// import { NavLink, useNavigate } from 'react-router-dom'

const SalaryInfo = () => {
   const auth = useSelector((state) => state.auth);
   const salary = useSelector((state) => state.salary);

   const dispatch = useDispatch()
   const salaryData = async (e) => {
      dispatch(getSalaryByUserId({userId: auth.user.Id}))
   }

   useEffect(() => {
      salaryData()
   },[])

   return (
      <>
         <div className="tab-content">
         <div className="" id="bank_statutory">
            <div className="card">
               <div className="card-body">
                  <h3 className="card-title"> Basic Salary Information</h3>
                  <div className="row">
                     <div className="col-sm-6">
                        <div>
                           <h4 className="m-b-10"><strong>Earnings</strong></h4>
                           <table className="table table-bordered">
                              <tbody>
                                 {
                                    salary.salary &&
                                       <>
                                          <tr>
                                             <td><strong>Basic Salary</strong> <span className="float-right">${salary.salary.basicSalary}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>House Rent Allowance (H.R.A.)</strong> <span className="float-right">${salary.salary.houseRentAllowance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Conveyance</strong> <span className="float-right">${salary.salary.conveyance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Other Allowance</strong> <span className="float-right">${salary.salary.otherAllowance}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Total Earnings</strong> <span className="float-right"><strong>${salary.salary.totalEarning}</strong></span></td>
                                          </tr>
                                       </>
                                    }
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div>
                           <h4 className="m-b-10"><strong>Deductions</strong></h4>
                           <table className="table table-bordered">
                              <tbody>
                                 {
                                    salary.salary &&
                                       <>
                                          <tr>
                                             <td><strong>Tax Deducted at Source (T.D.S.)</strong> <span className="float-right">${salary.salary.taxDeductedAtSource}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Provident Fund</strong> <span className="float-right">${salary.salary.providentFund}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>ESI</strong> <span className="float-right">${salary.salary.esi}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Loan</strong> <span className="float-right">${salary.salary.loan}</span></td>
                                          </tr>
                                          <tr>
                                             <td><strong>Total Deductions</strong> <span className="float-right"><strong>${salary.salary.totalDeduction}</strong></span></td>
                                          </tr>
                                       </>
                                    }
                              </tbody>
                           </table>
                        </div>
                     </div>
                     <div className="col-sm-12">
                     {
                        salary.salary &&
                           <p><strong>Net Salary: ${salary.salary.netSalary}</strong> ({writtenNumber(salary.salary.netSalary)})</p>
                     }      
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
      </>
   )
}

export default SalaryInfo
import axios from "axios";
import {
  CHATS_GET_FAIL,
  CHATS_GET_REQUEST,
  CHATS_GET_SUCCESS,
  CHAT_SELECT_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  MESSAGE_GET_FAIL,
  MESSAGE_GET_REQUEST,
  MESSAGE_GET_SUCCESS,
  MESSAGE_SEND_FAIL,
  MESSAGE_SEND_REQUEST,
  MESSAGE_SEND_SUCCESS,
  READ_CHAT_FAIL,
  READ_CHAT_REQUEST,
  READ_CHAT_SUCCESS,
  READ_MESSAGES_FAIL,
  READ_MESSAGES_REQUEST,
  READ_MESSAGES_SUCCESS,
  SERVERADDRESS,
} from "../constants";
import { toast } from "react-hot-toast";

// Get All Chats
export const getChatsAction = (obj) => async (dispatch) => {
  dispatch({
    type: CHATS_GET_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats/user`, obj);
    dispatch({
      type: CHATS_GET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CHATS_GET_FAIL,
      payload: err,
    });
  }
};

// Create Group
export const createGroupAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: GROUP_CREATE_REQUEST,
  });

  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats/group`, obj);
    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: data,
    });
    toast.success("Group Created Successfully!");
    dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    successHandler();
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);

    dispatch({
      type: GROUP_CREATE_FAIL,
      payload: err,
    });
  }
};

// Create Chat
export const createChatAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: GROUP_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/chats`, {
      userId: obj.user.id,
      company: obj.companyId,
      latestMessage: null,
      receiverId: obj.receiverId,
    });
    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: data,
    });
    dispatch(getChatsAction({ userId: obj.user.id, companyId: obj.companyId }));

    dispatch(
      sendMessageAction(
        {
          sender: obj.user,
          chatId: data,
          message: obj.message,
          dateTime: new Date(),
        },
        successHandler
      )
    );
  } catch (err) {
    console.log(err);
    dispatch({
      type: GROUP_CREATE_FAIL,
      payload: err,
    });
  }
};

// Create/Send Message
export const sendMessageAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: MESSAGE_SEND_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/messages`, {
      ...obj,
      senderId: obj.sender.id,
      chatId: obj.chatId.id,
    });
    dispatch({
      type: MESSAGE_SEND_SUCCESS,
      payload: obj,
    });
    successHandler();
  } catch (err) {
    console.log(err);
    dispatch({
      type: MESSAGE_SEND_FAIL,
      payload: err,
    });
  }
};

// Get Single Chat Messages
export const getMessagesAction = (chatId, userId) => async (dispatch) => {
  dispatch({
    type: MESSAGE_GET_REQUEST,
  });
  try {
    const { data } = await axios.get(`${SERVERADDRESS}/v1/messages/${chatId}`);
    dispatch({
      type: MESSAGE_GET_SUCCESS,
      payload: data,
    });
    // dispatch(readMessagesAction(chatId, userId));
    dispatch(readChatAction(chatId, userId));
  } catch (err) {
    console.log(err);
    dispatch({
      type: MESSAGE_GET_FAIL,
      payload: err,
    });
  }
};

// Read Messages Action
export const readMessagesAction = (chatId, readerId) => async (dispatch) => {
  dispatch({
    type: READ_MESSAGES_REQUEST,
  });

  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/messages/${chatId}`,
      {
        readerId,
      }
    );
    dispatch({
      type: READ_MESSAGES_SUCCESS,
      payload: data,
    });
    // toast.success("Group Created Successfully!");
    // dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    // successHandler();
  } catch (err) {
    console.log(err);
    // toast.error(err.response.data.message);

    dispatch({
      type: READ_MESSAGES_FAIL,
      payload: err,
    });
  }
};

// Read Chat Action
export const readChatAction = (chatId, readerId) => async (dispatch) => {
  dispatch({
    type: READ_CHAT_REQUEST,
  });

  try {
    const { data } = await axios.patch(`${SERVERADDRESS}/v1/chats/${chatId}`, {
      readerId,
    });
    dispatch({
      type: READ_CHAT_SUCCESS,
      payload: data,
    });
    // toast.success("Group Created Successfully!");
    // dispatch(getChatsAction(obj));
    // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
    // successHandler();
  } catch (err) {
    console.log(err);
    // toast.error(err.response.data.message);

    dispatch({
      type: READ_CHAT_FAIL,
      payload: err,
    });
  }
};

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getLocationByLocationId } from "../../actions/locationActions";
import EmployeeHeader from "../../components/employeeComponents/EmployeeHeader";
import LocationDescription from "../../components/employeeComponents/employeeLocationView/LocationDescription";
import LocationMap from "../../components/employeeComponents/employeeLocationView/LocationMap";
import EmployeeSidebar from "../../components/employeeComponents/EmployeeSidebar";
import EmployeeTitle from "../../components/employeeComponents/EmployeeTitle";
// import LocationLeaders from '../../components/employeeComponents/employeeLocationView/LocationLeaders'
// import LocationProjectDetails from '../../components/employeeComponents/employeeLocationView/LocationProjectDetails'
// import LocationGuards from '../../components/employeeComponents/employeeLocationView/LocationGuards'
// import LocationUploadedFiles from '../../components/employeeComponents/employeeLocationView/LocationUploadedFiles'
const EmployeeWorkLocation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      // console.log("dispatch")
      dispatch(getLocationByLocationId({ locationId: params.id }, navigate));
    }
  }, [params.id]);
  return (
    <>
      {/* <EmployeeHeader />
            <EmployeeSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <EmployeeTitle title="Location" />
            <div className="row">
              <div className="col-lg-8 col-xl-12">
                <LocationDescription />
                <LocationMap />
                {/* <LocationUploadedFiles /> */}
              </div>
              {/* <div className="col-lg-4 col-xl-3">
                                <LocationProjectDetails />
                                <LocationLeaders />
                                <LocationGuards />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeWorkLocation;

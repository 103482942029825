import React from 'react'

const TermsOfUse = () => {
    return (
        <>
            <section className="pricing-6-area pt-62 new-bg-color" id="kkk">
                <div className="container">
                    <div className="row contact-area justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                            <div className="pricing-6-item mt-30">
                                <div className="section-title-6 text-center">
                                    <h3 className="title">TERMS OF USE</h3>
                                    <p></p>
                                </div>
                                <div className="contact-form">
                                    <div>By accessing or otherwise using this site, you agree to be bound contractually by these Terms of Use. This contract sets out your rights and responsibilities when you use the services provided by Riyo Work. We’ll refer to our website, mobile apps, and other services as our “Services”.</div>
                                    

                                    <br />
                                    <h4><strong>License to Use Our Services</strong></h4>
                                    <div>We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Services. By using any of our Services (even just browsing our website), you’re agreeing to the Terms. If you don’t agree with the Terms, you may not use our Services.</div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>License</strong></div>
                                        <div>Riyo Work grants you a limited non-exclusive, non-transferable, and revocable license to access and use the public areas of this site, only for your personal use and not for purposes of resale, except as may be provided in any separate written agreement signed by the parties or separate agreement originating with this site; provided, however, that you do not modify this site, its content, or any copyright or other proprietary notices. This license terminates automatically if you breach any of these Terms of Use. Unauthorized use of this site or any content may violate copyright laws, laws, the laws of privacy and publicity, and communications regulations and statutes. You grant to Riyo Work and its service providers and licenses a non-exclusive, royalty-free digital photography to access, use, copy, reproduce without providing compensation to you or any other person.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Don’t Steal Our Stuff</strong></div>
                                        <div>You agree not to “crawl,” “scrape,” or “spider” any page of the Services or to reverse engineer or attempt to obtain the source code of the Services.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Don’t Try to Harm Our Systems</strong></div>
                                        <div>You agree not to interfere with or try to disrupt our Services, for example by distributing a virus or other harmful computer code.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Share Your Ideas</strong></div>
                                        <div>We love your suggestions and ideas! They can help us improve your experience and our Services. Any unsolicited ideas or other materials you submit to Riyo Work are considered non-confidential and non-proprietary to you. You grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.</div>
                                    </div>


                                    <br />
                                    <h4><strong>Security</strong></h4>
                                    <div>The designer is a tool to help you to produce estimates and should not be considered as a reference for final plans. It is your responsibility to verify the accuracy and compliance with your local building codes and site conditions.</div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Warranties and Limitation of Liability</strong></div>
                                        <div>Riyo Work accepts no liability for any damages including personal injuries or property losses for the information published from the Riyo Work web site. You assume total responsibility and risk for your use of the Riyo Work web site.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Limitation of Liability</strong></div>
                                        <div>Under no circumstances will Riyo Work or its affiliates have any liability with respect to any claims or damages (whether direct or indirect, special, incidental, consequential, or punitive) as a result of your access or use of (or inability to access or use) this site or its content, even if they have been advised of the possibility of such damages. You access and use this site at your own risk. To the fullest extent permitted by law, neither Riyo Work, nor our employees or directors shall be liable to you for any lost profits or revenues, or for any consequential, incidental, indirect, special, or punitive damages arising out of or in connection with the Services or these Terms.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Transaction and Application Fees</strong></div>
                                        <div>Third parties like Stripe and PayPal charge transaction and/or application fees. Those fees are non-refundable, and we have the exact same policy. So, if a transaction is done or even refunded, desired or by any mistake, of your part or our, all transactions and/or application fees are still applying and non-refundable. First transactions usually take more than 7 days to be processed and the next ones around 2 days. We don't control those delays.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Dispute and Chargeback</strong></div>
                                        <div>You are responsible of any possible money lost in a chargeback or dispute. If you lose the dispute, you need to pay back the amount disputed in full and required fees. The credit card company will decide if you win the dispute or not. If you don't agree with the resolution of that dispute, you will be responsible to resolve it with your client by yourself. It's possible that the amount disputed is taken back from your account before the resolution of the dispute.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Warranties</strong></div>
                                        <div>Riyo Work is dedicated to making our Services the best they can be, but we’re not perfect and sometimes things can go wrong. You understand that our Services are provided “as is” and without any kind of warranty (express or implied). We are expressly disclaiming any warranties of title, non-infringement, merchantability, and fitness for a particular purpose, as well as any warranties implied by a course of performance, course of dealing or usage of trade.</div>
                                    </div>
                                    <br />
                                    <br />
                                    <h4><strong>Termination</strong></h4>
                                    {/* <div>The designer is a tool to help you to produce estimates and should not be considered as a reference for final plans. It is your responsibility to verify the accuracy and compliance with your local building codes and site conditions.</div> */}
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Termination By You</strong></div>
                                        <div>We hate to see you go, but you may terminate your account with Riyo Work at any time from your account settings. You can find more information in this Help article. Terminating your account will not affect the availability of some of Your Content that you posted through the Services prior to termination.</div>
                                    </div>
                                    <br />
                                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                                        <div><strong>Termination By Riyo Work</strong></div>
                                        <div>We may terminate or suspend your account (and any related accounts) and your access to the Services at any time, for any reason, and without advance notice. If we do so, it’s important to understand that you don’t have a contractual or legal right to continue to use our Services, for example, to sell or buy on our website or mobile apps. Riyo Work may refuse service to anyone, at any time, for any reason. If you or Riyo Work terminate your account, you may lose any information associated with your account, including Your Content.</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default TermsOfUse
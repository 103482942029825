import React from 'react'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import VerifiedComponent from '../../components/frontendComponents/verifiedComponents/VerifiedComponent'
// import { useSelector } from 'react-redux'
const VerifiedPage = () => {
  // const myState = useSelector((state => state.changeTheTab))
  return (
    <>
      <NavBar />
      {/* {
            myState.newLeadEmail !== '' &&
          } */}
      <VerifiedComponent />
      <Footer />
    </>
  )
}
export default VerifiedPage
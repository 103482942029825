import axios from "axios";
import {
  ANNIVARSARY_REQUEST,
  ANNIVARSARY_SUCCESS,
  ANNIVARSARY_FAIL,
  EMPLOYEE_CREATE_REQUEST,
  EMPLOYEE_CREATE_SUCCESS,
  EMPLOYEE_CREATE_FAIL,
  COMPANY_USERS_REQUEST,
  COMPANY_USERS_SUCCESS,
  COMPANY_USERS_FAIL,
  COMPANY_USERS_QUERY_REQUEST,
  COMPANY_USERS_QUERY_SUCCESS,
  COMPANY_USERS_QUERY_FAIL,
  USER_NON_ACTIVE_REQUEST,
  USER_NON_ACTIVE_SUCCESS,
  USER_NON_ACTIVE_FAIL,
  USER_ACTIVE_REQUEST,
  USER_ACTIVE_SUCCESS,
  USER_ACTIVE_FAIL,
  USER_BY_ID_REQUEST,
  USER_BY_ID_SUCCESS,
  USER_BY_ID_FAIL,
  ALL_USER_BY_ID_REQUEST,
  ALL_USER_BY_ID_SUCCESS,
  ALL_USER_BY_ID_FAIL,
  EMPLOYEE_LAST_ID_REQUEST,
  EMPLOYEE_LAST_ID_SUCCESS,
  EMPLOYEE_LAST_ID_FAIL,
  UPDATE_USER_BY_ADMIN_REQUEST,
  UPDATE_USER_BY_ADMIN_SUCCESS,
  UPDATE_USER_BY_ADMIN_FAIL,
  REMOVE_USER_BY_ADMIN_REQUEST,
  REMOVE_USER_BY_ADMIN_SUCCESS,
  REMOVE_USER_BY_ADMIN_FAIL,
  GET_ALL_COMPANY_ADMIN_REQUEST,
  GET_ALL_COMPANY_ADMIN_SUCCESS,
  GET_ALL_COMPANY_ADMIN_FAIL,
  SERVERADDRESS,
} from "../constants";
import { toast } from "react-hot-toast";

// COMPANY LOCATION
export const employmentAnnivarsary = (obj) => async (dispatch) => {
  dispatch({
    type: ANNIVARSARY_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/employmentAnnivarsary`,
      obj
    );
    localStorage.setItem("annivarsary", JSON.stringify(data));
    dispatch({
      type: ANNIVARSARY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ANNIVARSARY_FAIL,
      payload: err,
    });
  }
};

// ADMIN ADD NEW EMPLOYEE

export const addEmployee =
  (obj, navigateUser, setErrEmail, setErrPhone) => async (dispatch) => {
    dispatch({
      type: EMPLOYEE_CREATE_REQUEST,
    });
    try {
      const { data } = await axios.post(`${SERVERADDRESS}/v1/users/`, obj);
      console.log(data, "data hai bhai");
      dispatch({
        type: EMPLOYEE_CREATE_SUCCESS,
        payload: data,
      });
      navigateUser();
      toast.success("Employee created successfully!");
    } catch (err) {
      toast.error(err.response.data.message);
      console.log(err);
      // if (err.response.data.message === "EP-404"){
      //     console.log("GOAT ahmed")
      // }
      setErrEmail(obj.email);
      setErrPhone(obj.phone);
      dispatch({
        type: EMPLOYEE_CREATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// COMPANY ALL USERS
export const allUsers = (obj, field, order) => async (dispatch) => {
  dispatch({
    type: COMPANY_USERS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsers/?sortBy=${field}:${order}&page=1`,
      obj
    );
    localStorage.setItem("allUsers", JSON.stringify(data));
    dispatch({
      type: COMPANY_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_USERS_FAIL,
      payload: err,
    });
  }
};

// COMPANY ALL USERS WIth QUERY
export const allUsersQuery = (obj, limitDef, pageNum, field, order) => async (dispatch) => {
  dispatch({
    type: COMPANY_USERS_QUERY_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsers/?sortBy=${field}:${order}&limit=${limitDef}&page=${pageNum}`,
      obj
    );
    dispatch({
      type: COMPANY_USERS_QUERY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_USERS_QUERY_FAIL,
      payload: err,
    });
  }
};

// ADMIN side USER KA NON ACTIVE
export const getDisabledUsers = (obj, pageNum, field, order) => async (dispatch) => {
  dispatch({
    type: USER_NON_ACTIVE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/getDisabledUsers/?sortBy=${field}:${order}&page=${pageNum}`,
      obj
    );
    localStorage.setItem("nonActive", JSON.stringify(data));
    // const { data } = await axios.post(`${SERVERADDRESS}/v1/users/getDisabledUsers/?sortBy=firstName%3Aasc&page=${pageNum}`, obj);
    dispatch({
      type: USER_NON_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_NON_ACTIVE_FAIL,
      payload: err,
    });
  }
};

// ADMIN side USER KA ACTIVE
export const getActiveUsers = (obj, pageNum, field, order) => async (dispatch) => {
  dispatch({
    type: USER_ACTIVE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/getActiveUsers/?sortBy=${field}:${order}&page=${pageNum}`,
      obj
    );
    localStorage.setItem("active", JSON.stringify(data));
    // const { data } = await axios.post(`${SERVERADDRESS}/v1/users/getActiveUsers/?sortBy=firstName%3Aasc&page=${pageNum}`, obj);
    dispatch({
      type: USER_ACTIVE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_ACTIVE_FAIL,
      payload: err,
    });
  }
};

// ADMIN GET USER BY ID
export const userById = (obj, navigate) => async (dispatch) => {
  dispatch({
    type: USER_BY_ID_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/userById`,
      obj
    );
    // localStorage.setItem("active", JSON.stringify(data));
    dispatch({
      type: USER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    navigate("/employees");
    dispatch({
      type: USER_BY_ID_FAIL,
      payload: err,
    });
  }
};

// ADMIN ALL USER WITHOUT PAGINATION
export const allUsersWithoutPaginate = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_USER_BY_ID_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsersWithoutPaginate`,
      obj
    );
    localStorage.setItem("allUsersWithoutPaginate", JSON.stringify(data));
    dispatch({
      type: ALL_USER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ALL_USER_BY_ID_FAIL,
      payload: err,
    });
  }
};

// EMPLOYEE LAST ID
export const lastId = (obj) => async (dispatch) => {
  dispatch({
    type: EMPLOYEE_LAST_ID_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/users/lastId`, obj);
    localStorage.setItem("lastEmployeeId", JSON.stringify(data.employeeId));
    dispatch({
      type: EMPLOYEE_LAST_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: EMPLOYEE_LAST_ID_FAIL,
      payload: err,
    });
  }
};

// UPDATE USER BY ADMIN
export const updateUserByAdmin = (obj) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_BY_ADMIN_REQUEST,
  });
  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/updateUserByAdmin`,
      obj
    );
    dispatch({
      type: UPDATE_USER_BY_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: UPDATE_USER_BY_ADMIN_FAIL,
      // payload: err,
      payload: err.response.data.message,
    });
  }
};

// REMOVE USER BY ADMIN
export const removeUserByAdmin = (obj) => async (dispatch) => {
  dispatch({
    type: REMOVE_USER_BY_ADMIN_REQUEST,
  });
  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/updateUserByAdmin`,
      obj
    );
    dispatch({
      type: REMOVE_USER_BY_ADMIN_SUCCESS,
      payload: data,
    });
    // navigate('/employees')
  } catch (err) {
    // console.log(err)
    dispatch({
      type: REMOVE_USER_BY_ADMIN_FAIL,
      payload: err,
    });
  }
};

// SUPER ADMIN K LIYA
// GET ALL ADMIN COMPANY
export const allCompanyAdmins = (obj) => async (dispatch) => {
  dispatch({
    type: GET_ALL_COMPANY_ADMIN_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${SERVERADDRESS}/v1/users/allCompanyAdmins`,
      obj
    );
    localStorage.setItem("getallCompanyAdmins", JSON.stringify(data));
    dispatch({
      type: GET_ALL_COMPANY_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_ALL_COMPANY_ADMIN_FAIL,
      payload: err,
    });
  }
};

import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { getAllNewLead } from '../../../actions/superAdminCompanyActions';
import AdminNewRequestTableRow from './AdminNewRequestTableRow'

const AdminNewRequestTable = () => {
    const company = useSelector((state) => state.company);
    const dispatch = useDispatch()
    let sNo = 1
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const newLeadData = async (e) => {
        dispatch(getAllNewLead({limit: limit, page: currentPageNumber }))
    }
    useEffect(() => {
        newLeadData()
    }, [])
    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(getAllNewLead({limit: event.target.value, page: 1 }))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= company.newLead.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            dispatch(getAllNewLead({limit: limit, page: currentPageNumber+1 }))
        }
    }
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getAllNewLead({limit: limit, page: currentPageNumber-1 }))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getAllNewLead({limit: limit, page: pageNo }))
    }
  return (
    <>
        <div className="row">
            <div className="col-sm-12 col-md-6">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show &nbsp;
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                            className="select" 
                            autoComplete='false'
                            onChange={handleLimit}>
                            {
                            [10,25,50,100].map((current, i) => {
                            return(
                                <option value={current}>{current}</option>
                                )
                            })
                            }
                        </select> entries
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                {/* <div className="table-responsive"> */}
                <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                        <tr>
                            <th style={{ width: "30px" }}>#</th>
                            <th>Company Name</th>
                            <th>Admin Name</th>
                            <th>Package</th>
                            <th>Subscription </th>
                            <th>Date</th>
                            <th>Total Amount</th>
                            <th>Verify Email</th>
                            <th className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        company.newLead &&
                        company.newLead.results &&
                        company.newLead.results.length > 0 &&
                        company.newLead.results.map((current, i) => {
                            if (current.generateUser === false) {
                                return(
                            <AdminNewRequestTableRow current={current} i={i} sNo={sNo++}/>
                            )
                        }
                    })
                    }
                    </tbody>
                </table>
            </div>
        </div>

        <br />
        <div className="row">
            <div className="col-sm-12 col-md-5">
                {
                    company.newLead &&
                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {company.newLead.displayingResults + (limit * (currentPageNumber-1))} of {company.newLead.totalResults} entries </label>
                }
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="avatar-pagination">
                    <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                        <li className="page-item">
                            <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                            </NavLink>
                        </li>
                        {
                            company.newLead &&
                            company.newLead.paginationArr &&
                            company.newLead.paginationArr.length > 0 &&
                            company.newLead.paginationArr.map((current, i) => {
                            return(
                                currentPageNumber === current ?
                                <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                :
                                <li className="page-item" key={i}><a className="page-link" 
                                value={current} onClick={()=>handleChangeData(current)}
                                >{current}</a></li>
                                )
                            })
                        }
                        <li className="page-item">
                            <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}
export default AdminNewRequestTable
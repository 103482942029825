import React from 'react'
import footerlogo from '../assetsgeneral/images/footerlogo.png'
import applestore from '../assetsgeneral/images/appstore_icon.png'
import appstore from '../assetsgeneral/images/playstore_icon.png'
const Footer = () => {
    return (
        <>
            <div className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <a href="#"> <img src={footerlogo} id="img-wa" /> </a>
                                <ul>
                                    <li> <a href="#"> <i className="fa fa-facebook" aria-hidden="true"></i> </a> </li>
                                    <li> <a href="#"> <i className="fa fa-twitter" aria-hidden="true"></i> </a> </li>
                                    <li> <a href="#"> <i className="fa fa-instagram" aria-hidden="true"></i>  </a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-logo-text">
                                <h2> Feautres </h2>
                                <ul>
                                    <li> <a href="#"> Employee Scheduling </a> </li>
                                    <li> <a href="#"> Time Clock </a> </li>
                                    <li> <a href="#"> Leave Management </a> </li>
                                    <li> <a href="#"> Payroll </a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-logo-text">
                                <h2> Support </h2>
                                <ul>
                                    <li> <a href="#"> Help Center </a> </li>
                                    <li> <a href="#"> Live Chat </a> </li>
                                    <li> <a href="#"> Commnunity Portal </a> </li>
                                    <li> <a href="#"> Contact Support </a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-logo-text">
                                <h2> Contact Us </h2>
                                <ul>
                                    <li> <a href="#"> <i className="fa fa-map-marker" aria-hidden="true"></i> 8900 Penn Ave S Suite 309, Bloomington, MN 55431. </a> </li>
                                    <li> <a href="#"> <i className="fa fa-envelope" aria-hidden="true"></i> info@riyowork.com</a> </li>
                                    <li> <a href="#">  <i className="fa fa-phone" aria-hidden="true"></i> (612) 423-2745 </a> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-logo-text">
                                <h2> Download the App </h2>
                                <ul>
                                    <li> <a href="#"> <img src={applestore} /></a></li>
                                    <li><a href="#"> <img src={appstore} /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="footer-end-se">
                                <p> Copyrights 2022 RiyoWork. All Rights Are Reserved. Designed By iconicTek </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer
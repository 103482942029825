import React from 'react'
import Footer from '../../components/Footer'
import FrontendClearDuesComponent from '../../components/frontendComponents/clearDuesComponent/FrontendClearDuesComponent'
import NavBar from '../../components/NavBar'
const DuesPage = () => {
  return (
    <>
      <NavBar />
      <FrontendClearDuesComponent />
      <Footer />
    </>
  )
}
export default DuesPage
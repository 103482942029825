import React from "react";
import { useSelector } from "react-redux";
import EmployeeShiftandScheduleSearchRow from "./EmployeeShiftandScheduleSearchRow";

const EmployeeShiftandScheduleSearchTable = () => {
  const shift = useSelector((state) => state.shift);
  return (
    <>
      <div className="row miordr2">
        <div className="col-md-12">
          <div className="table-responsive misftsch misftschdl">
            <table className="table table-striped custom-table datatable">
              <thead>
                <tr>
                  {/* <th>Scheduled Shift</th> */}
                  <th>
                    <div className="th-content">
                      <span>Employee</span>
                      {/* <span className="sort-icons">
                        <i className="fas fa-long-arrow-alt-up"></i>
                        <i className="fas fa-long-arrow-alt-down"></i>
                      </span> */}
                    </div>
                  </th>
                  <th style={{ textAlign: "left" }}>Date</th>
                  <th style={{ textAlign: "left" }}>Shift Details</th>
                </tr>
              </thead>
              <tbody>
                {shift.searchData &&
                  shift.searchData.length > 0 &&
                  shift.searchData.map((current, i) => {
                    return (
                      <>
                        <EmployeeShiftandScheduleSearchRow
                          current={current}
                          key={i}
                        />
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeShiftandScheduleSearchTable;

import moment from 'moment';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import EmployeeFileManagerModalViewDetail from './EmployeeFileManagerModalViewDetail';
import { BUCKET_BASE_URL } from '../../../constants';
const EmployeeFileManagerFileCard = ({current, i, fileIcon}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    const [showViewDetail, setshowViewDetail] = useState(false)
    const openViewDetail = () => {
        setshowViewDetail((showViewDetail) => !showViewDetail)
    }
    return (
        <>
            <div key={i} className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3" onMouseLeave={() => setshowMoreVertDropDown(false)}>
                <div className="card card-file">
                    <div className="dropdown-file">
                        <NavLink to="#" className="dropdown-link" data-toggle="dropdown" onClick={openMoreVertDropDown}><i className="fa fa-ellipsis-v"></i></NavLink>
                        <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                            <a href='#' className="dropdown-item" onClick={openViewDetail}>View Details</a>
                            <a href={BUCKET_BASE_URL + current.fileUrl} download className="dropdown-item">Download</a>
                        </div>
                    </div>
                    <div className="card-file-thumb">
                        <i className={fileIcon}></i>
                    </div>
                    <div className="card-body">
                        <h6><NavLink to="#">{current.fileName}</NavLink></h6>
                        <span>{current.size}</span>
                        {
                            !current.size &&
                            <br />
                        }
                    </div>
                    <div className="card-footer">
                        <span className="d-none d-sm-inline">Uploaded: </span>{moment(current.uploadDate).format('DD MMM h:mm a')}
                    </div>
                </div>
            </div>
            <EmployeeFileManagerModalViewDetail current={current} showViewDetail={showViewDetail} openViewDetail={openViewDetail} />
        </>
    )
}
export default EmployeeFileManagerFileCard



{/* <a href='#' className="dropdown-item">Share</a> */}
{/* <a href='#' className="dropdown-item" onClick={openDropDown}>Rename</a> */}
{/* <a href='#' className="dropdown-item" onClick={openDeleteDropDown}>Delete</a> */}
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../src/assetsgeneral/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import {
  tabHome,
  tabAboutUs,
  tabOurPlans,
  tabWeWork,
  tabContactUs,
} from "../../src/actions/tabActions";

const NavBar = () => {
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState.viewNavBar)
  const dispatch = useDispatch();

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // const [showDropDown, setshowDropDown] = useState(false)
  // const openDropDown = () => {
  //     setshowDropDown((showDropDown) => !showDropDown)
  // }
  // console.log(showDropDown)
  return (
    <>
      <div className="riyo-header-section new-bg-color">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="riyo-header-img">
                <nav className="navbar sticky-top navbar-expand-lg bg-dark2">
                  <div className="container">
                    <NavLink className="navbar-brand" to="/">
                      <img src={logo} id="logsedf" />
                    </NavLink>
                    <button
                      className="navbar-toggler"
                      onClick={openDropDown}
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>
                    {/* {
                                            <div className="navbar-collapse sub-menu-bar collapse" id="navbarSupportedContent"> */}
                    <div
                      className={`navbar-collapse sub-menu-bar collapse ${
                        showDropDown === true ? "show" : ".hide"
                      }`}
                      id="navbarSupportedContent"
                    >
                      {/* } */}
                      {myState.viewNavBar === "home" ? (
                        <>
                          <ul className="navbar-nav">
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabHome())}
                                id="home-col"
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabAboutUs())}
                                to="/about-us"
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabOurPlans())}
                                to="/our-plan"
                              >
                                Our Plans
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabWeWork())}
                                to="/we-work"
                              >
                                How We Work
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabContactUs())}
                                to="/contact"
                              >
                                Contact Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2 navvnv">
                              <NavLink
                                className="nav-link"
                                id="signin-button"
                                to="/login"
                              >
                                Sign In
                              </NavLink>
                            </li>
                          </ul>
                        </>
                      ) : myState.viewNavBar === "aboutus" ? (
                        <>
                          <ul className="navbar-nav">
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabHome())}
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabAboutUs())}
                                id="home-col"
                                to="/about-us"
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabOurPlans())}
                                to="/our-plan"
                              >
                                Our Plans
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabWeWork())}
                                to="/we-work"
                              >
                                How We Work
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabContactUs())}
                                to="/contact"
                              >
                                Contact Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2 navvnv">
                              <NavLink
                                className="nav-link"
                                id="signin-button"
                                to="/login"
                              >
                                Sign In
                              </NavLink>
                            </li>
                          </ul>
                        </>
                      ) : myState.viewNavBar === "ourplans" ? (
                        <>
                          <ul className="navbar-nav">
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabHome())}
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabAboutUs())}
                                to="/about-us"
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabOurPlans())}
                                id="home-col"
                                to="/our-plan"
                              >
                                Our Plans
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabWeWork())}
                                to="/we-work"
                              >
                                How We Work
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabContactUs())}
                                to="/contact"
                              >
                                Contact Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2 navvnv">
                              <NavLink
                                className="nav-link"
                                id="signin-button"
                                to="/login"
                              >
                                Sign In
                              </NavLink>
                            </li>
                          </ul>
                        </>
                      ) : myState.viewNavBar === "wework" ? (
                        <>
                          <ul className="navbar-nav">
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabHome())}
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabAboutUs())}
                                to="/about-us"
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabOurPlans())}
                                to="/our-plan"
                              >
                                Our Plans
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabWeWork())}
                                id="home-col"
                                to="/we-work"
                              >
                                How We Work
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabContactUs())}
                                to="/contact"
                              >
                                Contact Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2 navvnv">
                              <NavLink
                                className="nav-link"
                                id="signin-button"
                                to="/login"
                              >
                                Sign In
                              </NavLink>
                            </li>
                          </ul>
                        </>
                      ) : myState.viewNavBar === "contactus" ? (
                        <>
                          <ul className="navbar-nav">
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabHome())}
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabAboutUs())}
                                to="/about-us"
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabOurPlans())}
                                to="/our-plan"
                              >
                                Our Plans
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabWeWork())}
                                to="/we-work"
                              >
                                How We Work
                              </NavLink>
                            </li>
                            <li className="nav-item-2">
                              <NavLink
                                className="nav-link"
                                onClick={() => dispatch(tabContactUs())}
                                id="home-col"
                                to="/contact"
                              >
                                Contact Us
                              </NavLink>
                            </li>
                            <li className="nav-item-2 navvnv">
                              <NavLink
                                className="nav-link"
                                id="signin-button"
                                to="/login"
                              >
                                Sign In
                              </NavLink>
                            </li>
                          </ul>
                        </>
                      ) : (
                        <div>Loading</div>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NavBar;

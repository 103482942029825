import React from 'react'
import ProfileCardRecentShift from './ProfileCardRecentShift'
import ProfileCardSharedFiles from './ProfileCardSharedFiles'
import ProfileCardTotalLeave from './ProfileCardTotalLeave'
import ProfileCardUpcomingShift from './ProfileCardUpcomingShift'
// import ProfileCardPersonalInformation from './ProfileCardPersonalInformation'

const ProfileAllCards = () => {
    return (
        <>
            <div className="tab-content">
                <div id="emp_profile" className="pro-overview tab-pane fade show active">
                    {/* <Shifts /> */}
                    {/* <div className="row">
                        <ProfileCardPersonalInformation />
                        <ProfileCardTotalLeave />
                    </div> */}
                    <div className="row">
                        <ProfileCardUpcomingShift />
                        <ProfileCardRecentShift />
                    </div>
                    <div className="row">
                        <ProfileCardTotalLeave />
                        <ProfileCardSharedFiles />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileAllCards
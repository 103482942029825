import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <section className="pricing-6-area pt-62 new-bg-color" id="kkk">
        <div className="container">
          <div className="row contact-area justify-content-center">
            <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
              <div className="pricing-6-item mt-30">
                <div className="section-title-6 text-center">
                  <h3 className="title">Privacy Policy</h3>
                  <p></p>
                </div>
                <div className="contact-form">
                  <div><strong>Who we are</strong></div>
                  <div>Riyowork LLC. (“Riyowork”, “us”, “we”) is a cloud-based workforce management platform for web and mobile. We are incorporated in Bloomington, Minnesota.</div>
                  <br />
                  <div><strong>Scope:</strong></div>
                  {/* <div>This Private Policy applies to all users of riyowork.com, users of our services and our mobile applications, and recipients of our emails. It lays forth the personal data that we collect, how we use such data and how we protect this data. By using our website, services, or mobile applications, you are in agreement with our collection and use of your personal data described in this Private Policy.</div> */}
                  <div>This Private Policy applies to all users of riyowork.com, users of our services and our mobile applications, and recipients of our emails. It lays forth the personal data that we collect, how we use such data and how we protect this data. By using our website, services, or mobile applications, you are in agreement with our collection and use of your personal data described in this Private Policy.</div>
                  <br />
                  <div><strong>We make the following commitments:</strong></div>
                  {/* <div>We will not share your data unless you’ve given us permission, we’ve told you in advance, we’ve aggregated it and removed any information that would identify you, or legal rights are affected. We will not spam you.</div> */}
                  <div>We will not share your data unless you’ve given us permission, we’ve told you in advance, we’ve aggregated it and removed any information that would identify you, or legal rights are affected. We will not spam you.</div>
                  <br />
                  <div><strong>Data collected:</strong></div>
                  <div>By using our application:</div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>1.  You agree that if you disclose your personal information to us, we may create a database record of you</div> */}
                    <div>1.	You agree that if you disclose your personal information to us, we may create a database record of you</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>2.  If you register on our website and/or application, download any materials or conduct any other transaction, you agree for us to collect information necessary to provide you with the service you have signed up for. You agree that this information may be disclosed to our partners (who may be third parties) for the purpose of providing you this service.</div> */}
                    <div>2.	If you register on our website and/or application, download any materials or conduct any other transaction, you agree for us to collect information necessary to provide you with the service you have signed up for. You agree that this information may be disclosed to our partners (who may be third parties) for the purpose of providing you this service.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>3.  You agree for us to use cookies in your browser unless you removed them.</div> */}
                    <div>3.	You agree for us to use cookies in your browser unless you removed them.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>4.  You agree that we may contact you with information for services you may be interested in unless you have indicated you do not wish to receive such communications</div> */}
                    <div>4.	You agree that we may contact you with information for services you may be interested in unless you have indicated you do not wish to receive such communications</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>5.  Analytics. We use Google Analytics (or other such third-party analysis tools) to provide us information regarding the use of the Riyowork, and the effectiveness of our advertisements. Our service providers may collect certain information about your visits and activity to Riyowork and as well as other websites or services, which include the web page visited before you came to our website, the type of browser you are using, the length of visit and number of page views, and the page-by-page paths you take as you browse through our services.</div> */}
                    <div>5.	Analytics. We use Google Analytics (or other such third-party analysis tools) to provide us information regarding the use of the Riyowork, and the effectiveness of our advertisements. Our service providers may collect certain information about your visits and activity to Riyowork and as well as other websites or services, which include the web page visited before you came to our website, the type of browser you are using, the length of visit and number of page views, and the page-by-page paths you take as you browse through our services.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    {/* <div>6.  Your continued use of Riyowork platform indicates that you are in agreement with this policy and any future changes in Riyowork Private Policy.</div> */}
                    <div>6.	Your continued use of Riyowork platform indicates that you are in agreement with this policy and any future changes in Riyowork Private Policy.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                    <div>7.	Employer(s) (company) have the option to track their employees using the geolocation system in Riyowork. Each employer has the option to activate or disable geolocation for their employees.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "70px", marginRight: "70px" }}>
                    <strong>App Location Usage:</strong>
                  </div>
                  <div style={{ marginLeft: "70px", marginRight: "70px" }}>
                    <div>We may collect precise or approximate location from user's mobile devices when the app is in use or in the background if permission is granted by the user.</div>
                  </div>
                  <br />
                  <div style={{ marginLeft: "70px", marginRight: "70px" }}>
                    <div>This is used for tracking the employee's location by RiyoWork when he/she clocks in. The user (employee) must grant permission for foreground (App in use) and background location tracking when the schedule shift starts.</div>
                  </div>
                  <br />
                    {/* <div>Note that we are not responsible for the security of any data you are transmitting over the internet, or any data you are storing, posting, or providing directly to a third party’s website, which is governed by that party’s privacy policy.</div> */}
                    <div>Note that we are not responsible for the security of any data you are transmitting over the internet, or any data you are storing, posting, or providing directly to a third party’s website, which is governed by that party’s privacy policy.</div>
                    <br />
                  <div><strong>Cookies:</strong></div>
                  {/* <div>We use cookies on our website to collect data about your visit which allows you to navigate pages on our website without re-registering each time. We may also use such data to tailor advertisements to you. You can block and refuse such cookies simply by using the appropriate settings on your web browser.</div> */}
                  <div>We use cookies on our website to collect data about your visit which allows you to navigate pages on our website without re-registering each time. We may also use such data to tailor advertisements to you. You can block and refuse such cookies simply by using the appropriate settings on your web browser.</div>
                    <br />
                    <div><strong>Sharing your data when legal rights are affected:</strong></div>
                  <div>We may be required to share your information when our legal rights are affected. Some examples are:</div>
                    <br />
                    <div>We will share your information if it is required by law or to respond from requests from law enforcement officials, and regulatory agencies in connection with certain court proceedings</div>
                    <br />
                    <div>We may share your data where this is in the interest of protecting or exercising our or others’ legal rights, for example, in connection with court proceedings, or to detect or prevent criminal activity, fraud, material representation, or to establish our rights to defend against legal claims</div>
                    <br />
                  {/* <div><strong>Other Computer information:</strong></div>
                  <div>We collect session information such as IP (Internet Protocol) addresses, browser types, GPS coordinates, operating systems, and device information. Such information is used to enhance your experience with Riyowork as well as help us troubleshoot any technical issues that may arise when using our platform.</div>
                    <br />
                  <div><strong>Updating or Deleting your Data:</strong></div>
                  <div>You can edit/delete an uploaded image by using the edit feature in our mobile app or in your account online on our website. You may also close your account with us altogether by using the settings module in your account. If you have any information that you wish to change, and do not know how, you can contact us at contact@pushopertaions.com. Note that we may retain your personal information in our databases in accordance with applicable data retention policies and laws. We may retain certain information to respond to any issues that may arise later, for example, we may need certain information to prevent fraudulent activity, protect ourselves against liability, permit use to pursue available remedies or limit any damages we may sustain or if we believe in good faith that a law or regulation requires it. Your data will be stored in a secured location controlled by us. In all cases, we retain the right to use data obtained to verify your identify or take other actions that we believe are appropriate.</div>
                    <br />
                  <div><strong>Third Party Service Providers:</strong></div>
                  <div>We use third party service providers as part of delivering our service. These third parties provide us with advertising, referral, operations, and technology services (for example, hosting). We require each third-party provider to enter into a written agreement with us by which they agree to protect the confidentiality and security of your data and use your data only on our behalf and only in the way which we permit in the written agreement.</div>
                    <br />
                  <div><strong>Public parts of our website:</strong></div>
                  <div>There are public parts of our websites, such as blog comments. These parts are open to the public and if you disclose your personal data in any public forum on our website, that data becomes public knowledge and can be accessed by people other than us. We are not responsible for their use of your personal data.</div>
                    <br />
                  <div><strong>Minors:</strong></div>
                  <div>We cannot distinguish the age of individuals that access our site as such blanket private policy is applied. We do not intend or seek to receive any personal information from minors. Should a parent or guardian have reason to believe that a minor has provided Riyowork with personal information without their prior consent, please contact us to ensure that the ensure that the information is removed.</div>
                    <br />
                  <div><strong>Sharing your data when legal rights are affected:</strong></div>
                  <div>We may be required to share your information when our legal rights are affected. Some examples are:</div>
                  <br />
                  <div>We will share your information if it is required by law or to respond from requests from law enforcement officials, and regulatory agencies in connection with certain court proceedings</div>
                  <br />
                  <div>We may share your data where this is in the interest of protecting or exercising our or others’ legal rights, for example, in connection with court proceedings, or to detect or prevent criminal activity, fraud, material representation, or to establish our rights to defend against legal claims</div>
                  <br /> */}
                  <div><strong>Security:</strong></div>
                  {/* <div>Riyowork has implemented technology and security features to safeguard the privacy of your personal information using the industry’s best practices.</div> */}
                  <div>Riyowork has implemented technology and security features to safeguard the privacy of your personal information using the industry’s best practices.</div>
                  <br />
                  {/* <div>Riyowork will endeavor all reasonable steps to keep your information safe and secured. Your information is held in secured servers. Unfortunately, despite all technology and security features, no data transmitted over the internet is 100% secure. We cannot give absolute assurance that the information you provide us is always secured, and we cannot be responsible for unauthorized access to your personal information. If you are aware of any security breach, please let us know as soon as possible by contacting: contact@riyowork.com</div> */}
                  <div>Riyowork will endeavor all reasonable steps to keep your information safe and secured. Your information is held in secured servers. Unfortunately, despite all technology and security features, no data transmitted over the internet is 100% secure. We cannot give absolute assurance that the information you provide us is always secured, and we cannot be responsible for unauthorized access to your personal information. If you are aware of any security breach, please let us know as soon as possible by contacting: contact@riyowork.com</div>
                  <br />
                  <div><strong>Changes to Riyowork’ Privacy Policy:</strong></div>
                  {/* <div>Riyowork may amend this policy from time to time. If we make any substantial changes in any we use your personal information, you will be notified by posting a prominent announcement on our web page or when you log onto our application.</div> */}
                  <div>Riyowork may amend this policy from time to time. If we make any substantial changes in any we use your personal information, you will be notified by posting a prominent announcement on our web page or when you log onto our application.</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default PrivacyPolicy
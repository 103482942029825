import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  getAllLocationsWithPagination,
  getCompanyLocations,
} from "../../../actions/locationActions";
import { useDispatch, useSelector } from "react-redux";
import LocationTableRow from "./LocationTableRow";
// import EditModalLocation from './EditModalLocation'

const LocationTable = () => {
  // const user = useSelector((state) => state.user)
  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);
  const [dropDownRow, setDropDownRow] = useState(null);
  const dispatch = useDispatch();
  const locationData = async (e) => {
    dispatch(
      getAllLocationsWithPagination({
        companyId: auth.user.companyId,
        limit: limit,
        page: currentPageNumber,
      })
    );
  };

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getAllLocationsWithPagination({
        companyId: auth.user.companyId,
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= location.locationWithPagination.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllLocationsWithPagination({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllLocationsWithPagination({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllLocationsWithPagination({
        companyId: auth.user.companyId,
        limit: limit,
        page: pageNo,
      })
    );
  };
  useEffect(() => {
    locationData();
  }, []);
  console.log(location, "location");
  console.log(location.location, "location.loc2");
  console.log(location.locationWithPagination, "locationWithPagination333");
  console.log(locationData, "locationData");
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              Show &nbsp;
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="select"
                autoComplete="false"
                onChange={handleLimit}
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return <option value={current}>{current}</option>;
                })}
              </select>{" "}
              entries
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th style={{ width: "30px" }}></th>
                <th>Location </th>
                <th>Address </th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {location.locationWithPagination &&
                location.locationWithPagination.results &&
                location.locationWithPagination.results.length > 0 &&
                location.locationWithPagination.results.map((current, i) => {
                  return (
                    <LocationTableRow
                      current={current}
                      i={i}
                      dropDownRow={dropDownRow}
                      setDropDownRow={setDropDownRow}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>

      <br />
      <div className="row">
        {location.locationWithPagination && (
          <div className="col-sm-12 col-md-5">
            <label>
              {" "}
              Showing {1 + limit * (currentPageNumber - 1)} to{" "}
              {location.locationWithPagination.displayingResults +
                limit * (currentPageNumber - 1)}{" "}
              of {location.locationWithPagination.totalResults} entries{" "}
            </label>
          </div>
        )}
        <div className="col-sm-12 col-md-7">
          <div className="avatar-pagination">
            <ul className="pagination" style={{ justifyContent: "flex-end" }}>
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  onClick={onClickPrev}
                >
                  <span aria-hidden="true">Previous</span>
                  <span className="sr-only">Previous</span>
                </NavLink>
              </li>
              {location.locationWithPagination &&
                location.locationWithPagination.paginationArr &&
                location.locationWithPagination.paginationArr.length > 0 &&
                location.locationWithPagination.paginationArr.map(
                  (current, i) => {
                    return currentPageNumber === current ? (
                      <li className="page-item active" key={i}>
                        <a className="page-link" value={current}>
                          {current}
                        </a>
                      </li>
                    ) : (
                      <li className="page-item" key={i}>
                        <a
                          className="page-link"
                          value={current}
                          onClick={() => handleChangeData(current)}
                        >
                          {current}
                        </a>
                      </li>
                    );
                  }
                )}
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Next"
                  onClick={onClickNext}
                >
                  <span aria-hidden="true">Next</span>
                  <span className="sr-only">Next</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default LocationTable;

import React, { useState, useEffect } from 'react'
import { getOvertimeTypeByCompanyId } from '../../../actions/payrollAction'
import { useDispatch, useSelector } from 'react-redux'
import PayrollOvertimeButtonRow from './PayrollOvertimeButtonRow'

const PayrollOvertimeButton = () => {
    const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const payroll = useSelector((state) => state.payroll)
    const [ dropDownRow, setDropDownRow ] = useState(null)    
    const dispatch = useDispatch()
    const payrollOvertimeTable = async (e) => {
        dispatch(getOvertimeTypeByCompanyId({companyId: auth.user.companyId }))
    }
    useEffect(() => {
        payrollOvertimeTable()
    }, [])
    return (
        <>
        <div className="tab-pane" id="tab_overtime">
            <div className="payroll-table card">
                {/* <div className="table-responsive"> */}
                    <table className="table table-hover table-radius">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Rate</th>
                                <th className="text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            payroll.payrollOvertimeTable &&
                            payroll.payrollOvertimeTable.length > 0 &&
                            payroll.payrollOvertimeTable.map((current, i) =>{
                                return (
                            <PayrollOvertimeButtonRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                            )})}
                        </tbody>
                    </table>
                </div>
            {/* </div> */}
        </div>
        </>
    )
}
export default PayrollOvertimeButton
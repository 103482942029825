import React from "react";
const SAdminAddPolicyModal = ({ showDropDown, openDropDown }) => {
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_policy"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Policy</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Policy Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label>
                    Description <span className="text-danger">*</span>
                  </label>
                  <textarea className="form-control" rows="4"></textarea>
                </div>
                {/* <div className="form-group">
                                    <label className="col-form-label">Department</label>
                                    <select className="select">
                                        <option>All Departments</option>
                                        <option>Web Development</option>
                                        <option>Marketing</option>
                                        <option>IT Management</option>
                                    </select>
                                </div> */}
                {/* <div className="form-group">
                                    <label>Upload Policy <span className="text-danger">*</span></label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="policy_upload" />
                                        <label className="custom-file-label" for="policy_upload">Choose file</label>
                                    </div>
                                </div> */}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SAdminAddPolicyModal;

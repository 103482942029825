import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import SuperAdminProfileCover from "../../components/superAdminCompoents/profileComponentsSuperAdmin/SuperAdminProfileCover";
const SuperAdminProfile = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Profile" />
            <SuperAdminProfileCover />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminProfile;

import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminContactUsQueriesTable from "../../components/superAdminCompoents/superAdminContactUsQueries/SuperAdminContactUsQueriesTable";

const SuperAdminContactUsQueriesPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <AdminTittle title="Contact-Us Queries" /> */}
            <SuperAdminContactUsQueriesTable />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminContactUsQueriesPage;

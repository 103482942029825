import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getMailByMailId } from '../actions/mailActions'
import EmployeeHeader from '../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../components/employeeComponents/EmployeeTitle'
import MessageBoxDesign from '../components/MessageBoxDesign'
const MailView = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id) {
            // console.log("dispatch")
            dispatch(getMailByMailId({mailId: params.id}, navigate))
        } 
    }, [params.id]);
    return (
        <>
            <EmployeeHeader />
            <EmployeeSidebar />
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="View Message" />
                        <MessageBoxDesign />
                    </div>
                </div>
            </div>
        </>
    )
}
export default MailView
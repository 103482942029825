import React from 'react'
import Footer from '../components/Footer'
import LoginForm from '../components/loginComponents/LoginForm'
import NavBar from '../components/NavBar'
// import BannerLogin from '../components/loginComponents/BannerLogin'
const Login = () => {
  return (
    <>
      <NavBar />
      {/* <BannerLogin /> */}
      <LoginForm />
      <Footer />
    </>
  )
}
export default Login
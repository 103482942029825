import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import defaultImg from "../../assetsAdmin/img/avatarProfile.png";
import logonew from "../../assetsAdmin/img/footerlogoresize.png";

import { HeaderMessageModel } from "../HeaderMessageModel";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../actions/authActions";
import { BUCKET_BASE_URL } from "../../constants";
import HeaderNotificationModel from "../HeaderNotificationModel";
import logo10 from "../../assets/img/logo.png";
import logowhite from "../../assets/img/logo-white.png";
import logosmall from "../../assets/img/logo-small.png";

const EmployeeHeader = () => {
  // new line add krhe hain 3/8/2022

  // const {userById} = useSelector(
  //    userSelector
  // )
  const dispatch = useDispatch();
  // const [showDropDown, setshowDropDown] = useState(false)
  // const [showDropDownFlag, setshowDropDownFlag] = useState(false)
  const [showDropDownMessage, setshowDropDownMessage] = useState(false);
  const [opensidedrawer, setopensidedrawer] = useState(false);
  const [openDrop, setopenDrop] = useState(false);
  const auth = useSelector((state) => state.auth);
  const notifications = useSelector((state) => state.notifications);

  // const dispatch = useDispatch()
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  // const openDropDown = () => {
  //    setshowDropDown((state) => !state)
  //    setshowDropDownMessage(false)
  //    setshowDropDownFlag(false)
  //    setopenDrop(false)
  // }
  // const openDropDownFlag = () => {
  //    setshowDropDownFlag((state) => !state)
  //    setshowDropDown(false)
  //    setshowDropDownMessage(false)
  //    setopenDrop(false)
  // }
  const openDropDownMessage = () => {
    setshowDropDownMessage((state) => !state);
    // setshowDropDown(false)
    // setopenDrop(false)
    // setshowDropDownFlag(false)
  };
  const openDropDownMenu = () => {
    setopenDrop((state) => !state);
    // setshowDropDown(false)
    // setshowDropDownFlag(false)
    // setshowDropDownMessage(false)
  };
  const toggleDriver = () => {
    setopensidedrawer((state) => !state);
  };
  //   new line 3/8/2022

  // const getprofileByID =   (id) => {
  //    dispatch(myProfile(id))
  // }
  const mail = useSelector((state) => state.mail);
  let j = 0;
  let jj = 0;
  console.log(auth, "auth");
  return (
    <>
      <div className="header header-one">
        <div className="header-left header-left-one  d-flex justify-content-between">
          <div>
            <NavLink to="/dashboard" className="logo">
              <img src={logo10} alt="Logo" />
            </NavLink>
            <NavLink to="/dashboard" className="white-logo">
              <img src={logowhite} alt="Logo" />
            </NavLink>
            <NavLink to="/dashboard" className="logo logo-small d-lg-none">
              <img src={logosmall} alt="Logo" width="30" height="30" />
            </NavLink>
          </div>

          <div>
            <a id="toggle_btn">
              <i class="fas fa-bars"></i>
            </a>
            <a class="mobile_btn" id="mobile_btn">
              <i class="fas fa-bars"></i>
            </a>
          </div>
        </div>

        <div class=" header-right header-right-one  ">
          <div class="top-nav-search">
            <div class="header-title">
              {/* <h4>Welcome Admin!</h4> */}
              <span class="fs-6">Dashboard</span>
            </div>
          </div>
          <ul class="nav nav-tabs user-menu">
            <li key={jj++} class="nav-item dropdown">
              <a
                href="#"
                class="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                onClick={openDropDownMessage}
              >
                <i class="fa fa-comment"></i>
                {notifications.list.length > 0 && (
                  <span className="badge badge-pill">
                    {notifications.list.length -
                      notifications.list.filter((x) =>
                        x.readBy.find((y) => y.readerId == auth.user.id)
                      ).length}
                  </span>
                )}
              </a>
              <HeaderNotificationModel
                showDropDownMessage={showDropDownMessage}
                openDropDownMessage={openDropDownMessage}
              />
              <div class="dropdown-menu notifications">
                <div class="topnav-dropdown-header">
                  <span class="notification-title">Notifications</span>
                  <a class="clear-noti"> Clear All</a>
                </div>

                <div class="noti-content">
                  <ul class="notification-list">
                    <li class="notification-message">
                      <a href="activities.html">
                        <div class="media d-flex">
                          <span class="avatar avatar-sm">
                            <img
                              class="avatar-img rounded-circle"
                              alt=""
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </span>
                          <div class="media-body">
                            <p class="noti-details">
                              <span class="noti-title">Brian Johnson</span>
                              paid the invoice{" "}
                              <span class="noti-title">#DF65485</span>
                            </p>
                            <p class="noti-time">
                              <span class="notification-time">4 mins ago</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="notification-message">
                      <a href="activities.html">
                        <div class="media d-flex">
                          <span class="avatar avatar-sm">
                            <img
                              class="avatar-img rounded-circle"
                              alt=""
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </span>
                          <div class="media-body">
                            <p class="noti-details">
                              <span class="noti-title">Marie Canales</span>
                              has accepted your estimate{" "}
                              <span class="noti-title">#GTR458789</span>
                            </p>
                            <p class="noti-time">
                              <span class="notification-time">6 mins ago</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="notification-message">
                      <a href="activities.html">
                        <div class="media d-flex">
                          <div class="avatar avatar-sm">
                            <span class="avatar-title rounded-circle bg-primary-light">
                              <i class="far fa-user"></i>
                            </span>
                          </div>
                          <div class="media-body">
                            <p class="noti-details">
                              <span class="noti-title">
                                New user registered
                              </span>
                            </p>
                            <p class="noti-time">
                              <span class="notification-time">8 mins ago</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="notification-message">
                      <a href="activities.html">
                        <div class="media d-flex">
                          <span class="avatar avatar-sm">
                            <img
                              class="avatar-img rounded-circle"
                              alt=""
                              src="assets/img/profiles/avatar-04.jpg"
                            />
                          </span>
                          <div class="media-body">
                            <p class="noti-details">
                              <span class="noti-title">Barbara Moore</span>
                              declined the invoice{" "}
                              <span class="noti-title">#RDW026896</span>
                            </p>
                            <p class="noti-time">
                              <span class="notification-time">12 mins ago</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li class="notification-message">
                      <a href="activities.html">
                        <div class="media d-flex">
                          <div class="avatar avatar-sm">
                            <span class="avatar-title rounded-circle bg-info-light">
                              <i class="far fa-comment"></i>
                            </span>
                          </div>
                          <div class="media-body">
                            <p class="noti-details">
                              <span class="noti-title">
                                You have received a new message
                              </span>
                            </p>
                            <p class="noti-time">
                              <span class="notification-time">2 days ago</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="topnav-dropdown-footer">
                  <a href="activities.html">View all Notifications</a>
                </div>
              </div>
            </li>
            <div class="topbar-divider "></div>
            <li
              key={jj++}
              class="nav-item dropdown has-arrow main-drop"
              onClick={openDropDownMenu}
            >
              <a
                href="#"
                class="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <span class="user-img">
                  {auth.user && auth.user.profilePic ? (
                    auth.user.profilePic.fileUrl && (
                      <>
                        <img
                          src={BUCKET_BASE_URL + auth.user.profilePic.fileUrl}
                          alt=""
                        />
                      </>
                    )
                  ) : (
                    <img alt="" src={defaultImg} />
                  )}
                  <span class="status online"></span>
                </span>
                <span>{auth.user.firstName + " " + auth.user.lastName}</span>
              </a>
              <div
                class={`dropdown-menu ${openDrop ? "show" : "hide"}`}
                style={{ borderRadius: "0px 0px 15px 15px" }}
              >
                <Link class="dropdown-item" to="/profile">
                  {/* <i data-feather="user" class="me-1"></i> */}
                  <i className="la la-user" style={{ fontSize: "20px" }}></i>
                  My Profile
                </Link>
                {/* <Link class="dropdown-item" to="/settings">
                  <i data-feather="settings" class="me-1"></i>
                  Settings
                </Link> */}
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  <i
                    className="la la-sign-out"
                    style={{ fontSize: "20px" }}
                  ></i>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EmployeeHeader;

// <div className="main-wrapper">
//         <div className="header">
//           <div className="header-left">
//             <NavLink to="/dashboard" className="logo">
//               <img src={logonew} height="33" alt="" />
//             </NavLink>
//           </div>
//           <div className="page-title-box">
//             <h3>Riyo Work</h3>
//           </div>
//           <NavLink
//             id="mobile_btn"
//             className="mobile_btn"
//             to="#"
//             onClick={toggleDriver}
//           >
//             <i className="fa fa-bars"></i>
//           </NavLink>
//           <ul className="nav user-menu">
//             <li className="nav-item dropdown">
//               <NavLink
//                 to="#"
//                 className="dropdown-toggle nav-link"
//                 data-toggle="dropdown"
//                 onClick={openDropDownMessage}
//               >
//                 <i className="fa fa-comment-o"></i>
//                 {notifications.list.length > 0 && (
//                   <span className="badge badge-pill">
//                     {notifications.list.length -
//                       notifications.list.filter((x) =>
//                         x.readBy.find((y) => y.readerId == auth.user.id)
//                       ).length}
//                   </span>
//                 )}
//               </NavLink>
//               <HeaderNotificationModel
//                 showDropDownMessage={showDropDownMessage}
//                 openDropDownMessage={openDropDownMessage}
//               />
//             </li>
//             <li
//               className="nav-item dropdown has-arrow main-drop"
//               onClick={openDropDownMenu}
//             >
//               <NavLink
//                 to="#"
//                 className="dropdown-toggle nav-link"
//                 data-toggle="dropdown"
//               >
//                 <span className="avatar">
//                   {auth.user && auth.user.profilePic ? (
//                     auth.user.profilePic.fileUrl && (
//                       <>
//                         <img
//                           alt="Profile Pic"
//                           src={BUCKET_BASE_URL + auth.user.profilePic.fileUrl}
//                           style={{ objectFit: "fill", height: "100%" }}
//                         />
//                       </>
//                     )
//                   ) : (
//                     <img alt="" src={defaultImg} />
//                   )}
//                   <span
//                     className="status online"
//                     style={{
//                       float: "right",
//                       marginTop: "-0.6rem",
//                       position: "relative",
//                       border: "2px solid #fff",
//                     }}
//                   ></span>
//                 </span>
//                 <span>{auth.user.firstName + " " + auth.user.lastName}</span>
//               </NavLink>
//               <div
//                 className={`dropdown-menu dropdown-profile ${
//                   openDrop ? "show" : "hide"
//                 }`}
//               >
//                 <NavLink className="dropdown-item" to="/profile">
//                   My Profile
//                 </NavLink>
//                 <NavLink className="dropdown-item" to="/settings">
//                   Settings
//                 </NavLink>

//                 <a
//                   className="dropdown-item"
//                   style={{ cursor: "pointer" }}
//                   onClick={logout}
//                 >
//                   Logout
//                 </a>
//               </div>
//             </li>
//           </ul>
//           <div className="dropdown mobile-user-menu" onClick={openDropDownMenu}>
//             <NavLink
//               to="#"
//               className="nav-link dropdown-toggle"
//               data-toggle="dropdown"
//               aria-expanded="false"
//             >
//               <i className="fa fa-ellipsis-v"></i>
//             </NavLink>
//             <div
//               className={`dropdown-menu dropdown-menu-right ${
//                 openDrop ? "show" : "hide"
//               }`}
//             >
//               <NavLink className="dropdown-item" to="/profile">
//                 My Profile
//               </NavLink>
//               <NavLink className="dropdown-item" to="/settings">
//                 Settings
//               </NavLink>

//               <a
//                 className="dropdown-item"
//                 style={{ cursor: "pointer" }}
//                 onClick={logout}
//               >
//                 Logout
//               </a>
//             </div>
//           </div>
//           <div
//             className={`sidebar ${
//               opensidedrawer ? "showsidebar" : "hidesidebar"
//             }`}
//             id="sidebar"
//           >
//             <div className="sidebar-inner slimscroll">
//               <div id="sidebar-menu" className="sidebar-menu">
//                 <ul>
//                   <li>
//                     <NavLink to="/dashboard" className="noti-dot">
//                       <i className="la la-dashboard"></i>{" "}
//                       <span> Dashboard</span>
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink to="/chat">
//                       <i className="la la-wechat"></i>
//                       <span>Chat</span>
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink to="/shift-scheduling">
//                       <i className="la la-users"></i>
//                       <span>Shift &amp; Schedule</span>
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink to="/attendance">
//                       <i className="la la-clock-o"></i>
//                       <span>Time Clock</span>
//                     </NavLink>
//                   </li>

//                   <li>
//                     <NavLink to="/leaves">
//                       <i className="la la-external-link-square"></i>
//                       <span>Leaves </span>
//                     </NavLink>
//                   </li>

//                    <li><NavLink to="/leaves"><i className="la la-external-link-square"></i><span>Leaves </span><span className="badge badge-pill bg-primary float-right">1</span></NavLink></li> */}
//                   <li>
//                     <NavLink to="/training">
//                       <i className="la la-graduation-cap"></i>{" "}
//                       <span> Training </span>
//                     </NavLink>
//                   </li>
//                   <li>
//                     <a style={{ cursor: "pointer" }} onClick={logout}>
//                       <i className="la la-power-off"></i> <span>Logout</span>
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         className={`sidebar-overlay ${opensidedrawer ? "opened" : ""}`}
//       ></div>

import React, { useState } from "react";
import { createComment } from "../../../actions/messageWallActions";
import { useDispatch, useSelector } from "react-redux";

const CommentComponent = ({ current, setShowCommentBox }) => {
  // const auth = useSelector((state) => state.auth);
  // const dispatch = useDispatch();

  // const [comment, setComment] = useState();
  // const messageHandler = (e) => {
  //   setComment(e.target.value);
  // };
  // const commentOnMessage = {
  //   companyId: auth.user.companyId,
  //   userId: auth.user.userId,
  //   comment: comment,
  //   messageWallId: current?.id,
  //   // date: newCurrDate,
  // };
  // const handleComment = async (event) => {
  //   dispatch(createComment(commentOnMessage));
  //   setComment({
  //     comment: "",
  //   });
  // };
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");

  const messageHandler = (e) => {
    setComment(e.target.value);
  };

  const handleComment = async (event) => {
    // event.preventDefault();

    if (!comment.trim()) {
      // Do not dispatch if comment is empty
      return;
    }

    const commentOnMessage = {
      companyId: auth.user.companyId,
      userId: auth.user.userId,
      comment: comment,
      messageWallId: current?.id,
      // date: newCurrDate,
    };

    dispatch(createComment(commentOnMessage));
    setComment(""); // Clear the comment input after submission
  };

  return (
    <div className="col-md-12">
      <input
        placeholder="Write your comment"
        className="form-control bg-light mb-3"
        value={comment}
        onChange={messageHandler}
      />
      <div className="d-flex justify-content-between">
        <button className="btn btn-success rounded-1" onClick={handleComment}>
          Post Comment
        </button>
        <button
          className="btn btn-secondary rounded-1"
          onClick={() => setShowCommentBox(null)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CommentComponent;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AdminSSAddShiftModal from "./AdminSSAddShiftModal";
import EditModalSSEditScheduling from "./EditModalSSEditScheduling";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const WeeklyTableRow = ({ current, i, date }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [iii, setIII] = useState();
  const [numberShift, setNumberShift] = useState();
  // const [shiftData, setShiftData] = useState()

  const openMoreVertDropDown = (num, userShift, currentShift) => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
    // const bb = current.weeklyShift[num]
    // console.log(current.user, bb, currentShift )
    // setShiftData({
    //     // user: current.user,
    //     shift: currentShift,
    //     // val: jj
    // })
    setIII(num);
    // console.log(shiftData.user === '', num, "jhoota", shiftData)
    // }
    // setShiftData({
    //     user: current.user,
    //     shift: current.weeklyShift[iii],
    // })
    // console.log(num, "jhoota", shiftData)
  };
  const openDropDown = (num) => {
    setshowDropDown((showDropDown) => !showDropDown);
    setNumberShift(num);
  };
  return (
    <>
      <tr key={i}>
        <td>
          <h2 className="table-avatar">
            <NavLink to={`/profile/${current.user.id}`} className="avatar">
              {/* <img alt="" src="assetsAdmin/img/avatar-02.jpg" /> */}
              {current.user &&
              current.user.profilePic &&
              current.user.profilePic.fileUrl ? (
                <img
                  alt="profilePic"
                  src={BUCKET_BASE_URL + current.user.profilePic.fileUrl}
                  style={{ objectFit: "fill", height: "100%" }}
                />
              ) : (
                <img alt="profilePic" src={defaultImg} />
              )}
            </NavLink>
            <NavLink to={`/profile/${current.user.id}`}>
              {current.user.firstName + " " + current.user.lastName}{" "}
              <span>{current.user.jobTitle}</span>
            </NavLink>
          </h2>
        </td>
        {current.weeklyShift.length > 0
          ? current.weeklyShift.map((e, j) => {
              let shiftType;
              let shiftTypeCss = "miclsun";
              let shiftTypeIcon = "fa fa-sun-o";
              let empty = true;
              let temp;
              // let start, end;
              let stAmPm = "am";
              let enAmPm = "am";
              let date;
              if (Object.keys(e).length != 0) {
                let stStart = parseInt(e.startTime.substring(11, 13));
                let stStartMin = parseInt(e.startTime.substring(14, 16));
                let enEnd = parseInt(e.endTime.substring(11, 13));
                let enEndMin = parseInt(e.endTime.substring(14, 16));
                // console.log("times==>", stStart, stStartMin, enEnd, enEndMin);
                if (stStartMin < 10) {
                  stStartMin = "0" + stStartMin;
                }
                if (enEndMin < 10) {
                  enEndMin = "0" + enEndMin;
                }
                if (stStart === 0) {
                  stStart = 12;
                } else if (stStart == 12) {
                  stStart = 12;
                  stAmPm = "pm";
                } else if (stStart > 12) {
                  stStart = stStart - 12;
                  stAmPm = "pm";
                }
                if (enEnd === 0) {
                  enEnd = 12;
                } else if (enEnd == 12) {
                  enEnd = 12;
                  enAmPm = "pm";
                } else if (enEnd > 12) {
                  enEnd = enEnd - 12;
                  enAmPm = "pm";
                }
                if ((stStart === 12 || stStart <= 5) && stAmPm == "am") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                } else if (stStart >= 6 && stAmPm == "am") {
                  shiftType = "morning";
                  shiftTypeCss = "miclsun";
                  shiftTypeIcon = "fa fa-sun-o";
                } else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm") {
                  shiftType = "evening";
                  shiftTypeCss = "miclscl";
                  shiftTypeIcon = "fa fa-cloud";
                } else if (stStart >= 8 && stAmPm == "pm") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                } else if (stStart >= 0 && stAmPm == "am") {
                  shiftType = "night";
                  shiftTypeCss = "miclsmn";
                  shiftTypeIcon = "fa fa-moon-o";
                }
                date =
                  stStart +
                  ":" +
                  stStartMin +
                  " " +
                  stAmPm +
                  "-" +
                  enEnd +
                  ":" +
                  enEndMin +
                  " " +
                  enAmPm;
              }
              return (
                <>
                  {Object.keys(e).length != 0 ? (
                    <td key={j} style={{ borderCollapse: "separate" }}>
                      <div className={`user-add-shedule-list ${shiftTypeCss}`}>
                        <h2>
                          <a
                            onClick={() => {
                              openMoreVertDropDown(j, current, e);
                            }}
                          >
                            {/* <div > */}
                            {/* style={{width: "100%", height: "3rem"}} yeh add karo toh class achi hogi */}
                            <span className="username-info ">
                              {date} {e.duration}
                            </span>
                            {e.location ? (
                              <span className="userrole-info">
                                {e.userId.jobTitle} - {e.location.name}{" "}
                                <i className={shiftTypeIcon}></i>
                              </span>
                            ) : (
                              <span className="userrole-info">
                                {e.userId.jobTitle} -{" "}
                                <i className={shiftTypeIcon}></i>
                              </span>
                            )}
                            {/* </div> */}
                          </a>
                          {/* <EditModalSSEditScheduling current={current} valueIndex={j} typeStatus={'weekly'} showMoreVertDropDown={showMoreVertDropDown} openMoreVertDropDown={openMoreVertDropDown} /> */}
                        </h2>
                      </div>
                    </td>
                  ) : (
                    <td>
                      <div className="user-add-shedule-list">
                        <NavLink
                          to="#"
                          data-toggle="modal"
                          data-target="#add_schedule"
                          onClick={() => {
                            openDropDown(j);
                          }}
                        >
                          <span>
                            <i className="fa fa-plus"></i>
                          </span>
                        </NavLink>
                      </div>
                    </td>
                  )}
                </>
              );
            })
          : [0, 0, 0, 0, 0, 0, 0].map((g, p) => {
              return (
                <td>
                  <div className="user-add-shedule-list">
                    <NavLink
                      to="#"
                      data-toggle="modal"
                      data-target="#add_schedule"
                      onClick={() => {
                        openDropDown(p);
                      }}
                    >
                      <span>
                        <i className="fa fa-plus"></i>
                      </span>
                    </NavLink>
                  </div>
                </td>
              );
            })}
      </tr>
      <EditModalSSEditScheduling
        current={current}
        valueIndex={iii}
        typeStatus={"weekly"}
        showMoreVertDropDown={showMoreVertDropDown}
        openMoreVertDropDown={openMoreVertDropDown}
      />
      <AdminSSAddShiftModal
        current={current}
        number={numberShift}
        date={date}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};

export default WeeklyTableRow;

import React, { useState, useEffect } from "react";
import { getAllHolidays } from "../../../actions/holidayActions";
import { useDispatch, useSelector } from "react-redux";
import AdminLHolidaysRow from "./AdminLHolidaysRow";
import moment from "moment";
// import EditModalHolidays from './EditModalHolidays'
const AdminLHolidays = () => {
  const [dropDownRow, setDropDownRow] = useState(null);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const holiday = useSelector((state) => state.holiday);
  const dispatch = useDispatch();
  // const [showDropDown, setshowDropDown] = useState(false)
  // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  // const openMoreVertDropDown = () => {
  //    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  // }
  // const openDropDown = () => {
  //    setshowDropDown((showDropDown) => !showDropDown)
  // }
  // console.log(showDropDown)
  // const openDeleteDropDown = () => {
  //    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  // }
  const holidayTable = async (e) => {
    dispatch(getAllHolidays({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    holidayTable();
  }, []);
  // let i=0
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Title </th>
                <th>Holiday Date</th>
                <th>Day</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {holiday.holidayTable &&
                holiday.holidayTable.length > 0 &&
                holiday.holidayTable.map((current, i) => {
                  return (
                    <AdminLHolidaysRow
                      current={current}
                      i={i}
                      dropDownRow={dropDownRow}
                      setDropDownRow={setDropDownRow}
                    />
                  );
                })}
            </tbody>
          </table>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default AdminLHolidays;

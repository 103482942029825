import React from 'react'
import { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import CreateCompanyNewLeads from './CreateCompanyNewLeads'
import moment from 'moment'
const AdminNewRequestTableRow = ({current, i, sNo}) => {
    const [showDropDown, setshowDropDown] = useState(false)
    // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    // const dispatch = useDispatch()
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // const openDeleteDropDown = () => {
    //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    // }
  return (
    <>
        <tr key={i}>
            <td>{sNo}</td>
            <td>{current.companyName}</td>
            <td>{current.firstName + " " + current.lastName}</td>
            <td>{current.packageId.planName}</td>
            <td>{current.currentSubscriptionPackageType}</td>
            <td>{moment(current.date.substring(0, 10)).format("MM-DD-YYYY")}</td>
            <td>${current.currentSubscriptionPackageType === 'yearly' ? 
            // (Math.round( current.packageId.amountYearlyTotal * 100 /100).toFixed(2)) : (Math.round( current.packageId.amountMonthlyTotal * 100 /100).toFixed(2)) }</td>
            current.packageId.amountYearlyTotal : current.packageId.amountMonthlyTotal}</td>
            <td>{current.isEmailVerified === true ? "Verified" : "Non-verfied"}</td>
            {/* <td className="text-right miinl">
                <div className="dropdown dropdown-action">
                    <a className="dropdown-item" to="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a>
                    <a className="dropdown-item" to="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a>
                </div>
            </td> */}
            <td className="text-right miinl"><NavLink to="#" className="btn btn-sm btn-primary" style={{backgroundColor:"#9ac748", borderColor: "#9ac748"}} onClick={openDropDown}>Create Customer</NavLink></td>
        </tr>
        <CreateCompanyNewLeads current={current} showDropDown={showDropDown} openDropDown={openDropDown}/> 
    </>
  )
}
export default AdminNewRequestTableRow
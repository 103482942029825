import {
    MAIL_REQUEST,
    MAIL_SUCCESS,
    MAIL_FAIL,
    MAIL_BY_ID_REQUEST,
    MAIL_BY_ID_SUCCESS,
    MAIL_BY_ID_FAIL,
    MAIL_CREATE_REQUEST,
    MAIL_CREATE_SUCCESS,
    MAIL_CREATE_FAIL,
    UPDATE_MAIL_BY_ID_REQUEST,
    UPDATE_MAIL_BY_ID_SUCCESS,
    UPDATE_MAIL_BY_ID_FAIL,
    UPDATE_MAILSEEN_BY_ID_REQUEST,
    UPDATE_MAILSEEN_BY_ID_SUCCESS,
    UPDATE_MAILSEEN_BY_ID_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const mailReducer = (
    state = {
        mail: {
            mailData: null,
            singleMail: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                mailData: null,
                singleMail: null,
            };

        // USER AUTH
        case MAIL_REQUEST : 
            return {
                ...state,
            };
        case MAIL_SUCCESS :
            return {
                ...state,
                mailData: action.payload,
            };
        case MAIL_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case MAIL_BY_ID_REQUEST : 
            return {
                ...state,
                singleMail: null,
            };
        case MAIL_BY_ID_SUCCESS :
            return {
                ...state,
                singleMail: action.payload,
            };
        case MAIL_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        case MAIL_CREATE_REQUEST : 
            return {
                ...state,
            };
        case MAIL_CREATE_SUCCESS :
            return {
                ...state,
                singleMail: action.payload,
                mailData: [...state.mailData, action.payload],
            };
        case MAIL_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_MAIL_BY_ID_REQUEST : 
            return {
                ...state,
            };
        case UPDATE_MAIL_BY_ID_SUCCESS :
            return {
                ...state,
                singleMail: action.payload,
                // mailData: action.payload,
            };
        case UPDATE_MAIL_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_MAILSEEN_BY_ID_REQUEST : 
            return {
                ...state,
            };
        case UPDATE_MAILSEEN_BY_ID_SUCCESS :
            return {
                ...state,
                singleMail: action.payload,
                mailData: state.mailData.map((x) =>
                x.id === action.payload.id ? action.payload : x
                ),
            };
        case UPDATE_MAILSEEN_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        default:
            return state;
    }
}
import axios from "axios";
import {
    ADMIN_DASHBOARD_CARD_REQUEST,
    ADMIN_DASHBOARD_CARD_SUCCESS,
    ADMIN_DASHBOARD_CARD_FAIL,
    ADMIN_REPORT_SHIFT_WEEKLY_REQUEST,
    ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS,
    ADMIN_REPORT_SHIFT_WEEKLY_FAIL,
    ADMIN_REPORT_SHIFT_DAILY_REQUEST,
    ADMIN_REPORT_SHIFT_DAILY_SUCCESS,
    ADMIN_REPORT_SHIFT_DAILY_FAIL,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL,
    ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST,
    ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS,
    ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL,
    ADMIN_USERPAYMENTINFO_REQUEST,
    ADMIN_USERPAYMENTINFO_SUCCESS,
    ADMIN_USERPAYMENTINFO_FAIL,
    ADMIN_SHIFTALLUSERS_REQUEST,
    ADMIN_SHIFTALLUSERS_SUCCESS,
    ADMIN_SHIFTALLUSERS_FAIL,
    SHIFT_UPDATE_REQUEST,
    SHIFT_UPDATE_SUCCESS,
    SHIFT_UPDATE_FAIL,
    SEARCH_EMPLOYEE_REQUEST,
    SEARCH_EMPLOYEE_SUCCESS,
    SEARCH_EMPLOYEE_FAIL,
    SHIFT_CREATE_REQUEST,
    SHIFT_CREATE_SUCCESS,
    SHIFT_CREATE_FAIL,
    SEARCH_REPORT_TIMECLOCK_REQUEST,
    SEARCH_REPORT_TIMECLOCK_SUCCESS,
    SEARCH_REPORT_TIMECLOCK_FAIL,
    SEARCH_SALARYCALENDAR_DATA_REQUEST,
    SEARCH_SALARYCALENDAR_DATA_SUCCESS,
    SEARCH_SALARYCALENDAR_DATA_FAIL,
    SEARCH_REPORT_SHIFT_REQUEST,
    SEARCH_REPORT_SHIFT_SUCCESS,
    SEARCH_REPORT_SHIFT_FAIL,
    SERVERADDRESS,
} from "../constants"

// ADMIN DASHBOARD CARD
export const adminDashboard = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_DASHBOARD_CARD_REQUEST,
    });
    try {
      
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/getAllCard`, obj);
        localStorage.setItem("LocationCount", JSON.stringify(data[0].LocationCount));
        localStorage.setItem("UserCount", JSON.stringify(data[0].UserCount));
        localStorage.setItem("TrainingCount", JSON.stringify(data[0].TrainingCount));
        localStorage.setItem("CurrTimeIn", JSON.stringify(data[0].CurrTimeIn));
        localStorage.setItem("ActiveUser", JSON.stringify(data[0].ActiveUser));
        localStorage.setItem("UpcomingShift", JSON.stringify(data[0].UpcomingShift))
        localStorage.setItem("AnnualAnnieversary", JSON.stringify(data[0].AnnualAnniversary))
        localStorage.setItem("LateComing", JSON.stringify(data[0].LateComing))
        localStorage.setItem("AbsUsers", JSON.stringify(data[0].AbsUsers))

        dispatch({
            type: ADMIN_DASHBOARD_CARD_SUCCESS,
            payload: data,
        });

    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ADMIN_DASHBOARD_CARD_FAIL,
            payload: err,
        });
    }
};


// ADMIN SHIFT REPORT WEEKLY
export const reportShiftWeekly = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_REPORT_SHIFT_WEEKLY_REQUEST,
    });
    try {
      
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportShift`, obj);
        localStorage.setItem("shiftReportWeekly", JSON.stringify(data));

        dispatch({
            type: ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS,
            payload: data,
        });

    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_REPORT_SHIFT_WEEKLY_FAIL,
            payload: err,
        });
    }
};

// ADMIN SHIFT REPORT DAILY
export const reportShiftDaily = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_REPORT_SHIFT_DAILY_REQUEST,
    });
    try {
      
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportShift`, obj);
        localStorage.setItem("shiftReportDaily", JSON.stringify(data));

        dispatch({
            type: ADMIN_REPORT_SHIFT_DAILY_SUCCESS,
            payload: data,
        });

    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_REPORT_SHIFT_DAILY_FAIL,
            payload: err,
        });
    }
};



// ADMIN SHIFT TIME_CLOCK WEEKLY
export const reportTimeClockWeekly = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportTimeClock`, obj);
        localStorage.setItem("timeClockReportWeekly", JSON.stringify(data));
        dispatch({
            type: ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL,
            payload: err,
        });
    }
};

// ADMIN SHIFT TIME_CLOCK DAILY
export const reportTimeClockDaily = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST,
    });
    try {
      
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportTimeClock`, obj);
        localStorage.setItem("timeClockReportDaily", JSON.stringify(data));

        dispatch({
            type: ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS,
            payload: data,
        });

    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL,
            payload: err,
        });
    }
};



// ADMIN PAYMENT INFO
export const getStripeInovicesQuery = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_USERPAYMENTINFO_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/userPaymentInfo/getStripeInovicesQuery`, obj);
        localStorage.setItem("paymentInvoice", JSON.stringify(data));
        dispatch({
            type: ADMIN_USERPAYMENTINFO_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_USERPAYMENTINFO_FAIL,
            payload: err,
        });
    }
};

// ADMIN SHIFTS USERS
export const getShiftAllUsers = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_SHIFTALLUSERS_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/getShiftAllUsers`, obj);
        localStorage.setItem("usersShifts", JSON.stringify(data));
        dispatch({
            type: ADMIN_SHIFTALLUSERS_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: ADMIN_SHIFTALLUSERS_FAIL,
            payload: err,
        });
    }
};

// ADMIN UPDATE SHIFT
export const updateShift = (obj) => async ( dispatch ) => {
    dispatch({
        type: SHIFT_UPDATE_REQUEST,
    })
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/shift/updateShift`, obj)
        dispatch({
            type: SHIFT_UPDATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_UPDATE_FAIL,
            payload: err,
        })
    }
}

// ADMIN EMPLOYEE SEARCH
export const employeeSearchFilter = (obj) => async ( dispatch ) => {
    dispatch({
        type: SEARCH_EMPLOYEE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/employeeSearchFilter`, obj)
        localStorage.setItem("searchEmployee", JSON.stringify(data));
    dispatch({
        type: SEARCH_EMPLOYEE_SUCCESS,
        payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: SEARCH_EMPLOYEE_FAIL,
        payload: err,
    })
}
}


// ADMIN ADD NEW SHIFT
export const addShift = (obj, nn) => async ( dispatch ) => {
    dispatch({
        type: SHIFT_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/shift/createShift`, obj)
        dispatch({
            type: SHIFT_CREATE_SUCCESS,
            payload: data,
            nnIndex: nn,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SHIFT_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN TIME CLOCK REPORT SEARCH 
export const reportTimeClockSearch = (obj) => async ( dispatch ) => {
    dispatch({
        type: SEARCH_REPORT_TIMECLOCK_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportTimeClockSearch`, obj)
        localStorage.setItem("searchReportTimeClock", JSON.stringify(data));
        dispatch({
            type: SEARCH_REPORT_TIMECLOCK_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SEARCH_REPORT_TIMECLOCK_FAIL,
            payload: err,
        })
    }
}

// ADMIN SALARY CALENDAR SEARCH 
export const salarySearchFilter = (obj) => async ( dispatch ) => {
    dispatch({
        type: SEARCH_SALARYCALENDAR_DATA_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/salarySearchFilter`, obj)
        localStorage.setItem("searchSalaryCalendarData", JSON.stringify(data));
        dispatch({
            type: SEARCH_SALARYCALENDAR_DATA_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SEARCH_SALARYCALENDAR_DATA_FAIL,
            payload: err,
        })
    }
}

// ADMIN SEARCH REPORT SEARCH 
export const reportShiftSearch = (obj) => async ( dispatch ) => {
    dispatch({
        type: SEARCH_REPORT_SHIFT_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/reportShiftSearch`, obj)
        localStorage.setItem("searchReportShift", JSON.stringify(data));
        dispatch({
            type: SEARCH_REPORT_SHIFT_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SEARCH_REPORT_SHIFT_FAIL,
            payload: err,
        })
    }
}

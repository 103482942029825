import React, { useState, useEffect } from "react";
import {
  getAdditionTypeByCompanyId,
  getDeductionTypeByCompanyId,
} from "../../../actions/payrollAction";
import { useDispatch, useSelector } from "react-redux";
import PayrollAdditionButtonRow from "./PayrollAdditionButtonRow";
// import { NavLink } from 'react-router-dom'
// import EditModalAdditionsPayroll from './EditModalAdditionsPayroll'
const PayrollAdditionButton = () => {
  // const [showDropDown, setshowDropDown] = useState(false)
  // const user = useSelector((state) => state.user)
  const auth = useSelector((state) => state.auth);
  const payroll = useSelector((state) => state.payroll);
  const [dropDownRow, setDropDownRow] = useState(null);
  const dispatch = useDispatch();
  const payrollAdditionTable = async (e) => {
    dispatch(getAdditionTypeByCompanyId({ companyId: auth.user.companyId }));
    dispatch(getDeductionTypeByCompanyId({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    payrollAdditionTable();
  }, []);
  return (
    <>
      <div className="tab-pane show" id="tab_additions">
        <div className="payroll-table card">
          {/* <div className="table-responsive"> */}
          <table className="table table-hover table-radius">
            <thead>
              <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Default/Unit Amount</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {payroll.payrollAdditionTable &&
                payroll.payrollAdditionTable.length > 0 &&
                payroll.payrollAdditionTable.map((current, i) => {
                  return (
                    <PayrollAdditionButtonRow
                      current={current}
                      i={i}
                      dropDownRow={dropDownRow}
                      setDropDownRow={setDropDownRow}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default PayrollAdditionButton;

import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
// import { useDispatch } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import { getContactUsById } from '../../../actions/contactusActions'

const SAdminContactUsModal = ({ openDropDown, showDropDown, current }) => {
  const [values, setValues] = useState({
    id: "",
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
    date: "",
  });
  // const params = useParams()
  const currentContactus = async () => {
    try {
      setValues({
        id: "",
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
        date: "",
      });
      setValues({
        id: current.id,
        name: current.name,
        email: current.email,
        subject: current.subject,
        phone: current.phone,
        message: current.message,
        date: current.date,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // const contactusData = async () =>{
  //     dispatch(getContactUsById({_id: id}))
  // }

  useEffect(() => {
    currentContactus();
    // contactusData()
  }, []);
  // const dispatch = useDispatch()

  // const contactUsData = {
  //   _id: values.id,
  //   name: values.name,
  //    }
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="edit_training"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Details</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="col-form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.name}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.email}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Contact</label>
                      <input
                        className="form-control"
                        disabled
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.phone}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Subject</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.subject}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Date</label>
                      <input
                        className="form-control"
                        type="text"
                        style={{ height: "44px", fontSize: "14px" }}
                        disabled
                        autoComplete="false"
                        value={moment(values.date).format("lll")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        rows="7"
                        disabled
                        autoComplete="false"
                        value={values.message}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SAdminContactUsModal;

// import { NavLink } from 'react-router-dom'
import React, { useState } from "react";
import { addMonthlySalary } from "../../../actions/payrollAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
const AddSalaryModal = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const payroll = useSelector((state) => state.payroll);
  const user = useSelector((state) => state.user);
  const [values, setValues] = useState({
    companyId: "",
    date: "",
    addition: [],
    deduction: [],
    month: "",
    year: "",
    userId: "",
  });
  const handleChange = (fieldName) => (event) => {
    // if (fieldName === "userId"){
    //     setIsUser('')
    // }
    // console.log("fffffffffffff", fieldName)
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const [additionObj, setAdditionObj] = useState([]);
  const handleAdditionChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.addition.includes(abc.id) === false) {
        // console.log(abc)
        setAdditionObj([...additionObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.addition, abc.id],
        });
      }
    }
  };
  const deleteAdditionHandler = (id) => {
    setAdditionObj(additionObj.filter((x) => x.id !== id));
    const index = values.addition.indexOf(id);
    let arr = values.addition;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, addition: arr });
    }
  };
  const [deductionObj, setDeductionObj] = useState([]);
  const handleDeductionChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.deduction.includes(abc.id) === false) {
        // console.log(abc)
        setDeductionObj([...deductionObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.deduction, abc.id],
        });
      }
    }
  };
  const deleteDeductionHandler = (id) => {
    setDeductionObj(deductionObj.filter((x) => x.id !== id));
    const index = values.deduction.indexOf(id);
    let arr = values.deduction;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, deduction: arr });
    }
  };
  const dispatch = useDispatch();
  const monthlysalaryData = {
    companyId: auth.user.companyId,
    date: moment().format("YYYY-MM-DD"),
    addition: values.addition.length ? values.addition : [],
    deduction: values.deduction.length ? values.deduction : [],
    month: values.month,
    year: parseInt(values.year),
    userId: values.userId,
  };
  // console.log(monthlysalaryData)
  const setValuesToInit = () => {
    setValues({
      companyId: "",
      date: "",
      addition: "",
      deduction: "",
      month: "",
      year: "",
      userId: "",
    });
    setDeductionObj([]);
    setAdditionObj([]);
    openDropDown();
  };
  const [isUser, setIsUser] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(addMonthlySalary(monthlysalaryData, setValuesToInit, setIsUser));
    // setIsUser(monthlysalaryData.userId)
    // openDropDown()
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_salary"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Staff Salary</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Select Staff</label>
                      {isUser === values.userId &&
                        payroll.error.response.data.message ===
                          "Salary Structure of User not defined" && (
                          <span className="text-danger">
                            &nbsp; Salary Structure of User not defined.
                          </span>
                        )}
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.userId}
                        onChange={handleChange("userId")}
                      >
                        <option value="">Select</option>
                        {user.allUsersWithoutPaginate &&
                          user.allUsersWithoutPaginate.length > 0 &&
                          user.allUsersWithoutPaginate.map((current, i) => {
                            return (
                              <option value={current.id} key={i + 1}>
                                {current.firstName + " " + current.lastName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                                        <label>Net Salary</label>
                                        <input className="form-control" type="text" />
                                    </div> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Select Month</label>
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        value={values.month}
                        onChange={handleChange("month")}
                      >
                        <option value="">-</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Year</label>
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.year}
                        onChange={handleChange("year")}
                      >
                        <option value="">-</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="text-primary">Addition</h4>
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      // value={values.addition}
                      onChange={handleAdditionChange("addition")}
                    >
                      <option value="">Select</option>
                      {payroll.payrollAdditionTable &&
                        payroll.payrollAdditionTable.length > 0 &&
                        payroll.payrollAdditionTable.map((current, i) => {
                          return (
                            <option
                              value={JSON.stringify({
                                id: current.id,
                                name: current.name,
                              })}
                              key={i + 1}
                            >
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                    {additionObj && additionObj.length > 0 && (
                      <div className="form-group">
                        <br />
                        <label>Addition Added</label>
                        <ul className="files-list">
                          {additionObj.map((elem, ind) => {
                            return (
                              <li className="" key={ind}>
                                {elem.name}
                                <i
                                  style={{ float: "right" }}
                                  className="fa fa-trash"
                                  onClick={() => deleteAdditionHandler(elem.id)}
                                ></i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    {/* <div className="form-group">
                                            <label>Basic Salary</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>DA(40%)</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>House Rent Allowance (H.R.A.)(15%)</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>Conveyance</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>Allowance</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Medical Allowance</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Add Addition Salary</label>
                                            <br /> */}
                    {/* <select className="select"> */}
                    {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}>
                                                <option>???</option>
                                            </select>
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Add Overtime</label>
                                            <br /> */}
                    {/* <div className="form-group">
                                            <label>Others</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="add-more">
                                            <NavLink to="#"><i className="fa fa-plus-circle"></i> Add More</NavLink>
                                        </div> */}
                  </div>
                  <div className="col-sm-6">
                    <h4 className="text-primary">Deductions</h4>
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      // value={values.deduction}
                      onChange={handleDeductionChange("deduction")}
                    >
                      <option value="">Select</option>
                      {payroll.payrollDeductionTable &&
                        payroll.payrollDeductionTable.length > 0 &&
                        payroll.payrollDeductionTable.map((current, i) => {
                          return (
                            <option
                              value={JSON.stringify({
                                id: current.id,
                                name: current.name,
                              })}
                              key={i + 1}
                            >
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                    {deductionObj && deductionObj.length > 0 && (
                      <div className="form-group">
                        <br />
                        <label>Deduction Added</label>
                        <ul className="files-list">
                          {deductionObj.map((elem, ind) => {
                            return (
                              <li key={ind}>
                                {elem.name}
                                <i
                                  style={{ float: "right" }}
                                  className="fa fa-trash"
                                  onClick={() =>
                                    deleteDeductionHandler(elem.id)
                                  }
                                ></i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    {/* <div className="form-group">
                                            <label>Deduction Salary</label>
                                            <br /> */}
                    {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}>
                                                <option>???</option>
                                                <option>???</option>
                                            </select>
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Tax Deducted at Source (T.D.S.)</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>ESI</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>Provident Fund</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Leave</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Prof. Tax</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Labour Welfare</label>
                                            <input className="form-control" type="text" />
                                        </div> */}
                    {/* <div className="form-group">
                                            <label>Loan</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="form-group">
                                            <label>Others</label>
                                            <input className="form-control" type="text" />
                                        </div>
                                        <div className="add-more">
                                            <NavLink to="#"><i className="fa fa-plus-circle"></i> Add More</NavLink>
                                        </div> */}
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddSalaryModal;

import axios from "axios";
import {
  CLOCK_IN_REQUEST,
  CLOCK_IN_SUCCESS,
  CLOCK_IN_FAIL,
  CLOCK_OUT_REQUEST,
  CLOCK_OUT_SUCCESS,
  CLOCK_OUT_FAIL,
  CLOCK_EXIST_REQUEST,
  CLOCK_EXIST_SUCCESS,
  CLOCK_EXIST_FAIL,
  CLOCK_STATUS_REQUEST,
  CLOCK_STATUS_SUCCESS,
  CLOCK_STATUS_FAIL,
  CLOCK_MONTHLY_DATA_REQUEST,
  CLOCK_MONTHLY_DATA_SUCCESS,
  CLOCK_MONTHLY_DATA_FAIL,
  CLOCK_ATTENDANCE_REQUEST,
  CLOCK_ATTENDANCE_SUCCESS,
  CLOCK_ATTENDANCE_FAIL,
  ADMIN_CLOCK_OUT_REQUEST,
  ADMIN_CLOCK_OUT_SUCCESS,
  ADMIN_CLOCK_OUT_FAIL,
  MONTHLY_ATTENDANCE_SEARCH_REQUEST,
  MONTHLY_ATTENDANCE_SEARCH_SUCCESS,
  MONTHLY_ATTENDANCE_SEARCH_FAIL,
  SERVERADDRESS,
} from "../constants";
import toast from "react-hot-toast";

// USER CLOCK IN
export const clockIn = (obj) => async (dispatch) => {
  dispatch({
    type: CLOCK_IN_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/clock/clockIn`, obj);

    if (data !== "exists") {
      localStorage.setItem("clockData", JSON.stringify(data));
      localStorage.setItem("clockInButton", false);
      localStorage.setItem("breakButton", true);
    }
    dispatch({
      type: CLOCK_IN_SUCCESS,
      payload: data,
    });
    toast.success("Clock in successful, time tracking started!");
  } catch (err) {
    console.log(err);
    if (
      err.response.data.message ===
      "Location is not define for user to clocked in"
    ) {
      toast.error("GPS data shows you are not in the facility!");

      // window.alert("GPS data shows you are not in the facility!")
      dispatch({
        type: CLOCK_IN_FAIL,
        payload: err.response.data.message,
      });
    } else if (err.response.data.message === "Not a valid time frame!") {
      toast.error("No shift is available for you!");

      //   window.alert("Not a valid time frame!");
      dispatch({
        type: CLOCK_IN_FAIL,
        payload: err.response.data.message,
      });
    } else {
      toast.error(err.response.data.message);

      dispatch({
        type: CLOCK_IN_FAIL,
        payload: err,
      });
    }
  }
};

// USER CLOCK OUT
export const clockUpdate = (obj) => async (dispatch) => {
  // console.log("clock payload==>", obj);
  dispatch({
    type: CLOCK_OUT_REQUEST,
  });
  try {
    const { data, status } = await axios.put(
      `${SERVERADDRESS}/v1/clock/updateClock`,
      obj
    );
    localStorage.setItem("clockData", JSON.stringify(data));
    if (status === 200) {
      if (data.clockedIn === false) {
        localStorage.setItem("clockInButton", true);
        localStorage.setItem("breakButton", null);
      } else {
        localStorage.setItem("clockInButton", false);
        localStorage.setItem("breakButton", true);
      }
    }
    dispatch({
      type: CLOCK_OUT_SUCCESS,
      payload: data,
    });
    if (obj.clockIn)
      toast.success("Clock in successful, time tracking started!");
    else if (obj.clockOut)
      toast.success("Clock out successful, time tracking stopped!");
  } catch (err) {
    toast.err(err.response.data.message);

    console.log(err);
    dispatch({
      type: CLOCK_OUT_FAIL,
      payload: err,
    });
  }
};

// USER CLOCK CHECK BY DAYIDENTIFIER
export const clockExist = (obj) => async (dispatch) => {
  dispatch({
    type: CLOCK_EXIST_REQUEST,
  });
  try {
    const { data, status } = await axios.post(
      `${SERVERADDRESS}/v1/clock/getClockByDayIdentifier`,
      obj
    );
    if (status === 200) {
      localStorage.setItem("clockData", JSON.stringify(data));
      if (data.clockedIn === false) {
        localStorage.setItem("clockInButton", true);
        localStorage.setItem("breakButton", null);
      } else {
        localStorage.setItem("clockInButton", false);
        localStorage.setItem("breakButton", true);
      }
    }
    dispatch({
      type: CLOCK_EXIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // toast.error(err.response.data.message);

    console.log(err);
    localStorage.removeItem("clockData");
    dispatch({
      type: CLOCK_EXIST_FAIL,
      payload: err,
    });
  }
};

// MONTHLY AND WEEK CLOCK STATUS OF USER
export const monthlyClockStatusById = (obj) => async (dispatch) => {
  dispatch({
    type: CLOCK_STATUS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/clock/monthlyClockStatusById`,
      obj
    );
    localStorage.setItem("clockStatusData", JSON.stringify(data));
    dispatch({
      type: CLOCK_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({
      type: CLOCK_STATUS_FAIL,
      payload: err,
    });
  }
};

// MONTHLY CLOCK DATA OF USER
export const getClocksByMonthYearAndUserId = (obj) => async (dispatch) => {
  dispatch({
    type: CLOCK_MONTHLY_DATA_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/clock/getClocksByMonthYearAndUserId`,
      obj
    );
    localStorage.setItem("clockMonthlyData", JSON.stringify(data));
    dispatch({
      type: CLOCK_MONTHLY_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({
      type: CLOCK_MONTHLY_DATA_FAIL,
      payload: err,
    });
  }
};

// ADMIN ATTENDANCE REPORT API
export const monthlyAttendance = (obj) => async (dispatch) => {
  dispatch({
    type: CLOCK_ATTENDANCE_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/clock/monthlyAttendance`,
      obj
    );
    localStorage.setItem("clockAttendance", JSON.stringify(data));
    dispatch({
      type: CLOCK_ATTENDANCE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({
      type: CLOCK_ATTENDANCE_FAIL,
      payload: err,
    });
  }
};

// ADMIN CLOCK OUT
export const clockedOutByAdmin = (obj) => async (dispatch) => {
  dispatch({
    type: ADMIN_CLOCK_OUT_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/clock/clockedOutByAdmin`,
      obj
    );
    dispatch({
      type: ADMIN_CLOCK_OUT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    toast.error(err.response.data.message);

    console.log(err);
    dispatch({
      type: ADMIN_CLOCK_OUT_FAIL,
      payload: err,
    });
  }
};

// CLOCK SEARCH BY USING MONTHLY ATTENDANCE KPI ADMIN DEVELOPED IN CLOCK
export const monthlyAttendanceSearch = (obj) => async (dispatch) => {
  dispatch({
    type: MONTHLY_ATTENDANCE_SEARCH_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/clock/monthlyAttendanceSearch`,
      obj
    );
    dispatch({
      type: MONTHLY_ATTENDANCE_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (err) {
    toast.error(err.response.data.message);

    console.log(err);
    dispatch({
      type: MONTHLY_ATTENDANCE_SEARCH_FAIL,
      payload: err,
    });
  }
};
import moment from "moment";
import React from "react";
import { useState } from "react";

const AdminLoginInfoTable = ({ current, i }) => {
  const [showAccordion, setShowAccordion] = useState(false);
  const openAccordion = () => {
    setShowAccordion((state) => !state);
  };
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString.substring(11, 13));
    const minutes = timeString.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <>
      <tr onClick={openAccordion} key={i} style={{ cursor: "pointer" }}>
        <td style={{ minWidth: "16.66%", maxWidth: "16.66%" }}>
          {current.company.companyName}
        </td>
      </tr>
      {current.loggedInInfo &&
        current.loggedInInfo.length > 0 &&
        current.loggedInInfo.map((item, it) => {
          // company.adminLoginInfo &&
          // company.adminLoginInfo.length > 0 &&
          // company.adminLoginInfo.map((current, i) => {
          return (
            showAccordion === true && (
              <tr>
                <td>{current.company.companyName}</td>
                <td>{item.userId.firstName + " " + item.userId.lastName}</td>
                <td>{item.userId.role}</td>
                <td>{item.country}</td>
                <td>{item.ipAddress}</td>
                <td>
                  {moment(item.date.toString().substring(0, 10)).format(
                    "MM/DD/YYYY"
                  ) + ", "}{" "}
                  {formatTime(item.date)}
                  {/* {item.date.substring(11, 16)}{" "}
                  {parseInt(item.date.substring(11, 13)) > 12 ? "PM" : "AM"} */}
                </td>
              </tr>
            )
          );
        })}
      {/* <tr className={`${showAccordion ? 'show' : 'hide'} `}>
            <td >{current.company.companyName}</td>
            <td>{current.companycompanyName}</td>
            <td>{current.userId.firstName + " " + current.userId.lastName}</td>
            <td>{current.userId.role}</td>
            <td>{current.country}</td>
            <td>{current.ipAddress}</td>
            <td>{moment(current.date.toString().substring(0,10)).format('MMMM Do YYYY') +", "} {current.date.substring(11, 16)} {parseInt(current.date.substring(11, 13)) > 12 ? "PM" : "AM"}</td>
            </tr> */}
    </>
  );
};

export default AdminLoginInfoTable;

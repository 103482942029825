import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../../actions/authActions'
// import { useEffect } from 'react';
const NewPasswordForm = () => {
    // const auth = useSelector((state) => state.auth);
    const params = useParams()
    let navigate = useNavigate()
    const [ values, setValues ] = useState({
        password: '',
        // token: '',
    })
    // useEffect(() => {
    //     if (params.id) {
    //         setValues({
    //             ...values, token :
    //             params.id
    //         })
    //     } 
    // }, [params.id]);
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const dispatch = useDispatch()
    const userData = {
        password: values.password
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(resetPassword(userData, params.id, navigate))
    }
    return (
        <>
            <div className="form-section">
                <div className="wrapper-login">
                    <div id="formContent-login" style={{maxWidth: '600px'}}>
                        <form onSubmit={handleSubmit}>
                            <h2 className="text-center h2-tag-login"
                                style={{
                                    textAlign: "center",
                                    fontSize: "25px",
                                    fontWeight: "600",
                                    textTransform: "uppercase",
                                    display: "inline-block",
                                    margin: "40px 8px 10px 8px",
                                    color: "#32895E"
                            }}>Enter New Password</h2>
                           <input type="password" id="password" className="testing-login-input" name="login"
                            placeholder="Must contain a number and an alphabet. Minimum 8 characters"
                            pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                            style={{padding: '15px 3px'}}
                            autoComplete="false" 
                            value={values.password}
                            onChange={handleChange('password')}
                            />
                            <br />
                            <br />
                            <input type="submit" className="testing-login-input" value="Change Password" />
                        </form>
                        <div id="formFooter-login"></div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewPasswordForm
import {
    ANNIVARSARY_REQUEST,
    ANNIVARSARY_SUCCESS,
    ANNIVARSARY_FAIL,
    COMPANY_USERS_REQUEST,
    COMPANY_USERS_SUCCESS,
    COMPANY_USERS_FAIL,
    COMPANY_USERS_QUERY_REQUEST,
    COMPANY_USERS_QUERY_SUCCESS,
    COMPANY_USERS_QUERY_FAIL,
    EMPLOYEE_CREATE_REQUEST,
    EMPLOYEE_CREATE_SUCCESS,
    EMPLOYEE_CREATE_FAIL,
    USER_NON_ACTIVE_REQUEST,
    USER_NON_ACTIVE_SUCCESS,
    USER_NON_ACTIVE_FAIL,
    USER_ACTIVE_REQUEST,
    USER_ACTIVE_SUCCESS,
    USER_ACTIVE_FAIL,
    USER_BY_ID_REQUEST,
    USER_BY_ID_SUCCESS,
    USER_BY_ID_FAIL,
    ALL_USER_BY_ID_REQUEST,
    ALL_USER_BY_ID_SUCCESS,
    ALL_USER_BY_ID_FAIL,
    EMPLOYEE_LAST_ID_REQUEST,
    EMPLOYEE_LAST_ID_SUCCESS,
    EMPLOYEE_LAST_ID_FAIL,
    UPDATE_USER_BY_ADMIN_REQUEST,
    UPDATE_USER_BY_ADMIN_SUCCESS,
    UPDATE_USER_BY_ADMIN_FAIL,
    REMOVE_USER_BY_ADMIN_REQUEST,
    REMOVE_USER_BY_ADMIN_SUCCESS,
    REMOVE_USER_BY_ADMIN_FAIL,
    GET_ALL_COMPANY_ADMIN_REQUEST,
    GET_ALL_COMPANY_ADMIN_SUCCESS,
    GET_ALL_COMPANY_ADMIN_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const userReducer = (
    state = {
        user: {
            annivarsary: null,
            allUsers: null,
            nonActive: null,
            active: null,
            userById: null,
            allUsersWithoutPaginate: null,
            lastEmployeeId: null,
            loading: false,
            getallCompanyAdmins : null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                annivarsary: null,
                allUsers: null,
                nonActive: null,
                active: null,
                userById: null,
                allUsersWithoutPaginate: null,
                lastEmployeeId: null,
            };

        // USER AUTH
        case ANNIVARSARY_REQUEST : 
            return {
                ...state,
            };
        case ANNIVARSARY_SUCCESS :
            return {
                ...state,
                annivarsary: action.payload,
            };
        case ANNIVARSARY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        case COMPANY_USERS_REQUEST : 
            return {
                ...state,
            };
        case COMPANY_USERS_SUCCESS :
            return {
                ...state,
                allUsers: action.payload,
            };
        case COMPANY_USERS_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        case COMPANY_USERS_QUERY_REQUEST : 
            return {
                ...state,
            };
        case COMPANY_USERS_QUERY_SUCCESS :
            return {
                ...state,
                allUsers: action.payload,
            };
        case COMPANY_USERS_QUERY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
            
        case EMPLOYEE_CREATE_REQUEST :
            return {
                ...state,
            };
        case EMPLOYEE_CREATE_SUCCESS :
            return {
                ...state,
                // allUsers: [...state.allUsers, action.payload],    
                // allUsers: {...state.allUsers, results:state.allUsers.results.map((x) => x.id === action.payload.id ? action.payload : x)},    
                // allUsersWithoutPaginate: [...state.allUsersWithoutPaginate, action.payload],
                // lastEmployeeId: action.payload.employeeId,
                // (parseInt(action.payload.employeeId) +1).toString().length === 1 ?
                // "00"+ (parseInt(action.payload.employeeId) +1).toString() : 
                // (parseInt(action.payload.employeeId) +1).toString().length === 2 ?
                // "0"+ (parseInt(action.payload.employeeId) +1).toString() : 
                // (parseInt(action.payload.employeeId) +1).toString() ,
                allUsersWithoutPaginate: [...state.allUsersWithoutPaginate, action.payload],
                // allUsersWithoutPaginate: state.allUsersWithoutPaginate.map((x) =>
                // x.id === action.payload.id ? action.payload : x
                // ),
            };
        case EMPLOYEE_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case USER_NON_ACTIVE_REQUEST : 
            return {
                ...state,
            };
        case USER_NON_ACTIVE_SUCCESS :
            return {
                ...state,
                nonActive: action.payload,
                
            };
        case USER_NON_ACTIVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case USER_ACTIVE_REQUEST : 
            return {
                ...state,
            };
        case USER_ACTIVE_SUCCESS :
            return {
                ...state,
                active: action.payload,
            
            };
        case USER_ACTIVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // ADMIN GET USER BY ID
        case USER_BY_ID_REQUEST : 
            return {
                ...state,
                userById: null,
            };
        case USER_BY_ID_SUCCESS :
            return {
                ...state,
                userById: action.payload,
            };
        case USER_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // ADMIN GET USER BY ID
        case ALL_USER_BY_ID_REQUEST : 
        return {
            ...state,
        };
        case ALL_USER_BY_ID_SUCCESS :
        return {
            ...state,
            allUsersWithoutPaginate: action.payload,
        };
        case ALL_USER_BY_ID_FAIL :
        return {
            ...state,
            error: action.payload,
        };


        case EMPLOYEE_LAST_ID_REQUEST : 
            return {
                ...state,
            };
        case EMPLOYEE_LAST_ID_SUCCESS :
            return {
                ...state,
                lastEmployeeId: action.payload.employeeId,
            };
        case EMPLOYEE_LAST_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case UPDATE_USER_BY_ADMIN_REQUEST : 
            return {
                ...state,
            };
        case UPDATE_USER_BY_ADMIN_SUCCESS :
            return {
                ...state,
            allUsers: {...state.allUsers, results:state.allUsers.results.map((x) => x.id === action.payload.id ? action.payload : x)},
            active: {...state.allUsers, results:state.active.results.map((x) => x.id === action.payload.id ? action.payload : x)},
            nonActive: {...state.allUsers, results:state.nonActive.results.map((x) => x.id === action.payload.id ? action.payload : x)},
            allUsersWithoutPaginate: state.allUsersWithoutPaginate.map((x) =>
            x.id === action.payload.id ? action.payload : x
          ),    
        };
        case UPDATE_USER_BY_ADMIN_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case REMOVE_USER_BY_ADMIN_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_USER_BY_ADMIN_SUCCESS :
            return {
                ...state,
                // userById: action.payload,
                // allUsers: state.allUsers.results.filter((x) => x.id !== action.payload.id),
                // nonActive: state.nonActive.results.filter((x) => x.id !== action.payload.id),
                // active: state.active.results.filter((x) => x.id !== action.payload.id),
                allUsersWithoutPaginate: state.allUsersWithoutPaginate.filter((x) => x.id !== action.payload.id),
            };
        case REMOVE_USER_BY_ADMIN_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // GET ALL COMPANY ADMIN 
        case GET_ALL_COMPANY_ADMIN_REQUEST :
            return {
                ...state,
            };
        case GET_ALL_COMPANY_ADMIN_SUCCESS :
            return {
                ...state,
                getallCompanyAdmins : action.payload, 
            };
        case GET_ALL_COMPANY_ADMIN_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}
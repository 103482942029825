// import React, { useState } from 'react'
import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
const TrainingTableRow = ({ current, i }) => {
  return (
    <>
      <tr key={i}>
        <td>{++i}</td>
        <td>{current.trainingName}</td>
        <td>{current.trainingType}</td>
        <td>
          {/* <ul className="team-members">
                        <li>
                            <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip"><img alt="" src="assetsAdmin/img/avatar-21.jpg" /></NavLink>
                        </li>
                        <li>
                            <NavLink to="#" title="Richard Miles" data-toggle="tooltip"><img alt="" src="assetsAdmin/img/avatar-21.jpg" /></NavLink>
                        </li>
                        <li className="dropdown avatar-dropdown">
                            <NavLink to="#" className="all-users dropdown-toggle" data-toggle="dropdown" aria-expanded="false">+15</NavLink>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="avatar-group">
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                    </ul> */}
        </td>
        <td>
          {moment(current.startDate.substring(0, 10)).format("MM/DD/YYYY")} -{" "}
          {moment(current.endDate.substring(0, 10)).format("MM/DD/YYYY")}
        </td>
        <td>
          {current.description
            ? current.description.length <= 15
              ? current.description
              : current.description.substring(0, 15) + "..."
            : "-"}
        </td>
        {/* <td> */}
        {/* status ki kiya need hai jani? */}
        {/* <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> {current.status === true ? "Active" : "Non-Active"} <i className="fa fa-circle-o text-success"></i> </NavLink> */}
        {/* <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> {current.status} <i className="fa fa-circle-o text-success"></i> </NavLink> */}
        {/* </td> */}
        <td className="text-center">
          {current.status === "Rejected" ? (
            <div className="action-label">
              {/* <NavLink className="btn btn-white btn-sm btn-rounded" to="javascript:void(0);"> */}
              <NavLink className="btn btn-white btn-sm btn-rounded">
                <i className="fa fa-dot-circle-o text-danger"></i> Declined
              </NavLink>
            </div>
          ) : current.status === "Accepted" ? (
            <div className="action-label">
              <a className="btn btn-white btn-sm btn-rounded">
                <i className="fa fa-dot-circle-o text-success"></i> Approved
              </a>
            </div>
          ) : (
            <div className="action-label">
              &nbsp; &nbsp; &nbsp;
              <a className="btn btn-white btn-sm btn-rounded">
                <i className="fa fa-dot-circle-o text-purple"></i> Pending
              </a>
            </div>
          )}
        </td>
        <td className="text-right miinl">
          {/* <div className="dropdown dropdown-action"> */}
          {/* <a className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a> */}
          {/* <a className="dropdown-item" to="#" data-toggle="modal" data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a> */}
          {/* </div> */}
        </td>
      </tr>
    </>
  );
};
export default TrainingTableRow;

import React, { useState, useEffect } from 'react'
import { getDeductionTypeByCompanyId } from '../../../actions/payrollAction'
import { useDispatch, useSelector } from 'react-redux'
import PayrollDeductionButtonRow from './PayrollDeductionButtonRow'
// import { LEAVE_ANNUAL_STATUS_FAIL } from '../../../constants'

const PayrollDeductionButton = () => {
    const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const payroll = useSelector((state) => state.payroll)
    const dispatch = useDispatch()
    const [ dropDownRow, setDropDownRow ] = useState(null)
    const payrollDeductionTable = async (e) => {
        dispatch(getDeductionTypeByCompanyId({companyId: auth.user.companyId }))
    }
    useEffect(() => {
        payrollDeductionTable()
    }, [])
    return (
        <>
        <div className="tab-pane" id="tab_deductions">
            <div className="payroll-table card">
                {/* <div className="table-responsive"> */}
                    <table className="table table-hover table-radius">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Default/Unit Amount</th>
                                <th className="text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                payroll.payrollDeductionTable &&
                                payroll.payrollDeductionTable.length > 0 &&
                                payroll.payrollDeductionTable.map((current, i) =>{ 
                                    return (
                                        <PayrollDeductionButtonRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        {/* </div> */}
        </>
    )
}
export default PayrollDeductionButton
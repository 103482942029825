import axios from "axios"
import {
    MESSAGE_WALL_CREATE_REQUEST,
    MESSAGE_WALL_CREATE_SUCCESS,
    MESSAGE_WALL_CREATE_FAIL,
    MESSAGE_WALL_TABLE_REQUEST,
    MESSAGE_WALL_TABLE_SUCCESS,
    MESSAGE_WALL_TABLE_FAIL,
    MESSAGE_WALL_UPDATE_REQUEST,
    MESSAGE_WALL_UPDATE_SUCCESS,
    MESSAGE_WALL_UPDATE_FAIL,
    REMOVE_MESSAGE_WALL_REQUEST,
    REMOVE_MESSAGE_WALL_SUCCESS,
    REMOVE_MESSAGE_WALL_FAIL,
    COMMENT_CREATE_REQUEST,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_FAIL,
    COMMENT_TABLE_REQUEST,
    COMMENT_TABLE_SUCCESS,
    COMMENT_TABLE_FAIL,
    COMMENT_UPDATE_REQUEST,
    COMMENT_UPDATE_SUCCESS,
    COMMENT_UPDATE_FAIL,
    REMOVE_COMMENT_REQUEST,
    REMOVE_COMMENT_SUCCESS,
    REMOVE_COMMENT_FAIL,
    SERVERADDRESS,
} from "../constants"

// ADMIN ADD NEW MESSAGE_WALL
export const createMessageWall = (obj) => async ( dispatch ) => {
    dispatch({
        type: MESSAGE_WALL_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/message-wall/createMessageWall`, obj)
        // console.log('API Response:', data); 
        dispatch({
            type: MESSAGE_WALL_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        // console.error('Error creating policy:', err)
        console.log(err)
        dispatch({
            type: MESSAGE_WALL_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN GET MESSAGE_WALL TABLE
export const getMessageWallById = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: MESSAGE_WALL_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/message-wall/getMessageWallById`, obj);
        localStorage.setItem("getMessageWallById", JSON.stringify(data));
        dispatch({
            type: MESSAGE_WALL_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MESSAGE_WALL_TABLE_FAIL,
            payload: err,
        });
    }
};



// UPDATE MESSAGE_WALL
export const updateMessageWall = (obj) => async (dispatch) => {
    dispatch({
        type: MESSAGE_WALL_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/message-wall/updateMessageWall`, obj);
        dispatch({
            type: MESSAGE_WALL_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MESSAGE_WALL_UPDATE_FAIL,
            payload: err,
        });
    }
};


// REMOVE USER BY ADMIN
export const removeMessageWall = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_MESSAGE_WALL_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/message-wall/updateMessageWall`, obj);
        dispatch({
            type: REMOVE_MESSAGE_WALL_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_MESSAGE_WALL_FAIL,
            payload: err,
        });
    }
};


// ADMIN ADD NEW COMMENT
export const createComment = (obj) => async ( dispatch ) => {
    dispatch({
        type: COMMENT_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/message-wall/createComment`, obj)
        // console.log('API Response:', data); 
        dispatch({
            type: COMMENT_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        // console.error('Error creating policy:', err)
        console.log(err)
        dispatch({
            type: COMMENT_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN GET COMMENT TABLE
export const getCommentById = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: COMMENT_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/message-wall/getCommentById`, obj);
        localStorage.setItem("getCommentById", JSON.stringify(data));
        dispatch({
            type: COMMENT_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: COMMENT_TABLE_FAIL,
            payload: err,
        });
    }
};



// UPDATE COMMENT
export const updateComment = (obj) => async (dispatch) => {
    dispatch({
        type: COMMENT_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/message-wall/updateComment`, obj);
        dispatch({
            type: COMMENT_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: COMMENT_UPDATE_FAIL,
            payload: err,
        });
    }
};


// REMOVE USER BY ADMIN
export const removeComment = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_COMMENT_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/message-wall/updateComment`, obj);
        dispatch({
            type: REMOVE_COMMENT_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_COMMENT_FAIL,
            payload: err,
        });
    }
};
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStripeSubscription } from "../../../actions/superAdminCompanyActions";

const ChangePlanModel = ({ current, openDropDown, showDropDown }) => {
  // const auth = useSelector((state) => state.auth);
  const allPackage = useSelector((state) => state.package);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    companyId: "",
    packageId: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  useEffect(() => {
    if (current) {
      setValues({
        companyId: current.company.companyId.id,
        packageId: current.company.currentSubscriptionPackage.id,
      });
    }
  }, [current]);
  const packageData = {
    companyId: values.companyId,
    packageId: values.packageId,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateStripeSubscription(packageData));
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="upgrade_plan"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => openDropDown()}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="modal-body">
              <h5 className="modal-title text-center mb-3">Upgrade Plan</h5>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Change Plan</label>
                      {current &&
                      current.company &&
                      current.company.currentSubscriptionPackageType ===
                        "monthly" ? (
                        <select
                          className="form-select"
                          value={values.packageId}
                          onChange={handleChange("packageId")}
                        >
                          {allPackage.package &&
                            allPackage.package.length > 0 &&
                            allPackage.package.map((current, i) => {
                              return (
                                current.toBeDisplay === true && (
                                  <option value={current.id}>
                                    {current.planName +
                                      " monthly $" +
                                      current.amountMonthly}
                                  </option>
                                )
                              );
                            })}
                        </select>
                      ) : (
                        <select
                          className="form-select"
                          value={values.packageId}
                          onChange={handleChange("packageId")}
                        >
                          {allPackage.package &&
                            allPackage.package.length > 0 &&
                            allPackage.package.map((current, i) => {
                              return (
                                current.toBeDisplay === true && (
                                  <option value={current.id}>
                                    {current.planName +
                                      " annually $" +
                                      current.amountYearly}
                                  </option>
                                )
                              );
                            })}
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                <div className="m-t-20 text-center">
                  <button className="btn btn-primary submit-btn">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePlanModel;

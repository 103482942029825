import React from 'react'
import FileManagerMain from '../../components/employeeComponents/employeeFileManager/FileManagerMain'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'

const EmployeeFileManager = () => {
    return (
        <>
            {/* <EmployeeHeader />
            <EmployeeSidebar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row">
                            <div class="col-sm-12">
                                <div class="file-wrap">
                                    <FileManagerMain />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeFileManager
import React, { useState} from 'react'
import { addTheSalary } from '../../../actions/salaryActions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// import SalaryInfoViewFromAdmin from './SalaryInfoViewFromAdmin'

const SalaryStructure = () => {
    // const salary = useSelector((state) => state.salary)
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const params = useParams();
    const [ values, setValues ] = useState({
        companyId: '',
        userId: '',
        basicSalary: '',
        houseRentAllowance: '',
        conveyance: '',
        otherAllowance: '',
        taxDeductedAtSource: '',
        providentFund: '',
        esi: '',
        loan: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    const theSalaryData = {
        companyId: auth.user.companyId,
        userId: params.id,
        basicSalary: parseInt(values.basicSalary),
        houseRentAllowance: parseInt(values.houseRentAllowance),
        conveyance: parseInt(values.conveyance),
        otherAllowance: parseInt(values.otherAllowance),
        taxDeductedAtSource: parseInt(values.taxDeductedAtSource),
        providentFund: parseInt(values.providentFund),
        esi: parseInt(values.esi),
        loan: parseInt(values.loan),
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(addTheSalary(theSalaryData))
        // openDropDown()
        setValues({
            companyId: '',
            userId: '',
            basicSalary: '',
            houseRentAllowance: '',
            conveyance: '',
            otherAllowance: '',
            taxDeductedAtSource: '',
            providentFund: '',
            esi: '',
            loan: '',
        })
    }
    return (
        <>
            <div className="tab-content">
                <div className="" id="bank_statutory">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title"> Salary Information</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h4 className="text-primary">Earnings</h4>
                                        <div className="form-group">
                                            <label>Basic Salary</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.basicSalary}
                                            onChange={handleChange('basicSalary')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>House Rent Allowance (H.R.A.)</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.houseRentAllowance}
                                            onChange={handleChange('houseRentAllowance')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Conveyance</label>
                                            <input className="form-control" type="text"
                                            autoComplete="false" value={values.conveyance}
                                            onChange={handleChange('conveyance')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Other Allowance</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.otherAllowance}
                                            onChange={handleChange('otherAllowance')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Total Earnings</label>
                                            <input className="form-control" type="text" disabled/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <h4 className="text-primary">Deductions</h4>
                                        <div className="form-group">
                                            <label>Tax Deducted at Source (T.D.S.)</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.taxDeductedAtSource}
                                            onChange={handleChange('taxDeductedAtSource')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Provident Fund</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.providentFund}
                                            onChange={handleChange('providentFund')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>ESI</label>
                                            <input className="form-control" type="text"
                                            autoComplete="false" value={values.esi}
                                            onChange={handleChange('esi')} />
                                        </div>
                                        <div className="form-group">
                                            <label>Loan</label>
                                            <input className="form-control" type="text"
                                            autoComplete="false" value={values.loan}
                                            onChange={handleChange('loan')}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Total Deductions</label>
                                            <input className="form-control" type="text" disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SalaryStructure
import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import { useSelector } from 'react-redux'
import LocationTable from '../../components/adminComponents/adminLocationAdministration/LocationTable'
// import FileManagerMain from '../../components/adminComponents/adminLocationAdministration/FileManagerMain'
const AdminLocationAdministration = () => {
    // const myState = useSelector((state => state.changeTheTab))
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                    <AdminTittle title="Location"/>
                    <LocationTable />
                    {/* {
                    myState.viewLocationAdministrationMenu === "location" ?
                    <>
                    <AdminTittle title="Location" location/>
                    <LocationTable />
                    </> 
                    : myState.viewLocationAdministrationMenu === "filemanager" ?
                    <>
                    <AdminTittle title="File Manager" location/>
                    <FileManagerMain />
                    </>
                    :
                    <div>Loading</div>
                    } */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminLocationAdministration
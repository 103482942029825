import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { BUCKET_BASE_URL } from "../../constants";
import defaultImg from "../../assetsAdmin/img/avatarProfile.png";

function MessageItem({ chatMessage }) {
  const auth = useSelector((state) => state.auth);

  const timeNow = new Date();
  const messageTime = new Date(chatMessage.dateTime);
  const secondsDiff = Math.round((timeNow - messageTime) / 1000);
  const hoursDiff = Math.round(secondsDiff / 60 / 60);

  let time = "";
  if (secondsDiff < 60) {
    time = "Just Now";
  } else {
    if (secondsDiff < 600) {
      time = Math.round(secondsDiff / 60) + " mins ago";
    } else {
      if (hoursDiff > 24) {
        time = moment(messageTime).format("ddd, hh:mm a");
      } else {
        // console.log("messageTIme==>", messageTime);
        time = moment(messageTime).format("hh:mm a");
      }
    }
  }
  //   console.log("first==>", timeNow, "second==>", messageTime, secondsDiff);

  return (
    <li
      class={`d-flex mb-1 gap-2 ${
        chatMessage?.sender?.id === auth.user.id ? "flex-row-reverse" : ""
      }`}
    >
      <img
        src={
          chatMessage.sender?.profilePic
            ? BUCKET_BASE_URL + chatMessage.sender?.profilePic?.fileUrl
            : defaultImg
        }
        alt="avatar"
        class="rounded-circle d-flex align-self-start shadow-1-strong object-fit-cover"
        height="40"
        width="40"
      />
      <div class="card chat-message-cont">
        <div class="card-header d-flex align-items-center justify-content-between p-2">
          <p class="fw-bold mb-0">
            {chatMessage?.sender?.id === auth.user.id
              ? "You"
              : `${chatMessage.sender?.firstName} ${chatMessage.sender?.lastName}`}
          </p>
          <p class="text-muted small mb-0">
            <i class="far fa-clock"></i> {time}
          </p>
        </div>
        <div class="card-body p-2">
          <p class="mb-0">{chatMessage.message}</p>
        </div>
      </div>
    </li>
  );
}

export default MessageItem;

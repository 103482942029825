import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { reportTimeClockDaily } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const TimeClockReportDaily = ({ today }) => {
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const dailyTimeClockReportData = () => {
    dispatch(
      reportTimeClockDaily({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Daily",
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  useEffect(() => {
    dailyTimeClockReportData();
  }, [today]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      reportTimeClockDaily({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Daily",
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (
      i <= admin.timeClockReportDaily[admin.timeClockReportDaily.length - 5]
    ) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        reportTimeClockDaily({
          companyId: auth.user.companyId,
          date: moment(today).format("YYYY-MM-DD"),
          type: "Daily",
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  // console.log(currentPageNumber)
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        reportTimeClockDaily({
          companyId: auth.user.companyId,
          date: moment(today).format("YYYY-MM-DD"),
          type: "Daily",
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      reportTimeClockDaily({
        companyId: auth.user.companyId,
        date: moment(today).format("YYYY-MM-DD"),
        type: "Daily",
        limit: limit,
        page: pageNo,
      })
    );
  };
  return (
    <>
      <div className="row miordr2">
        <div className="col-md-12">
          <div className="table-responsive misftsch">
            <table className="table table-striped custom-table datatable schedul-rptbl w-100">
              <thead>
                <tr>
                  <th colSpan="2">All Employees / Positions</th>
                  <th className="report-tbhd2 report-tbhd1st" colspan="3">
                    {moment(today).format("MMM DD, YYYY")}
                  </th>
                </tr>
                <tr className="report-tbhdrw2">
                  {/* <th>Name</th> */}
                  <th>
                    <div className="th-content">
                      <span>Employee</span>
                      {/* <span className="sort-icons">
                        <i className="fas fa-long-arrow-alt-up"></i>
                        <i className="fas fa-long-arrow-alt-down"></i>
                      </span> */}
                    </div>
                  </th>
                  <th className="report-tbhdtt1">Total</th>
                  <th className="report-tbhdst1 report-tbhdfst">Start</th>
                  <th className="report-tbhden1">End</th>
                  <th className="report-tbhdhr1">Hrs</th>
                </tr>
              </thead>
              <tbody>
                {admin.timeClockReportDaily &&
                  admin.timeClockReportDaily.length > 0 &&
                  admin.timeClockReportDaily.map((current, i) => {
                    return (
                      i < admin.timeClockReportDaily.length - 5 && (
                        <tr key={i}>
                          <td>
                            <h2 className="table-avatar">
                              <NavLink
                                to={`/profile/${current.e.id}`}
                                className="avatar"
                              >
                                {/* <img alt="" src="assetsAdmin/img/avatar-02.jpg" /> */}
                                {current.e &&
                                current.e.profilePic &&
                                current.e.profilePic.fileUrl ? (
                                  <img
                                    alt="profilePic"
                                    src={
                                      BUCKET_BASE_URL +
                                      current.e.profilePic.fileUrl
                                    }
                                    style={{
                                      objectFit: "fill",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  <img alt="profilePic" src={defaultImg} />
                                )}
                              </NavLink>
                              <NavLink to={`/profile/${current.e.id}`}>
                                {current.e.firstName +
                                  ", " +
                                  current.e.lastName}
                                <span>{current.e.jobTitle}</span>
                              </NavLink>
                            </h2>
                          </td>
                          <td className="report-tbhdtt1">
                            {Math.round(current.totalHours * 100) / 100}
                          </td>
                          {/* {
                                            current.dailyData.length > 0 && */}
                          <td className="report-tbhdst1 report-tbhdfst">
                            {current.dailyData !== "no record"
                              ? current.dailyData.dayClockIn
                                  .toString()
                                  .substring(11, 16)
                              : ""}
                          </td>
                          <td className="report-tbhden1">
                            {current.dailyData !== "no record"
                              ? current.dailyData.dayClockOut
                                  .toString()
                                  .substring(11, 16)
                              : ""}
                          </td>
                          <td className="report-tbhdhr1">
                            {current.dailyData !== "no record"
                              ? current.dailyData.hoursUsed
                              : 0}
                          </td>
                          {/* } */}
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
            <div className="row">
              {admin.timeClockReportDaily &&
                admin.timeClockReportDaily.length > 0 && (
                  <div className="col-sm-12 col-md-5">
                    <label>
                      {" "}
                      Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                      {admin.timeClockReportDaily[
                        admin.timeClockReportDaily.length - 4
                      ] +
                        limit * (currentPageNumber - 1)}{" "}
                      of{" "}
                      {
                        admin.timeClockReportDaily[
                          admin.timeClockReportDaily.length - 2
                        ]
                      }{" "}
                      entries{" "}
                    </label>
                  </div>
                )}
              <div className="col-sm-12 col-md-7">
                {admin.timeClockReportDaily && (
                  <div className="avatar-pagination">
                    <ul
                      className="pagination"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          onClick={onClickPrev}
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {admin.timeClockReportDaily &&
                        admin.timeClockReportDaily.length > 0 &&
                        admin.timeClockReportDaily[
                          admin.timeClockReportDaily.length - 1
                        ].map((current, i) => {
                          return currentPageNumber === current ? (
                            <li className="page-item active" key={i}>
                              <a className="page-link" value={current}>
                                {current}
                              </a>
                            </li>
                          ) : (
                            <li className="page-item" key={i}>
                              <a
                                className="page-link"
                                value={current}
                                onClick={() => handleChangeData(current)}
                              >
                                {current}
                              </a>
                            </li>
                          );
                        })}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          onClick={onClickNext}
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div
                  className="dataTables_length"
                  id="DataTables_Table_0_length"
                >
                  <label>
                    Show &nbsp;
                    <select
                      name="DataTables_Table_0_length"
                      aria-controls="DataTables_Table_0"
                      className="select"
                      autoComplete="false"
                      onChange={handleLimit}
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return <option value={current}>{current}</option>;
                      })}
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimeClockReportDaily;

import React from 'react'
import { useSelector } from 'react-redux'

const LocationDescription = () => {
    const location = useSelector((state) => state.location);
    return (
        <>
            <div className="card">
            {
            location &&
            location.locationById &&
                <div className="card-body">
                    <div className="project-title">
                        <h5 className="card-title">{location.locationById.name}</h5>
                        <small className="block text-ellipsis m-b-15"><span className="text-muted">Address :</span> <span>{location.locationById.address}</span></small>
                    </div>
                    <p>{location.locationById.description}</p>
                </div>
                }
            </div>
        </>
    )
}
export default LocationDescription
import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useSelector } from 'react-redux'
// import { getMailByMailId } from '../../../actions/mailActions';
import SuperAdminInboxComRow from './SuperAdminInboxComRow'

const SuperAdminInboxComponent = () => {
    // const auth = useSelector((state) => state.auth);
    const mail = useSelector((state) => state.mail);
    const [windowWidth, setWindowWidth] = useState(0)
    // console.log(windowWidth==576,"windowWidth==576");
    useEffect(() => {
        window.addEventListener('resize', e => {
            setWindowWidth(window.innerWidth);
        });
    }, [window.innerWidth]);
    // const dispatch = useDispatch()
    // const mailIndividual = async (idMail) => {
    //     dispatch(getMailByMailId({mailId: idMail}))
    // }
    // let arr =[]  
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="email-header">
                                <div className="row">
                                    <div className="col top-action-left">
                                        <div className="float-left">
                                            {/* <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown">Select <i className="fa fa-angle-down "></i></button>
                                                <div className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">All</NavLink>
                                                    <NavLink className="dropdown-item" to="#">None</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Read</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Unread</NavLink>
                                                </div>
                                            </div> */}
                                            {/* <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown">Actions <i className="fa fa-angle-down "></i></button>
                                                <div className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Reply</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forward</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Archive</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Mark As Read</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Mark As Unread</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Delete</NavLink>
                                                </div>
                                            </div> */}
                                            {/* <div className="btn-group dropdown-action">
                                                <button type="button" className="btn btn-white dropdown-toggle" data-hrefggle="dropdown"><i className="fa fa-folder"></i> <i className="fa fa-angle-down"></i></button>
                                                <div role="menu" className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Social</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forums</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Updates</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Spam</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Trash</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">New</NavLink>
                                                </div>
                                            </div> */}
                                            {/* <div className="btn-group dropdown-action">
                                                <button type="button" data-hrefggle="dropdown" className="btn btn-white dropdown-toggle"><i className="fa fa-tags"></i> <i className="fa fa-angle-down"></i></button>
                                                <div role="menu" className="dropdown-menu">
                                                    <NavLink className="dropdown-item" to="#">Work</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Family</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Social</NavLink>
                                                    <div className="dropdown-divider"></div>
                                                    <NavLink className="dropdown-item" to="#">Primary</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Promotions</NavLink>
                                                    <NavLink className="dropdown-item" to="#">Forums</NavLink>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="float-left d-none d-sm-block">
                                            <input type="text" placeholder="Search Messages" className="form-control search-message" />
                                        </div> */}
                                    </div>
                                    {/* <div className="col-auto top-action-right">
                                        <div className="text-right">
                                            <button type="button" title="Refresh" data-hrefggle="tooltip" className="btn btn-white d-none d-md-inline-block"><i className="fa fa-refresh"></i></button>
                                            <div className="btn-group">
                                                <button className="btn btn-white" to=""><i className="fa fa-angle-left"></i></button>
                                                <button className="btn btn-white" to=""><i className="fa fa-angle-right"></i></button>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-muted d-none d-md-inline-block">Showing 1-10 of 112 </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="email-content">
                                <div className="table-responsive">
                                    <table className="table table-inbox table-hover">
                                        <thead>
                                            <tr><th colSpan="1">
                                                {/* <input type="checkbox" className="checkbox-all" /> */}
                                            </th></tr>
                                        </thead>
                                        <tbody>
                                        {
                                            mail.mailData &&
                                            mail.mailData.map((item,i)=>{
                                                return(
                                             <>
                                                {/* data-href ki jagah kuch dalna hai */}
                                                <SuperAdminInboxComRow item={item} i={i}  />
                                            </>
                                        )})}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SuperAdminInboxComponent
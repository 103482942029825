import React from 'react'
import { useSelector } from 'react-redux'
import MailConfirm from '../components/confirmMailComponents/MailConfirm'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
const ConfirmMail = () => {
  const myState = useSelector((state => state.changeTheTab))
  return (
    <>
      <NavBar />
      {
        myState.newLeadEmail !== '' &&
        <MailConfirm />
      }
      <Footer />
    </>
  )
}
export default ConfirmMail
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminRecentClockInRow from "./AdminRecentClockInRow";
import { clockedOutByAdmin } from "../../../actions/clockActions";
import shift1 from "../../../assetsAdmin/img/undraw_Time_management_re_tk5w.png";

const AdminRecentClockIn = ({ tracking }) => {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  //    const clockout = async (e) => {
  //       dispatch(clockedOutByAdmin({clockedOutBy: auth.user.id}))
  //   }
  const onClickUser = async (id) => {
    dispatch(clockedOutByAdmin({ _id: id, clockedOutBy: auth.user.id }));
  };
  //   useEffect(()=>{
  //       clockout()
  //    }, [])
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th>
                  <div className="th-content">
                    <span>Employee ID</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Date</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Clock-In</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                {/* <th>Date</th> */}
                {/* <th>Clock-In</th> */}
                <th>Shift Time</th>
                <th>Location</th>
                {tracking ? <th>Track</th> : <th>Length</th>}
                <th className="text-center">Position</th>
                <th className me="text-center">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {admin.ActiveUser &&
                admin.ActiveUser.length > 0 &&
                admin.ActiveUser.map((current, i) => {
                  console.log(current, "current");
                  return (
                    <AdminRecentClockInRow current={current} i={i} tracking />
                  );
                })}
            </tbody>
          </table>
          {admin.ActiveUser && admin.ActiveUser.length === 0 && (
            <div className="card">
              <div className="card-body">
                <div className="time-list">
                  <div className="dash-stats-list">
                    <br />
                    <br />
                    <img
                      alt=""
                      src={shift1}
                      style={{ width: "125px", opacity: "0.8" }}
                    />
                    <br />
                    <br />
                    <p style={{ opacity: "0.5" }}>No one is Clocked-in.</p>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="request-btn"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
    </>
  );
};
export default AdminRecentClockIn;

import axios from "axios";
import {
    PACKAGE_REQUEST,
    PACKAGE_SUCCESS,
    PACKAGE_FAIL,
    PACKAGE_CREATE_REQUEST,
    PACKAGE_CREATE_SUCCESS,
    PACKAGE_CREATE_FAIL,
    PACKAGE_UPDATE_REQUEST,
    PACKAGE_UPDATE_SUCCESS,
    PACKAGE_UPDATE_FAIL,
    // REMOVE_PACKAGE_REQUEST,
    // REMOVE_PACKAGE_SUCCESS,
    // REMOVE_PACKAGE_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY PACKAGE
export const getPackage = () => async (dispatch) => {
    dispatch({
        type: PACKAGE_REQUEST,
    });
    try {
        const { data } = await axios.get(`${SERVERADDRESS}/v1/package/getPackage`);
        localStorage.setItem("package", JSON.stringify(data));
        dispatch({
            type: PACKAGE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        // console.log(err)
        dispatch({
            type: PACKAGE_FAIL,
            payload: err,
        });
    }
};



// COMPANY ADD NEW PACKAGE
export const createPackage = (obj) => async ( dispatch ) => {
    dispatch({
        type: PACKAGE_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/package/createPackage`, obj)
    dispatch({
        type: PACKAGE_CREATE_SUCCESS,
        payload: data,
    })
}
catch (err) {
    console.log(err)
    dispatch({
        type: PACKAGE_CREATE_FAIL,
        payload: err,
    })
}
}


// UPDATE PACKAGE BY ADMIN
export const updatePackage = (obj) => async (dispatch) => {
    dispatch({
        type: PACKAGE_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/package/updatePackage`, obj);
        dispatch({
            type: PACKAGE_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PACKAGE_UPDATE_FAIL,
            payload: err,
        });
    }
};


// // // REMOVE PACKAGE BY ADMIN
// export const removeTraining = (obj) => async (dispatch) => {
//     dispatch({
//         type: REMOVE_PACKAGE_REQUEST,
//     });
//     try {
//         const { data } = await axios.put(`${SERVERADDRESS}/v1/package/updateTraining`, obj);
//         dispatch({
//             type: REMOVE_PACKAGE_SUCCESS,
//             payload: data,
//         });
//     }
//     catch (err) {
//         console.log(err)
//         dispatch({
//             type: REMOVE_PACKAGE_FAIL,
//             payload: err,
//         });
//     }
// };

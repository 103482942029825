import React from 'react' 
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStripeSubscriptionsAllCompanies } from '../../../actions/superAdminCompanyActions'
import SubsTableRow from './SubsTableRow'
// import { useState } from 'react'
// import ChangePlanModel from './ChangePlanModel'
const SubsTable = () => {
    const company = useSelector((state) => state.company);
    const dispatch = useDispatch()
    const subData = async () =>{
        dispatch(getStripeSubscriptionsAllCompanies())
    }
    useEffect(() => {
        subData()
    },[])
    let sNo= 1
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-hover custom-table datatable mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Client</th>
                                    <th>Plan</th>
                                    <th>Users</th>
                                    <th>Plan Duration</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Amount</th>
                                    <th>Plan Status</th>
                                    <th>Update Plan</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    company.subscriptionData &&
                                    company.subscriptionData.length > 0 &&
                                    company.subscriptionData.map((current, i) => {
                                        return(
                                            <SubsTableRow current={current} i={i} sNo={sNo++}/>
                                        )
                                    })}
                                {/* <SubsTableRow /> */}
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
export default SubsTable
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClocksByMonthYearAndUserId } from "../../../actions/clockActions";
import moment from "moment";
const AttendanceOverall = () => {
  const clock = useSelector((state) => state.clock);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let sNo = 0;
  const monthlyData = async () => {
    const currDate = moment().format();
    // const arr = currDate.split("+")
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0]+".000+00:00"
    const newCurrDate = arr + ".000+00:00";

    dispatch(
      getClocksByMonthYearAndUserId({ userId: auth.user.id, date: newCurrDate })
    );
  };
  useEffect(() => {
    monthlyData();
  }, []);
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString.substring(11, 13));
    const minutes = timeString.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table table-striped custom-table mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date </th>
                  <th>Clock In</th>
                  <th>Clock Out</th>
                  <th>Production</th>
                  <th>Break</th>
                  {/* <th>Overtime</th> */}
                </tr>
              </thead>
              <tbody>
                {clock.clockMonthlyData &&
                  clock.clockMonthlyData.length > 0 &&
                  clock.clockMonthlyData.map((current, i) => {
                    const date = moment(current.date).format("MM/DD/YYYY");
                    return (
                      <tr key={i}>
                        <td>{++sNo}</td>
                        <td>{date}</td>
                        <td>
                          {/* {current.dayClockIn.substring(11, 16)}{" "}
                          {parseInt(current.dayClockIn.substring(11, 13)) > 12
                            ? "PM"
                            : "AM"} */}
                          {formatTime(current.dayClockIn)}
                        </td>
                        <td>
                          {/* {current.dayClockOut
                            ? current.dayClockOut.substring(11, 16)
                            : " "}{" "} */}
                          {current.dayClockOut
                            ? formatTime(current.dayClockOut)
                            : //  parseInt(current.dayClockOut.substring(11, 13)) >
                              //    12
                              //    ? "PM"
                              //    : "AM"
                              " "}
                        </td>
                        <td>{current.hoursUsed}</td>
                        <td>{current.breakUsed}</td>
                        {/* <td>0</td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AttendanceOverall;

import React from 'react'
import { NavLink } from 'react-router-dom'
import chkmail from "../../../assetsgeneral/images/undraw_Mail_sent_re_0ofv.png"
// import { useSelector } from 'react-redux'

const VerifiedComponent = () => {
    // const myState = useSelector((state => state.changeTheTab))
    return (
        <>
            <section className="pricing-6-area pt-62 new-bg-color">
                <div className="container">
                    <div className="row contact-area justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                            <div className="pricing-6-item mt-30">
                                <div className="text-center">
                                    <img className="icom-email" src={chkmail} alt="" />
                                    <h3>Verfied !</h3>
                                    <p className="text-muted font-14 mt-2">Your Email Is Verified.<b></b>
                                    {/* <p className="text-muted font-14 mt-2"> An email will be send to <b>{myState.newLeadEmail}</b>. */}
                                        {/* <br /> */}
                                        <br />
                                        </p>
                                    <NavLink to="/" className="main-btn main-btn-2" style={{ display: "inline-block" }}>Back to Home</NavLink> &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default VerifiedComponent
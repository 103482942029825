import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeSearchFilter } from "../../../actions/adminActions";
import {
  tabActive,
  tabAllStaff,
  tabNonActive,
  tabSearch,
} from "../../../actions/tabActions";
import {
  allUsers,
  allUsersQuery,
  getActiveUsers,
  getDisabledUsers,
  lastId,
} from "../../../actions/userActions";
import AdminEmployeeTableRow from "./AdminEmployeeTableRow";
import { Link } from "react-router-dom";

const AdminEmployeeFandS = () => {
  const auth = useSelector((state) => state.auth);
  const myState = useSelector((state) => state.changeTheTab);
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const [values, setValues] = useState({
    name: "",
    employeeId: "",
    jobTitle: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const dispatch = useDispatch();
  const employeeData = {
    companyId: auth.user.companyId,
    name: values.name,
    employeeId: values.employeeId,
    jobTitle: values.jobTitle,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    if (employeeData.name === "") {
      delete employeeData.name;
    }
    if (employeeData.employeeId === "") {
      delete employeeData.employeeId;
    }
    if (employeeData.jobTitle === "") {
      delete employeeData.jobTitle;
    }
    dispatch(tabSearch());
    dispatch(employeeSearchFilter(employeeData));
  };

  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= user.allUsers.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      // console.log(currentPageNumber)
      dispatch(
        allUsersQuery(
          { companyId: auth.user.companyId },
          10,
          currentPageNumber + 1
        )
      );
    }
  };
  const onClickPrev = async (field, order) => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        allUsersQuery(
          { companyId: auth.user.companyId },
          10,
          currentPageNumber - 1,
          field,
          order
        )
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(allUsersQuery({ companyId: auth.user.companyId }, 10, pageNo));
  };
  const onClickNextActive = async (field, order) => {
    let i = currentPageNumber;
    i++;
    if (i <= user.allUsers.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      // console.log(currentPageNumber)
      dispatch(
        getActiveUsers(
          { companyId: auth.user.companyId },
          currentPageNumber + 1,
          field,
          order
        )
      );
    }
  };
  const onClickPrevActive = async (field, order) => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getActiveUsers(
          { companyId: auth.user.companyId },
          currentPageNumber - 1,
          field,
          order
        )
      );
    }
  };
  const handleChangeDataActive = async (pageNo, field, order) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getActiveUsers({ companyId: auth.user.companyId }, pageNo, field, order)
    );
  };
  const onClickNextInActive = async (field, order) => {
    let i = currentPageNumber;
    i++;
    if (i <= user.allUsers.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      // console.log(currentPageNumber)
      dispatch(
        getDisabledUsers(
          { companyId: auth.user.companyId },
          currentPageNumber + 1,
          field,
          order
        )
      );
    }
  };
  const onClickPrevInActive = async (field, order) => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getDisabledUsers(
          { companyId: auth.user.companyId },
          currentPageNumber - 1,
          field,
          order
        )
      );
    }
  };
  const handleChangeDataInActive = async (pageNo, field, order) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getDisabledUsers({ companyId: auth.user.companyId }, pageNo, field, order)
    );
  };

  const [sortField, setSortField] = useState("employeeId");
  const [sortOrder, setSortOrder] = useState("desc");

  const allUsersData = async (field, order) => {
    dispatch(
      allUsers({ companyId: auth.user.companyId }, sortField, sortOrder)
    );
  };

  useEffect(() => {
    allUsersData(sortField, sortOrder);
  }, []); // Empty dependency array ensures this runs once on mount

  const handleSort = (field) => {
    // Toggle sort order: if it's currently ascending, make it descending and vice versa
    const newOrder =
      sortField === field && sortOrder === "desc" ? "asc" : "desc";
    setSortField(field);
    setSortOrder(newOrder);
    allUsersData(field, newOrder);
  };

  const lastIdData = async () => {
    dispatch(lastId({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    allUsersData();
    lastIdData();
  }, []);

  const [dropDownRow, setDropDownRow] = useState(null);
  return (
    <>
      {/* <div class="page-wrapper"> */}
      <div class="content container-fluid">
        <div class="row py-3">
          <div class="col-xl-3 col-sm-3 col-12">
            <input
              type="text"
              class="form-control"
              placeholder="Employee ID"
              value={values.employeeId}
              onChange={handleChange("employeeId")}
            />
          </div>
          <div class="col-xl-3 col-sm-3 col-12">
            <input
              type="text"
              class="form-control"
              placeholder="Employee Name"
              value={values.name}
              onChange={handleChange("name")}
            />
          </div>
          <div class="col-xl-3 col-sm-3 col-12">
            <input
              type="text"
              class="form-control"
              placeholder="Job Title"
              value={values.jobTitle}
              onChange={handleChange("jobTitle")}
            />
          </div>
          <div class="col-xl-3 col-sm-3 col-12">
            <div class="d-grid">
              <button class="btn btn-success" onClick={handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-sm-12 col-12">
            <div class="card rounded-2 card-table">
              <div class="card-header p-0">
                <div class="row mx-2">
                  <di class="text-end">
                    {myState.viewAllEmployeeSideMenu === "allstaff" ? (
                      <>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "allstaff"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabAllStaff())}
                        >
                          All Staff
                        </button>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "active"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabActive())}
                        >
                          Active
                        </button>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "nonactive"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabNonActive())}
                        >
                          Non-Active
                        </button>
                      </>
                    ) : myState.viewAllEmployeeSideMenu === "active" ? (
                      <>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "allstaff"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabAllStaff())}
                        >
                          All Staff
                        </button>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "active"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabActive())}
                        >
                          Active
                        </button>
                        <button
                          className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                            myState.viewAllEmployeeSideMenu === "nonactive"
                              ? "active active3"
                              : ""
                          }`}
                          onClick={() => dispatch(tabNonActive())}
                        >
                          Non-Active
                        </button>
                      </>
                    ) : (
                      myState.viewAllEmployeeSideMenu === "nonactive" && (
                        <>
                          <button
                            className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                              myState.viewAllEmployeeSideMenu === "allstaff"
                                ? "active active3"
                                : ""
                            }`}
                            onClick={() => dispatch(tabAllStaff())}
                          >
                            All Staff
                          </button>
                          <button
                            className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                              myState.viewAllEmployeeSideMenu === "active"
                                ? "active active3"
                                : ""
                            }`}
                            onClick={() => dispatch(tabActive())}
                          >
                            Active
                          </button>
                          <button
                            className={`btn btn-light px-3 rounded-3 me-3 my-3 ${
                              myState.viewAllEmployeeSideMenu === "nonactive"
                                ? "active active3"
                                : ""
                            }`}
                            onClick={() => dispatch(tabNonActive())}
                          >
                            Non-Active
                          </button>
                        </>
                      )
                    )}

                    <Link
                      to="/employees-add"
                      class="btn btn-success px-3 rounded-3 my-3 "
                    >
                      <i class="fa fa-plus-circle"></i> Add Employee
                    </Link>
                  </di>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead class="thead-warning">
                      <tr>
                        <th>Name</th>
                        <th>Employee ID</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Position</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myState.viewAllEmployeeSideMenu === "allstaff" ? (
                        user.allUsers &&
                        user.allUsers.results &&
                        user.allUsers.results.length > 0 &&
                        user.allUsers.results.map((current, i) => {
                          return (
                            <AdminEmployeeTableRow
                              current={current}
                              i={i}
                              dropDownRow={dropDownRow}
                              setDropDownRow={setDropDownRow}
                            />
                          );
                        })
                      ) : myState.viewAllEmployeeSideMenu === "active" ? (
                        user.active &&
                        user.active.results &&
                        user.active.results.length > 0 &&
                        user.active.results.map((current, i) => {
                          return (
                            <AdminEmployeeTableRow
                              current={current}
                              i={i}
                              dropDownRow={dropDownRow}
                              setDropDownRow={setDropDownRow}
                            />
                          );
                        })
                      ) : myState.viewAllEmployeeSideMenu === "nonactive" ? (
                        user.nonActive &&
                        user.nonActive.results &&
                        user.nonActive.results.length > 0 &&
                        user.nonActive.results.map((current, i) => {
                          return (
                            <AdminEmployeeTableRow
                              current={current}
                              i={i}
                              dropDownRow={dropDownRow}
                              setDropDownRow={setDropDownRow}
                            />
                          );
                        })
                      ) : myState.viewAllEmployeeSideMenu === "search" ? (
                        admin.searchEmployee &&
                        admin.searchEmployee.length > 0 &&
                        admin.searchEmployee.map((current, i) => {
                          return (
                            <AdminEmployeeTableRow
                              current={current}
                              i={i}
                              dropDownRow={dropDownRow}
                              setDropDownRow={setDropDownRow}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {/* more vert ka new design neecha ha  */}
                      {/* <tr>
                        <td>
                          <h2 class="table-avatar">
                            <a href="#" class="avatar avatar-sm me-2">
                              <img
                                class="avatar-img rounded-circle"
                                src="assets/img/profiles/avatar-12.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="#">ABC </a>
                          </h2>
                        </td>
                        <td>12345</td>
                        <td>testingemployee4@gmail.com</td>
                        <td>(631)-889-3210</td>
                        <td>assitant</td>
                        <td>
                          <span class="badge bg-success-dark text-white">
                            Active
                          </span>
                        </td>
                        <td class="text-center">
                          <div>
                            <a href="#">
                              <i class="fa fa-eye text-secondary mx-2"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-edit text-secondary mx-2"></i>
                            </a>
                            <a href="#">
                              <i class="fa fa-trash text-secondary mx-2"></i>
                            </a>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer ">
                <div class="row align-items-center">
                  {myState.viewAllEmployeeSideMenu !== "search" &&
                  myState.viewAllEmployeeSideMenu === "allstaff" ? (
                    user.allUsers && (
                      <div class="col">
                        <p>
                          Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                          {user.allUsers.displayingResults +
                            limit * (currentPageNumber - 1)}{" "}
                          of {user.allUsers.totalResults} entries{" "}
                        </p>
                      </div>
                    )
                  ) : myState.viewAllEmployeeSideMenu === "active" ? (
                    user.active && (
                      <div class="col">
                        <p>
                          Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                          {user.active.displayingResults +
                            limit * (currentPageNumber - 1)}{" "}
                          of {user.active.totalResults} entries{" "}
                        </p>
                      </div>
                    )
                  ) : myState.viewAllEmployeeSideMenu === "nonactive" ? (
                    user.nonActive && (
                      <div class="col">
                        <p>
                          Showing {1 + limit * (currentPageNumber - 1)} to{" "}
                          {user.nonActive.displayingResults +
                            limit * (currentPageNumber - 1)}{" "}
                          of {user.nonActive.totalResults} entries{" "}
                        </p>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {/* {myState.viewAllEmployeeSideMenu !== "search" && (
                    <div class="col-auto">
                      <nav aria-label="Page navigation example">
                        <ul
                          class="pagination justify-content-end"
                          style={{ paddingTop: "13px" }}
                        >
                          <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1">
                              Previous
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )} */}
                  {myState.viewAllEmployeeSideMenu !== "search" && (
                    <div className="col-auto">
                      <nav aria-label="Page navigation example">
                        <ul
                          className="pagination justify-content-end"
                          style={{ paddingTop: "13px" }}
                        >
                          {/* Previous button */}
                          <li className="page-item">
                            {myState.viewAllEmployeeSideMenu === "allstaff" ? (
                              <a
                                className="page-link"
                                aria-label="Previous"
                                onClick={onClickPrev}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            ) : myState.viewAllEmployeeSideMenu === "active" ? (
                              <a
                                className="page-link"
                                aria-label="Previous"
                                onClick={onClickPrevActive}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            ) : myState.viewAllEmployeeSideMenu ===
                              "nonactive" ? (
                              <a
                                className="page-link"
                                aria-label="Previous"
                                onClick={onClickPrevInActive}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            ) : (
                              <></>
                            )}
                          </li>

                          {/* Pagination links */}
                          {myState.viewAllEmployeeSideMenu === "allstaff" ? (
                            user.allUsers &&
                            user.allUsers.paginationArr &&
                            user.allUsers.paginationArr.length > 0 &&
                            user.allUsers.paginationArr.map((current, i) => {
                              return currentPageNumber === current ? (
                                <li className="page-item active" key={i}>
                                  <a className="page-link" value={current}>
                                    {current}
                                  </a>
                                </li>
                              ) : (
                                <li className="page-item" key={i}>
                                  <a
                                    className="page-link"
                                    value={current}
                                    onClick={() => handleChangeData(current)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {current}
                                  </a>
                                </li>
                              );
                            })
                          ) : myState.viewAllEmployeeSideMenu === "active" ? (
                            user.active &&
                            user.active.paginationArr &&
                            user.active.paginationArr.length > 0 &&
                            user.active.paginationArr.map((current, i) => {
                              return currentPageNumber === current ? (
                                <li className="page-item active" key={i}>
                                  <a className="page-link" value={current}>
                                    {current}
                                  </a>
                                </li>
                              ) : (
                                <li className="page-item" key={i}>
                                  <a
                                    className="page-link"
                                    value={current}
                                    onClick={() =>
                                      handleChangeDataActive(current)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {current}
                                  </a>
                                </li>
                              );
                            })
                          ) : myState.viewAllEmployeeSideMenu ===
                            "nonactive" ? (
                            user.nonActive &&
                            user.nonActive.paginationArr &&
                            user.nonActive.paginationArr.length > 0 &&
                            user.nonActive.paginationArr.map((current, i) => {
                              return currentPageNumber === current ? (
                                <li className="page-item active" key={i}>
                                  <a className="page-link" value={current}>
                                    {current}
                                  </a>
                                </li>
                              ) : (
                                <li className="page-item" key={i}>
                                  <a
                                    className="page-link"
                                    value={current}
                                    onClick={() =>
                                      handleChangeDataInActive(current)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {current}
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <></>
                          )}

                          {/* Next button */}
                          <li className="page-item">
                            {myState.viewAllEmployeeSideMenu === "allstaff" ? (
                              <a
                                className="page-link"
                                aria-label="Next"
                                onClick={onClickNext}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                              </a>
                            ) : myState.viewAllEmployeeSideMenu === "active" ? (
                              <a
                                className="page-link"
                                aria-label="Next"
                                onClick={onClickNextActive}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                              </a>
                            ) : myState.viewAllEmployeeSideMenu ===
                              "nonactive" ? (
                              <a
                                className="page-link"
                                aria-label="Next"
                                onClick={onClickNextInActive}
                                style={{ cursor: "pointer" }}
                              >
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                              </a>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default AdminEmployeeFandS;

{
  /* <div className="row filter-row">
<div className="col-sm-6 col-md-3">
  <div className="form-group form-focus select-focus">
    <input
      type="text"
      className="form-control floating"
      value={values.employeeId}
      onChange={handleChange("employeeId")}
    />
    <label className="focus-label">Employee ID</label>
  </div>
</div>
<div className="col-sm-6 col-md-3">
  <div className="form-group form-focus select-focus">
    <input
      type="text"
      className="form-control floating"
      value={values.name}
      onChange={handleChange("name")}
    />
    <label className="focus-label">Employee Name</label>
  </div>
</div>
<div className="col-sm-6 col-md-3">
  <div className="form-group form-focus select-focus">
    <input
      type="text"
      className="form-control floating"
      value={values.jobTitle}
      onChange={handleChange("jobTitle")}
    />
    <label className="focus-label">Job Title</label>
  </div>
</div>
<div className="col-sm-6 col-md-3">
  <a
    to="#"
    className="btn btn-success btn-block"
    onClick={handleSubmit}
  >
    {" "}
    Search{" "}
  </a>
</div>
</div> */
}

// import React, { useState } from 'react'
import React from "react";
// import moment from 'moment';
import { useSelector } from "react-redux";
import DailyTableRow from "./DailyTableRow";
import PaginationAndNextPrev from "./PaginationAndNextPrev";
// import EditModalSSEditScheduling from './EditModalSSEditScheduling'
// import { NavLink } from 'react-router-dom'

const AdminSSDailyTable = ({ dateForTableData }) => {
  const admin = useSelector((state) => state.admin);

  return (
    <>
      <div class="card-body">
        <div class="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Scheduled Shift</th>
                <th style={{ textAlign: "left" }}>
                  {dateForTableData.format("ddd DD")}
                </th>
              </tr>
            </thead>
            <tbody>
              {admin.usersShifts &&
                admin.usersShifts.length > 0 &&
                admin.usersShifts.map((current, i) => {
                  return (
                    i < admin.usersShifts.length - 5 && (
                      <>
                        <DailyTableRow current={current} key={i} />
                      </>
                    )
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <PaginationAndNextPrev dateForPagination={dateForTableData} />
    </>
  );
};
export default AdminSSDailyTable;

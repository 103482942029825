import React, { useEffect } from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminPayslipView from '../../components/adminComponents/adminPayrollItems/PEmployeeGeneratePayslip'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getMonthlySalaryById } from '../../actions/payrollAction'
import { updateSalaryCalendar } from '../../actions/payrollAction'
const AdminSalaryView = () => {
    // confirmReceipt
    const payroll = useSelector((state) => state.payroll)
    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // console.log("params==>", params.id);
//   const navigateUser = () => {
//     dispatch(tabEmployeeSalaries)
// }
    const confirmPayslip = async (e) => {
        dispatch(updateSalaryCalendar({_id: params.id, confirmReceipt:true }))
    }
    useEffect(() => {
        if (params.id) {
            // console.log("dispatch")
            dispatch(getMonthlySalaryById({_id: params.id}, navigate))
        } 
        // else {
        //   console.log("dispatch")
        // }
    }, [params.id]);
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className='page-wrapper'>
                    <div className='content container-fluid'>
                        <div className="col-auto float-right ml-auto">
                            <div className="btn-group btn-group-sm">
                                {
                                    payroll &&
                                    payroll.monthlySalary &&
                                    payroll.monthlySalary.confirmReceipt === true ?
                                    <button className="btn btn-white" style={{backgroundColor: "#9ac748"}}><i className="fa fa-check"></i>Confirmed</button>
                                    :
                                    payroll &&
                                    payroll.monthlySalary &&
                                    payroll.monthlySalary.confirmReceipt === false ?
                                    <button className="btn btn-white" onClick={confirmPayslip}>Confirm Payslip?</button>
                                    :
                                    <></>
                                }
                                {/* <button className="btn btn-white">CSV</button> */}
                                {/* <button className="btn btn-white">PDF</button> */}
                                <button className="btn btn-white" ><i className="fa fa-print fa-lg"></i> Print</button>
                                {/* <button className="btn btn-white" onclick={window.print()}><i className="fa fa-print fa-lg"></i> Print</button> */}
                            </div>
                        </div>
                        <AdminTittle title={"Payslip"} />
                        <AdminPayslipView />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSalaryView
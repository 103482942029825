import React from 'react'
import { useSelector } from 'react-redux'
const SubsCards = () => {
    const company = useSelector((state) => state.company)
    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Joining</h6>
                        {
                            company.SAdminCardsDetails &&
                            company.SAdminCardsDetails.joiningThisMonthCount &&
                            <h4>{company.SAdminCardsDetails.joiningThisMonthCount} <span>This Month</span></h4>
                        }
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Renewal</h6>
                        <h4>12 <span>This Month</span></h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Renewal</h6>
                        <h4>0 <span>Next Month</span></h4>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="stats-info">
                        <h6>Total Companies</h6>
                        {
                            company.SAdminCardsDetails &&
                            company.SAdminCardsDetails.companyCount &&
                            <h4>{company.SAdminCardsDetails.companyCount}</h4>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default SubsCards
import React from 'react'
import about from  '../../assetsgeneral/images/about.png'
const BannerAboutUs = () => {
    return (
        <>
            <div className="slider-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="work-schedule work-about">
                                <h2>About Us</h2>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="work-schedule-img">
                                <img src={about} id="sli-img-se" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BannerAboutUs
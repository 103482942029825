import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getAllTraining } from "../../../actions/trainingActions";
import { useSelector, useDispatch } from "react-redux";
import TrainingTableRow from "./TrainingTableRow";
import { useState } from "react";

const TrainingTable = () => {
  // const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const training = useSelector((state) => state.training);
  const dispatch = useDispatch();
  const trainingData = async (e) => {
    dispatch(
      getAllTraining({
        companyId: auth.user.companyId,
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  useEffect(() => {
    trainingData();
  }, []);
  // let i=0
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const handleLimit = async (event) => {
    setLimit(parseInt(event.target.value));
    dispatch(
      getAllTraining({
        companyId: auth.user.companyId,
        limit: event.target.value,
        page: 1,
      })
    );
    setCurrentPageNumber(1);
  };
  const onClickNext = async () => {
    let i = currentPageNumber;
    i++;
    if (i <= training.training.totalPages) {
      setCurrentPageNumber(currentPageNumber + 1);
      dispatch(
        getAllTraining({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber + 1,
        })
      );
    }
  };
  const onClickPrev = async () => {
    let i = currentPageNumber;
    i--;
    if (i >= 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      dispatch(
        getAllTraining({
          companyId: auth.user.companyId,
          limit: limit,
          page: currentPageNumber - 1,
        })
      );
    }
  };
  const handleChangeData = async (pageNo) => {
    setCurrentPageNumber(pageNo);
    dispatch(
      getAllTraining({
        companyId: auth.user.companyId,
        limit: limit,
        page: pageNo,
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>
              Show &nbsp;
              <select
                name="DataTables_Table_0_length"
                aria-controls="DataTables_Table_0"
                className="select"
                autoComplete="false"
                onChange={handleLimit}
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return <option value={current}>{current}</option>;
                })}
              </select>{" "}
              entries
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                {/* <th style={{ width: "30px" }}>#</th> */}
                <th>
                  <div className="th-content">
                    <span style={{ width: "30px" }}># </span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                {/* <th>Name</th> */}
                <th>
                  <div className="th-content">
                    <span>Training Name</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th>
                  <div className="th-content">
                    <span>Training Type</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                {/* <th>Training Type</th> */}
                {/* <th>Employee</th> */}
                <th></th>
                <th>Time Duration</th>
                <th>Description </th>
                {/* <th>Status </th> */}
                <th>
                  <div className="th-content">
                    <span>Status</span>
                    {/* <span className="sort-icons">
                      <i className="fas fa-long-arrow-alt-up"></i>
                      <i className="fas fa-long-arrow-alt-down"></i>
                    </span> */}
                  </div>
                </th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {training.training &&
                training.training.results &&
                training.training.results.length > 0 &&
                training.training.results.map((current, i) => {
                  if (current.delete === false) {
                    return <TrainingTableRow current={current} i={i} />;
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <div className="row">
        {training.training && (
          <div className="col-sm-12 col-md-5">
            <label>
              {" "}
              Showing {1 + limit * (currentPageNumber - 1)} to{" "}
              {training.training.displayingResults +
                limit * (currentPageNumber - 1)}{" "}
              of {training.training.totalResults} entries{" "}
            </label>
          </div>
        )}
        <div className="col-sm-12 col-md-7">
          <div className="avatar-pagination">
            <ul className="pagination" style={{ justifyContent: "flex-end" }}>
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Previous"
                  onClick={onClickPrev}
                >
                  <span aria-hidden="true">Previous</span>
                  <span className="sr-only">Previous</span>
                </NavLink>
              </li>
              {training.training &&
                training.training.paginationArr &&
                training.training.paginationArr.length > 0 &&
                training.training.paginationArr.map((current, i) => {
                  return currentPageNumber === current ? (
                    <li className="page-item active" key={i}>
                      <a className="page-link" value={current}>
                        {current}
                      </a>
                    </li>
                  ) : (
                    <li className="page-item" key={i}>
                      <a
                        className="page-link"
                        value={current}
                        onClick={() => handleChangeData(current)}
                      >
                        {current}
                      </a>
                    </li>
                  );
                })}
              <li className="page-item">
                <NavLink
                  className="page-link"
                  to="#"
                  aria-label="Next"
                  onClick={onClickNext}
                >
                  <span aria-hidden="true">Next</span>
                  <span className="sr-only">Next</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default TrainingTable;

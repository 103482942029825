import React from 'react'
import { useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom'

const ProfileLeaves = () => {
  const leave = useSelector((state) => state.leave);
  return (
    <>
      <div className="col-md-6 d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">Leaves</h3>
            {/* <div className="table-responsive milevtb"> */}
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th>Leave Types</th>
                    <th className="text-center">Total Leave</th>
                    <th className="text-center">Remaining Leave</th>
                    {/* <th className="text-center">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                    {   
                      leave.leaveAnnualStatus &&
                      leave.leaveAnnualStatus.length > 0 &&
                      leave.leaveAnnualStatus.map((current, i) => {
                        return(
                            <tr key={i}>
                                <td>
                                    <strong>{current.leaveCategory.name}</strong>
                                </td>
                                <td className="text-center">{current.annualLeavesDays}</td>
                                <td className="text-center">{current.remainingLeavesDays}</td>
                            </tr>
                        )
                    })}
                </tbody>
              </table>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
export default ProfileLeaves
import axios from "axios";
import {
    HOLIDAY_REQUEST,
    HOLIDAY_SUCCESS,
    HOLIDAY_FAIL,
    HOLIDAY_CREATE_REQUEST,
    HOLIDAY_CREATE_SUCCESS,
    HOLIDAY_CREATE_FAIL,
    HOLIDAY_TABLE_REQUEST,
    HOLIDAY_TABLE_SUCCESS,
    HOLIDAY_TABLE_FAIL,
    HOLIDAY_UPDATE_REQUEST,
    HOLIDAY_UPDATE_SUCCESS,
    HOLIDAY_UPDATE_FAIL,
    REMOVE_HOLIDAY_REQUEST,
    REMOVE_HOLIDAY_SUCCESS,
    REMOVE_HOLIDAY_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY HOLIDAY
export const companyHolidays = (obj) => async (dispatch) => {
    dispatch({
        type: HOLIDAY_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/holiday/companyHolidays`, obj);
        localStorage.setItem("holiday", JSON.stringify(data));
        dispatch({
            type: HOLIDAY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: HOLIDAY_FAIL,
            payload: err,
        });
    }
};


// ADMIN ADD NEW HOLIDAY
export const addHoliday = (obj) => async ( dispatch ) => {
    dispatch({
        type: HOLIDAY_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/holiday/createHoliday`, obj)
        dispatch({
            type: HOLIDAY_CREATE_SUCCESS,
            payload: data,
        })
        // navigateUser()
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: HOLIDAY_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN HOLIDAY TABLE SHOW
export const getAllHolidays = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: HOLIDAY_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/holiday/getAllHolidays`, obj);
        localStorage.setItem("holidayTable", JSON.stringify(data));
        dispatch({
            type: HOLIDAY_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: HOLIDAY_TABLE_FAIL,
            payload: err,
        });
    }
};


// UPDATE HOLIDAY
export const updateHoliday = (obj) => async (dispatch) => {
    dispatch({
        type: HOLIDAY_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/holiday/updateHoliday`, obj);
        dispatch({
            type: HOLIDAY_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: HOLIDAY_UPDATE_FAIL,
            payload: err,
        });
    }
};

// UPDATE HOLIDAY
export const removeHoliday = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_HOLIDAY_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/holiday/updateHoliday`, obj);
        dispatch({
            type: REMOVE_HOLIDAY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_HOLIDAY_FAIL,
            payload: err,
        });
    }
};
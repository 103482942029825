import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageWallById,
  removeMessageWall,
} from "../../../actions/messageWallActions";
// import { useNavigate } from 'react-router-dom'

const DeletePin = ({ showDeleteDropDown, openDeleteDropDown, current2 }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [dataUpdate, setDataUpdate] = useState({
    _id: current2.id,
    delete: true,
  });
  const valueUpdate = () => {
    if (current2 !== undefined) {
      setDataUpdate({
        _id: current2.id,
        delete: true,
      });
    }
    // console.log(dataUpdate, "dataUpdate")
  };
  useEffect(() => {
    valueUpdate();
    // console.log(dataUpdate, "dataUpdate")
  }, [current2]);
  const allUsersData = async () => {
    dispatch(getMessageWallById({ companyId: auth.user.companyId }));
  };
  const handleSubmit = async () => {
    // console.log("dataUpdate:", dataUpdate);
    dispatch(removeMessageWall(dataUpdate));
    openDeleteDropDown();
    setTimeout(() => allUsersData(), 1000);
  };
  // console.log(current2.messageOnWall, current2.id);
  return (
    <>
      <div
        className={`modal-backdrop fade ${
          showDeleteDropDown ? "show" : "hide"
        }`}
      ></div>
      <div
        className={`modal custom-modal fade ${
          showDeleteDropDown ? "show" : "hide"
        }`}
        id="delete_warn"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeleteDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Pin</h3>
                <p>Are you sure want to pin?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      className="btn btn-primary continue-btn"
                      onClick={handleSubmit}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                      onClick={() => openDeleteDropDown()}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeletePin;

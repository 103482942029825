import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getLeaveByUserId } from "../../../actions/leaveActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
const LeavesTable = () => {
  const auth = useSelector((state) => state.auth);
  const leave = useSelector((state) => state.leave);
  const dispatch = useDispatch();
  const leaveListData = async (e) => {
    dispatch(getLeaveByUserId({ userId: auth.user.id }));
  };
  useEffect(() => {
    leaveListData();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <div className="table-responsive"> */}
          <table className="table table-striped custom-table mb-0 datatable">
            <thead>
              <tr>
                <th>Leave Type</th>
                <th>From</th>
                <th>To</th>
                <th>No of Days</th>
                <th>Reason</th>
                <th className="text-center">Status</th>
                <th>Rejected Reason</th>
                <th>Reviewed by</th>
              </tr>
            </thead>
            <tbody>
              {leave?.leaveList &&
                leave?.leaveList?.length > 0 &&
                leave?.leaveList?.map((current, i) => {
                  return (
                    <tr
                      key={i}
                      // style={{maxWidth: "100%"}}
                    >
                      <td>{current?.leaveType?.name}</td>
                      <td>{moment(current?.from).format("MM/DD/YYYY")}</td>
                      <td>{moment(current?.to).format("MM/DD/YYYY")}</td>
                      <td>{current?.numberOfDays} days</td>
                      <td style={{ minWidth: "150px", maxWidth: "250px" }}>
                        {current?.leaveReason?.length <= 15
                          ? current?.leaveReason
                          : current?.leaveReason.substring(0, 15) + "..."}
                      </td>

                      <td className="text-center">
                        {current?.status === "Pending" ? (
                          <div className="action-label">
                            {/* &nbsp; &nbsp; &nbsp; */}
                            <a className="btn btn-white btn-sm btn-rounded">
                              <i className="fa fa-dot-circle-o text-purple"></i>{" "}
                              Pending
                            </a>
                          </div>
                        ) : current?.status === "Accepted" ? (
                          <div className="action-label">
                            {/* &nbsp; &nbsp; &nbsp; */}
                            <a className="btn btn-white btn-sm btn-rounded">
                              <i className="fa fa-dot-circle-o text-success"></i>{" "}
                              Approved
                            </a>
                          </div>
                        ) : (
                          <div className="action-label">
                            {/* &nbsp; &nbsp; &nbsp; */}
                            <a className="btn btn-white btn-sm btn-rounded">
                              <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                              Declined
                            </a>
                          </div>
                        )}
                      </td>
                      <td style={{ minWidth: "150px", maxWidth: "250px" }}>
                        {current?.rejectedReason
                          ? current?.rejectedReason?.length <= 15
                            ? current?.rejectedReason
                            : current?.rejectedReason.substring(0, 15) + "..."
                          : "-"}
                      </td>
                      <td>
                        <h2 className="table-avatar">
                          {/* <a className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></a> */}
                          {current?.status === "Pending" ? (
                            <a href="#">-</a>
                          ) : (
                            <a href="#">Admin</a>
                          )}
                        </h2>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default LeavesTable;

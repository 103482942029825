import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import EditModalEmployeeSalaryTable from "./EditModalEmployeeSalaryTable";
import moment from "moment/moment";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import DeleteEmployeeSalary from "./DeleteEmployeeSalary";
import { BUCKET_BASE_URL } from "../../../constants";
import { useSelector } from "react-redux";
const PEmployeeSalariesRow = ({ current, i, dropDownRow, setDropDownRow }) => {
  const user = useSelector((state) => state.user);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
    setshowMoreVertDropDown(false);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
    setshowMoreVertDropDown(false);
  };
  console.log(user, "user");
  console.log(user?.userById?.user?.id, "user2");
  console.log(current.userId.id, "current2");
  console.log(current, "current");
  console.log(current?.userId?.startingDate);
  return (
    <>
      <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
        <td>
          <h2 className="table-avatar">
            <NavLink to={`/profile/${current.userId.id}`} className="avatar">
              {current.userId.profilePic &&
              current.userId.profilePic.fileUrl ? (
                <img
                  alt="profilePic"
                  src={BUCKET_BASE_URL + current.userId.profilePic.fileUrl}
                  style={{ objectFit: "fill", height: "100%" }}
                />
              ) : (
                <img alt="profilePic" src={defaultImg} />
              )}
            </NavLink>
            <NavLink to={`/profile/${current.userId.id}`}>
              {current.userId.firstName + " " + current.userId.lastName}
              <span>{current.userId.jobTitle}</span>
            </NavLink>
          </h2>
        </td>
        <td>{current.userId.employeeId}</td>
        <td>{current.userId.phone}</td>
        {/* <td>
          {user?.allUsersWithoutPaginate.map(
            (x) =>
              x?.id === current?.userId?.id && (
                <td key={x.id}>
                  {moment(x.startingDate).format("MM/DD/YYYY")}
                </td>
              )
          )}
        </td> */}
        <td>
          {/* {moment(current?.userId?.startingDate).format("DD MMMM YYYY")} <br /> */}
          {user?.allUsersWithoutPaginate
            .filter((x) => x?.id === current?.userId?.id)
            .map((x) => (
              <span key={x.id}>
                {moment(x.startingDate).format("MM/DD/YYYY")}
              </span>
            ))}
        </td>
        <td>${current.salaryThisMonth}</td>
        <td>
          <NavLink
            className="btn btn-sm btn-primary"
            to={`/salary-view/${current.id}`}
          >
            Generate Slip
          </NavLink>
        </td>
        <td className="text-right">
          <div className="dropdown dropdown-action">
            {/* <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => { */}
            {/* href haaaaaaataaaayaaa haaai */}
            {current && current.confirmReceipt === false && (
              <NavLink
                to="#"
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => {
                  openMoreVertDropDown();
                  setDropDownRow(i);
                }}
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons">more_vert</i>
              </NavLink>
            )}
            {dropDownRow === i && (
              <>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    showMoreVertDropDown ? "show" : "hide"
                  }`}
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    onClick={openDropDown}
                    data-target="#edit_salary"
                  >
                    <i className="fa fa-pencil m-r-5"></i> Edit
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    data-toggle="modal"
                    onClick={openDeleteDropDown}
                    data-target="#delete_approve"
                  >
                    <i className="fa fa-trash-o m-r-5"></i> Delete
                  </a>
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
      <EditModalEmployeeSalaryTable
        current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
      <DeleteEmployeeSalary
        current={current}
        showDeleteDropDown={showDeleteDropDown}
        openDeleteDropDown={openDeleteDropDown}
      />
    </>
  );
};
export default PEmployeeSalariesRow;

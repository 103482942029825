import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getLocationByLocationId } from '../../actions/locationActions'
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import LocationDescription from '../../components/employeeComponents/employeeLocationView/LocationDescription'
import LocationMap from '../../components/employeeComponents/employeeLocationView/LocationMap'

const AdminWorkLocation = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (params.id) {
            dispatch(getLocationByLocationId({locationId: params.id}, navigate))
        } 
    }, [params.id]);
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Location"/>
                        <div className="row">
                            <div className="col-lg-8 col-xl-12">
                                <LocationDescription />
                                <LocationMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminWorkLocation
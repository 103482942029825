// import React, { forwardRef, useState } from 'react'
import React, { useState } from "react";
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { createContactUs } from "../../actions/contactusActions";

const ContactUsMessage = ({ showDropDown, openDropDown }) => {
  // const auth = useSelector((state) => state.auth)
  // const contactus = useSelector((state) => state.contactus)
  const [values, setValues] = useState({
    // id: '',
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
    date: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const dispatch = useDispatch();
  const contactusData = {
    // companyId: auth.user.companyId,
    // assignUserId: auth.user.id,
    name: values.name,
    email: values.email,
    subject: values.subject,
    phone: values.phone,
    message: values.message,
    // date: date.toISOString(),
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(createContactUs(contactusData));
    // openDropDown()
  };
  return (
    <>
      <div className="form-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-sec">
                <h2> Get In Touch With Us</h2>
                <div className="container">
                  <div className="row2 input-container">
                    <div className="col-md-6 col-sm-12">
                      <p className="emass"> Name</p>
                      <div className="styled-input">
                        <input
                          type="text"
                          placeholder="Enter Name"
                          autoComplete="false"
                          value={values.name}
                          onChange={handleChange("name")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <p className="emass"> Email</p>
                      <div className="styled-input">
                        <input
                          type="text"
                          placeholder="Enter Email"
                          autoComplete="false"
                          value={values.email}
                          onChange={handleChange("email")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <p className="emass"> Subject</p>
                      <div className="styled-input">
                        <input
                          type="text"
                          placeholder="Enter Subject"
                          autoComplete="false"
                          value={values.subject}
                          onChange={handleChange("subject")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <p className="emass"> Phone</p>
                      <div className="styled-input">
                        <input
                          type="text"
                          placeholder="Enter Number"
                          autoComplete="false"
                          value={values.phone}
                          onChange={handleChange("phone")}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <p className="emass"> Message</p>
                      <div className="styled-input wide">
                        <textarea
                          placeholder="Enter Message"
                          autoComplete="false"
                          value={values.message}
                          onChange={handleChange("message")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div
                        className="btn-lrg submit-btn-general"
                        onClick={handleSubmit}
                      >
                        Send Yours Message
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUsMessage;

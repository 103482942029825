import React from 'react'
import one from '../../src/assetsgeneral/images/1.png'
import two from '../../src/assetsgeneral/images/2.png'
import three from '../../src/assetsgeneral/images/3.png'
import four from '../../src/assetsgeneral/images/4.png'
import five from '../../src/assetsgeneral/images/con-cha.png'
const Features = () => {
    return (
        <>
            <div className="features-section ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="features">
                                <h3> FEATURES</h3>

                                <h2> Put the back office in your<br />
                                    front pocket.</h2>

                                <div className="team-manag-sec">
                                    <div className="team-manag"> <img src={one} id="team-man-igf" /> </div>
                                    <div className="team-manag-text">
                                        <h2>Team Management </h2>
                                        <p>Finding the perfect learning <br />
                                            tool for Flash is a daunting </p>
                                    </div>
                                </div>
                            </div>
                            <div className="team-manag-sec teammang-secc">
                                <div className="team-manag"> <img src={two} id="team-man-igf" /> </div>
                                <div className="team-manag-text">
                                    <h2>Team Accountability </h2>
                                    <p>Finding the perfect learning <br />
                                        tool for Flash is a daunting</p>
                                </div>
                            </div>

                            <div className="team-manag-sec">
                                <div className="team-manag"> <img src={three} id="team-man-igf" /> </div>
                                <div className="team-manag-text">
                                    <h2>Team Communication</h2>
                                    <p>Finding the perfect learning <br />
                                        tool for Flash is a daunting</p>
                                </div>
                            </div>

                            <div className="team-manag-sec teammang-secc">
                                <div className="team-manag"> <img src={four} id="team-man-igf" /> </div>
                                <div className="team-manag-text">
                                    <h2>Team Empowerment</h2>
                                    <p>Finding the perfect learning <br />
                                        tool for Flash is a daunting </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="features">
                                <img src={five} id="img-width" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Features
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import content from '../../assetsgeneral/images/content.png'
const Advertiser = ({initialVisible}) => {
    return (
        <>
            <div className="learn-how-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            {/* {
                                initialVisible && <div className="advertiser-thumb animated wow fadeInLeft" >
                                    <img src={content} alt="" width="450px" />
                                </div>
                            } */}
                            {
                                !initialVisible && 
                                <AnimationOnScroll animateIn="fadeInLeft" duration="1.5" animateOnce="true" >
                                    <div className="leanr-how-img">
                                        <img src={content} id="img-width" alt='advertiser'/>
                                    </div>
                                </AnimationOnScroll>
                            } 
                        </div>
                        <div className="col-md-6">
                            <div className="leanr-how-text">
                                <h2> Learn how Riyo-Work solves employee <br />
                                    scheduling in your industry</h2>
                                <p> From healthcare and manufacturing to universities and retail, see how <br />
                                    Riyo-work’s flexible, smart scheduling software will meet your needs.</p>
                                <ul>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Healthcare </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Retail </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Hospitality </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Universities </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Food &amp; Beverage</li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Fitness &amp; Recreation</li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Manufacturing &amp; Facilities </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Transportation &amp; Delivery </li>
                                    <li><i className="fa fa-check" aria-hidden="true" id="icon-chan"></i> Call Center</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Advertiser
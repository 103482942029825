import {
    PACKAGE_REQUEST,
    PACKAGE_SUCCESS,
    PACKAGE_FAIL,
    PACKAGE_CREATE_REQUEST,
    PACKAGE_CREATE_SUCCESS,
    PACKAGE_CREATE_FAIL,
    PACKAGE_UPDATE_REQUEST,
    PACKAGE_UPDATE_SUCCESS,
    PACKAGE_UPDATE_FAIL,
    // REMOVE_PACKAGE_REQUEST,
    // REMOVE_PACKAGE_SUCCESS,
    // REMOVE_PACKAGE_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const packageReducer = (
    state = {
        package: {
            package: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                package: null,
            };

        // USER PACKAGE TABLE
        case PACKAGE_REQUEST : 
            return {
                ...state,
            };
        case PACKAGE_SUCCESS :
            return {
                ...state,
                package: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case PACKAGE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // ADMIN CREATE PACKAGE REDUCER
        case PACKAGE_CREATE_REQUEST :
            return {
                ...state,
            }
        case PACKAGE_CREATE_SUCCESS :
            return {
                ...state,
                package: [...state.package, action.payload],
            }
        case PACKAGE_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        case PACKAGE_UPDATE_REQUEST :
            return {
                ...state,
            };
        case PACKAGE_UPDATE_SUCCESS :
            return {
                ...state,
                // package: [...state.package, action.payload],
                package: state.package.map((x) =>
                x.id === action.payload.id ? action.payload : x
                ),
            };
        case PACKAGE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // case REMOVE_PACKAGE_REQUEST : 
        //     return {
        //         ...state,
        //     };
        // case REMOVE_PACKAGE_SUCCESS :
        //     return {
        //         ...state,
        //         package: state.package.filter((x) => x.id !== action.payload.id),
        //     };
        // case REMOVE_PACKAGE_FAIL :
        //     return {
        //         ...state,
        //         error: action.payload,
        //     };

        default:
            return state;
    }
}
import React, { useState } from "react";
import AWS from "aws-sdk";
import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_BASE_URL,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_REGION,
  BUCKET_SECRET_KEY,
} from "../constants";

const s3 = new AWS.S3({
  // accessKeyId: "42517f1906a1e312810ee9ed7a0307ab",
  // secretAccessKey: "5dcddf9ff5cb2705f6ac2fbf65712c825b9969278e4a1efb551bd8f98e79f2ee",
  region: BUCKET_REGION,
  endpoint: BUCKET_ENDPOINT,
  credentials: {
    accessKeyId: BUCKET_ACCESS_KEY_ID,
    secretAccessKey: BUCKET_SECRET_KEY,
  },
});
const UploadImageToS3WithNativeSdk = () => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const uploadFile = async (file) => {
    const today = new Date();
    const tsForUnique = today.getTime();
    //   const { name } = req.body;
    const filePath = file.name;

    const params = {
      Bucket: BUCKET_NAME,
      Key: filePath,
      Body: file,

      // ACL: 'public-read',
    };

    await s3
      .upload(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        //   console.log("pro==>", Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
    // let url="";
    // await multipartUpload();
    const url = filePath;
  };
  return (
    <div>
      <div>Native SDK File Upload Progress is {progress}%</div>
      <input type="file" onChange={handleFileInput} />
      <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
    </div>
  );
};
export default UploadImageToS3WithNativeSdk;

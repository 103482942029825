import React from 'react'
import { NavLink } from 'react-router-dom'

const SuperAdminShiftCards = () => {
  return (
    <>
        <div className="row">
            <div className="col-md-6 d-flex">
                <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Who's On Now <span className="badge badge-pill bg-success">16</span></h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table custom-table mb-0">
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Time-In</th>
                                <th>Shift Timing</th>
                                <th>Location</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <h2 className="table-avatar">
                                        <NavLink to="#" className="avatar"><img alt="" src="assets/img/avatar-19.jpg"/></NavLink>
                                        <NavLink to="profile.html">Barry Cuda <span>Unarmed Officer</span></NavLink>
                                    </h2>
                                </td>
                                <td>16:43</td>
                                <td>16:30 - 21:00</td>
                                <td>
                                    <span className="badge bg-inverse-warning">Rd Museum</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="table-avatar">
                                        <NavLink to="#" className="avatar"><img alt="" src="assets/img/avatar-19.jpg"/></NavLink>
                                        <NavLink to="profile.html">Barry Cuda <span>Unarmed Officer</span></NavLink>
                                    </h2>
                                </td>
                                <td>14:43</td>
                                <td>16:30 - 21:00</td>
                                <td>
                                    <span className="badge bg-success">Madame tussauds</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="table-avatar">
                                        <NavLink to="#" className="avatar"><img alt="" src="assets/img/avatar-19.jpg"/></NavLink>
                                        <NavLink to="profile.html">Barry Cuda <span>Unarmed Officer</span></NavLink>
                                    </h2>
                                </td>
                                <td>15:43</td>
                                <td>16:30 - 21:00</td>
                                <td>
                                    <span className="badge bg-info">White House</span>
                                </td>
                            </tr>
                                <tr>
                                <td>
                                    <h2 className="table-avatar">
                                        <NavLink to="#" className="avatar"><img alt="" src="assets/img/avatar-19.jpg"/></NavLink>
                                        <NavLink to="profile.html">Barry Cuda <span>Unarmed Officer</span></NavLink>
                                    </h2>
                                </td>
                                <td>16:43</td>
                                <td>16:30 - 21:00</td>
                                <td>
                                    <span className="badge bg-inverse-warning">Rd Museum</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2 className="table-avatar">
                                        <NavLink to="#" className="avatar"><img alt="" src="assets/img/avatar-19.jpg"/></NavLink>
                                        <NavLink to="profile.html">Barry Cuda <span>Unarmed Officer</span></NavLink>
                                    </h2>
                                </td>
                                <td>14:43</td>
                                <td>16:30 - 21:00</td>
                                <td>
                                    <span className="badge bg-success">Madame tussauds</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink to="recent-clock.html">View all Active Employees</NavLink>
                </div>
                </div>
            </div>


            <div className="col-md-6 d-flex">
                <div className="card card-table flex-fill">
                <div className="card-header">
                    <h3 className="card-title mb-0">Upcoming Shifts</h3>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table custom-table mb-0">
                            <thead>
                            <tr>
                                <th>Locations </th>
                                <th>Start Date</th>
                                <th>Work Duration</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <h2><NavLink to="location-view.html">Madame tussauds</NavLink></h2>
                                    <small className="block text-ellipsis">
                                    <span className="text-muted">Timings :</span> <span>16:30 - 21:00</span>,
                                    <span className="text-muted">Guards Assigned :</span> <span>9</span> 
                                    </small>
                                </td>
                                <td>
                                    23-August-2021
                                </td>
                                <td>
                                    4 weeks
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2><NavLink to="location-view.html">Madame tussauds</NavLink></h2>
                                    <small className="block text-ellipsis">
                                    <span className="text-muted">Timings :</span> <span>16:30 - 21:00</span>,
                                    <span className="text-muted">Guards Assigned :</span> <span>9</span> 
                                    </small>
                                </td>
                                <td>
                                    23-August-2021
                                </td>
                                <td>
                                    4 weeks
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2><NavLink to="location-view.html">Madame tussauds</NavLink></h2>
                                    <small className="block text-ellipsis">
                                    <span className="text-muted">Timings :</span> <span>16:30 - 21:00</span>,
                                    <span className="text-muted">Guards Assigned :</span> <span>9</span> 
                                    </small>
                                </td>
                                <td>
                                    23-August-2021
                                </td>
                                <td>
                                    4 weeks
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2><NavLink to="location-view.html">Madame tussauds</NavLink></h2>
                                    <small className="block text-ellipsis">
                                    <span className="text-muted">Timings :</span> <span>16:30 - 21:00</span>,
                                    <span className="text-muted">Guards Assigned :</span> <span>9</span> 
                                    </small>
                                </td>
                                <td>
                                    23-August-2021
                                </td>
                                <td>
                                    4 weeks
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h2><NavLink to="location-view.html">Madame tussauds</NavLink></h2>
                                    <small className="block text-ellipsis">
                                    <span className="text-muted">Timings :</span> <span>16:30 - 21:00</span>,
                                    <span className="text-muted">Guards Assigned :</span> <span>9</span> 
                                    </small>
                                </td>
                                <td>
                                    23-August-2021
                                </td>
                                <td>
                                    4 weeks
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink to="Locations.html">View all Locations</NavLink>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default SuperAdminShiftCards
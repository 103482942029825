import React, { useEffect, useState } from "react";
import CommentComponent from "./CommentComponent";
import DeletePin from "./DeletePin";
import {
  createMessageWall,
  getCommentById,
  getMessageWallById,
  updateMessageWall,
} from "../../../actions/messageWallActions";
import { Link } from "react-router-dom";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";

const MessageWallRow = ({ current2, userDetail }) => {
  const auth = useSelector((state) => state.auth);
  const messageWall = useSelector((state) => state.messageWall);
  const messageWallData = async () => {
    dispatch(getMessageWallById({ companyId: auth.user.companyId }));
  };
  const [showCommentBox, setShowCommentBox] = useState(null); // Track comment input visibility
  const [showComments, setShowComments] = useState({}); // Track visibility of comments per message

  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const [sortedMessages, setSortedMessages] = useState([]);
  const sortMessages = (messages) => {
    return messageWall?.getMessageWallById.sort((a, b) => {
      // If 'isPin' is true, it goes to the top
      if (a.isPin && !b.isPin) {
        return -1;
      } else if (!a.isPin && b.isPin) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const dispatch = useDispatch();

  const getAllComments = async (id) => {
    dispatch(getCommentById({ messageWallId: id }));
    setShowComments((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle comment visibility for the clicked message
    }));
  };

  const handlePin = async (id) => {
    dispatch(updateMessageWall({ _id: id, isPin: true }));
  };
  useEffect(() => {
    if (
      messageWall.getMessageWallById &&
      messageWall.getMessageWallById.length > 0
    ) {
      // Sort messages and set sorted state
      const sorted = sortMessages([...messageWall.getMessageWallById]);
      setSortedMessages(sorted);
    }
  }, [messageWall.getMessageWallById]);
  useEffect(() => {
    messageWallData();
    // allUsersDataWithoutPagination();
    getAllComments();
  }, []);
  let i = 0;
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString.substring(11, 13));
    const minutes = timeString.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  const formatDate = (date) => {
    const sp = date.split("T");
    const spDash = sp[0].split("-");
    return spDash[1] + "/" + spDash[2] + "/" + spDash[0];
  };

  useEffect(() => {
    if (
      messageWall.getMessageWallById &&
      messageWall.getMessageWallById.length > 0
    ) {
      // Sort messages and set sorted state
      const sorted = sortMessages([...messageWall.getMessageWallById]);
      setSortedMessages(sorted);
    }
  }, [messageWall.getMessageWallById]);

  return (
    <>
      <div class="card-body" key={i}>
        <div class="table-responsive mb-2">
          <table class="table">
            <tr>
              <td>
                <h2 class="table-avatar">
                  <a href="#" class="avatar avatar-sm me-2">
                    {userDetail?.profilePic?.fileUrl ? (
                      <img
                        alt="profilePic"
                        src={BUCKET_BASE_URL + userDetail?.profilePic?.fileUrl}
                        style={{
                          objectFit: "fill",
                          height: "100%",
                        }}
                      />
                    ) : (
                      <img alt="profilePic" src={defaultImg} />
                    )}
                  </a>
                  <a href="#">
                    {/* Coe, Reginld <span>Oct 1, 2024 . 7:35pm</span> */}
                    {userDetail?.firstName} {userDetail?.lastName}
                    <span>
                      {formatDate(current2.date) +
                        " " +
                        formatTime(current2?.date)}
                    </span>
                  </a>
                </h2>
              </td>
              <td class="text-end">
                <i
                  class="la la-trash"
                  style={{ cursor: "pointer" }}
                  onClick={() => openDeleteDropDown(current2.id)}
                ></i>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePin(current2.id)} // Toggle pin status on click
                  className={`fa fa-thumbtack ${
                    current2.isPin ? "bg-warning p-2 rounded" : ""
                  }`}
                ></i>
              </td>
            </tr>
          </table>
        </div>
        <p>{current2.messageOnWall}</p>
        <div>
          <button
            onClick={() => getAllComments(current2.id)}
            className="btn btn-link"
          >
            {showComments[current2.id] ? "Hide Comments" : "See Comments"}
          </button>
          <Link
            to="/dashboard"
            onClick={() =>
              setShowCommentBox((prev) =>
                prev === current2.id ? null : current2.id
              )
            }
          >
            {" "}
            <i className="far fa-comment"></i> Write a comment
          </Link>
        </div>
        <div className="">
          {showComments[current2.id] &&
            messageWall.getCommentById &&
            messageWall.getCommentById.length > 0 &&
            messageWall.getCommentById
              .filter((comment) => comment.messageWallId === current2.id) // Compare IDs
              .map((current3, j) => {
                return (
                  <div className="col-md-12 mb-2" key={j}>
                    <p
                      style={{
                        backgroundColor: "aliceblue",
                        // height: "40px",
                        alignContent: "center",
                        padding: "10px",
                      }}
                    >
                      {current3.comment}
                    </p>
                  </div>
                );
              })}
        </div>
        <DeletePin
          current2={current2}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
        />
        {showCommentBox === current2.id && (
          <CommentComponent
            current={current2}
            setShowCommentBox={setShowCommentBox}
          />
        )}
      </div>
    </>
  );
};

export default MessageWallRow;

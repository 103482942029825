import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ModalAddLeave from "./ModalAddLeave";
import ModalCreateLeave from "./ModalCreateLeave";
import ModalAddHoliday from "./ModalAddHoliday";
import { useSelector, useDispatch } from "react-redux";
import {
  tabLeave,
  tabLeaveReport,
  tabLeaveSetting,
  tabHolidays,
} from "../../../actions/tabActions";

const AdminLeaveNavItem = () => {
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState.viewLeaveNavItem)
  const dispatch = useDispatch();
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // console.log(showDropDown)
  return (
    <>
      {myState.viewLeaveNavItem === "leave" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item active">
                <NavLink
                  to="#leaves"
                  onClick={() => dispatch(tabLeave())}
                  className=""
                >
                  Leave
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-reports"
                  onClick={() => dispatch(tabLeaveReport())}
                  className=""
                >
                  Leave Report
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-settings"
                  onClick={() => dispatch(tabLeaveSetting())}
                  className=""
                >
                  Leave Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#holidays"
                  onClick={() => dispatch(tabHolidays())}
                  className=""
                >
                  Holidays
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-2 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_leave"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Leave{" "}
            </NavLink>
          </div>
          <ModalAddLeave
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        </>
      ) : myState.viewLeaveNavItem === "leavereport" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#leaves"
                  onClick={() => dispatch(tabLeave())}
                  className=""
                >
                  Leave
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#leave-reports"
                  onClick={() => dispatch(tabLeaveReport())}
                  className=""
                >
                  Leave Report
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-settings"
                  onClick={() => dispatch(tabLeaveSetting())}
                  className=""
                >
                  Leave Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#holidays"
                  onClick={() => dispatch(tabHolidays())}
                  className=""
                >
                  Holidays
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-2 float-right ml-auto">
            <NavLink to="#" className="btn add-btn">
              <i className="fa fa-file-pdf-o"></i> PDF
            </NavLink>
          </div>
        </>
      ) : myState.viewLeaveNavItem === "leavesetting" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#leaves"
                  onClick={() => dispatch(tabLeave())}
                  className=""
                >
                  Leave
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-reports"
                  onClick={() => dispatch(tabLeaveReport())}
                  className=""
                >
                  Leave Report
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#leave-settings"
                  onClick={() => dispatch(tabLeaveSetting())}
                  className=""
                >
                  Leave Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#holidays"
                  onClick={() => dispatch(tabHolidays())}
                  className=""
                >
                  Holidays
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-2 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="create_leave"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Create Leave
            </NavLink>
          </div>
          <ModalCreateLeave
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        </>
      ) : myState.viewLeaveNavItem === "holidays" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#leaves"
                  onClick={() => dispatch(tabLeave())}
                  className=""
                >
                  Leave
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-reports"
                  onClick={() => dispatch(tabLeaveReport())}
                  className=""
                >
                  Leave Report
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-settings"
                  onClick={() => dispatch(tabLeaveSetting())}
                  className=""
                >
                  Leave Settings
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#holidays"
                  onClick={() => dispatch(tabHolidays())}
                  className=""
                >
                  Holidays
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-2 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_holiday"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Holiday
            </NavLink>
          </div>
          <ModalAddHoliday
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        </>
      ) : myState.viewLeaveNavItem === "leaveSearch" ? (
        <>
          <div className="col-7 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item active">
                <NavLink
                  to="#leaves"
                  onClick={() => dispatch(tabLeave())}
                  className=""
                >
                  Leave
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-reports"
                  onClick={() => dispatch(tabLeaveReport())}
                  className=""
                >
                  Leave Report
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#leave-settings"
                  onClick={() => dispatch(tabLeaveSetting())}
                  className=""
                >
                  Leave Settings
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#holidays"
                  onClick={() => dispatch(tabHolidays())}
                  className=""
                >
                  Holidays
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-2 float-right ml-auto">
            {/* <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="create_leave" onClick={openDropDown}><i className="fa fa-plus"></i> Create Leave</NavLink> */}
          </div>
          {/* <ShiftSearchTable/> */}
        </>
      ) : (
        <div>Loading Module</div>
      )}
    </>
  );
};
export default AdminLeaveNavItem;

// const [ viewLeaveNavItem, setViewLeaveNavItem ] = useState("leave")
// const handleLeave = (e) => {
//     setViewLeaveNavItem("leave")
// }
// const handleLeaveReport = (e) => {
//     setViewLeaveNavItem("leavereport")
// }
// const handleLeaveSetting= (e) => {
//     setViewLeaveNavItem("leavesetting")
// }
// const handleHolidays = (e) => {
//     setViewLeaveNavItem("holidays")
// }

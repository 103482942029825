import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
// import SearchByEmployee from '../../components/adminComponents/adminSchedulingReport/SearchByEmployee'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
// import AdminAttendanceReport from '../../components/adminComponents/adminAttendReport/AdminAttendanceReport'
import AdminEmpAttendanceSearch from '../../components/adminComponents/adminTimeClock/AdminEmpAttendanceSearch'
import AdminEmpAttendanceSheet from '../../components/adminComponents/adminTimeClock/AdminEmpAttendanceSheet'
import SearchAdminEmpAttendanceSheet from '../../components/adminComponents/adminTimeClock/SearchAdminEmpAttendanceSheet'
import { useSelector } from 'react-redux'

const AdminAttendReport = () => {
    const myState = useSelector((state => state.changeTheTab))
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Attendance Reports" />
                        {/* <SearchByEmployee /> */}
                        {/* <AdminAttendanceReport /> */}
                        <AdminEmpAttendanceSearch />
                        {
                            myState.viewTClk === "attendance" ?
                            <AdminEmpAttendanceSheet />
                            :
                            myState.viewTClk === "attendanceSearch" ?
                            <SearchAdminEmpAttendanceSheet />
                            :
                            <></>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminAttendReport
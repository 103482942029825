import React, { useState } from "react";
import AdminAnnualPlan from "./AdminAnnualPlan";
import AdminSMonthlyPlan from "./AdminSMonthlyPlan";
import AdminSubsPlanTransactions from "./AdminSubsPlanTransactions";

const AdminSubscriptionMenu = () => {
  const [viewSubscriptionMenu, setViewSubscriptionMenu] =
    useState("monthlyplantab");
  const handleMonthlyPlanTab = (e) => {
    setViewSubscriptionMenu("monthlyplantab");
  };
  const handleAnnuallyPlanTab = (e) => {
    setViewSubscriptionMenu("annuallyplantab");
  };

  return (
    <>
      {viewSubscriptionMenu === "monthlyplantab" ? (
        <>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row justify-content-center mb-4">
                <div className="col-auto">
                  <nav className="nav btn-group">
                    <a
                      href="#monthly"
                      className="btn btn-outline-secondary active show"
                      onClick={handleMonthlyPlanTab}
                      data-toggle="tab"
                    >
                      Monthly Plan
                    </a>
                    <a
                      href="#annual"
                      className="btn btn-outline-secondary"
                      onClick={handleAnnuallyPlanTab}
                      data-toggle="tab"
                    >
                      Annual Plan
                    </a>
                  </nav>
                </div>
              </div>
              <AdminSMonthlyPlan />
              <AdminSubsPlanTransactions />
            </div>
          </div>
        </>
      ) : viewSubscriptionMenu === "annuallyplantab" ? (
        <>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="row justify-content-center mb-4">
                <div className="col-auto">
                  <nav className="nav btn-group">
                    <a
                      href="#monthly"
                      className="btn btn-outline-secondary"
                      onClick={handleMonthlyPlanTab}
                      data-toggle="tab"
                    >
                      Monthly Plan
                    </a>
                    <a
                      href="#annual"
                      className="btn btn-outline-secondary active show"
                      onClick={handleAnnuallyPlanTab}
                      data-toggle="tab"
                    >
                      Annual Plan
                    </a>
                  </nav>
                </div>
              </div>
              <AdminAnnualPlan />
              <AdminSubsPlanTransactions />
            </div>
          </div>
        </>
      ) : (
        <div>loading</div>
      )}
      {/* <AdminSubsPlanTransactions /> */}
    </>
  );
};
export default AdminSubscriptionMenu;

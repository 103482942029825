import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DeletePayrollDeduction from './DeletePayrollDeduction'
import EditModalDeductionPayroll from './EditModalDeductionPayroll'
const PayrollDeductionButtonRow = ({current, i, dropDownRow, setDropDownRow}) => {
  const [showDropDown, setshowDropDown] = useState(false)
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  }
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown)
    setshowMoreVertDropDown(false)
  }
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    setshowMoreVertDropDown(false)
  }
  return (
    <>
      <tr key={i} onMouseLeave = {() => setshowMoreVertDropDown(false)}>
        <th>{current.name}</th>
        <td>${current.amount}</td>
        <td className="text-right">
          <div className="dropdown dropdown-action">
            {/* href haaaaaaataaaayaaa haaai */}
            <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => {
              openMoreVertDropDown() 
              setDropDownRow(i)}} style={{cursor: 'pointer'}}><i className="material-icons">more_vert</i></NavLink>
            {
              dropDownRow === i &&
              <>
                <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                  <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_deduction"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                  <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                </div>
              </>
            }
          </div>
        </td>
      </tr>
      <EditModalDeductionPayroll current={current} showDropDown={showDropDown} openDropDown={openDropDown} />
      <DeletePayrollDeduction current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} />
    </>
  )
}
export default PayrollDeductionButtonRow
import React, { useEffect } from "react";
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
import AdminDashboardCard from "../../components/adminComponents/adminDashboardComponents/AdminDashboardCard";
import ShiftTime from "../../components/adminComponents/adminDashboardComponents/ShiftTime";
import AdminHeader from "../../components/adminComponents/AdminHeader";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import EmployeeDetails from "../../components/adminComponents/adminDashboardComponents/EmployeeDetails";
// import { adminDashboard, getShiftAllUsers, getStripeInovicesQuery } from '../../actions/adminActions'
import {
  adminDashboard,
  getStripeInovicesQuery,
} from "../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLocationsWithUsers,
  getCompanyLocations,
} from "../../actions/locationActions";
import { allUsers, allUsersWithoutPaginate } from "../../actions/userActions";
import { monthlyAttendance } from "../../actions/clockActions";
import moment from "moment";
import { companyById } from "../../actions/authActions";
import { getMailByUserId } from "../../actions/mailActions";
import { lastId } from "../../actions/userActions";

const AdminDashboard = () => {
  return (
    <>
      {/* <AdminHeader />
            <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <AdminTittle admin /> */}
            <div className="container">
              <AdminDashboardCard />
              <EmployeeDetails />
            </div>
            {/* <ShiftTime /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;

import React from 'react'
import we1 from "../../assetsgeneral/images/we1.png"
// import we1 from "../../assetsgeneral/images/screenshorts-item-1.png"
import Slider from "react-slick";
// import '../../../src/slick.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Screenshots = () => {
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style,
                    display: "block",
                    background: "#888",
                    margin: "0 -30px", 
                }}
                onClick={onClick}
            />
        );
    }
      
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style,
                    display: "block",
                    cursor: "pointer",
                    position: "absolute",
                    top: "50%",
                    width: "auto",
                    background: "#888",
                    // height: "30px",
                    // width:"30px",

                    // marginTop: "-30px",
                    // padding: "16px",
                    // color: "#888",
                    // fontWeight: "bold",
                    // fontSize: "20px",
                    // borderRadius: "0 3px 3px 0",
                    // userSelect: "none",
                    margin: "0 -30px",
                }}
                onClick={onClick }
            />
        );
    }
    var settings = {
        dots: true,
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        // slidesToShow: 3,
        speed: 1000,
        variableWidth: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <>
            <div className="main-carous-se">
            {/* <section className="screenshorts-area pt-115"> */}
                {/* <div className="screenshorts-bg"></div> */}
                <div className="container">
                    <div className="row blog">
                        <div className="col-md-12">
                            <div className="easyas">
                                <h3> EASY AS 123</h3>
                                <h2> Communiacte, Schedule, and Track <br /> Attendance-all in one place</h2>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="testimonial tesstiomsecsss">
                    <div className="slideshow-container slideshow-wework">
                        <Slider {...settings} >
                            {/* <div className="col-lg-4"> */}
                            <div className="mySlides mymysl" style={{display: 'none'}}>
                                        <img src={we1} className="imgff" alt='ss'/>
                                    </div>
                            {/* </div> */}
                            {/* <div className="col-lg-4"> */}
                            <div className="mySlides mymysl" style={{display: 'none'}}>
                                        <img src={we1} className="imgff" alt='ss'/>
                                    </div>
                            {/* </div> */}
                            {/* <div className="col-lg-4"> */}
                            <div className="mySlides mymysl" style={{display: 'none'}}>
                                        <img src={we1} className="imgff" alt='ss'/>
                                    </div>
                            {/* </div> */}
                            {/* <div className="col-lg-4"> */}
                            <div className="mySlides mymysl" style={{display: 'none'}}>
                                        <img src={we1} className="imgff" alt='ss'/>
                                {/* </div> */}

                                {/* <a className="prev" onclick="plusSlides(-1) "> < </a>
                                <a className="next" onclick="plusSlides(1) "> > </a> */}
                            </div>
                            {/* <div className="dot-container dot-cobte">
                                    <span className="dot" onclick="currentSlide(1)"></span>
                                    <span className="dot" onclick="currentSlide(2)"></span>
                                    <span className="dot" onclick="currentSlide(3)"></span>
                                </div> */}
                        </Slider>
                    </div>
                </div>
            {/* </section> */}
            </div>
        </>
    )
}
export default Screenshots




        {/* <div className="testimonial tesstiomsecsss">
                <div className="slideshow-container slideshow-wework">
                    
                    <div className="mySlides mymysl" style={{display: 'none'}}>
                        <img src={we1} className="imgff" />
                    </div>
                    <div className="mySlides mymysl" style={{display: 'none'}}>
                        <img src={we1} className="imgff" />
                    </div>
                    <div className="mySlides mymysl" style={{display: 'none'}}>
                        <img src={we1} className="imgff" />
                    </div>
                    <a className="prev" onclick="plusSlides(-1)"></a>
                    <a className="next" onclick="plusSlides(1)"></a>
                </div>
                <div className="dot-container dot-cobte">
                    <span className="dot" onclick="currentSlide(1)"></span>
                    <span className="dot active" onclick="currentSlide(2)"></span>
                    <span className="dot" onclick="currentSlide(3)"></span>
                </div>
            </div>
        </div>
    </div>
</div> */}
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import CompaniesEditModal from './CompaniesEditModal'
import CompanyDeleteModal from './CompanyDeleteModal'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'

const CompanySearchedFilterCards = ({ current, i, dropDownRow, setDropDownRow }) => {
    const [showDropDown, setshowDropDown] = useState(false)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
        setshowMoreVertDropDown(false)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
        setshowMoreVertDropDown(false)
    }
    return (
    <>
        <div key={i} onMouseLeave={() => setshowMoreVertDropDown(false)} className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                <div className="profile-widget">
                    <div className="profile-img">
                        <NavLink to={`/client-profile/${current.company.id}`} className="avatar">
                            {
                                current.admin.profilePic &&
                                current.admin.profilePic.fileUrl ?
                                <img alt="profilePic" src={BUCKET_BASE_URL + current.admin.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                :
                                <img alt="profilePic" src={defaultImg} />
                            }
                        </NavLink>
                    </div>
                    <div className="dropdown profile-action">
                        <NavLink to="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={() => {
                        openMoreVertDropDown()
                        setDropDownRow(i)}} style={{cursor: 'pointer'}} aria-expanded="false"><i className="material-icons">more_vert</i></NavLink>
                        {
                            dropDownRow === i &&
                            <>
                                <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                    <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_addition"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                    <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                </div>
                            </>
                        }
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis"><NavLink to={`/client-profile/${current.company.id}`}>{current.company.companyName}</NavLink></h4>
                    <h5 className="user-name m-t-10 mb-0 text-ellipsis"><NavLink to={`/client-profile/${current.company.id}`}>{current.admin.firstName + " " + current.admin.lastName} </NavLink></h5>
                    <div className="small text-muted">{current.admin.jobTitle ? current.admin.jobTitle : " " }</div>
                    <NavLink to="/compose" className="btn btn-white btn-sm m-t-10">Message</NavLink>
                    <NavLink to={`/client-profile/${current.company.id}`} className="btn btn-white btn-sm m-t-10">View Profile</NavLink>
                </div>
            </div>
            <CompaniesEditModal current={current} showDropDown={showDropDown} openDropDown={openDropDown} />
            <CompanyDeleteModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} />
    </>
  )
}
export default CompanySearchedFilterCards
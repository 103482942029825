import React, { useState } from 'react'
import SAdminEditModal from '../superAdminPoliciesAdministration/SAdminEditModal'
import SAdminPolicyDeleteModal from '../superAdminPoliciesAdministration/SAdminPolicyDeleteModal'
const SAdminUsersTable = () => {
    const [showDropDown, setshowDropDown] = useState(false)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
        setshowMoreVertDropDown(false)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
        setshowMoreVertDropDown(false)
    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped custom-table datatable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Company</th>
                                    <th>Created Date</th>
                                    <th>Role</th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <a href="/profile" className="avatar"><img src="assets/img/profiles/avatar-21.jpg" alt="" /></a>
                                            <a href="#">Daniel Porter <span>Super Admin</span></a>
                                        </h2>
                                    </td>
                                    <td>Loremipsum@example.com</td>
                                    <td>Riyo Work</td>
                                    <td>1 Jan 2013</td>
                                    <td>
                                        <span className="badge bg-inverse-danger">Admin</span>
                                    </td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                                <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <SAdminEditModal showDropDown={showDropDown} openDropDown={openDropDown}/>
                                <SAdminPolicyDeleteModal showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <a href="/profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></a>
                                            <a href="#">Lorem ipsum <span>Web Designer</span></a>
                                        </h2>
                                    </td>
                                    <td>Loremipsum@example.com</td>
                                    <td>Riyo Work</td>
                                    <td>1 Jan 2013</td>
                                    <td>
                                        <span className="badge bg-inverse-success">Employee</span>
                                    </td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <a href="/profile" className="avatar"><img src="assets/img/profiles/avatar-19.jpg" alt="" /></a>
                                            <a href="#">Lorem ipsum <span>Global Technologies</span></a>
                                        </h2>
                                    </td>
                                    <td>Loremipsum@example.com</td>
                                    <td>Iconic Guard</td>
                                    <td>1 Jan 2013</td>
                                    <td>
                                        <span className="badge bg-inverse-info">Client</span>
                                    </td>
                                    <td className="text-right">
                                        <div className="dropdown dropdown-action">
                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SAdminUsersTable
import React from 'react'
import { NavLink } from 'react-router-dom'

const SharedFiles = () => {
    return (
        <>
            {/* <div className="row"> */}
                <div className="col-md-6 d-flex">
                    <div className="file-wrap midsfl midsfl4">
                        <div className="file-cont-wrap">
                            <div className="file-cont-inner">
                                <div className="file-cont-header">
                                    <span>Shared Files</span>
                                </div>
                                <div className="file-content">
                                    <div className="file-body">
                                        <div className="file-scroll">
                                            <div className="file-content-inner">
                                                <div className="row row-sm">
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                                        <div className="card card-file">
                                                            <div className="dropdown-file">
                                                                <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="card-file-thumb">
                                                                <i className="fa fa-file-pdf-o"></i>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6><NavLink to="">Sample.pdf</NavLink></h6>
                                                                <span>10.45kb</span>
                                                            </div>
                                                            <div className="card-footer">
                                                                <span className="d-none d-sm-inline">Last Modified: </span>1 min ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                                        <div className="card card-file">
                                                            <div className="dropdown-file">
                                                                <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="card-file-thumb">
                                                                <i className="fa fa-file-word-o"></i>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6><NavLink to="">Document.docx</NavLink></h6>
                                                                <span>22.67kb</span>
                                                            </div>
                                                            <div className="card-footer">
                                                                <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                                        <div className="card card-file">
                                                            <div className="dropdown-file">
                                                                <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="card-file-thumb">
                                                                <i className="fa fa-file-image-o"></i>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6><NavLink to="">icon.png</NavLink></h6>
                                                                <span>12.47kb</span>
                                                            </div>
                                                            <div className="card-footer">
                                                                <span className="d-none d-sm-inline">Last Modified: </span>1 hour ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 col-xl-3">
                                                        <div className="card card-file">
                                                            <div className="dropdown-file">
                                                                <NavLink to="" className="dropdown-link" data-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></NavLink>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <NavLink to="#" className="dropdown-item">View Details</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Share</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Download</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Rename</NavLink>
                                                                    <NavLink to="#" className="dropdown-item">Delete</NavLink>
                                                                </div>
                                                            </div>
                                                            <div className="card-file-thumb">
                                                                <i className="fa fa-file-excel-o"></i>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6><NavLink to="">Users.xls</NavLink></h6>
                                                                <span>35.11kb</span>
                                                            </div>
                                                            <div className="card-footer">
                                                                <span className="d-none d-sm-inline">Last Modified: </span>30 mins ago
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}

        </>
    )
}

export default SharedFiles
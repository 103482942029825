import React from 'react'
import { useSelector  } from 'react-redux'
// import { NavLink } from 'react-router-dom';
// import { useDispatch  } from 'react-redux'

const ProfileLocations = (current) => {
    const auth = useSelector((state) => state.auth);
    // const user = useSelector((state) => state.user);
    // const location = useSelector((state) => state.location);
    return (
        <>
            <div className="" id="emp_Locations">
                {/* "pro-overview tab-pane fade show active"
                remove this class  */}
                {
                    auth.user.location &&
                    auth.user.location.length > 0 &&
                    auth.user.location.map((current, i) => {
                        return (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tab-content milocpmn">
                                    <div className="card card-table mb-0">
                                        <div className="card-header">
                                            <h4 className="card-title mb-0">
                                                <strong>Location: </strong> {current.name}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )})}
            </div>
        </>
    )
}
export default ProfileLocations
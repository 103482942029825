import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOvertimeTypeByCompanyId,
  removeOvertimeType,
} from "../../../actions/payrollAction";

const DeletePayrollOvertime = ({
  showDeleteDropDown,
  openDeleteDropDown,
  current,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [dataUpdate, setDataUpdate] = useState({
    _id: "",
    delete: true,
  });
  const valueUpdate = () => {
    if (current !== undefined) {
      setDataUpdate({
        _id: current.id,
        delete: true,
      });
    }
  };
  useEffect(() => {
    valueUpdate();
    // console.log(dataUpdate, "dataUpdate")
  }, [current]);
  const allOvertimeData = async () => {
    dispatch(getOvertimeTypeByCompanyId({ companyId: auth.user.companyId }));
  };
  const handleSubmit = async () => {
    dispatch(removeOvertimeType(dataUpdate));
    openDeleteDropDown();
    setTimeout(() => allOvertimeData(), 1000);
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${
          showDeleteDropDown ? "show" : "hide"
        }`}
      ></div>
      <div
        className={`modal custom-modal fade ${
          showDeleteDropDown ? "show" : "hide"
        }`}
        id="delete_warn"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeleteDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Payroll Overtime</h3>
                <p>Are you sure want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      className="btn btn-primary continue-btn"
                      onClick={handleSubmit}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      data-dismiss="modal"
                      className="btn btn-primary cancel-btn"
                      onClick={() => openDeleteDropDown()}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DeletePayrollOvertime;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AddLPModal from "./AddLPModal";
import AddBRModal from "./AddBRModal";
import { useSelector, useDispatch } from "react-redux";
import { tabAllEmployee } from "../../../actions/tabActions";
import { tabLocationsPosition } from "../../../actions/tabActions";
import { tabBreakRules } from "../../../actions/tabActions";

const AdminEmployeeMenu = () => {
  const myState = useSelector((state) => state.changeTheTab);
  // console.log("***********", myState)
  const dispatch = useDispatch();

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // console.log(showDropDown)
  return (
    <>
      {/* {myState.viewEmployeeMenu === "employee" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item active">
                <NavLink
                  to="#employees-add"
                  onClick={() => dispatch(tabAllEmployee())}
                  className=""
                >
                  All Employee
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#location-position"
                  onClick={() => dispatch(tabLocationsPosition())}
                  className=""
                >
                  Locations Position
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#break-rules"
                  onClick={() => dispatch(tabBreakRules())}
                  className=""
                >
                  Break Rules
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto">
            <NavLink to="/employees-add" className="btn add-btn">
              <i className="fa fa-plus"></i> Add Employee
            </NavLink>
          </div>
        </>
      ) : myState.viewEmployeeMenu === "locationposition" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#employees-add"
                  onClick={() => dispatch(tabAllEmployee())}
                  className=""
                >
                  All Employee
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#location-position"
                  onClick={() => dispatch(tabLocationsPosition())}
                  className=""
                >
                  Locations Position
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#break-rules"
                  onClick={() => dispatch(tabBreakRules())}
                  className=""
                >
                  Break Rules
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_plan"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Location
            </NavLink>
          </div>
          <AddLPModal showDropDown={showDropDown} openDropDown={openDropDown} />
        </>
      ) : myState.viewEmployeeMenu === "breakrules" ? (
        <>
          <div className="col-4 mitbsnav">
            <ul className="nav nav-pills nav-justified">
              <li className="nav-item">
                <NavLink
                  to="#employees-add"
                  onClick={() => dispatch(tabAllEmployee())}
                  className=""
                >
                  All Employee
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="#location-position"
                  onClick={() => dispatch(tabLocationsPosition())}
                  className=""
                >
                  Locations Position
                </NavLink>
              </li>
              <li className="nav-item active">
                <NavLink
                  to="#break-rules"
                  onClick={() => dispatch(tabBreakRules())}
                  className=""
                >
                  Break Rules
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-4 float-right ml-auto">
            <NavLink
              to="#"
              className="btn add-btn"
              data-toggle="modal"
              data-target="#add_plan"
              onClick={openDropDown}
            >
              <i className="fa fa-plus"></i> Add Break Rules
            </NavLink>
          </div>
          <AddBRModal showDropDown={showDropDown} openDropDown={openDropDown} />
        </>
      ) : (
        <div>Loading</div>
      )} */}
    </>
  );
};
export default AdminEmployeeMenu;

import React, { useState, useEffect } from "react";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import cake from "../../../assetsAdmin/img/cakeundraw_Birthday_cake_re_bsw5.png";
import shift1 from "../../../assetsAdmin/img/undraw_Time_management_re_tk5w.png";
import shift2 from "../../../assetsAdmin/img/undraw_Site_stats_re_ejgy.png";
import tabel from "../../../assets/img/icons/tabel.png";
// import { NavLink, useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { shiftPageData, shiftDashboard } from "../../../actions/shiftActions";
import { getCompanyLocations } from "../../../actions/locationActions";
import { companyHolidays } from "../../../actions/holidayActions";
import {
  clockIn,
  clockUpdate,
  clockExist,
} from "../../../actions/clockActions";
import { employmentAnnivarsary } from "../../../actions/userActions";
import { getUserAnnualStatusByUserId } from "../../../actions/leaveActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { companyById } from "../../../actions/authActions";
import { userRecentFiles } from "../../../actions/fileManagerActions";
import SharedFile from "./SharedFile";
import { BUCKET_BASE_URL } from "../../../constants";
import TimeComponent from "../../TimeComponent";
import { toast } from "react-hot-toast";
import AddLeaveModal from "../employeeLeavesComponents/AddLeaveModal";
// import { getMailByUserId } from "../../../actions/mailActions";

const EmployeeMain = () => {
  const auth = useSelector((state) => state.auth);
  const shift = useSelector((state) => state.shift);
  const clock = useSelector((state) => state.clock);
  const holiday = useSelector((state) => state.holiday);
  const user = useSelector((state) => state.user);
  // const location = useSelector((state) => state.location);
  const leave = useSelector((state) => state.leave);
  let remaining = 0;
  let taken = 0;
  let daysLeaveTotal = 0;
  const [clockOutReq, setClockOutReq] = useState(false);
  const [clockInReq, setClockInReq] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [coordinatesClockOut, setCoordinatesClockOut] = useState({
    lat: null,
    lng: null,
  });
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = new Date(moment().format("YYYY-MM-DD"));
  const dt = today.toISOString().substring(0, 10);
  const clockDate = new Date(moment().format("YYYY-MM-DD"));
  const shiftData = async (e) => {
    dispatch(shiftPageData({ userId: auth.user.id, date: dt }));
    dispatch(
      shiftDashboard({
        userId: auth.user.id,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  };
  const locationData = async (e) => {
    dispatch(getCompanyLocations({ companyId: auth.user.companyId }));
  };
  const recentFilesData = async (e) => {
    dispatch(userRecentFiles({ userId: auth.user.id }));
  };
  const holidayData = async (e) => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    dispatch(
      companyHolidays({ companyId: auth.user.companyId, date: newCurrDate })
    );
  };
  const checkClock = async () => {
    const dd = moment().format("YYYY-MM-DD");
    // const bd = new Date()
    // let month = bd.getMonth()+1
    // if ( month < 10){
    //     month = "0"+month
    // }
    // const todayRegex = bd.getFullYear()+"-"+month+"-"+dd.substring(0,10)
    const todayRegex = dd.substring(0, 10);
    // console.log(todayRegex);
    const iden = "newDate" + todayRegex + "user" + auth.user.id;
    dispatch(
      clockExist({
        dayIdentifier: iden,
        userId: auth.user.id,
        dateForCheck: todayRegex,
      })
    );
  };
  const annivarsaryData = async (e) => {
    const currDate = moment().format();
    const arr = currDate.split("T");
    const newCurrDate = arr[0];
    await dispatch(
      employmentAnnivarsary({
        companyId: auth.user.companyId,
        date: newCurrDate,
      })
    );
    // console.log("first")
  };
  // const mailData = async () => {
  //     dispatch(getMailByUserId({userId: auth.user.id}))
  // }
  const annualLeaveStatusData = async (e) => {
    dispatch(
      getUserAnnualStatusByUserId({
        userId: auth.user.id,
        date: moment().format(),
      })
    );
  };
  const companyData = async () => {
    dispatch(companyById({ companyId: auth.user.companyId }));
  };
  useEffect(() => {
    companyData();
    shiftData();
    locationData();
    holidayData();
    checkClock();
    annivarsaryData();
    annualLeaveStatusData();
    recentFilesData();
    locationEffect();
    // mailData()
  }, []);
  // if (auth.tokens === null) {
  //   navigate('/login')
  // }
  const locationEffect = () => {
    // if (coordinates.lat && coordinates.lng && coordinatesClockOut.lat && coordinatesClockOut.lng){
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setCoordinatesClockOut({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    // }
  };

  const clockInUser = async () => {
    // if (navigator.geolocation) {
    //     navigator.geolocation.watchPosition((position) => {
    //         setCoordinates({
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //         })
    //     });
    // }
    setClockInReq(true);
  };
  const clockOutUser = async () => {
    // if (navigator.geolocation) {
    //     navigator.geolocation.watchPosition((position) => {
    //         setCoordinatesClockOut({
    //             lat: position.coords.latitude,
    //             lng: position.coords.longitude
    //         })
    //     });
    // }
    setClockOutReq(true);
  };

  const clockOutButton = async () => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    if (auth.user.locationRequired) {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockOut: newCurrDate,
          longitude: coordinatesClockOut.lng,
          latitude: coordinatesClockOut.lat,
        })
      );
    } else {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockOut: newCurrDate,
        })
      );
    }
  };
  useEffect(() => {
    if (clockOutReq) {
      if (auth.user.locationRequired === true) {
        if (
          coordinatesClockOut.lat !== null &&
          coordinatesClockOut.lng !== null
        ) {
          clockOutButton();
          setClockOutReq(false);
        } else {
          toast.error(
            "location is denied, please refresh the page and give access to your location "
          );
          // alert(
          //   "location is denied, please refresh the page and give access to your location "
          // );
        }
      } else {
        clockOutButton();
        setClockOutReq(false);
      }
    }
    // console.log(coordinatesClockOut, "<-");
    // }, [coordinatesClockOut.lat])
  }, [clockOutReq]);
  // }, [coordinatesClockOut.lat, coordinatesClockOut.lng, clockOutReq === true])
  const clockInButton = async (e) => {
    const currDate = moment().format();
    // console.log("curr date==>", currDate)
    // const arr = currDate.split("+");
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    if (auth.user.locationRequired) {
      dispatch(
        clockIn({
          userId: auth.user.id,
          companyId: auth.user.companyId,
          // date: clockDate.toLocaleString(), longitude: coordinates.lng, latitude: coordinates.lat}))
          date: newCurrDate,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        })
      );
    } else {
      dispatch(
        clockIn({
          userId: auth.user.id,
          companyId: auth.user.companyId,
          // date: clockDate.toLocaleString(), longitude: coordinates.lng, latitude: coordinates.lat}))
          date: newCurrDate,
        })
      );
    }
  };
  const clockInUpdate = async (e) => {
    const currDate = moment().format();
    // console.log("curr date==>", currDate)

    // const arr = currDate.split("+");
    const arr = currDate.slice(0, -6);

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    if (auth.user.locationRequired) {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockIn: newCurrDate,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        })
      );
    } else {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockIn: newCurrDate,
        })
      );
    }
  };

  useEffect(() => {
    if (clockInReq) {
      if (auth.user.locationRequired) {
        // console.log("location hai ===>");

        if (coordinates.lat !== null && coordinates.lng !== null) {
          if (clock.clockData !== null) {
            clockInUpdate();
            setClockInReq(false);
          } else {
            clockInButton();
            setClockInReq(false);
          }
        } else {
          toast.error(
            "location is denied, please refresh the page and give access to your location "
          );
          // alert(
          //   "location is denied, please refresh the page and give access to your location "
          // );
        }
      } else {
        // console.log("location nahi hai ===>", clock);
        if (clock.clockData !== null) {
          clockInUpdate();
          setClockInReq(false);
        } else {
          clockInButton();
          setClockInReq(false);
        }
      }
    }
    // },[coordinates.lat])
  }, [clockInReq]);

  // console.log("clock in req==>", clockInReq);

  // },[coordinates.lat, coordinates.lng, clockInReq === true])
  // console.log(coordinates);
  // console.log(coordinatesClockOut)
  const currentTimeadmin = () => {
    let hours = new Date().getHours();
    let amPm = "am";
    if (hours == 0) {
      hours = 12;
    }
    if (hours > 12) {
      hours = hours - 12;
      amPm = "pm";
    }
    let mints = new Date().getMinutes();
    // console.log(mints)
    if (mints < 10) {
      mints = "0" + mints;
      // console.log(mints)
    }
    const CurrDate = hours + ":" + mints + " " + amPm;
    return CurrDate;
  };
  const [timer, setTime] = useState(currentTimeadmin());
  useEffect(() => {
    const intervel = setInterval(() => setTime(currentTimeadmin()), 1000);
    return () => clearInterval(intervel);
  }, []);

  let daysArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const firstName = auth.user.firstName;
  const lastName = auth.user.lastName;

  let day = new Date().getDay();
  let month = new Date().getMonth();

  let dayName = daysArray[day];
  let monthName = monthArray[month];
  const currentTime = new Date();
  const dateToday = `${dayName}, ${currentTime.getDate()} ${monthName} ${currentTime.getFullYear()}`;
  const filemanager = useSelector((state) => state.filemanager);

  const [showDropDown, setshowDropDown] = useState(false);

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* <div className="row">
              <div className="col-md-12">
                <div className="welcome-box">
                  <div className="welcome-img">
                    {auth.user && auth.user.profilePic ? (
                      auth.user.profilePic.fileUrl && (
                        <img
                          alt="Profile Pic"
                          src={BUCKET_BASE_URL + auth.user.profilePic.fileUrl}
                          style={{ objectFit: "fill", height: "100%" }}
                        />
                      )
                    ) : (
                      <img alt="" src={defaultImg} />
                    )}
                  </div>
                  <div className="welcome-det">
                    <h3>
                      Welcome, {firstName} {lastName}
                    </h3>
                    <p>{dateToday}</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="container">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card flex-fill">
                    <div class="card-body p-3">
                      <div class="d-flex justify-content-between align-items-center bg-ligter mb-3">
                        <h5 class="card-title2">Today </h5>
                      </div>
                      <div class="d-sm-block d-lg-flex justify-content-between align-items-center p-2">
                        <div class="dash-count">
                          <div class="dash-title">Time Clock</div>
                          <span style={{ fontSize: "medium" }}>
                            {dateToday} <br /> {timer}
                          </span>
                        </div>
                        <div>
                          {/* <div class="bg-ligter border-dark-new mb-3"> */}
                          <p>
                            {" "}
                            <TimeComponent clockData={clock.clockData} />
                          </p>
                          {/* </div> */}
                          <div class="text-center mb-2">
                            {clock.clockInButton && (
                              <button
                                class="btn btn-success btn-sm "
                                onClick={clockInUser}
                              >
                                Clock In
                              </button>
                            )}
                            {!clock.clockInButton && (
                              <button
                                class="btn btn-success btn-sm "
                                onClick={clockOutUser}
                              >
                                Clock Out
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card flex-fill">
                    <div class="card-body p-3">
                      <div class="d-flex justify-content-between align-items-center bg-ligter mb-2">
                        <h5 class="card-title2">Tomorrow </h5>
                      </div>
                      <div class="tableimg text-center p-2">
                        <img src={tabel} alt="" class="mb-3" />
                        <p class="text-center">
                          You are not scheduled on tomorrow shift.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="card flex-fill">
                    <div className="card-body p-3">
                      <div className="d-flex justify-content-between align-items-center bg-ligter mb-2">
                        <h5 className="card-title2">Next seven days </h5>
                      </div>

                      {/* Checking if there are shifts scheduled */}
                      {shift.thisWeek && shift.thisWeek.length > 0 ? (
                        <div className="dash-sec-content">
                          {/* Loop through each shift */}
                          {shift.thisWeek.map((current, i) => (
                            <div className="dash-info-list" key={i}>
                              <div className="dash-card">
                                <div className="dash-card-container">
                                  <div className="dash-card-icon">
                                    <i className="fa fa-suitcase"></i>
                                  </div>
                                  <div className="dash-card-content miminctbx">
                                    <p>
                                      You will be working as{" "}
                                      {auth.user.jobTitle}
                                    </p>
                                    {/* Display shift location and time */}
                                    {current.location ? (
                                      <div className="mimintpbx">
                                        <span>
                                          <i className="fa fa-clock-o"></i>
                                        </span>{" "}
                                        On {current.startTime.substring(5, 10)},{" "}
                                        {current.startTime.substring(11, 16)} -{" "}
                                        {current.endTime.substring(11, 16)},{" "}
                                        <span>
                                          <i className="fa fa-map-marker"></i>
                                        </span>{" "}
                                        <NavLink
                                          to={
                                            current.location
                                              ? `/location-view/${current.location.id}`
                                              : "#"
                                          }
                                        >
                                          {current.location.name}
                                        </NavLink>
                                      </div>
                                    ) : (
                                      <div className="mimintpbx">
                                        <span>
                                          <i className="fa fa-clock-o"></i>
                                        </span>{" "}
                                        On {current.startTime.substring(5, 10)},{" "}
                                        {current.startTime.substring(11, 16)} -{" "}
                                        {current.endTime.substring(11, 16)}{" "}
                                        <span>
                                          <i className=""></i>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        // If no shifts are scheduled, show this section
                        <div className="tableimg text-center p-2">
                          <img src={tabel} alt="No shifts" className="mb-3" />
                          <p className="text-center">
                            You are not scheduled on any shifts yet.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-xl-3">
                  <div class="card flex-fill">
                    <div class="card-body p-3">
                      <div class="d-flex justify-content-between align-items-center bg-ligter mb-2">
                        <h5 class="card-title2">Your Leaves </h5>
                      </div>
                      <div class="dash-widget-header justify-content-between mb-2">
                        <div class="dash-count">
                          <div>
                            <p>Leave Taken</p>
                          </div>
                        </div>
                        <div>
                          {leave.leaveAnnualStatus &&
                            leave.leaveAnnualStatus.length > 0 && (
                              <h5>{(taken = daysLeaveTotal - remaining)}</h5>
                            )}
                        </div>
                      </div>
                      <div class="dash-widget-header justify-content-between mb-3">
                        <div class="dash-count">
                          <div>
                            <p>Remaining</p>
                          </div>
                        </div>
                        <div>
                          {leave.leaveAnnualStatus &&
                            leave.leaveAnnualStatus.length > 0 && (
                              <h5>{remaining}</h5>
                            )}
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          class="btn btn-success btn-sm "
                          onClick={openDropDown}
                        >
                          Apply Leaves
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {user?.annivarsary && (
                  <div class="col-xl-3">
                    <div class="card flex-fill">
                      <div class="card-body p-3">
                        <div class="d-flex justify-content-between align-items-center bg-ligter mb-2">
                          <h5 class="card-title2">Annual Anniversaries </h5>
                        </div>

                        <div class="table-responsive">
                          <table class="table table-hover">
                            <tbody>
                              {user?.annivarsary &&
                                user?.annivarsary?.length > 0 &&
                                user?.annivarsary?.map(
                                  (current, i) =>
                                    current.length > 0 &&
                                    current.map((e, index) => (
                                      <tr key={index} class="table table-hover">
                                        <td>
                                          <h2 class="table-avatar">
                                            <Link to="#">
                                              {e.firstName} {e.lastName}{" "}
                                              <span>
                                                {i === 0
                                                  ? "Today"
                                                  : i === 1
                                                  ? "Tomorrow"
                                                  : "In " +
                                                    (i + 1) +
                                                    " days"}{" "}
                                                |{" "}
                                                {parseInt(
                                                  e.startingDate.substring(0, 4)
                                                ) ===
                                                parseInt(
                                                  moment()
                                                    .format()
                                                    .substring(0, 4)
                                                )
                                                  ? "Joined Recently"
                                                  : parseInt(
                                                      moment()
                                                        .format()
                                                        .substring(0, 4)
                                                    ) -
                                                    parseInt(
                                                      e.startingDate.substring(
                                                        0,
                                                        4
                                                      )
                                                    ) +
                                                    " Year"}
                                              </span>
                                            </Link>
                                          </h2>
                                        </td>
                                        <td class="text-end">
                                          {e.profilePic ? (
                                            <Link
                                              to="#"
                                              class="avatar avatar-sm me-2"
                                            >
                                              <img
                                                class="avatar-img rounded-circle"
                                                src={
                                                  BUCKET_BASE_URL +
                                                  e.profilePic.fileUrl
                                                }
                                                alt="User Image"
                                              />
                                            </Link>
                                          ) : (
                                            <Link
                                              to="#"
                                              class="avatar avatar-sm me-2"
                                            >
                                              <img
                                                class="avatar-img rounded-circle"
                                                src={defaultImg}
                                                alt="User Image"
                                              />
                                            </Link>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                )}
                            </tbody>
                          </table>
                          {user.annivarsary.length === 0 && (
                            <div class="tableimg text-center p-2">
                              <img src={cake} alt="" class="mb-3" />
                              <p class="text-center">No Anniversaries</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddLeaveModal showDropDown={showDropDown} openDropDown={openDropDown} />
    </>
  );
};

export default EmployeeMain;

{
  /* <div className="row">
<div className="col-lg-8 col-md-8">
  <section className="dash-section">
    <h1 className="dash-sec-title">Today</h1>
    <div className="dash-sec-content">
      <div className="dash-info-list">
        <a className="dash-card">
          <div className="dash-card-container">
            <div className="dash-card-container">
              <div className="dash-card-icon">
                <i className="fa fa-suitcase"></i>
              </div>
              <div className="dash-card-content">
                <p>
                  Time Clock{" "}
                  <span className="text-success">
                    {dateToday} <br /> {timer}
                  </span>
                </p>
              </div>
            </div>
            <div className="dash-card-avatars">
              <div className="request-btn midshbtn">
                <TimeComponent clockData={clock.clockData} />
                {clock.clockInButton && (
                  <p
                    className="btn btn-primary"
                    onClick={clockInUser}
                  >
                    Clock-In
                  </p>
                )}
                {!clock.clockInButton && (
                  <p
                    className="btn btn-primary"
                    onClick={clockOutUser}
                  >
                    Clock Out
                  </p>
                )}
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section> */
}
// <section className="dash-section">
//   {shift && shift.tomorrowShift ? (
//     Object.keys(shift).length !== 0 && (
//       <>
//         <h1 className="dash-sec-title">Tomorrow</h1>
//         <div className="dash-sec-content">
//           <div className="dash-info-list">
//             <div className="dash-card">
//               <div className="dash-card-container">
//                 <div className="dash-card-icon">
//                   <i className="fa fa-suitcase"></i>
//                 </div>
//                 <div className="dash-card-content miminctbx">
//                   <p>
//                     You Will be working as {auth.user.jobTitle}
//                   </p>
//                   {shift.tomorrowShift.location ? (
//                     <div className="mimintpbx">
//                       <span>
//                         <i className="fa fa-clock-o"></i>
//                       </span>{" "}
//                       On{" "}
//                       {shift.tomorrowShift.startTime.substring(
//                         5,
//                         10
//                       )}
//                       ,{" "}
//                       {shift.tomorrowShift.startTime.substring(
//                         11,
//                         16
//                       )}{" "}
//                       -{" "}
//                       {shift.tomorrowShift.endTime.substring(
//                         11,
//                         16
//                       )}
//                       ,{" "}
//                       <span>
//                         <i className="fa fa-map-marker"></i>
//                       </span>{" "}
//                       <NavLink
//                         to={
//                           shift.tomorrowShift &&
//                           shift.tomorrowShift.location
//                             ? `/location-view/${shift.tomorrowShift.location.id}`
//                             : "#"
//                         }
//                       >
//                         {" "}
//                         {shift.tomorrowShift.location.name}
//                       </NavLink>
//                     </div>
//                   ) : (
//                     // <div> ssss</div>
//                     <div className="mimintpbx">
//                       <span>
//                         <i className="fa fa-clock-o"></i>
//                       </span>{" "}
//                       On{" "}
//                       {shift.tomorrowShift.startTime.substring(
//                         5,
//                         10
//                       )}
//                       ,{" "}
//                       {shift.tomorrowShift.startTime.substring(
//                         11,
//                         16
//                       )}{" "}
//                       -{" "}
//                       {shift.tomorrowShift.endTime.substring(
//                         11,
//                         16
//                       )}{" "}
//                       <span>
//                         <i className=""></i>
//                       </span>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     )
//   ) : (
//     <>
//       <h1 className="dash-sec-title">Tomorrow</h1>
//       <div className="dash-sec-content">
//         <div className="dash-info-list">
//           <div className="card">
//             <div className="card-body">
//               <div className="time-list">
//                 <div className="dash-stats-list">
//                   <br />
//                   <br />
//                   <img
//                     alt=""
//                     src={shift1}
//                     style={{ width: "125px", opacity: "0.8" }}
//                   />
//                   <br />
//                   <p style={{ opacity: "0.5" }}>
//                     You are not scheduled on tomorrow shift.
//                   </p>
//                 </div>
//               </div>
//               <div className="request-btn"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )}
// </section>
// <section className="dash-section">
//   {shift.thisWeek && shift.thisWeek.length > 0 ? (
//     <>
//       <h1 className="dash-sec-title">Next seven days</h1>
//       <div className="dash-sec-content">
//         {shift.thisWeek !== null &&
//           shift.thisWeek.map((current, i) => {
//             return (
//               <>
//                 <div className="dash-info-list">
//                   <div className="dash-card">
//                     <div className="dash-card-container">
//                       <div className="dash-card-icon">
//                         <i className="fa fa-suitcase"></i>
//                       </div>
//                       <div className="dash-card-content miminctbx">
//                         <p>
//                           You Will be working as{" "}
//                           {auth.user.jobTitle}
//                         </p>
//                         {current.location ? (
//                           <div className="mimintpbx">
//                             <span>
//                               <i className="fa fa-clock-o"></i>
//                             </span>{" "}
//                             On{" "}
//                             {current.startTime.substring(5, 10)}
//                             ,{" "}
//                             {current.startTime.substring(
//                               11,
//                               16
//                             )}{" "}
//                             -{" "}
//                             {current.endTime.substring(11, 16)},{" "}
//                             <span>
//                               <i className="fa fa-map-marker"></i>
//                             </span>{" "}
//                             <NavLink
//                               to={
//                                 current.location
//                                   ? `/location-view/${current.location.id}`
//                                   : "#"
//                               }
//                             >
//                               {" "}
//                               {current.location.name}
//                             </NavLink>
//                           </div>
//                         ) : (
//                           <div className="mimintpbx">
//                             <span>
//                               <i className="fa fa-clock-o"></i>
//                             </span>{" "}
//                             On{" "}
//                             {current.startTime.substring(5, 10)}
//                             ,{" "}
//                             {current.startTime.substring(
//                               11,
//                               16
//                             )}{" "}
//                             -{" "}
//                             {current.endTime.substring(11, 16)}{" "}
//                             <span>
//                               <i className=""></i>
//                             </span>{" "}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </>
//             );
//           })}
//       </div>
//     </>
//   ) : (
//     <>
//       <h1 className="dash-sec-title">Next seven days</h1>
//       <div className="dash-sec-content">
//         <div className="dash-info-list">
//           <div className="card">
//             <div className="card-body">
//               <div className="time-list">
//                 <div className="dash-stats-list">
//                   <br />
//                   <br />
//                   <img
//                     alt=""
//                     src={shift2}
//                     style={{ width: "125px", opacity: "0.8" }}
//                   />
//                   <br />
//                   <p style={{ opacity: "0.5" }}>
//                     You are not scheduled on any shifts yet.
//                   </p>
//                 </div>
//               </div>
//               <div className="request-btn"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )}
// </section>

// <section>
//   {holiday.holiday && holiday.holiday.length > 0 && (
//     <>
//       <div className="dash-sec-content">
//         <h1 className="dash-sec-title">Holidays</h1>
//         {holiday.holiday.map((current, i) => {
//           return (
//             <div className="dash-info-list" key={i}>
//               <NavLink to="" className="dash-card">
//                 <div className="dash-card-container">
//                   <div className="dash-card-icon">
//                     <i className="fa fa-calendar"></i>
//                   </div>
//                   <div className="dash-card-content">
//                     <p>
//                       It's {current.holidayName} Holiday on{" "}
//                       {current.day}
//                     </p>
//                   </div>
//                 </div>
//               </NavLink>
//             </div>
//           );
//         })}
//       </div>
//     </>
//   )}
// </section>
// <br />
// </div>
{
  /* <div className="col-lg-4 col-md-4">
  <div className="dash-sidebar">
    <section>
      <h1 className="dash-sec-title">Your Leaves</h1>
      <div className="card">
        <div className="card-body">
          <div className="time-list">
            <div className="dash-stats-list">
              {leave.leaveAnnualStatus &&
                leave.leaveAnnualStatus.length > 0 &&
                leave.leaveAnnualStatus.forEach((current) => {
                  remaining += current.remainingLeavesDays;
                  daysLeaveTotal += current.annualLeavesDays;
                })}
              {leave.leaveAnnualStatus &&
                leave.leaveAnnualStatus.length > 0 && (
                  <h4>{(taken = daysLeaveTotal - remaining)}</h4>
                )}
              <p>Leave Taken</p>
            </div>
            <div className="dash-stats-list">
              {leave.leaveAnnualStatus &&
                leave.leaveAnnualStatus.length > 0 && (
                  <h4>{remaining}</h4>
                )}
              <p>Remaining</p>
            </div>
          </div>
          <div className="request-btn">
            <NavLink className="btn btn-primary" to="/leaves">
              Apply Leave
            </NavLink>
          </div>
        </div>
      </div>
    </section> */
}

// {user.annivarsary && user.annivarsary.length > 0 ? (
//   <section className="dash-section">
//     <h1 className="dash-sec-title">Annual Anniversaries</h1>
//     {user.annivarsary.map((current, i) => {
//       return (
//         current.length > 0 && (
//           <div className="dash-sec-content" key={i}>
//             <div className="dash-info-list">
//               {current.map((e, index) => {
//                 // console.log(e);
//                 return (
//                   <div className="dash-card" key={index}>
//                     <div className="dash-card-container">
//                       <div className="dash-card-icon">
//                         <i className="fa fa-suitcase"></i>
//                       </div>
//                       <div className="dash-card-content miminctbx">
//                         <p>
//                           {e.firstName}, {e.lastName}
//                         </p>
//                         <div className="mimintpbx">
//                           <span>
//                             <i className="fa fa-clock-o"></i>
//                           </span>
//                           {i === 0
//                             ? " Today"
//                             : i === 1
//                             ? " Tomorrow"
//                             : " In " + (i + 1) + " days"}{" "}
//                           <span>
//                             <i className="fa fa-hourglass-2"></i>
//                           </span>{" "}
//                           <NavLink
//                             to="#"
//                             style={{ cursor: "default" }}
//                           >
//                             {" "}
//                             {parseInt(
//                               e.startingDate.substring(0, 4)
//                             ) ===
//                             parseInt(
//                               moment()
//                                 .format()
//                                 .substring(0, 4)
//                             )
//                               ? "Joined Recently"
//                               : parseInt(
//                                   moment()
//                                     .format()
//                                     .substring(0, 4)
//                                 ) -
//                                 parseInt(
//                                   e.startingDate.substring(
//                                     0,
//                                     4
//                                   )
//                                 ) +
//                                 " Year"}
//                           </NavLink>
//                         </div>
//                       </div>
//                       <div className="dash-card-avatars miminbtbx mianibx">
//                         {e.profilePic ? (
//                           <NavLink
//                             to="#"
//                             className="e-avatar"
//                           >
//                             <img
//                               src={
//                                 BUCKET_BASE_URL +
//                                 e.profilePic.fileUrl
//                               }
//                               style={{
//                                 objectFit: "fill",
//                                 height: "100%",
//                                 cursor: "default",
//                               }}
//                               alt=""
//                             />
//                           </NavLink>
//                         ) : (
//                           <NavLink
//                             to="#"
//                             className="e-avatar"
//                           >
//                             <img
//                               src={defaultImg}
//                               style={{ cursor: "default" }}
//                               alt=""
//                             />
//                           </NavLink>
//                         )}
//                           </div>
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             )
//           );
//         })}
//       </section>
//     ) : (
//       <section className="dash-section">
//         <h1 className="dash-sec-title">Annual Anniversaries</h1>
//         <div className="dash-sec-content">
//           <div className="dash-info-list">
//             <div className="card">
//               <div className="card-body">
//                 <div className="time-list">
//                   <div className="dash-stats-list">
//                     <br />
//                     <br />
//                     <img
//                       alt=""
//                       src={cake}
//                       style={{ width: "125px", opacity: "0.8" }}
//                     />
//                     <br />
//                     <p style={{ opacity: "0.5" }}>
//                       No Upcoming Anniversaries
//                     </p>
//                   </div>
//                 </div>
//                 <div className="request-btn"></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     )}
//   </div>
// </div>
// </div>

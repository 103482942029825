import React from 'react'
import { useSelector } from 'react-redux'
const SuperAdminDashboardCards = () => {
    const company = useSelector((state) => state.company)

    return (
        <>
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-cubes"></i></span>
                            <div className="dash-widget-info">
                                <h3>112</h3>
                                <span>Locations</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-usd"></i></span>
                            <div className="dash-widget-info">
                                {
                                    company.SAdminCardsDetails &&
                                    company.SAdminCardsDetails.companyCount &&
                                    <h3>{company.SAdminCardsDetails.companyCount}</h3>
                                }
                                <span>Clients</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-user"></i></span>
                            <div className="dash-widget-info">
                                {
                                    company.SAdminCardsDetails &&
                                    company.SAdminCardsDetails.userCount &&
                                    <h3>{company.SAdminCardsDetails.userCount}</h3>
                                }
                                <span>Total Users</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-clock-o"></i></span>
                            <div className="dash-widget-info">
                                <h3>Time-In</h3>
                                <span>Aug 21, 2021 - 07:45</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SuperAdminDashboardCards
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMailByUserId } from "../actions/mailActions";
import EmployeeHeader from "../components/employeeComponents/EmployeeHeader";
import EmployeeSidebar from "../components/employeeComponents/EmployeeSidebar";
import EmployeeTitle from "../components/employeeComponents/EmployeeTitle";
import InboxMessages from "../components/InboxMessages";
const Inbox = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const mailData = async () => {
    dispatch(getMailByUserId({ userId: auth.user.id }));
  };
  useEffect(() => {
    mailData();
  }, []);
  return (
    <>
      <EmployeeHeader />
      <EmployeeSidebar />
      {/* <div className="main-wrapper"> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <EmployeeTitle title="Inbox" />
          <InboxMessages />
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default Inbox;

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getMailByMailId } from '../../actions/mailActions'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import MessageBoxDesign from '../../components/adminComponents/MessageBoxDesign'

const ViewMailView = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    useEffect(() => {
        if (params.id) {
            // console.log("dispatch")
            dispatch(getMailByMailId({mailId: params.id}, navigate))
        } 
    }, [params.id]);
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="View Message" ComposeMessage/>
                        <MessageBoxDesign />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewMailView
import React, { useState,useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
// import { getMailByMailId } from '../../actions/mailActions';
import InboxMessageTableRow from './InboxMessageTableRow';

const InboxMessages = () => {
    // const auth = useSelector((state) => state.auth);
    const mail = useSelector((state) => state.mail);
    const [windowWidth, setWindowWidth] = useState(0)
    // console.log(windowWidth==576,"windowWidth==576");
    useEffect(() => {
        window.addEventListener('resize', e => {
            setWindowWidth(window.innerWidth);
        });
    }, [window.innerWidth]);
    // const dispatch = useDispatch()
    // const mailIndividual = async (idMail) => {
    //     dispatch(getMailByMailId({mailId: idMail}))
    // }
    // let arr =[]
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="email-header">
                                <div className="row">
                                    <div className="col top-action-left">
                                        <div className="float-left">     
                                        </div>
                                    </div>
                                    <div className="col-auto top-action-right">
                                    </div>
                                </div>
                            </div>
                            <div className="email-content">
                                <div className="table-responsive">
                                    <table className="table table-inbox table-hover">
                                        <thead>
                                            <tr>
                                                <th colSpan="1">
                                                {/* <input type="checkbox" className="checkbox-all" /> */}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            mail.mailData &&
                                            mail.mailData.map((item,i)=>{
                                                return(
                                                    <>
                                                        <InboxMessageTableRow item={item} i={i}  />
                                                    </>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InboxMessages
import React from 'react'
// import { NavLink } from 'react-router-dom'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeCalendar = () => {
    return (
        <>
            {/* <EmployeeHeader />
            <EmployeeSidebar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="Calendar" />
                        {/* calender ka component banana hai abhi */}
                        {/* <div className="col-auto float-right ml-auto">
                            <NavLink to="shift-scheduling.html" className="btn add-btn"><i className="la la-users"></i> Shift &amp; Schedule</NavLink>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div id="calendar"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeCalendar
import React, { useEffect } from "react";
import { getSalaryByUserId } from "../../../actions/salaryActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import writtenNumber from "written-number";

const PEmployeeGeneratePayslip = () => {
  const auth = useSelector((state) => state.auth);
  const salary = useSelector((state) => state.salary);
  const payroll = useSelector((state) => state.payroll);

  const dispatch = useDispatch();
  const salaryData = async (e) => {
    dispatch(getSalaryByUserId({ userId: auth.user.Id }));
  };

  useEffect(() => {
    salaryData();
  }, []);

  return (
    <>
      {payroll.monthlySalary && (
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <>
                  <h4 className="payslip-title">
                    Payslip for the month of{" "}
                    {payroll.monthlySalary.month +
                      " " +
                      payroll.monthlySalary.year}
                  </h4>
                  <div className="row">
                    <div className="col-sm-6 m-b-20">
                      <img
                        src="assetsAdmin/img/logo2.png"
                        className="inv-logo"
                        alt=""
                      />
                      <ul className="list-unstyled mb-0">
                        {auth.company[0].company.companyName && (
                          <li>{auth.company[0].company.companyName}</li>
                        )}
                        <li>{auth.user.address}</li>
                        <li>{auth.user.state + ", " + auth.user.pinCode}</li>
                      </ul>
                    </div>
                    <div className="col-sm-6 m-b-20">
                      <div className="invoice-details">
                        <h3 className="text-uppercase">
                          Payslip #{payroll.monthlySalary.payslipNumber}
                        </h3>
                        <ul className="list-unstyled">
                          <li>
                            Dated:{" "}
                            <span>
                              {moment(payroll.monthlySalary.date).format("LL")}
                            </span>
                          </li>
                          <li>
                            Receipt Date: <span>{moment().format("LL")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 m-b-20">
                      <ul className="list-unstyled">
                        <li>
                          <h5 className="mb-0">
                            <strong>
                              {payroll.monthlySalary.userId.firstName +
                                " " +
                                payroll.monthlySalary.userId.lastName}
                            </strong>
                          </h5>
                        </li>
                        <li>
                          <span>{payroll.monthlySalary.userId.jobTitle}</span>
                        </li>
                        <li>
                          Employee ID: {payroll.monthlySalary.userId.employeeId}
                        </li>
                        <li>
                          Starting Date:{" "}
                          {moment(
                            payroll.monthlySalary.userId.startingDate
                          ).format("MM/DD/YYYY")}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div>
                        <h4 className="m-b-10">
                          <strong>Earnings</strong>
                        </h4>
                        <table className="table table-bordered">
                          <tbody>
                            {/* {
                                          payroll.monthlySalary.salaryStructure &&
                                             <> */}
                            <tr>
                              <td>
                                <strong>Basic Salary</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .basicSalary
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>House Rent Allowance (H.R.A.)</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .houseRentAllowance
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Conveyance</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .conveyance
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Other Allowance</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .otherAllowance
                                  }
                                </span>
                              </td>
                            </tr>
                            {/* </>
                                       } */}
                            <tr>
                              <td>
                                <strong>Total Earnings</strong>{" "}
                                <span className="float-right">
                                  <strong>
                                    $
                                    {
                                      payroll.monthlySalary.salaryStructure
                                        .totalEarning
                                    }
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <h4 className="m-b-10">
                          <strong>Deductions</strong>
                        </h4>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <strong>Tax Deducted at Source (T.D.S.)</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .taxDeductedAtSource
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Provident Fund</strong>{" "}
                                <span className="float-right">
                                  $
                                  {
                                    payroll.monthlySalary.salaryStructure
                                      .providentFund
                                  }
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>ESI</strong>{" "}
                                <span className="float-right">
                                  ${payroll.monthlySalary.salaryStructure.esi}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Loan</strong>{" "}
                                <span className="float-right">
                                  ${payroll.monthlySalary.salaryStructure.loan}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Total Deductions</strong>{" "}
                                <span className="float-right">
                                  <strong>
                                    $
                                    {
                                      payroll.monthlySalary.salaryStructure
                                        .totalDeduction
                                    }
                                  </strong>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {payroll.monthlySalary.addition &&
                      payroll.monthlySalary.addition.length > 0 && (
                        <div className="col-sm-6">
                          <div>
                            <h4 className="m-b-10">
                              <strong>Additions</strong>
                            </h4>
                            <table className="table table-bordered">
                              <tbody>
                                {payroll.monthlySalary.addition.map(
                                  (ee, ind) => {
                                    return (
                                      <tr key={ind}>
                                        <td>
                                          <strong>{ee.name}</strong>{" "}
                                          <span className="float-right">
                                            ${ee.amount}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    {payroll.monthlySalary.deduction &&
                      payroll.monthlySalary.deduction.length > 0 && (
                        <div className="col-sm-6">
                          <div>
                            <h4 className="m-b-10">
                              <strong>Deductions</strong>
                            </h4>
                            <table className="table table-bordered">
                              <tbody>
                                {payroll.monthlySalary.deduction.map(
                                  (ee, ind) => {
                                    return (
                                      <tr key={ind}>
                                        <td>
                                          <strong>{ee.name}</strong>{" "}
                                          <span className="float-right">
                                            ${ee.amount}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    <div className="col-sm-12">
                      <p>
                        <strong>
                          Net Salary: ${payroll.monthlySalary.salaryThisMonth}
                        </strong>{" "}
                        ({writtenNumber(payroll.monthlySalary.salaryThisMonth)}
                        .)
                      </p>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PEmployeeGeneratePayslip;

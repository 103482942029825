import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

// const searchOptions = {
//   componentRestrictions: { country: ["pk"] },
// };

export default function AutoComplete({handleSelect, coordinates, address, setAddress, isRequired}) {

  return (
    <div>
      {/* <p>Enter Your Address</p> */}
      {/* <p className="text-primary text-md ">Enter Your Address</p> */}
      <PlacesAutocomplete
        // searchOptions={searchOptions}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="mb-2 relative" style={{width:"100%"}}>
            <input
              className="form-control" style={{width:"100%"}}
              {...getInputProps({ placeholder: "Search By Map" })}
              required={isRequired ? true : false}
            />
            {/* <div className=""> */}
            {suggestions.length>0 && 
            <div className="absolute z-10 bg-white py-4 px-2 shadow-lg rounded-md max-h-[200px] overflow-y-scroll">
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                return (
                  <div
                    className={` border-b-gray-500 rounded-md px-2 py-1
                        ${suggestion.active ? "bg-primary text-white" : ""}
                      `}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>}
          </div>
          // </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

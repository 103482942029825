import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatsAction, getMessagesAction } from "../../actions/chatActions";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CHAT_SELECT_SUCCESS, MESSAGE_RECEIVE_SUCCESS } from "../../constants";
import CreateChatModal from "./createChatModal";
import { useState } from "react";
import CreateGroupModal from "./createGroupModal";
import ChatItem from "./chatItem";
import Loader from "../Loader";

function UsersArea() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const chats = useSelector((state) => state.chats);
  const sockets = useSelector((state) => state.sockets);

  const { socketConnected, socketInstance } = sockets;
  const [chatModal, setChatModal] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (auth.user)
      dispatch(
        getChatsAction({ userId: auth.user.id, companyId: auth.user.companyId })
      );
  }, [auth.user]);

  const selectChat = (chat) => {
    dispatch({
      type: CHAT_SELECT_SUCCESS,
      payload: chat,
    });
  };

  console.log("chats mili", chats);
  useEffect(() => {
    if (chats.list.length > 0 && !chats.selectedChat) {
      selectChat(chats.list[0]);
    }
  }, [chats.list.length]);

  useEffect(() => {
    if (socketInstance && chats.selectedChat) {
      socketInstance.on("typing", (user) => setIsTyping(user));
      socketInstance.on("stop typing", (user) => setIsTyping(false));
      dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));

      socketInstance.emit("join room", chats.selectedChat.id);

      // socket.on("message recieved", (newMessageRecieved) => {
      //   console.log("message received==>", newMessageRecieved);

      //   dispatch({
      //     type: MESSAGE_RECEIVE_SUCCESS,
      //     payload: newMessageRecieved,
      //   });
      //   const lastChildElement = scrollRef.current?.lastElementChild;
      //   lastChildElement?.scrollIntoView({
      //     behavior: "smooth",
      //     block: "end",
      //     bottom: 0,
      //   });
      //   // if (
      //   //   (!selectedChatCompare || selectedChatCompare._id) !==
      //   //   newMessageRecieved.chatId._id
      //   // ) {
      //   //   if (!notifications.includes(newMessageRecieved)) {
      //   //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
      //   //   }
      //   // } else {
      //   //   setMessages([...messages, newMessageRecieved]);
      //   // }
      //   // dispatch(fetchChats());
      // });
    }
  }, [chats.selectedChat]);

  // const { activeChat, notifications } = useSelector((state) => state.chats)
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  // const activeUser = useSelector((state) => state.activeUser)

  // const keyDownFunction = async (e) => {
  //   if ((e.key === "Enter" || e.type === "click") && message) {
  //     setMessage("");
  //     socket.emit("stop typing", activeChat._id);
  //     const data = await sendMessage({ chatId: activeChat._id, message });
  //     socket.emit("new message", data);
  //     setMessages([...messages, data]);
  //     dispatch(fetchChats());
  //   }
  // };

  // useEffect(() => {
  //   socket = io(ENDPOINT);
  //   socket.on("typing", () => setIsTyping(true));
  //   socket.on("stop typing", () => setIsTyping(false));
  // }, []);

  // useEffect(() => {
  //   socket.emit("setup", activeUser);
  //   socket.on("connected", () => {
  //     setSocketConnected(true);
  //   });
  // }, [messages, activeUser]);

  // useEffect(() => {
  //   const fetchMessagesFunc = async () => {
  //     if (chats.selectedChat) {
  //       setLoading(true);
  //       const data = await fetchMessages(activeChat._id);
  //       setMessages(data);
  //       socket.emit("join room", activeChat._id);
  //       setLoading(false);
  //     }
  //     return;
  //   };
  //   fetchMessagesFunc();
  //   selectedChatCompare = activeChat;
  // }, [chats.selectedChat]);

  // useEffect(() => {
  //   if (socketInstance)
  //     socketInstance.on("message recieved", (newMessageRecieved) => {
  //       console.log("message received==>", newMessageRecieved);

  //       dispatch({
  //         type: MESSAGE_RECEIVE_SUCCESS,
  //         payload: newMessageRecieved,
  //       });

  //       // const lastChildElement = scrollRef.current?.lastElementChild;
  //       // lastChildElement?.scrollIntoView({ behavior: "smooth" });
  //       // if (
  //       //   (!selectedChatCompare || selectedChatCompare._id) !==
  //       //   newMessageRecieved.chatId._id
  //       // ) {
  //       //   if (!notifications.includes(newMessageRecieved)) {
  //       //     dispatch(setNotifications([newMessageRecieved, ...notifications]));
  //       //   }
  //       // } else {
  //       //   setMessages([...messages, newMessageRecieved]);
  //       // }
  //       // dispatch(fetchChats());
  //     });
  // }, [socketInstance]);

  return (
    <div class="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0 h-100" style={{}}>
      {/* <h5 class="font-weight-bold mb-3 text-center text-lg-start">
      Member
    </h5> */}

      <div class="card h-100">
        <div class="card-body p-2 d-flex flex-column">
          <span className="">
            <span className="d-flex">
              <input placeholder="Search chat..." />{" "}
              <button class="btn btn-info">Search</button>
            </span>
            {auth.user.role !== "user" && (
              <span className="d-flex my-2 gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() => setChatModal(true)}
                >
                  New Chat
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setGroupModal(true)}
                >
                  New Group
                </button>
              </span>
            )}
          </span>
          <ul
            class="list-unstyled mb-0 mt-1 h-100 w-100 d-flex flex-column"
            style={{ overflowY: "auto" }}
          >
            {chats.loading && <Loader />}
            {chats.list.map((chat) => {
              const receiver = chat.users.find((x) => x.id !== auth.user.id);
              return (
                <ChatItem
                  chat={chat}
                  receiver={receiver}
                  selectChat={selectChat}
                  isTyping={isTyping}
                />
              );
            })}

            {/* <li class="p-2 border-bottom">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Danny Smith</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">5 mins ago</p>
                </div>
              </a>
            </li>
            <li class="p-2 border-bottom">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-2.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Alex Steward</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">Yesterday</p>
                </div>
              </a>
            </li>
            <li class="p-2 border-bottom">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-3.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Ashley Olsen</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">Yesterday</p>
                </div>
              </a>
            </li>
            <li class="p-2 border-bottom">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-4.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Kate Moss</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">Yesterday</p>
                </div>
              </a>
            </li>
            <li class="p-2 border-bottom">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Lara Croft</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">Yesterday</p>
                </div>
              </a>
            </li>
            <li class="p-2">
              <a href="#!" class="d-flex justify-content-between">
                <div class="d-flex flex-row">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp"
                    alt="avatar"
                    class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                    width="40"
                  />
                  <div class="pt-1">
                    <p class="fw-bold mb-0">Brad Pitt</p>
                    <p class="small text-muted">Lorem ipsum dolor sit.</p>
                  </div>
                </div>
                <div class="pt-1">
                  <p class="small text-muted mb-1">5 mins ago</p>
                  <span class="text-muted float-end">
                    <i class="fas fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      {chatModal && (
        <CreateChatModal setOpenModal={setChatModal} openModal={chatModal} />
      )}
      {groupModal && (
        <CreateGroupModal setOpenModal={setGroupModal} openModal={groupModal} />
      )}
    </div>
  );
}

export default UsersArea;

import React from 'react'
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import FileManagerMain from '../../components/adminComponents/adminLocationAdministration/FileManagerMain'
const AdminFileManager = () => {
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="File Manager"/>
                        <FileManagerMain />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminFileManager
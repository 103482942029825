import React from "react";
import { useState } from "react";
import moment from "moment";
import SAdminContactUsModal from "./SAdminContactUsModal";

// import { NavLink } from 'react-router-dom'
// import EditModalEmployeeSalaryTable from '../../adminComponents/adminPayrollItems/EditModalEmployeeSalaryTable'

const SuperAdminContactUsQueriesTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  // const openMoreVertDropDown = () => {
  //     setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
  // }
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
    setshowMoreVertDropDown(false);
  };
  // const openDeleteDropDown = () => {
  //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  //     setshowMoreVertDropDown(false)
  // }
  return (
    <>
      <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
        <td>{++i}</td>
        <td>{current.name}</td>
        <td>{current.email}</td>
        <td>{current.phone}</td>
        <td>{current.subject}</td>
        <td>
          {current.message.length <= 25
            ? current.message
            : current.message.substring(0, 25) + "..."}
        </td>
        <td>{moment(current.date).format("lll")}</td>
        <td className="text-right">
          <a className="btn btn-sm btn-primary" to="#" onClick={openDropDown}>
            Details
          </a>
        </td>
      </tr>
      {/* <EditModalEmployeeSalaryTable current={current} showDropDown={showDropDown} openDropDown={openDropDown} /> */}
      <SAdminContactUsModal
        current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default SuperAdminContactUsQueriesTableRow;

import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'
import TrainingTable from '../../components/employeeComponents/employeeTrainingComponents/TrainingTable'

const EmployeeTraining = () => {
  return (
    <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <div className="main-wrapper">
          <div className="page-wrapper">
              <div className="content container-fluid">
                  <EmployeeTitle title="Training" />
                  <TrainingTable />
              </div>
          </div>
      </div>
    </>
  )
}

export default EmployeeTraining
import React, { useEffect } from "react"
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from "../../components/adminComponents/AdminHeader"
import AdminSideBar from "../../components/adminComponents/AdminSideBar"
import ProfileCover from "../../components/adminComponents/profileComponent/ProfileCover"
import AdminTittle from "../../components/adminComponents/AdminTittle"
import ProfileBar from "../../components/adminComponents/profileComponent/ProfileBar"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { userById } from "../../actions/userActions"
import moment from "moment"

const ProfileAtAdmin = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    //   console.log("params==>", params.id);
    useEffect(() => {
        if (params.id) {
            dispatch(userById({userId: params.id, date: moment().format('YYYY-MM-DD')}, navigate))
        } 
        // else {
        //   navigate("/profile");
        // }
    }, [params.id]);
    const user = useSelector((state) => state.user); 
    return (
        <>
        {/* <AdminHeader />
        <AdminSideBar /> */}
        <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Profile" />
                        {
                            user.userById &&
                            user.userById.user !== null &&
                            <>
                                <ProfileCover />
                                <ProfileBar />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfileAtAdmin
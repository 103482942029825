import React from 'react'

const Compare = () => {
    return (
        <>
            <div className="ourplan-table-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-plan">
                                <h2> Compare Plans by Features</h2>
                                <table className='table-general'>
                                    <thead>
                                        <tr>
                                            <th className=" th-general">Features</th>
                                            <th className="th-general">Starter</th>
                                            <th className="th-general">Premium</th>
                                            <th className="th-general">Enterprise</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Users</td>
                                            <td className="td-general">Up to 20</td>
                                            <td className="td-general">Up to 50</td>
                                            <td className="td-general">  Umlimited</td>
                                        </tr>
                                        <tr>
                                            <td className=" td-general">Administrator</td>
                                            <td className="td-general">Up to 3</td>
                                            <td className="td-general">Up to 3</td>
                                            <td className="td-general">Up to 3</td>
                                        </tr>
                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Gps Tracking</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                        <tr>
                                            <td className=" td-general">Team Messaging</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Time Of Management</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                        <tr>
                                            <td className=" td-general">Workplace Dashboard</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Time Zone Support</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr>
                                            <td className=" td-general">Geolocation Clock-In/Clock-Out</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Mobile Access</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr>
                                            <td className=" td-general">Time Clock Features</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Unlimited 24/7 Support</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr>
                                            <td className=" td-general">Notification (SMS, Email, and in-app</td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>

                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Locations</td>
                                            <td className="td-general">1</td>
                                            <td className="td-general">Unlimited</td>
                                            <td className="td-general">Unlimited</td>
                                        </tr>


                                        <tr>
                                            <td className=" td-general">Reporting</td>
                                            <td className="td-general"><i className="fa fa-times chnfo" aria-hidden="true" id="icon-re"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>


                                        <tr className='tr-zebra'>
                                            <td className=" td-general">Custom API integration</td>
                                            <td className="td-general"><i className="fa fa-times chnfo" aria-hidden="true" id="icon-re"></i></td>
                                            <td className="td-general"><i className="fa fa-times chnfo" aria-hidden="true" id="icon-re"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                        <tr>
                                            <td className=" td-general">Custom Features</td>
                                            <td className="td-general"><i className="fa fa-times chnfo" aria-hidden="true" id="icon-re"></i></td>
                                            <td className="td-general"><i className="fa fa-times chnfo" aria-hidden="true" id="icon-re"></i></td>
                                            <td className="td-general"><i className="fa fa-check chnfo" aria-hidden="true" id="gpst"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Compare
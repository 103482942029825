import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLeaveCategory } from "../../../actions/leaveActions";
const EditModalLeaveSetting = ({ showDropDown, openDropDown, current }) => {
  const [values, setValues] = useState({
    // companyId: '',
    id: "",
    name: "",
    numberOfDays: "",
    everyYear: "",
    paidLeave: "",
    status: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentLeaveSetting = async () => {
    try {
      setValues({
        // companyId: '',
        id: "",
        name: "",
        numberOfDays: "",
        everyYear: "",
        paidLeave: "",
        status: "",
      });
      setValues({
        // companyId: '',
        id: current.id,
        name: current.name,
        numberOfDays: current.numberOfDays,
        everyYear: current.everyYear,
        paidLeave: current.paidLeave,
        status: current.status,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    currentLeaveSetting();
  }, []);
  const dispatch = useDispatch();
  const updateLeaveSettingData = {
    _id: values.id,
    name: values.name,
    numberOfDays: values.numberOfDays,
    everyYear: values.everyYear,
    paidLeave: values.paidLeave,
    status: values.status,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateLeaveCategory(updateLeaveSettingData));
    // openDropDown()
    setValues({
      id: "",
      name: "",
      numberOfDays: "",
      everyYear: "",
      paidLeave: "",
      status: "",
    });
  };
  // console.log(values, "leaveeeeeeeee itt")
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_leave"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div className="form-group">
                  <label>
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    value={values.numberOfDays}
                    onChange={handleChange("numberOfDays")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Paid Leave <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    style={{ height: "44px", fontSize: "14px" }}
                    autoComplete="false"
                    value={values.everyYear}
                    onChange={handleChange("everyYear")}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Continue this Leave Category after 1 year
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    style={{ height: "44px", fontSize: "14px" }}
                    autoComplete="false"
                    value={values.paidLeave}
                    onChange={handleChange("paidLeave")}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Status<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select"
                    style={{ height: "44px", fontSize: "14px" }}
                    autoComplete="false"
                    value={values.status}
                    onChange={handleChange("status")}
                  >
                    <option value="true">On</option>
                    <option value="false">Off</option>
                  </select>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalLeaveSetting;

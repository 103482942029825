import React, { useState } from "react";
import EditModalLeaveSetting from "./EditModalLeaveSetting";
import DeleteLeaveCategory from "./DeleteLeaveCategory";
const AdminLSRow = ({ current, i }) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  return (
    <>
      {current.delete === false && (
        <div key={i} className="card leave-box" id="leave_annual">
          <div className="card-body">
            <div className="h3 card-title with-switch">
              {current.name}
              <div className="onoffswitch">
                {current.status === true ? (
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    className="onoffswitch-checkbox"
                    id="switch_annual"
                    checked
                  />
                ) : (
                  <input
                    type="checkbox"
                    name="onoffswitch"
                    className="onoffswitch-checkbox"
                    id="switch_annual"
                  />
                )}
                <label className="onoffswitch-label" for="switch_annual">
                  <span className="onoffswitch-inner"></span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
              <button
                className="btn btn-sm btn-primary ml-2"
                type="button"
                data-toggle="modal"
                onClick={openDropDown}
                data-target="#add_leave"
              >
                <i className="fa fa-plus"></i> Edit Leave
              </button>
              <button
                className="btn btn-danger leave-delete-btn"
                onClick={openDeleteDropDown}
                type="button"
              >
                Delete
              </button>
              <EditModalLeaveSetting
                current={current}
                showDropDown={showDropDown}
                openDropDown={openDropDown}
              />
              <DeleteLeaveCategory
                current={current}
                showDeleteDropDown={showDeleteDropDown}
                openDeleteDropDown={openDeleteDropDown}
              />
            </div>
            <div className="custom-policy">
              {/* <div className="table-responsive"> */}
              <table className="table table-hover table-nowrap leave-table mb-0">
                <thead>
                  <tr>
                    <th className="l-name">Number of days</th>
                    {/* <th className="l-name">From</th> */}
                    <th className="l-name"></th>
                    <th className="l-name"></th>
                    {/* <th className="l-name">Position</th> */}
                    <th className="l-name">Paid Leave</th>
                    {/* <th className="l-name">Location</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{current.numberOfDays}</td>
                    {/* <td>12-August-2022</td> */}
                    <td></td>
                    <td></td>
                    {/* <td>66 west</td> */}
                    <td>{current.paidLeave === true ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default AdminLSRow;

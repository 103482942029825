import React from 'react'
import wework from '../../assetsgeneral/images/we-work.png'
const WeWorkBanner = () => {
    return (
        <>
            <div className="slider-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="work-schedule work-about">
                                <h2>How We Work</h2>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="work-schedule-img">
                                <img src={wework} id="weworkf" alt='work' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WeWorkBanner
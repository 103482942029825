import {
    CLOCK_IN_REQUEST,
    CLOCK_IN_SUCCESS,
    CLOCK_IN_FAIL,
    CLOCK_OUT_REQUEST,
    CLOCK_OUT_SUCCESS,
    CLOCK_OUT_FAIL,
    CLOCK_EXIST_REQUEST,
    CLOCK_EXIST_SUCCESS,
    CLOCK_EXIST_FAIL,
    CLOCK_STATUS_REQUEST,
    CLOCK_STATUS_SUCCESS,
    CLOCK_STATUS_FAIL,
    CLOCK_MONTHLY_DATA_REQUEST,
    CLOCK_MONTHLY_DATA_SUCCESS,
    CLOCK_MONTHLY_DATA_FAIL,
    CLOCK_ATTENDANCE_REQUEST,
    CLOCK_ATTENDANCE_SUCCESS,
    CLOCK_ATTENDANCE_FAIL,
    ADMIN_CLOCK_OUT_REQUEST,
    ADMIN_CLOCK_OUT_SUCCESS,
    ADMIN_CLOCK_OUT_FAIL,
    MONTHLY_ATTENDANCE_SEARCH_REQUEST,
    MONTHLY_ATTENDANCE_SEARCH_SUCCESS,
    MONTHLY_ATTENDANCE_SEARCH_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const clockReducer = (
    state = {
        clock: {
            clockData: null,
            clockInButton: true,
            breakButton: null,
            clockStatusData: null,
            clockMonthlyData: null,
            clockAttendance: null,
            searchData: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                clockData: null,
                clockInButton: true,
                breakButton: null,
                clockStatusData: null,
                clockMonthlyData: null,
                clockAttendance: null,
                searchData: null,
            };

        // USER CLOCK IN
        case CLOCK_IN_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_IN_SUCCESS :
            if (action.payload !== "exists"){
            return {
                ...state,
                clockData: action.payload,
                clockInButton: false,
                breakButton: true,
            };
            };
        case CLOCK_IN_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER CLOCK UPDATE
        case CLOCK_OUT_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_OUT_SUCCESS :
            if (action.payload.clockedIn === false){
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: true,
                    breakButton: null,
                };
            }
            else {
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: false,
                    breakButton: true,
                };
            }
        case CLOCK_OUT_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER CLOCK CHECK
        case CLOCK_EXIST_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_EXIST_SUCCESS :
            if (action.payload.clockedIn === false){
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: true,
                    breakButton: null,
                };
            }
            else {
                return {
                    ...state,
                    clockData: action.payload,
                    clockInButton: false,
                    breakButton: true,
                };
            }
        case CLOCK_EXIST_FAIL :
            return {
                ...state,
                clockData: null,
                clockInButton: true,
                breakButton: null,
                error: action.payload,
            };
        // MONTHLY AND WEEK CLOCK STATUS OF USER
        case CLOCK_STATUS_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_STATUS_SUCCESS :
            return {
                ...state,
                clockStatusData: action.payload,
            };
        case CLOCK_STATUS_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // MONTHLY CLOCK DATA OF USER
        case CLOCK_MONTHLY_DATA_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_MONTHLY_DATA_SUCCESS :
            return {
                ...state,
                clockMonthlyData: action.payload,
            };
        case CLOCK_MONTHLY_DATA_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // ADMIN ATTENDANCE REPORT API
        case CLOCK_ATTENDANCE_REQUEST : 
            return {
                ...state,
            };
        case CLOCK_ATTENDANCE_SUCCESS :
            return {
                ...state,
                clockAttendance: action.payload,
            };
        case CLOCK_ATTENDANCE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        // ADMIN CLOCK OUT 
        case ADMIN_CLOCK_OUT_REQUEST : 
            return {
                ...state,
            };
        case ADMIN_CLOCK_OUT_SUCCESS :
            return {
                ...state,

            };
        case ADMIN_CLOCK_OUT_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        
        // ADMIN SEARCH MONTHLY ATTENDANCE
        case MONTHLY_ATTENDANCE_SEARCH_REQUEST : 
            return {
                ...state,
            };
        case MONTHLY_ATTENDANCE_SEARCH_SUCCESS :
            return {
                ...state,
                searchData: action.payload,
            };
        case MONTHLY_ATTENDANCE_SEARCH_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}
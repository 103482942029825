import React from 'react'
import conn from '../../assetsgeneral/images/conn.png'
const BannerContactUs = () => {
    return (
        <>
            <div className="slider-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="work-schedule work-about">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="work-schedule-img">
                                <img src={conn} id="sli-img-se" alt='banner-contact'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BannerContactUs
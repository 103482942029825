import React, { useState } from 'react'
import Switch from "react-switch"
import YearPr from './YearPr'
import MonthPr from './MonthPr'
// import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { tabPackageMonthly, tabPackageYearly } from '../actions/tabActions'
const Pricing = () => {
    const [toggle, setToggle] = useState(false)
    const dispatch = useDispatch()
    const handleChange = () => {
        setToggle(toggle => !toggle)
    }
    const yearly = () => {
        dispatch(tabPackageYearly())
    }
    const monthly = () => {
        dispatch(tabPackageMonthly())
    }
    // const handleChange = () => {
    //     setToggle(toggle => !toggle)
    // }
    return (
        <>
            <div className="ourplans-section new-bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ourplans-tex">
                                <h3> Our Plans</h3>
                                <h2>Choose Your Subscription </h2>
                                <p> No matter what your business does, Riyowork will make your day work. You can<br /> compare the features each package includes or speak with a specialist about your<br /> business’ needs.</p>
                                </div>
                                
                                {/* <p className="monthly"> monthly <span>
                                    <label className="switch">
                                    <input type="checkbox" checked />
                                    <span className="slider round"></span>
                                    </label></span> yearly 
                                </p> */}

                                <ul className="list-inline text-center switch-toggler-list" role="tablist" id="switch-toggle-tab">
                                
                               
                                <h5><p className="monthly">
                                    &nbsp;monthly&nbsp; 
                                    <span>
                                    <Switch onChange={handleChange} checked={toggle} handleDiameter={30} offColor='#cccccc' onColor="#32895E" checkedIcon={false} uncheckedIcon={false} height={43} width={70} 
                                    onHandleColor="#fff"/>
                                    {/* <span className="slider round"></span> */}
                                    {/* </label> */}
                                    </span> 
                                    &nbsp;yearly&nbsp; 
                                </p></h5>
                                </ul>
                            </div>
                        {/* </div> */}
                        { toggle === true ? <YearPr /> : <MonthPr />}
                        { toggle === true ? yearly() : monthly()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing
import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import QuoteForm from '../components/quoteComponents/QuoteForm'
const Quote = () => {
  const myState = useSelector((state => state.changeTheTab))
  return (
    <>
      <NavBar />
      {
        myState.packageIdForProceed !== '' &&
        <QuoteForm />
      }
      <Footer />
    </>
  )
}
export default Quote
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from 'react-router-dom'
import { updateSalaryCalendar } from "../../../actions/payrollAction";
const EditModalEmployeeSalaryTable = ({
  showDropDown,
  openDropDown,
  current,
}) => {
  // const auth = useSelector((state) => state.auth)
  const payroll = useSelector((state) => state.payroll);
  const user = useSelector((state) => state.user);
  const [values, setValues] = useState({
    id: "",
    date: "",
    addition: [],
    deduction: [],
    month: "",
    year: "",
    userId: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const [additionObj, setAdditionObj] = useState([]);
  const [deductionObj, setDeductionObj] = useState([]);
  const handleAdditionChange = (fieldName) => (event) => {
    // console.log(event.target.value, values.addition)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.addition.includes(abc.id) === false) {
        // console.log(abc)
        setAdditionObj([...additionObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.addition, abc.id],
        });
      }
    }
  };
  const deleteAdditionHandler = (id) => {
    setAdditionObj(additionObj.filter((x) => x.id !== id));
    const index = values.addition.indexOf(id);
    let arr = values.addition;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, addition: arr });
    }
  };

  const handleDeductionChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.deduction.includes(abc.id) === false) {
        // console.log(abc)
        setDeductionObj([...deductionObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.deduction, abc.id],
        });
      }
    }
  };
  const deleteDeductionHandler = (id) => {
    setDeductionObj(deductionObj.filter((x) => x.id !== id));
    const index = values.deduction.indexOf(id);
    let arr = values.deduction;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, deduction: arr });
    }
  };

  // const params = useParams()
  const currentSalary = async () => {
    try {
      setValues({
        id: "",
        date: "",
        addition: [],
        deduction: [],
        month: "",
        year: "",
        userId: "",
      });
      let arr = [];
      let arr2 = [];
      let arr3 = [];
      let arr4 = [];
      // console.log(current.addition, deductionObj, "objobjobj")
      for (const e of current.addition) {
        for (const f of payroll.payrollAdditionTable) {
          if (e._id === f.id) {
            arr.push({ id: e._id, name: e.name });
            arr2.push(e._id);
          }
        }
      }
      for (const e of current.deduction) {
        for (const f of payroll.payrollDeductionTable) {
          if (e._id === f.id) {
            arr3.push({ id: e._id, name: e.name });
            arr4.push(e._id);
          }
        }
      }
      setValues({
        id: current.id,
        date: current.date,
        addition: arr2,
        deduction: arr4,
        month: current.month,
        year: current.year,
        userId: current.userId.id,
      });
      setAdditionObj(arr);
      setDeductionObj(arr3);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    currentSalary();
  }, [current]);
  const dispatch = useDispatch();
  const updateSalaryData = {
    _id: values.id,
    date: values.date,
    addition: values.addition,
    deduction: values.deduction,
    month: values.month,
    year: values.year,
    userId: values.userId,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateSalaryCalendar(updateSalaryData));
    setValues({
      id: "",
      date: "",
      addition: "",
      deduction: "",
      month: "",
      year: "",
      userId: "",
    });
    // setDeductionObj([])
    setAdditionObj([]);
  };
  // console.log(current, "employe salary table chal rha hai saaeinnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="ed it_salary"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Staff Salary</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Select Staff</label>
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        // value={values.month}
                        // onChange={handleChange('month')}
                        autoComplete="false"
                        value={values.userId}
                        onChange={handleChange("userId")}
                      >
                        {/* <option value="">Select</option> */}
                        {user.allUsersWithoutPaginate &&
                          user.allUsersWithoutPaginate.length > 0 &&
                          user.allUsersWithoutPaginate.map((current, i) => {
                            return (
                              <option value={current.id} key={i + 1}>
                                {current.firstName + " " + current.lastName}
                              </option>
                            );
                          })}
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Select Month</label>
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        value={values.month}
                        onChange={handleChange("month")}
                      >
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Year</label>
                      <br />
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        value={values.year}
                        onChange={handleChange("year")}
                      >
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="text-primary">Addition</h4>
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      // value={values.addition}
                      // onChange={handleChange('addition')}>
                      //   <option value="">Select</option>
                      autoComplete="false"
                      // value={values.addition}
                      onChange={handleAdditionChange("addition")}
                    >
                      <option value={values.addition}>Select other</option>
                      {payroll.payrollAdditionTable &&
                        payroll.payrollAdditionTable.length > 0 &&
                        payroll.payrollAdditionTable.map((current, i) => {
                          return (
                            <option
                              value={JSON.stringify({
                                id: current.id,
                                name: current.name,
                              })}
                              key={i + 1}
                            >
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                    {additionObj && additionObj.length > 0 && (
                      <div className="form-group">
                        <br />
                        <label>Addition Added</label>
                        <ul className="files-list">
                          {additionObj.map((elem, ind) => {
                            return (
                              <li key={ind}>
                                {elem.name}
                                <i
                                  style={{ float: "right" }}
                                  className="fa fa-trash"
                                  onClick={() => deleteAdditionHandler(elem.id)}
                                ></i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <h4 className="text-primary">Deductions</h4>
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      // value={values.deduction}
                      onChange={handleDeductionChange("deduction")}
                    >
                      <option value="">Select</option>
                      {payroll.payrollDeductionTable &&
                        payroll.payrollDeductionTable.length > 0 &&
                        payroll.payrollDeductionTable.map((current, i) => {
                          return (
                            <option
                              value={JSON.stringify({
                                id: current.id,
                                name: current.name,
                              })}
                              key={i + 1}
                            >
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                    {deductionObj && deductionObj.length > 0 && (
                      <div className="form-group">
                        <br />
                        <label>Deduction Added</label>
                        <ul className="files-list">
                          {deductionObj.map((elem, ind) => {
                            return (
                              <li key={ind}>
                                {elem.name}
                                <i
                                  style={{ float: "right" }}
                                  className="fa fa-trash"
                                  onClick={() =>
                                    deleteDeductionHandler(elem.id)
                                  }
                                ></i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalEmployeeSalaryTable;

import axios from "axios";
import {
    LEAVE_ANNUAL_STATUS_REQUEST,
    LEAVE_ANNUAL_STATUS_SUCCESS,
    LEAVE_ANNUAL_STATUS_FAIL,
    LEAVE_REQUEST,
    LEAVE_SUCCESS,
    LEAVE_FAIL,
    LEAVE_CREATE_REQUEST,
    LEAVE_CREATE_SUCCESS,
    LEAVE_CREATE_FAIL,
    LEAVE_CATEGORY_CREATE_REQUEST,
    LEAVE_CATEGORY_CREATE_SUCCESS,
    LEAVE_CATEGORY_CREATE_FAIL,
    ALL_LEAVE_QUERY_REQUEST,
    ALL_LEAVE_QUERY_SUCCESS,
    ALL_LEAVE_QUERY_FAIL,
    LEAVE_REPORT_REQUEST,
    LEAVE_REPORT_SUCCESS,
    LEAVE_REPORT_FAIL,
    LEAVE_SETTING_REQUEST,
    LEAVE_SETTING_SUCCESS,
    LEAVE_SETTING_FAIL,
    ADMIN_LEAVE_CARDS_DATA_REQUEST,
    ADMIN_LEAVE_CARDS_DATA_SUCCESS,
    ADMIN_LEAVE_CARDS_DATA_FAIL,
    LEAVE_UPDATE_REQUEST,
    LEAVE_UPDATE_SUCCESS,
    LEAVE_UPDATE_FAIL,
    REMOVE_LEAVE_REQUEST,
    REMOVE_LEAVE_SUCCESS,
    REMOVE_LEAVE_FAIL,
    LEAVE_SEARCH_REQUEST,
    LEAVE_SEARCH_SUCCESS,
    LEAVE_SEARCH_FAIL,
    ADMIN_CREATED_LEAVE_UPDATE_REQUEST,
    ADMIN_CREATED_LEAVE_UPDATE_SUCCESS,
    ADMIN_CREATED_LEAVE_UPDATE_FAIL,
    LEAVE_REPORT_USER_REQUEST,
    LEAVE_REPORT_USER_SUCCESS,
    LEAVE_REPORT_USER_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER ANNUAL LEAVE STATUS
export const getUserAnnualStatusByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_ANNUAL_STATUS_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getUserAnnualStatusByUserId`, obj);
        localStorage.setItem("leaveAnnualStatus", JSON.stringify(data));
        dispatch({
            type: LEAVE_ANNUAL_STATUS_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_ANNUAL_STATUS_FAIL,
            payload: err,
        });
    }
};

// USER LEAVE LIST
export const getLeaveByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getLeaveByUserId`, obj);
        localStorage.setItem("leaveList", JSON.stringify(data));
        dispatch({
            type: LEAVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_FAIL,
            payload: err,
        });
    }
};

// CREATE USER NEW LEAVE 
export const createLeave = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_CREATE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/createLeave`, obj);
        // localStorage.setItem("leaveList", JSON.stringify(data));
        dispatch({
            type: LEAVE_CREATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_CREATE_FAIL,
            payload: err,
        });
    }
};


// ADMIN ADD NEW LEAVE
export const addLeave = (obj) => async ( dispatch ) => {
    dispatch({
        type: LEAVE_CATEGORY_CREATE_REQUEST,
    })

    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leavecategory/createLeaveCategory`, obj)
        dispatch({
            type: LEAVE_CATEGORY_CREATE_SUCCESS,
            payload: data,
        })
        // navigateUser()
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_CATEGORY_CREATE_FAIL,
            payload: err,
        })
    }
}



// ADMIN GET ALL LEAVES QUERY - ALL LEAVES DISPLAY
export const getLeaveByCompanyId = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: ALL_LEAVE_QUERY_REQUEST,
    });
    try {
        // const { data } = await axios.post(`${SERVERADDRESS}/v1/users/allUsers/?sortBy=firstName%3Aasc&page=${pageNum}`, obj);
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getLeaveByCompanyId`, obj);
        localStorage.setItem("leaveDisplay", JSON.stringify(data));
        dispatch({
            type: ALL_LEAVE_QUERY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ALL_LEAVE_QUERY_FAIL,
            payload: err,
        });
    }
};



// ADMIN GET LEAVE REPORT
export const getLeaveStatus = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: LEAVE_REPORT_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getLeaveStatus`, obj);
        localStorage.setItem("leaveReport", JSON.stringify(data));
        dispatch({
            type: LEAVE_REPORT_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_REPORT_FAIL,
            payload: err,
        });
    }
};

// ADMIN GET LEAVE REPORT COPY
export const getLeaveStatusUsers = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: LEAVE_REPORT_USER_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leave/getLeaveStatusUsers`, obj);
        localStorage.setItem("leaveReportUsers", JSON.stringify(data));
        dispatch({
            type: LEAVE_REPORT_USER_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_REPORT_USER_FAIL,
            payload: err,
        });
    }
};


// ADMIN GET LEAVE SETTING
export const getCompanyLeaveCategory = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: LEAVE_SETTING_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/leavecategory/getCompanyLeaveCategory`, obj);
        localStorage.setItem("leaveSetting", JSON.stringify(data));
        dispatch({
            type: LEAVE_SETTING_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_SETTING_FAIL,
            payload: err,
        });
    }
};



// ADMIN LEAVE CARD DATA CONST
export const getLeaveCard = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_LEAVE_CARDS_DATA_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/leaveCardsAdmin`, obj);
        localStorage.setItem("leaveCard", JSON.stringify(data));
        dispatch({
            type: ADMIN_LEAVE_CARDS_DATA_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ADMIN_LEAVE_CARDS_DATA_FAIL,
            payload: err,
        });
    }
};



// UPDATE LEAVE CREATE / LEAVE SETTING
export const updateLeaveCategory = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/leavecategory/updateLeaveCategory`, obj);
        dispatch({
            type: LEAVE_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_UPDATE_FAIL,
            payload: err,
        });
    }
};

// delete leave setting se delete leave
export const removeLeaveCategory = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_LEAVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/leavecategory/updateLeaveCategory`, obj);
        dispatch({
            type: REMOVE_LEAVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_LEAVE_FAIL,
            payload: err,
        });
    }
};



// ADMIN UPDATE LEAVE CREATE BY EMPLOYEE
export const updateLeave = (obj) => async (dispatch) => {
    dispatch({
        type: ADMIN_CREATED_LEAVE_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/leave/updateLeave`, obj);
        console.log(data,"data hai yeh data")
        dispatch({
            type: ADMIN_CREATED_LEAVE_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ADMIN_CREATED_LEAVE_UPDATE_FAIL,
            payload: err,
        });
    }
};


// LEAVE SEARCH BY USING LEAVE SEARCH KPI
export const leaveSearchFilter = (obj) => async (dispatch) => {
    dispatch({
        type: LEAVE_SEARCH_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/kpi/leaveSearchFilter`, obj);
        dispatch({
            type: LEAVE_SEARCH_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LEAVE_SEARCH_FAIL,
            payload: err,
        });
    }
};

const initialState = {
viewEmployeeMenu : "employee",
viewPayrollMenu: "payrollitem",
viewTClk : "timeclock",
viewLeaveNavItem : "leave",
viewLocationAdministrationMenu : "location",
viewAllEmployeeSideMenu : "allstaff",
viewCompanyAndSubscription : "company",
viewTheShift: "weekly",
viewNavBar: "home",
monthYearCheck: null,
packageType : "monthly",
packageIdForProceed: '',
packagePrice: '',
newLeadEmail: '',
viewReportTimeClk: "weekly",
viewReportShift: "weekly",
viewOfIdentifyUser: "identify",
viewIfCompanySearch: "company"
// rowNumberTable: null,
};

export const changeTheTab = (state = initialState, action) => {

    switch(action.type){
        case "TAB_ALLEMPLOYEE" : return {
            ...state,
            viewEmployeeMenu : "employee" 
        }
        case "TAB_LOCATIONSPOSITION" : return {
            ...state,
            viewEmployeeMenu : "locationposition"
        }
        case "TAB_BREAKRULES" : return {
            ...state,
            viewEmployeeMenu : "breakrules"
        }

        // default : return state;
        case "TAB_PAYROLL" : return {
            ...state,
            viewPayrollMenu : "payrollitem"        
        }
        case "TAB_EMPLOYEESALARIES" : return {
            ...state,
            viewPayrollMenu : "employeesalaries"        
        }

        case "TAB_TIMECLOCK" : return {
            ...state,
            viewTClk : "timeclock"
        }
        case "TAB_RECENTCLOCKIN" : return {
            ...state,
            viewTClk : "recentlyclockedin"
        }
        case "TAB_ATTENDANCE" : return {
            ...state,
            viewTClk : "attendance"
        }
        case "TAB_TIMESHEET" : return {
            ...state,
            viewTClk : "timesheet"
        }
        case "TAB_OVERTIME" : return {
            ...state,
            viewTClk : "overtime"
        }

        case "TAB_LEAVE" : return {
            ...state,
            viewLeaveNavItem : "leave"
        }
        case "TAB_LEAVEREPORT" : return {
            ...state,
            viewLeaveNavItem : "leavereport"
        }
        case "TAB_LEAVESETTING" : return {
            ...state,
            viewLeaveNavItem : "leavesetting"
        }
        case "TAB_HOLIDAYS" : return {
            ...state,
            viewLeaveNavItem : "holidays"
        }
        case "TAB_LEAVESEARCH" : return {
            ...state,
            viewLeaveNavItem : "leaveSearch"
        }

        case "TAB_LOCATION" : return {
            ...state,
            viewLocationAdministrationMenu : "location"
        }
        case "TAB_FILEMANAGER" : return {
            ...state,
            viewLocationAdministrationMenu : "filemanager"
        }

        case "TAB_ALLSTAFF" : return {
            ...state,
            viewAllEmployeeSideMenu : "allstaff"
        }
        case "TAB_ACTIVE" : return {
            ...state,
            viewAllEmployeeSideMenu : "active"
        }
        case "TAB_NONACTIVE" : return {
            ...state,
            viewAllEmployeeSideMenu : "nonactive"
        }
        case "TAB_SEARCH" : return {
            ...state,
            viewAllEmployeeSideMenu : "search"
        }

        // shift admin ka liya
        case "TAB_WEEKLY" : return {
            ...state,
            viewTheShift : "weekly"
        }
        case "TAB_DAILY" : return {
            ...state,
            viewTheShift : "daily"
        }
        case "TAB_MONHTLY" : return {
            ...state,
            viewTheShift : "monthly"
        }
        case "TAB_SHIFTSEARCH" : return {
            ...state,
            viewTheShift : "thesearch"
        }
        case "TAB_ATTENDANCESEARCH" : return {
            ...state,
            viewTClk : "attendanceSearch"
        }
        case "TAB_CHECKMONTHANDYEARATTENDANCE" : return {
            ...state,
            monthYearCheck: action.value
        }

        case "TAB_REPORTTIMECLKWEEK" : return {
            ...state,
            viewReportTimeClk: "weekly"
        }
        case "TAB_REPORTTIMECLKDAILY" : return {
            ...state,
            viewReportTimeClk: "daily"
        }
        case "TAB_REPORTTIMECLKSEARCH" : return {
            ...state,
            viewReportTimeClk: "search"
        }
        case "TAB_SALARYCALENDARSEARCH" : return {
            ...state,
            viewPayrollMenu: "search"
        }
        case "TAB_REPORTSHIFTWEEK" : return {
            ...state,
            viewReportShift: "weekly"
        }
        case "TAB_REPORTSHIFTDAILY" : return {
            ...state,
            viewReportShift: "daily"
        }
        case "TAB_REPORTSHIFTSEARCH" : return {
            ...state,
            viewReportShift: "search"
        }


        // superadmin redux tab reducer

        case "TAB_COMPANY" : return {
            ...state,
            viewCompanyAndSubscription : "company"
        }
        case "TAB_SUBSCRIPTION" : return {
            ...state,
            viewCompanyAndSubscription : "subscription"
        }
        case "TAB_SCOMP" : return {
            ...state,
            viewCompanyAndSubscription : "scomp"
        }
        case "TAB_NEWREQUEST" : return {
            ...state,
            viewCompanyAndSubscription : "newrequest"
        }
        case "TAB_SEARCHCOMPANY" : return {
            ...state,
            viewCompanyAndSubscription : "search"
        }


        case "TAB_COMPANY_SEARCH_FILTER" : return {
            ...state,
            viewIfCompanySearch : "searchFilter"
        }
        case "TAB_COMPANY_MAIN_PAGE" : return {
            ...state,
            viewIfCompanySearch : "company"
        }

        // case "TAB_TABLEROWNUMBER" : return {
        //     ...state,
        //     rowNumberTable: action.value
        // }




        // general website tab actions
        case "TAB_HOME" : return {
            ...state,
            viewNavBar : "home" 
        }
        case "TAB_ABOUTUS" : return {
            ...state,
            viewNavBar : "aboutus"
        }
        case "TAB_OURPLANS" : return {
            ...state,
            viewNavBar : "ourplans"
        }

        case "TAB_WEWORK" : return {
            ...state,
            viewNavBar : "wework"
        }
        case "TAB_CONTACTUS" : return {
            ...state,
            viewNavBar : "contactus"
        }

        case "TAB_PACKAGEMONTHLY" : return {
            ...state,
            packageType : "monthly"
        }
        case "TAB_PACKAGEYEARLY" : return {
            ...state,
            packageType : "yearly"
        }
        case "TAB_PACKAGEID" : return {
            ...state,
            packageIdForProceed : action.value
        }
        case "TAB_PACKAGEPRICE" : return {
            ...state,
            packagePrice : action.value
        }
        case "TAB_CUSTOMEREMAIL" : return {
            ...state,
            newLeadEmail : action.value
        }
        case "TAB_IDENTIFYUSER" : return {
            ...state,
            viewOfIdentifyUser : "indentify"
        }
        case "TAB_LINKSENDTOUSER" : return {
            ...state,
            viewOfIdentifyUser : "requestSend"
        }


        default :
            return state;
    }
}
// export default changeTheTab

import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createPackage } from "../../../actions/packageActions";

const AddSubscription = ({ showDropDown, openDropDown }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    planName: "",
    description: "",
    amountMonthly: "",
    amountYearly: "",
    users: "",
  });
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const packageData = {
    planName: values.planName,
    description: values.description,
    amountMonthly: parseFloat(values.amountMonthly),
    amountYearly: parseFloat(values.amountYearly),
    users: parseInt(values.users),
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    for (let key of Object.keys(packageData)) {
      if (packageData[key] === "" || packageData[key] === "+") {
        delete packageData[key];
      }
    }
    dispatch(createPackage(packageData));
    openDropDown();
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="add_plan"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => openDropDown()}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="modal-body">
              <h5 className="modal-title text-center mb-3">Add Plan</h5>
              <form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Plan Name</label>
                      <input
                        type="text"
                        placeholder="Free Trial"
                        class="form-control"
                        value={values.planName}
                        onChange={handleChange("planName")}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Plan Monthly Amount</label>
                      <input
                        type="text"
                        placeholder="5.99"
                        class="form-control"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.amountMonthly}
                        onChange={handleChange("amountMonthly")}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Plan Annually Amount</label>
                      <input
                        type="text"
                        placeholder="5.99"
                        class="form-control"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.amountYearly}
                        onChange={handleChange("amountYearly")}
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>No of Users</label>
                      <input
                        type="text"
                        placeholder="20"
                        class="form-control"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        value={values.users}
                        onChange={handleChange("users")}
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Plan Description</label>
                  <textarea
                    placeholder="Lorem ipsum..."
                    class="form-control"
                    rows="4"
                    cols="30"
                    value={values.description}
                    onChange={handleChange("description")}
                  ></textarea>
                </div>
                <div class="m-t-20 text-center">
                  <button class="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddSubscription;

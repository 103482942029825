import axios from "axios";
import {
    MAIL_REQUEST,
    MAIL_SUCCESS,
    MAIL_FAIL,
    MAIL_BY_ID_REQUEST,
    MAIL_BY_ID_SUCCESS,
    MAIL_BY_ID_FAIL,
    MAIL_CREATE_REQUEST,
    MAIL_CREATE_SUCCESS,
    MAIL_CREATE_FAIL,
    UPDATE_MAIL_BY_ID_REQUEST,
    UPDATE_MAIL_BY_ID_SUCCESS,
    UPDATE_MAIL_BY_ID_FAIL,
    UPDATE_MAILSEEN_BY_ID_REQUEST,
    UPDATE_MAILSEEN_BY_ID_SUCCESS,
    UPDATE_MAILSEEN_BY_ID_FAIL,
    SERVERADDRESS,
} from "../constants"

// USER MAIL
export const getMailByUserId = (obj) => async (dispatch) => {
    dispatch({
        type: MAIL_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/mail/getMailByUserId`, obj);
        localStorage.setItem("mailData", JSON.stringify(data));
        dispatch({
            type: MAIL_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MAIL_FAIL,
            payload: err,
        });
    }
};


// USER MAIL
export const getMailByMailId = (obj, navigate) => async (dispatch) => {
    dispatch({
        type: MAIL_BY_ID_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/mail/getMailByMailId`, obj);
        localStorage.setItem("singleMail", JSON.stringify(data));
        dispatch({
            type: MAIL_BY_ID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        navigate('/inbox')
        dispatch({
            type: MAIL_BY_ID_FAIL,
            payload: err,
        });
    }
};


// ADD NEW MAIL 
export const createMail = (obj) => async ( dispatch ) => {
    dispatch({
        type: MAIL_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/mail/createMail`, obj)
        dispatch({
            type: MAIL_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MAIL_CREATE_FAIL,
            payload: err,
        })
    }
}


// MAIL UPDATE BY ID
export const updateMailById = (obj) => async (dispatch) => {
    dispatch({
        type: UPDATE_MAIL_BY_ID_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/mail/updateMailById`, obj);
        dispatch({
            type: UPDATE_MAIL_BY_ID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: UPDATE_MAIL_BY_ID_FAIL,
            payload: err,
        });
    }
};
  

// MAIL SEEN UPDATE BY ID
export const updateMailByIdSeen = (obj) => async (dispatch) => {
    dispatch({
        type: UPDATE_MAILSEEN_BY_ID_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/mail/updateMailByIdSeen`, obj);
        dispatch({
            type: UPDATE_MAILSEEN_BY_ID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: UPDATE_MAILSEEN_BY_ID_FAIL,
            payload: err,
        });
    }
};
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { updateUserLoginInfo, userLogout } from "../../actions/authActions";

const AdminSideBar = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const navigateUser = () => {
    // dispatch(updateUserLoginInfo({
    //     _id: auth.activeUserCountry.id,
    //     loginStatus:false
    // }))
    navigate("/login");
  };
  const logout = async (e) => {
    auth && auth.activeUserCountry
      ? await dispatch(
          userLogout(
            { refreshToken: auth.tokens.refresh.token },
            navigateUser,
            auth.activeUserCountry.id
          )
        )
      : await dispatch(
          userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
        );
    // await dispatch(userLogout({refreshToken: auth.tokens.refresh.token}, navigateUser, auth.activeUserCountry.id))
  };
  const [showAdministratorDropDown, setshowAdministratorDropDown] =
    useState(false);
  const [showReportDropDown, setshowReportDropDown] = useState(false);
  const openReportDropDown = () => {
    setshowReportDropDown((showReportDropDown) => !showReportDropDown);
    setshowAdministratorDropDown(false);
  };
  // console.log(showReportDropDown)
  const openAdministratorDropDown = () => {
    setshowAdministratorDropDown(
      (showAdministratorDropDown) => !showAdministratorDropDown
    );
    setshowReportDropDown(false);
  };

  const location = useLocation();
  const currentPath = location.pathname;

  const isActive = (path) => (currentPath === path ? "active" : "");
  return (
    <>
      <div className="">
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className={isActive("/dashboard")}>
                  <NavLink to="/dashboard">
                    <i className="la la-home"></i> <span>Dashboard</span>
                  </NavLink>
                </li>
                <li className={isActive("/employees")}>
                  <NavLink to="/employees">
                    <i className="la la-users"></i> <span>All Employees</span>
                  </NavLink>
                </li>
                <li className={isActive("/chat")}>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i> <span>Chat</span>
                  </NavLink>
                </li>
                <li className={isActive("/time-clock")}>
                  <NavLink to="/time-clock">
                    <i className="la la-clock-o"></i>
                    <span>Time Clock</span>
                  </NavLink>
                </li>
                <li className={isActive("/shift-scheduling")}>
                  <NavLink to="/shift-scheduling">
                    <i className="la la-calendar"></i>
                    <span>Shift &amp; Schedule</span>
                  </NavLink>
                </li>
                <li className={isActive("/tracking")}>
                  <NavLink to="/tracking">
                    <i className="la la-map-marker"></i>
                    <span>Tracking</span>
                  </NavLink>
                </li>
                {/* <li className={isActive("/leaves")}>
                  <NavLink to="/leaves">
                    <i className="la la-user-times"></i>
                    <span>Leaves</span>
                  </NavLink>
                </li>
                <li className={isActive("/training")}>
                  <NavLink to="/training">
                    <i className="la la-laptop"></i> <span>Training</span>
                  </NavLink>
                </li> */}
                <li className={isActive("/payroll-items")}>
                  <NavLink to="/payroll-items">
                    <i className="la la-credit-card"></i> <span>Payroll</span>
                  </NavLink>
                </li>
                <li
                  className={`submenu ${
                    isActive("/shift-report") ||
                    isActive("/timeclk-report") ||
                    isActive("/attend-report")
                      ? "active"
                      : ""
                  }`}
                >
                  <NavLink to="#" onClick={openReportDropDown}>
                    <i className="la la la-file-invoice"></i>
                    <span>Reports</span> <span className="menu-arrow"></span>
                  </NavLink>
                  <ul className={`${showReportDropDown ? "show" : "hide"}`}>
                    <li className={isActive("/shift-report")}>
                      <NavLink to="/shift-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Shift Reports</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/timeclk-report")}>
                      <NavLink to="/timeclk-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Time Clock Reports</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/attend-report")}>
                      <NavLink to="/attend-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Attendance Reports</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li
                  className={`submenu ${
                    isActive("/leaves") ||
                    isActive("/training") ||
                    isActive("/locations") ||
                    isActive("/file-manager") ||
                    isActive("/policies") ||
                    isActive("/users") ||
                    isActive("/subscriptions-company") ||
                    isActive("/settings")
                      ? "active"
                      : ""
                  }`}
                >
                  <NavLink to="#" onClick={openAdministratorDropDown}>
                    <i className="las la-cogs"></i>
                    <span>Administration</span>{" "}
                    <span className="menu-arrow"></span>
                  </NavLink>
                  <ul
                    className={`${showAdministratorDropDown ? "show" : "hide"}`}
                  >
                    <li className={isActive("/leaves")}>
                      <NavLink to="/leaves">
                        <i className="la la-user-times"></i>
                        <span>Leaves</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/training")}>
                      <NavLink to="/training">
                        <i className="la la-laptop"></i> <span>Training</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/locations")}>
                      <NavLink to="/locations">
                        <i className="la la-map-marker"></i>{" "}
                        <span>Locations</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/file-manager")}>
                      <NavLink to="/file-manager">
                        {/* <i className="la la-folder"></i> */}
                        <i class="fa fa-layer-group"></i>
                        <span>My Files</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/policies")}>
                      <NavLink to="/policies">
                        <i className="la la-file"></i> <span>Policies</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/users")}>
                      <NavLink to="/users">
                        <i className="la la-users"></i> <span>Users</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/subscriptions-company")}>
                      <NavLink to="/subscriptions-company">
                        <i className="la la-hand-o-up"></i>{" "}
                        <span>Subscriptions</span>
                      </NavLink>
                    </li>
                    <li className={isActive("/settings")}>
                      <NavLink to="/settings">
                        <i className="la la-cog"></i> <span>Settings</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#fff" }}
                    onClick={logout}
                  >
                    <i className="la la-sign-out"></i> <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSideBar;

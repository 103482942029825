import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken } from '../actions/authActions'
import Footer from '../components/Footer'
// import NewPasswordBanner from '../components/frontendComponents/enterNewPassword/NewPasswordBanner'
import NewPasswordForm from '../components/frontendComponents/enterNewPassword/NewPasswordForm'
import NavBar from '../components/NavBar'
const EnterNewPassword = () => {
  const params = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      // console.log("dispatch")
      dispatch(getToken({token: params.id}, navigate))
    } 
  }, [params.id]);
  return (
    <>
      <NavBar />
      {/* <NewPasswordBanner /> */}
      <NewPasswordForm />
      <Footer />
    </>
  )
}
export default EnterNewPassword
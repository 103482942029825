import axios from "axios"
import {
    ADDITION_CREATE_REQUEST,
    ADDITION_CREATE_SUCCESS,
    ADDITION_CREATE_FAIL,
    OVERTIME_CREATE_REQUEST,
    OVERTIME_CREATE_SUCCESS,
    OVERTIME_CREATE_FAIL,
    DEDUCTION_CREATE_REQUEST,
    DEDUCTION_CREATE_SUCCESS,
    DEDUCTION_CREATE_FAIL,
    PAYROLL_ADDITION_TABLE_REQUEST,
    PAYROLL_ADDITION_TABLE_SUCCESS,
    PAYROLL_ADDITION_TABLE_FAIL,
    PAYROLL_OVERTIME_TABLE_REQUEST,
    PAYROLL_OVERTIME_TABLE_SUCCESS,
    PAYROLL_OVERTIME_TABLE_FAIL,
    PAYROLL_DEDUCTION_TABLE_REQUEST,
    PAYROLL_DEDUCTION_TABLE_SUCCESS,
    PAYROLL_DEDUCTION_TABLE_FAIL,
    PAYROLL_ADDITION_UPDATE_REQUEST,
    PAYROLL_ADDITION_UPDATE_SUCCESS,
    PAYROLL_ADDITION_UPDATE_FAIL,
    PAYROLL_OVERTIME_UPDATE_REQUEST,
    PAYROLL_OVERTIME_UPDATE_SUCCESS,
    PAYROLL_OVERTIME_UPDATE_FAIL,
    PAYROLL_DEDUCTION_UPDATE_REQUEST,
    PAYROLL_DEDUCTION_UPDATE_SUCCESS,
    PAYROLL_DEDUCTION_UPDATE_FAIL,
    PAYROLL_ADDITION_REMOVE_REQUEST,
    PAYROLL_ADDITION_REMOVE_SUCCESS,
    PAYROLL_ADDITION_REMOVE_FAIL,
    PAYROLL_OVERTIME_REMOVE_REQUEST,
    PAYROLL_OVERTIME_REMOVE_SUCCESS,
    PAYROLL_OVERTIME_REMOVE_FAIL,
    PAYROLL_DEDUCTION_REMOVE_REQUEST,
    PAYROLL_DEDUCTION_REMOVE_SUCCESS,
    PAYROLL_DEDUCTION_REMOVE_FAIL,
    SALARY_CALENDER_REQUEST,
    SALARY_CALENDER_SUCCESS,
    SALARY_CALENDER_FAIL,
    MONTHLY_SALARY_CREATE_REQUEST,
    MONTHLY_SALARY_CREATE_SUCCESS,
    MONTHLY_SALARY_CREATE_FAIL,
    MONTHLY_SALARY_DATA_CREATE_REQUEST,
    MONTHLY_SALARY_DATA_CREATE_SUCCESS,
    MONTHLY_SALARY_DATA_CREATE_FAIL,
    EMPLOYEE_SALARY_UPDATE_REQUEST,
    EMPLOYEE_SALARY_UPDATE_SUCCESS,
    EMPLOYEE_SALARY_UPDATE_FAIL,
    EMPLOYEE_SALARY_REMOVE_REQUEST,
    EMPLOYEE_SALARY_REMOVE_SUCCESS,
    EMPLOYEE_SALARY_REMOVE_FAIL,
    SERVERADDRESS,
} from "../constants"
import { tabEmployeeSalaries } from "./tabActions"


// ADMIN ADD NEW ADDITION
export const addAddition = (obj) => async ( dispatch ) => {
    dispatch({
        type: ADDITION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/additionType/createAdditionType`, obj)
        dispatch({
            type: ADDITION_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: ADDITION_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN ADD NEW OVERTIME
export const addOvertime = (obj) => async ( dispatch ) => {
    dispatch({
        type: OVERTIME_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/overtimeType/createOvertimeType`, obj)
        dispatch({
            type: OVERTIME_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: OVERTIME_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN ADD NEW DEDUCTION
export const addDeduction = (obj) => async ( dispatch ) => {
    dispatch({
        type: DEDUCTION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/deductionType/createDeductionType`, obj)
        dispatch({
            type: DEDUCTION_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: DEDUCTION_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN GET ADDTION 
export const getAdditionTypeByCompanyId = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: PAYROLL_ADDITION_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/additionType/getAdditionTypeByCompanyId`, obj);
        localStorage.setItem("payrollAdditionTable", JSON.stringify(data));
        dispatch({
            type: PAYROLL_ADDITION_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_ADDITION_TABLE_FAIL,
            payload: err,
        });
    }
};


// ADMIN GET OVERTIME 
export const getOvertimeTypeByCompanyId = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: PAYROLL_OVERTIME_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/overtimeType/getOvertimeTypeByCompanyId`, obj);
        localStorage.setItem("payrollOvertimeTable", JSON.stringify(data));
        dispatch({
            type: PAYROLL_OVERTIME_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_OVERTIME_TABLE_FAIL,
            payload: err,
        });
    }
};



// ADMIN GET DEDUCTION
export const getDeductionTypeByCompanyId = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: PAYROLL_DEDUCTION_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/deductionType/getDeductionTypeByCompanyId`, obj);
        localStorage.setItem("payrollDeductionTable", JSON.stringify(data));
        dispatch({
            type: PAYROLL_DEDUCTION_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_DEDUCTION_TABLE_FAIL,
            payload: err,
        });
    }
};



// ADMIN SALARY CALENDER OR SALARY TABLE 
export const getAllMonthlySalaryByCompanyId = (obj) => async (dispatch) => {
    dispatch({
        type: SALARY_CALENDER_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salaryCalender/getAllMonthlySalaryByCompanyId`, obj);
        localStorage.setItem("salaryCalender", JSON.stringify(data));
        dispatch({
            type: SALARY_CALENDER_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: SALARY_CALENDER_FAIL,
            payload: err,
        });
    }
};


// ADMIN ADD NEW SALARY
export const addMonthlySalary = (obj, setValuesToInit, setIsUser) => async ( dispatch ) => {
    dispatch({
        type: MONTHLY_SALARY_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salaryCalender/createMonthlySalary`, obj)
        dispatch({
            type: MONTHLY_SALARY_CREATE_SUCCESS,
            payload: data,
        })
        setValuesToInit()
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: MONTHLY_SALARY_CREATE_FAIL,
            payload: err,
        })
        setIsUser(obj.userId)
    }
}


// SALARY BY SALARY MONTHLY ID
export const getMonthlySalaryById = (obj, navigate) => async ( dispatch ) => {
    dispatch({
        type: MONTHLY_SALARY_DATA_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/salaryCalender/getMonthlySalaryById`, obj)
        localStorage.setItem("monthlySalary", JSON.stringify(data));
        dispatch({
            type: MONTHLY_SALARY_DATA_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        // console.log(err)
        navigate('/payroll-items')
        // navigateUser()
        dispatch(tabEmployeeSalaries())
        dispatch({
            type: MONTHLY_SALARY_DATA_CREATE_FAIL,
            payload: err,
        })
    }
}


// UPDATE ADDITION 
export const updateAdditionType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_ADDITION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/additionType/updateAdditionType`, obj);
        dispatch({
            type: PAYROLL_ADDITION_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_ADDITION_UPDATE_FAIL,
            payload: err,
        });
    }
};


// UPDATE OVERTIME
export const updateOvertimeType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_OVERTIME_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/overtimeType/updateOvertimeType`, obj);
        dispatch({
            type: PAYROLL_OVERTIME_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_OVERTIME_UPDATE_FAIL,
            payload: err,
        });
    }
};


// UPDATE DEDUCTION
export const updateDeductionType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_DEDUCTION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/deductionType/updateDeductionType`, obj);
        dispatch({
            type: PAYROLL_DEDUCTION_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_DEDUCTION_UPDATE_FAIL,
            payload: err,
        });
    }
};



// UPDATE EMPLOYEE SALARY UPDATE
export const updateSalaryCalendar = (obj) => async (dispatch) => {
    dispatch({
        type: EMPLOYEE_SALARY_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/salaryCalender/updateSalaryCalendar`, obj);
        dispatch({
            type: EMPLOYEE_SALARY_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: EMPLOYEE_SALARY_UPDATE_FAIL,
            payload: err,
        });
    }
};



// REMOVE PAYROLL ADDITION
export const removeAdditionType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_ADDITION_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/additionType/updateAdditionType`, obj);
        dispatch({
            type: PAYROLL_ADDITION_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_ADDITION_REMOVE_FAIL,
            payload: err,
        });
    }
};


// REMOVE PAYROLL OVERTIME
export const removeOvertimeType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_OVERTIME_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/overtimeType/updateOvertimeType`, obj);
        dispatch({
            type: PAYROLL_OVERTIME_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_OVERTIME_REMOVE_FAIL,
            payload: err,
        });
    }
};


// REMOVE PAYROLL DEDUCTION
export const removeDeductionType = (obj) => async (dispatch) => {
    dispatch({
        type: PAYROLL_DEDUCTION_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/deductionType/updateDeductionType`, obj);
        dispatch({
            type: PAYROLL_DEDUCTION_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: PAYROLL_DEDUCTION_REMOVE_FAIL,
            payload: err,
        });
    }
};


// REMOVE EMPLOYEE
export const removeEmployeeSalaryCalender = (obj) => async (dispatch) => {
    dispatch({
        type: EMPLOYEE_SALARY_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/salaryCalender/updateSalaryCalendar`, obj);
        dispatch({
            type: EMPLOYEE_SALARY_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: EMPLOYEE_SALARY_REMOVE_FAIL,
            payload: err,
        });
    }
};

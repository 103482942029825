import React from "react";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import AllInvoiceComponent from "../../components/superAdminCompoents/superAdminALLInvoice/AllInvoiceComponent";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";

const AllInvoices = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Invoices" />
            <AllInvoiceComponent />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllInvoices;

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SAdminCompanySearchFilter } from '../../../actions/superAdminCompanyActions'
import { tabCompanySearchFilter } from '../../../actions/tabActions'

const CompaniesSearch = () => {
    // const auth = useSelector((state) => state.auth)
    const company = useSelector((state) => state.company)
    const [ values, setValues ] = useState({
        clientId:'',
        name: '',
        companyName: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const dispatch = useDispatch()
    const searchCompanyData = {
        // companyId: company.user.companyId,
        clientId: values.clientId,
        name:values.name,
        companyName: values.companyName,
    }
    // console.log(values, "console pa ARHA HAI vvvockpxk pk ok dokvo")
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (searchCompanyData.clientId === ''){
            delete searchCompanyData.clientId
        }
        if (searchCompanyData.name === ''){
            delete searchCompanyData.name
        }
        if (searchCompanyData.companyName === ''){
            delete searchCompanyData.companyName
        }
        dispatch(tabCompanySearchFilter())
        dispatch(SAdminCompanySearchFilter(searchCompanyData))
    }
    return (
        <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus select-focus">
                    <input type="text" className="form-control floating" 
                    value={values.clientId} onChange={handleChange('clientId')}/>
                    <label className="focus-label">Client ID</label>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus select-focus">
                    <input type="text" className="form-control floating" 
                    value={values.name} onChange={handleChange('name')}/>
                    <label className="focus-label">Client Name</label>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus select-focus">
                    <select className=" form-select" style={{ height: "50px", fontSize: "13px" }}
                        type="text"
                        autoComplete="false" value={values.companyName}
                        onChange={handleChange('companyName')}>
                            <option value=''>All Company</option>
                            {
                                company && 
                                company.superAdminCompanyCC && 
                                company.superAdminCompanyCC.length > 0 &&
                                company.superAdminCompanyCC.map((current, i) => {
                                    return (
                                        <option value={current.company.companyName} key={i+1}>{current.company.companyName}</option>
                                    )
                                })
                            }
                    </select>
                    <label className="focus-label">Company</label>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <a href="#" className="btn btn-success btn-block" onClick={handleSubmit}> Search </a>
            </div>
        </div>
    )
}
export default CompaniesSearch
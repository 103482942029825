import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tabWeWork } from '../actions/tabActions'
import Features from '../components/Features'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import Screenshots from '../components/weWorkComponents/Screenshots'
import Tutorial from '../components/weWorkComponents/Tutorial'
import WeWorkBanner from '../components/weWorkComponents/WeWorkBanner'
import WeWorkInfo from '../components/weWorkComponents/WeWorkInfo'
const WeWork = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tabWeWork())
  }, [])
  return (
    <>
      <NavBar />
      <WeWorkBanner />
      <WeWorkInfo />
      <Features />
      <Screenshots />
      <Tutorial />
      <Footer />
    </>
  )
}
export default WeWork
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import work from '../../assetsgeneral/images/work.png'
const WeWorkInfo = () => {
    return (
        <>
            <div className="about-us-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="about-uss-text work-secs">
                                <h2> WORK SCHEDULING  </h2>
                                <h3>WORK BETTER, TOGETHER </h3>
                                <p>Riyowork is an employee scheduling app that does more than just save you time on scheduling. It also helps you improve communication, eliminate excuses, boost accountability among your staff, track time and attendance, and grow your business.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <AnimationOnScroll animateIn='fadeInRight' duration="1.5" animateOnce="true" >
                            <div className="work-img">
                                <img src={work} id="about-img" alt='about' />
                            </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeWorkInfo
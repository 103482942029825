import React from 'react'

const SAdminUsersSearchAndFilter = () => {
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus">
                        <input type="text" className="form-control floating" />
                        <label className="focus-label">Name</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}>
                            <option>Select Company</option>
                            <option>Global Technologies</option>
                            <option>Delta Infotech</option>
                        </select>
                        <label className="focus-label">Company</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}>
                            <option>Select Roll</option>
                            <option>Web Developer</option>
                            <option>Web Designer</option>
                            <option>Android Developer</option>
                            <option>Ios Developer</option>
                        </select>
                        <label className="focus-label">Role</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <a href="#" className="btn btn-success btn-block"> Search </a>
                </div>
            </div>
        </>
    )
}
export default SAdminUsersSearchAndFilter
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
const SuperAdminInvoiceTable = () => {
  const company = useSelector((state) => state.company);
  return (
    <>
      <div className="row">
        <div className="col-md-12 d-flex">
          <div className="card card-table flex-fill">
            <div className="card-header">
              <h3 className="card-title mb-0">Invoices</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-nowrap custom-table mb-0">
                  <thead>
                    <tr>
                      <th>Invoice ID</th>
                      <th>Client</th>
                      <th>Subscription Date</th>
                      <th>Due Date</th>
                      <th>Total</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {company.StripeInovicesQueryAllCompanies &&
                      company.StripeInovicesQueryAllCompanies.length > 0 &&
                      company.StripeInovicesQueryAllCompanies.map(
                        (current, i) => {
                          return (
                            i < 5 && (
                              <tr>
                                <td>
                                  <NavLink to="#">{current.invoice.id}</NavLink>
                                </td>
                                <td>
                                  {current.company &&
                                    current.company.companyName && (
                                      <h2>
                                        <NavLink to="#">
                                          {current.company.companyName}
                                        </NavLink>
                                      </h2>
                                    )}
                                </td>
                                <td>
                                  {moment
                                    .unix(
                                      current.invoice.lines.data[0].period.start
                                    )
                                    .format("MM/DD/YYYY")}
                                </td>
                                <td>
                                  {moment
                                    .unix(
                                      current.invoice.lines.data[0].period.end
                                    )
                                    .format("MM/DD/YYYY")}
                                </td>
                                <td>${current.invoice.lines.data[0].amount}</td>
                                <td>
                                  {current.invoice.status === "paid" ? (
                                    <span className="badge bg-success text-white">
                                      {current.invoice.status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-white">
                                      {current.invoice.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <NavLink to="/all-invoices">View all invoices</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminInvoiceTable;

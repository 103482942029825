import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { reportShiftWeekly } from "../../../actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const ShiftReportWeeklySearchTable = () => {
  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);
  const [today, setToday] = useState(moment());
  // let date = moment(today.format('YYYY-MM-DD'))
  let dateForJS = moment(today.format("YYYY-MM-DD"));
  let compArr = [];
  let dateDay = [0, 1, 2, 3, 4, 5, 6];
  for (let e of dateDay)
    compArr[e] = dateForJS.startOf("week").add(++e, "days").format("DD");
  // console.log(compArr)
  const dispatch = useDispatch();
  const weeklyShiftReportData = () => {
    dispatch(
      reportShiftWeekly({
        companyId: auth.user.companyId,
        date: moment().format("YYYY-MM-DD"),
        type: "Weekly",
      })
    );
  };
  useEffect(() => {
    weeklyShiftReportData();
  }, []);

  return (
    <>
      <br />
      <div className="row miordr2">
        <div className="col-md-12">
          <div className="table-responsive misftsch">
            <table className="table table-striped custom-table datatable ">
              <thead>
                <tr style={{ backgroundColor: "#fff" }}>
                  {/* <th colSpan="2">All Employees / Positions</th> */}
                  <th colSpan="23">All Employees / Positions</th>
                </tr>
                {/* </thead>
                                <thead> */}
                <tr
                  className="report-tbhdrw2"
                  style={{ backgroundColor: "#F1F6FF", width: "100%" }}
                >
                  <th>Name</th>
                  <th
                    className="report-tbhdtt1"
                    style={{ backgroundColor: "#F1F6FF" }}
                    colSpan="22"
                  >
                    Total
                  </th>
                  {/* <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th> */}
                  {/* <th></th> */}
                  {/* <th className="report-tbhdst1 report-tbhdfst">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th> */}
                </tr>
              </thead>
              <tbody>
                {admin.searchReportShift &&
                  admin.searchReportShift.length > 0 &&
                  admin.searchReportShift.map((current, i) => {
                    return (
                      <tr key={i}>
                        {current.vary && current.vary.length > 0 && (
                          <>
                            <td>
                              <h2 className="table-avatar">
                                <NavLink
                                  to={`/profile/${current.e.id}`}
                                  className="avatar"
                                >
                                  {current.e &&
                                  current.e.profilePic &&
                                  current.e.profilePic.fileUrl ? (
                                    <img
                                      alt="profilePic"
                                      src={
                                        BUCKET_BASE_URL +
                                        current.e.profilePic.fileUrl
                                      }
                                      style={{
                                        objectFit: "fill",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img alt="profilePic" src={defaultImg} />
                                  )}
                                </NavLink>
                                <NavLink to={`/profile/${current.e.id}`}>
                                  {current.e.firstName +
                                    ", " +
                                    current.e.lastName}
                                  <span>{current.e.jobTitle}</span>
                                </NavLink>
                              </h2>
                            </td>
                            <td className="report-tbhdtt1">
                              {Math.round(current.totalHours * 100) / 100}
                            </td>

                            {
                              current.vary &&
                                current.vary.length > 0 &&
                                current.vary.map((e, j) => {
                                  let shiftType;
                                  let shiftTypeCss = "miclsun";
                                  let shiftTypeIcon = "fa fa-sun-o";
                                  let empty = true;
                                  let temp;
                                  // let start, end;
                                  let stAmPm = "am";
                                  let enAmPm = "am";
                                  let date;
                                  if (Object.keys(e).length != 0) {
                                    let stStart = parseInt(
                                      e.startTime.substring(11, 13)
                                    );
                                    let stStartMin = parseInt(
                                      e.startTime.substring(14, 16)
                                    );
                                    let enEnd = parseInt(
                                      e.endTime.substring(11, 13)
                                    );
                                    let enEndMin = parseInt(
                                      e.endTime.substring(14, 16)
                                    );
                                    if (stStartMin < 10) {
                                      stStartMin = "0" + stStartMin;
                                    }
                                    if (enEndMin < 10) {
                                      enEndMin = "0" + enEndMin;
                                    }
                                    if (stStart === 0) {
                                      stStart = 12;
                                    }
                                    if (enEnd === 0) {
                                      enEnd = 12;
                                    }
                                    if (stStart > 12) {
                                      stStart = stStart - 12;
                                      stAmPm = "pm";
                                    }
                                    if (enEnd > 12) {
                                      enEnd = enEnd - 12;
                                      enAmPm = "pm";
                                    }
                                    if (
                                      (stStart === 12 || stStart <= 5) &&
                                      stAmPm == "am"
                                    ) {
                                      shiftType = "night";
                                      shiftTypeCss = "miclsmn";
                                      shiftTypeIcon = "fa fa-moon-o";
                                    } else if (stStart >= 6 && stAmPm == "am") {
                                      shiftType = "morning";
                                      shiftTypeCss = "miclsun";
                                      shiftTypeIcon = "fa fa-sun-o";
                                    } else if (
                                      stStart >= 0 &&
                                      stStart <= 7 &&
                                      stAmPm == "pm"
                                    ) {
                                      shiftType = "evening";
                                      shiftTypeCss = "miclscl";
                                      shiftTypeIcon = "fa fa-cloud";
                                    } else if (stStart >= 8 && stAmPm == "pm") {
                                      shiftType = "night";
                                      shiftTypeCss = "miclsmn";
                                      shiftTypeIcon = "fa fa-moon-o";
                                    } else if (stStart >= 0 && stAmPm == "am") {
                                      shiftType = "night";
                                      shiftTypeCss = "miclsmn";
                                      shiftTypeIcon = "fa fa-moon-o";
                                    }
                                    date =
                                      stStart +
                                      ":" +
                                      stStartMin +
                                      " " +
                                      stAmPm +
                                      "-" +
                                      enEnd +
                                      ":" +
                                      enEndMin +
                                      " " +
                                      enAmPm;
                                  }
                                  return (
                                    <>
                                      {
                                        Object.keys(e).length != 0 && (
                                          // ?
                                          <>
                                            <td
                                              className="report-tbhdst1 report-tbhdfst report-tbhdhr1"
                                              style={{
                                                borderCollapse: "separate",
                                                width: "auto",
                                                textAlign: "center",
                                                borderTop: "2px solid #000",
                                                borderBottom: "2px solid #000",
                                              }}
                                            >
                                              <div
                                                className={`user-add-shedule-list ${shiftTypeCss}`}
                                              >
                                                <h2>
                                                  <NavLink
                                                    to={
                                                      e.location
                                                        ? `/location-view/${e.location.id}`
                                                        : "#"
                                                    }
                                                  >
                                                    <div>
                                                      {/* style={{width: "100%", height: "3rem"}} yeh add karo toh class achi hogi */}
                                                      <span className="username-info ">
                                                        Date:{" "}
                                                        {e.date
                                                          .toString()
                                                          .substring(0, 10)}
                                                      </span>
                                                      <span className="username-info ">
                                                        {date} {e.duration}
                                                      </span>
                                                      {e.location ? (
                                                        <span className="userrole-info">
                                                          {e.userId.jobTitle} -{" "}
                                                          {e.location.name}{" "}
                                                          <i
                                                            className={
                                                              shiftTypeIcon
                                                            }
                                                          ></i>
                                                        </span>
                                                      ) : (
                                                        <span className="userrole-info">
                                                          {e.userId.jobTitle} -{" "}
                                                          <i
                                                            className={
                                                              shiftTypeIcon
                                                            }
                                                          ></i>
                                                        </span>
                                                      )}
                                                      {/* <span className="userrole-info">{e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span> */}
                                                    </div>
                                                  </NavLink>
                                                </h2>
                                              </div>
                                            </td>
                                          </>
                                        )
                                        // :
                                        // <>
                                        //     <td className="report-tbhdst1 report-tbhdfst"></td>
                                        //     <td className="report-tbhden1"></td>
                                        //     <td className="report-tbhdhr1"></td>
                                        // </>
                                      }
                                    </>
                                  );
                                })
                              // :
                              // <td></td>
                              // [0,0,0,0,0,0,0].map(g=>{
                              //     return(
                              //         <>
                              //             <td className="report-tbhdst1 report-tbhdfst"></td>
                              //             <td className="report-tbhden1"></td>
                              //             <td className="report-tbhdhr1"></td>
                              //         </>
                              //     )
                              //     })
                            }
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/* <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <label> Showing 1 to 4 of 4 entries </label>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div className="avatar-pagination">
                                    <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                                        <li className="page-item">
                                            <NavLink className="page-link" to="#" aria-label="Previous">
                                                <span aria-hidden="true">Previous</span>
                                                <span className="sr-only">Previous</span>
                                            </NavLink>
                                        </li>
                                        <li className="page-item active"><NavLink className="page-link" to="#">1</NavLink></li>
                                        <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
                                        <li className="page-item">
                                            <NavLink className="page-link" to="#" aria-label="Next">
                                                <span aria-hidden="true">Next</span>
                                                <span className="sr-only">Next</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>Show &nbsp;
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                            className="select">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries
                                    </label>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default ShiftReportWeeklySearchTable;

{
  /* <tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Ali, Abdirahman <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr>
<tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Bennet, Ishiae <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr>
<tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Bowman, Luvell <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr> */
}

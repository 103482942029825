import React from 'react'
// import hit2 from "../../assets/images/how-it-works-thumb-2.png"
// import hit_1 from "../../assets/images/how-it-works-thumb_1.png"
// import hit_2 from "../../assets/images/how-it-works-thumb_2.png"
// import da from '../../assetsgeneral/images/da.png' 
// import daa from '../../assetsgeneral/images/daa.png' 
// import daw from '../../assetsgeneral/images/daw.png' 
import da from '../../assetsgeneral/images/how-it-works-thumb-2.png' 
import daa from '../../assetsgeneral/images/how-it-works-thumb_1.png' 
// import daw from '../../assetsgeneral/images/how-it-works-thumb_2.png' 
import daw from '../../assetsgeneral/images/ourwrokremovebg-preview-removebg-preview.png' 
import { AnimationOnScroll } from "react-animation-on-scroll"

const Tutorial = () => {
    return (
        <>
            <section className="how-it-works-area" style={{backgroundColor: "#ffffff"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-9">
                            <div className="section-title-6 text-center easyas easycase">
                                <h3>How it works</h3>
                                {/* <span>How it works</span> */}
                                <h2 className="title">Every easy step  can work easily</h2>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-works-item mt-40">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="how-it-works-content">
                                    <div className="line"></div>
                                    <span>01</span>
                                    <h3 className="title">Sign Up or Log In Your <br /> Dashboard Account</h3>
                                    <p className="text">Riyowork is an employee scheduling app that does more than just save you time on scheduling. It also helps you improve communication, eliminate excuses, boost accountability among your staff, track time and attendance, and grow your business.</p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="how-it-works-thumb animated wow fadeInRight">
                                    {/* data-wow-duration="1500ms" data-wow-delay="200ms" */}
                                    <AnimationOnScroll animateIn="fadeInRight" duration="1.5" delay="200" animateOnce="true">
                                        <img src={da} alt="thumb" />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-works-item mt-120">
                        <div className="row">
                            <div className="col-lg-5 order-2 order-lg-1">
                                <div className="how-it-works-thumb animated wow fadeInLeft">
                                    {/* data-wow-duration="1500ms" data-wow-delay="200ms" */}
                                    <AnimationOnScroll animateIn="fadeInLeft" duration="1.5" delay="200" animateOnce="true">
                                        <img src={daa} alt="" />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                            <div className="col-lg-7 order-1 order-lg-2">
                                <div className="how-it-works-content">
                                    <div className="line"></div>
                                    <span>02</span>
                                    <h3 className="title">Sign Up or Log In Your <br /> Dashboard Account</h3>
                                    <p className="text">Riyowork is an employee scheduling app that does more than just save you time on scheduling. It also helps you improve communication, eliminate excuses, boost accountability among your staff, track time and attendance, and grow your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how-it-works-item mt-80">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="how-it-works-content">
                                    <div className="line"></div>
                                    <span>03</span>
                                    <h3 className="title">Sign Up or Log In Your <br /> Dashboard Account</h3>
                                    <p className="text">Riyowork is an employee scheduling app that does more than just save you time on scheduling. It also helps you improve communication, eliminate excuses, boost accountability among your staff, track time and attendance, and grow your business.</p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="how-it-works-thumb mihmimg3 animated wow fadeInRight" >
                                    {/* data-wow-duration="1500ms" data-wow-delay="200ms" */}
                                    <AnimationOnScroll animateIn="fadeInRight" duration="1.5" delay="200" animateOnce="true">
                                        <img src={daw} alt="thumb" />
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Tutorial
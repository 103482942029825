import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCompanyAndAdmin } from "../../../actions/superAdminCompanyActions";
import moment from "moment/moment";
// import { forwardRef, useEffect } from 'react'

const CompaniesAddModal = ({ showDropDown, openDropDown }) => {
  // const auth = useSelector((state) => state.auth)
  // const user = useSelector((state) => state.user)
  // const company = useSelector((state) => state.company)
  const allPackage = useSelector((state) => state.package);
  const [values, setValues] = useState({
    // companyId: '',
    // name: '',
    // companyName: '',
    // companyPhone: '',
    // companyEmail: '',
    // expMonthYear: '',
    expiryMonth: "",
    expiryYear: "",
    cvc: "",
    cardNumber: "",
    numberOfEmployees: "",
    industry: "",
    role: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    year: "",
    packageId: "",
    currentSubscriptionPackageType: "",
    date: "",
    trial_period_days: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handlePhone = (fieldName) => (event) => {
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];

  const dispatch = useDispatch();
  const updateData = {
    companyEmail: values.email,
    companyPhone: values.phone,
    expMonthYear: values.expiryMonth + "/" + values.expiryYear,
    cvc: parseInt(values.cvc),
    cardNumber: parseInt(values.cardNumber),
    companyName: values.companyName,
    year: moment().format("YY").toString(),
    numberOfEmployees: values.numberOfEmployees,
    industry: values.industry,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    role: "admin",
    packageId: values.packageId,
    currentSubscriptionPackageType: values.currentSubscriptionPackageType,
    trial_period_days: parseInt(values.trial_period_days),
    date: moment().format("YYYY-MM-DD"),
  };
  // console.log(updateData, "uhiuhiubgiubyigugggggggggggggg")
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      updateData.trial_period_days === NaN ||
      updateData.trial_period_days === 0
    ) {
      delete updateData.trial_period_days;
    }
    if (parseInt(values.expiryMonth) < 13 && parseInt(values.expiryMonth) > 0) {
      dispatch(createCompanyAndAdmin(updateData));
      openDropDown();
    }
  };

  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_client"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Client</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        User Email<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control floating"
                        type="email"
                        autoComplete="false"
                        value={values.email}
                        onChange={handleChange("email")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        User Phone<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        minLength="14"
                        maxLength="14"
                        required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 6
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 2
                          ) {
                            setValues({
                              ...values,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 10
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 9),
                            });
                          }
                        }}
                        name="phone"
                        value={values.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123) 456-7890"
                        autocomplete="new-mobileNunmber"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.companyName}
                        onChange={handleChange("companyName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Number of Employees
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        required
                        value={values.numberOfEmployees}
                        onChange={handleChange("numberOfEmployees")}
                      >
                        <option value="">-</option>
                        <option value="20">1 - 20</option>
                        <option value="50">21 - 50</option>
                        <option value="200">51 - 200</option>
                        <option value="500">201 - 500</option>
                        <option value="3000">501 - 3,000</option>
                        <option value="3001">3,001+</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Industry<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        required
                        value={values.industry}
                        onChange={handleChange("industry")}
                      >
                        <option value="">-</option>
                        <option value="Call Center / Customer Support">
                          Call Center / Customer Support
                        </option>
                        <option value="Education">Education</option>
                        <option value="Food Services">Food Services</option>
                        <option value="Government">Government</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Hospitality / Entertainment">
                          Hospitality / Entertainment
                        </option>
                        <option value="Logistics">Logistics</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="On-demand / Gig">On-demand / Gig</option>
                        <option value="Retail">Retail</option>
                        <option value="Services">Services</option>
                        <option value="Technology">Technology</option>
                        <option value="Something Else">Something Else</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Date <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.date}
                                            onChange={handleChange('date')}/>
                                        </div>
                                    </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Package Name<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.packageId}
                        onChange={handleChange("packageId")}
                      >
                        <option value="">-</option>
                        {allPackage.package &&
                          allPackage.package.length > 0 &&
                          allPackage.package.map((current, i) => {
                            return (
                              current.toBeDisplay === true && (
                                <option value={current.id}>
                                  {current.planName}
                                </option>
                              )
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Package Type<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.currentSubscriptionPackageType}
                        onChange={handleChange(
                          "currentSubscriptionPackageType"
                        )}
                      >
                        <option value="">-</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Trial Period Days<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        autoComplete="false"
                        value={values.trial_period_days}
                        onChange={handleChange("trial_period_days")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Card Number<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        minLength="16"
                        maxLength="16"
                        required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="cardNumber"
                        value={values.cardNumber}
                        onChange={handleChange("cardNumber")}
                        placeholder="Valid card number..."
                        autocomplete="new-cardNumber"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        CVC<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        minLength="3"
                        maxLength="3"
                        required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="cvc"
                        value={values.cvc}
                        onChange={handleChange("cvc")}
                        placeholder="3 digit CVC..."
                        autocomplete="new-cvc"
                      />
                      {/* <div id="card-element-cvc"></div> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Expiry Month<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        minLength="2"
                        maxLength="2"
                        required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="expiryMonth"
                        value={values.expiryMonth}
                        onChange={handleChange("expiryMonth")}
                        placeholder="MM..."
                        max-length="2"
                        autocomplete="new-expiryMonth"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Expiry Year<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        minLength="4"
                        maxLength="4"
                        required
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="expiryYear"
                        value={values.expiryYear}
                        onChange={handleChange("expiryYear")}
                        placeholder="YYYY..."
                        max-length="4"
                        autocomplete="new-expiryYear"
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompaniesAddModal;

import React from 'react'
import Footer from '../../components/Footer'
import PrivacyPolicy from '../../components/frontendComponents/websiteSignupForm/PrivacyPolicy'
import NavBar from '../../components/NavBar'

const PrivacyPolicyForm = () => {
  return (
    <>
      <NavBar />
      <PrivacyPolicy />  
      <Footer />
    </>
  )
}
export default PrivacyPolicyForm
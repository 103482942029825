import React from "react";
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from "../../components/adminComponents/AdminHeader";
import AdminSideBar from "../../components/adminComponents/AdminSideBar";
import { useSelector } from "react-redux";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import AdminLeavesCard from "../../components/adminComponents/adminLeaveComponents/AdminLeavesCard";
import AdminLeavesFilterandSearch from "../../components/adminComponents/adminLeaveComponents/AdminLeavesFilterandSearch";
import AdminLeavesTable from "../../components/adminComponents/adminLeaveComponents/AdminLeavesTable";
// import AdminLRFilterandSearch from '../../components/adminComponents/adminLeaveComponents/AdminLRFilterandSearch'
import AdminLRTable from "../../components/adminComponents/adminLeaveComponents/AdminLRTable";
import AdminLS from "../../components/adminComponents/adminLeaveComponents/AdminLS";
import AdminLHolidays from "../../components/adminComponents/adminLeaveComponents/AdminLHolidays";
import AdminLeavesTableSearch from "../../components/adminComponents/adminLeaveComponents/AdminLeavesTableSearch";
// import AdminLeaveNavItem from "../../components/adminComponents/adminLeaveComponents/AdminLeaveNavItem"
const AdminLeaveMenu = () => {
  const myState = useSelector((state) => state.changeTheTab);
  return (
    <>
      {/* <AdminHeader />
            <AdminSideBar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {myState.viewLeaveNavItem === "leave" ? (
              <>
                <AdminTittle title="Leave" leave />
                <AdminLeavesCard />
                <AdminLeavesFilterandSearch />
                <AdminLeavesTable />
              </>
            ) : myState.viewLeaveNavItem === "leavereport" ? (
              <>
                <AdminTittle title="Leave Report" leave />
                {/* <AdminLRFilterandSearch /> */}
                <AdminLRTable />
              </>
            ) : myState.viewLeaveNavItem === "leavesetting" ? (
              <>
                <AdminTittle title="Leave Setting" leave />
                <AdminLS />
              </>
            ) : myState.viewLeaveNavItem === "holidays" ? (
              <>
                <AdminTittle title="Holidays" leave />
                <AdminLHolidays />
              </>
            ) : myState.viewLeaveNavItem === "leaveSearch" ? (
              <>
                <AdminTittle title="Leave" leave />
                <AdminLeavesCard />
                <AdminLeavesFilterandSearch />
                <AdminLeavesTableSearch />
              </>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLeaveMenu;

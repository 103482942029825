import React from 'react'
import { useSelector } from 'react-redux'

const SuperAdminClientRepresentative = () => {
    const company = useSelector((state) => state.company)

    return (
        <>
            <div className="col-lg-12">
                <div className="tab-content milocpmn">
                    <div className="card card-table mb-0">
                        <div className="card-header">
                            <h4 className="card-title mb-0"><strong>Company's Representative Details </strong></h4>
                        </div>
                        <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                                <tbody>
                                {
                                    company.superAdminCompanyCPC &&
                                    <>
                                    <tr>
                                        <td className="sorting_1">
                                            <h2 className="table-avatar">
                                                <a>Name:</a>
                                            </h2>
                                        </td>
                                    <td>{company.superAdminCompanyCPC[0].admin.firstName + " "+ company.superAdminCompanyCPC[0].admin.lastName}</td>
                                    <td className="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td className="sorting_1">
                                            <h2 className="table-avatar">
                                                <a>Phone:</a>
                                            </h2>
                                        </td>
                                    <td>{company.superAdminCompanyCPC[0].admin.phone}</td>
                                    <td className="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td className="sorting_1">
                                            <h2 className="table-avatar">
                                                <a>Email:</a>
                                            </h2>
                                        </td>
                                    <td>{company.superAdminCompanyCPC[0].admin.email}</td>
                                    <td className="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td className="sorting_1">
                                            <h2 className="table-avatar">
                                                <a>Date of Birth:</a>
                                            </h2>
                                        </td>
                                    <td>{company.superAdminCompanyCPC[0].admin.dateOfBirth}</td>
                                    <td className="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td className="sorting_1">
                                            <h2 className="table-avatar">
                                                <a>Gender:</a>
                                            </h2>
                                        </td>
                                    <td>{company.superAdminCompanyCPC[0].admin.gender}</td>
                                    <td className="text-right">
                                    </td>
                                    </tr>
                                    </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
export default SuperAdminClientRepresentative
import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import io from "socket.io-client"
import { SERVERADDRESS, SOCKETADDRESS } from '../../../constants';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const containerStyle = {
  width: '100%',
  height: '80vh'
};

const socket = io(SOCKETADDRESS)

const TrackingEmployee = () => {
    // const socket = io('https://api.riyowork.com')
    // let socket;
    // useEffect(() => {
    //     socket = io('localhost:5000')
    // }, [])
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
    })
    const params = useParams()
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });
    const [places, setPlaces] = useState([]);
    const [map, setMap] = React.useState(null)
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [cc, setCC] = useState(0)
    useEffect(() => {
        // console.log(socket.id, "useeddecr")
        socket.emit("user-location", { userId: params.id }, (response) => {
            setCoordinates({
                lat: response.status.latitude,
                lng: response.status.longitude,
            });
            return () => {
                socket.off("user-location")
            }
        })

    }, [cc, params.id, coordinates.lat, coordinates.lng,])
    useEffect(() => {
        setCurrentLocation()
    }, [params.id])
    let bb = 0
    useEffect(()=>{
      setInterval(() => 
          setCC(bb++)
      , 2000);
    },[])
    const setCurrentLocation = async () => {
        const res = await axios.post(`${SERVERADDRESS}/v1/track/trackData`, 
            {
                userId: params.id
            }
        )
        if (res.data.latitude !== null || res.data.longitude !== null){
            setCenter({
                lat: res.data.latitude,
                lng: res.data.longitude,
            })
            setCoordinates({
                lat: res.data.latitude,
                lng: res.data.longitude,
            });
        }
        else {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    // setCenter([position.coords.latitude, position.coords.longitude])
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    })
                    setCoordinates({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
            }
        }
    }
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    return isLoaded ? (
        <>
        <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onUnmount={onUnmount}
        // onClick={_onClick}
        >
        <Marker
            position={coordinates}
        />
        </GoogleMap>
        </>
    ) : <></>
}
export default TrackingEmployee
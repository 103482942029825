import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import EmployeeHome from "./pages/employeePages/EmployeeHome";
// import "./assets/css/bootstrap-datetimepicker.min.css"
// import "./assets/css/bootstrap.min.css"
// import "./assets/css/dataTables.bootstrap4.min.css"
// import "./assets/css/font-awesome.min.css"
// import "./assets/css/fullcalendar.min.css"
// import "./assets/css/jquery.circliful.css"
// import "./assets/css/line-awesome.min.css"
// import "./assets/css/morris.css"
// import "./assets/css/select2.min.css"
// import "./assets/css/style.css"
// import "./assets/css/tagsinput.css"

import EmployeeSchedule from "./pages/employeePages/EmployeeSchedule";
import EmployeeAttendance from "./pages/employeePages/EmployeeAttendance";
import EmployeeLeaves from "./pages/employeePages/EmployeeLeaves";
import EmployeeTraining from "./pages/employeePages/EmployeeTraining";
import EmployeeActivities from "./pages/employeePages/EmployeeActivities";
import Inbox from "./pages/Inbox";
import Profile from "./pages/Profile";
import EmployeeSettings from "./pages/employeePages/EmployeeSettings";
import EmployeeCalendar from "./pages/employeePages/EmployeeCalendar";
import EmployeeTimesheet from "./pages/employeePages/EmployeeTimesheet";
import EmployeeOvertime from "./pages/employeePages/EmployeeOvertime";
import MailView from "./pages/MailView";
import EmployeeWorkLocation from "./pages/employeePages/EmployeeWorkLocation";
import EmployeeFileManager from "./pages/employeePages/EmployeeFileManager";
import "./App.css";

// import "./pages/adminPages/App.css"
// import "./assetsAdmin/css/style.css"

import EmployeeLogin from "./pages/EmployeeLogin";
import { useSelector } from "react-redux";
import Delaypage from "./pages/employeePages/Delaypage";
import Unauthorized from "./pages/Unauthorized";

import AdminSchedulingReport from "./pages/adminPages/AdminSchedulingReport";
import AdminDashboard from "./pages/adminPages/AdminDashboard";
import AdminAllEmployees from "./pages/adminPages/AdminAllEmployees";
import AdminShiftReport from "./pages/adminPages/AdminShiftReport";
import AdminTimeClockReport from "./pages/adminPages/AdminTimeClockReport";
import AdminAttendReport from "./pages/adminPages/AdminAttendReport";
import AdminLocationAdministration from "./pages/adminPages/AdminLocationAdministration";
import AdminActiveLocations from "./pages/adminPages/AdminActiveLocations";
import AdminPoliciesView from "./pages/adminPages/AdminPoliciesView";
import AdminUsersView from "./pages/adminPages/AdminUsersView";
import AdminAdministrationSettingView from "./pages/adminPages/AdminAdministrationSettingView";
import AdminSalaryView from "./pages/adminPages/AdminSalaryView";
import AdminLeaveMenu from "./pages/adminPages/AdminLeaveMenu";
import AdminTClockView from "./pages/adminPages/AdminTClockView";
import AdminSSchedulingView from "./pages/adminPages/AdminSSchedulingView";
import AdminTrainingView from "./pages/adminPages/AdminTrainingView";
import AdminPIView from "./pages/adminPages/AdminPIView";
import AdminAdministrationSubscription from "./pages/adminPages/AdminAdministrationSubscription";
import AddEmployeeFormPage from "./pages/adminPages/AddEmployeeFormPage";
import ProfileAtAdmin from "./pages/adminPages/ProfileAtAdmin";
import ViewInbox from "./pages/adminPages/ViewInbox";
import ViewMailView from "./pages/adminPages/ViewMailView";
import ViewComposeMsg from "./pages/adminPages/ViewComposeMsg";
import ProfileAtAdminAdmin from "./pages/adminPages/ProfileAtAdminAdmin";
import MyComponent from "./pages/Test";
import ViewActivity from "./pages/adminPages/ViewActivity";
import UploadImageToS3WithNativeSdk from "./pages/UploadImageToS3WithNativeSdk";

import SuperAdminCompaniesPage from "./pages/superAdminPages/SuperAdminCompaniesPage";
import SuperAdminSubscriptionPage from "./pages/superAdminPages/SuperAdminSubscriptionPage";
import SuperAdminSubCompanyPage from "./pages/superAdminPages/SuperAdminSubCompanyPage";

// import "./assetsAdmin/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./customCss.css";

import "./assets/css/style.css";
import "./assets/css/feather.css";

import "./assetsgeneral/css/style.css";
import "./assetsgeneral/css/font-awesome.css";
import "./assetsgeneral/css/font-awesome.min.css";



import "./assetsgeneral/fonts/fontawesome-webfont.eot";
import "./assetsgeneral/fonts/fontawesome-webfont.svg";
import "./assetsgeneral/fonts/fontawesome-webfont.ttf";
import "./assetsgeneral/fonts/fontawesome-webfont.woff";
import "./assetsgeneral/fonts/fontawesome-webfont.woff2";


import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurPlans from "./pages/OurPlans";
import WeWork from "./pages/WeWork";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import "./animate.min.css";
import Quote from "./pages/Quote";
import ConfirmMail from "./pages/ConfirmMail";
// import PaymentDetails from './pages/PaymentDetails'
import SuperAdminDashboard from "./pages/superAdminPages/SuperAdminDashboard";
import SuperAdminFileManagerPage from "./pages/superAdminPages/SuperAdminFileManagerPage";
import SuperAdminPoliciesPage from "./pages/superAdminPages/SuperAdminPoliciesPage";
import SuperAdminUsersPage from "./pages/superAdminPages/SuperAdminUsersPage";
import SuperAdminSubscriptionPageAdministration from "./pages/superAdminPages/SuperAdminSubscriptionPageAdministration";
import SuperAdminSettingPage from "./pages/superAdminPages/SuperAdminSettingPage";
import SuperAdminClientProfile from "./pages/superAdminPages/SuperAdminClientProfile";
import SuperAdminProfile from "./pages/superAdminPages/SuperAdminProfile";
import TermsOfUseForm from "./pages/frontendPages/TermsOfUseForm";
import PrivacyPolicyForm from "./pages/frontendPages/PrivacyPolicyForm";
import VerifiedPage from "./pages/frontendPages/VerifiedPage";
import DuesPage from "./pages/frontendPages/DuesPage";
import DisableWarningPage from "./pages/frontendPages/DisableWarningPage";
import AllInvoices from "./pages/superAdminPages/AllInvoices";
import AdminWorkLocation from "./pages/adminPages/AdminWorkLocation";
import EnterEmailPage from "./pages/EnterEmailPage";
import EnterNewPassword from "./pages/EnterNewPassword";
import SuperAdminInbox from "./pages/superAdminPages/SuperAdminInbox";
import SuperAdminComposePage from "./pages/superAdminPages/SuperAdminComposePage";
import EmailSentToUserPage from "./pages/EmailSentToUserPage";
import SuperAdminComposetoAdminPage from "./pages/superAdminPages/SuperAdminComposetoAdminPage";
import SuperAdminComposetoUserPage from "./pages/superAdminPages/SuperAdminComposetoUserPage";
import SuperAdminMailViewPage from "./pages/superAdminPages/SuperAdminMailViewPage";
import AdminLoginInfo from "./pages/superAdminPages/AdminLoginInfo";
import Test2 from "./pages/Test2";
import AdminFileManager from "./pages/adminPages/AdminFileManager";
import AdminTrackingofEmployeePage from "./pages/adminPages/AdminTrackingofEmployeePage";
import AdminTrackingofEmpIDPage from "./pages/adminPages/AdminTrackingofEmpIDPage";
import SuperAdminContactUsQueriesPage from "./pages/superAdminPages/SuperAdminContactUsQueriesPage";
import Test3 from "./pages/Test3";
import AdminChatPage from "./pages/adminPages/AdminChatPage";
import AdminLayout from "./layout/adminLayout";
import EmployeeLayout from "./layout/employeeLayout";
import SuperAdminLayout from "./layout/superAdminLayout";
import SuperAdminChatPage from "./pages/superAdminPages/SuperAdminChatPage";

const App = () => {
  const auth = useSelector((state) => state.auth);
  // const [subdomain, setSubDomain] = useState(null);
  // useEffect(() => {
  // 	const host = window.location.host; // gets the full domain of the app
  // 	const arr = host
  // 		.split(".")
  // 		.slice(0, host.includes("localhost") ? -1 : -2);
  // 	if (arr.length > 0) setSubDomain(arr[0]);
  // }, []);
  // let companyInitialArr = auth.company.companyName.split(" ")
  // let companyInitial = "";
  // for (let i =0; i<companyInitialArr.length; i++){
  //   companyInitial += companyInitialArr[i].substring(0,1).toLowerCase()
  // }
  // console.log(companyInitial,"companyInitial")
  // const requestedUser = companyInitial
  // window.location = window.location.protocol + "//" + companyInitial + "." + window.location.host;
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-plan" element={<OurPlans />} />
        <Route path="/we-work" element={<WeWork />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/sign-up/plan=:id" element={<Quote />} />
        <Route path="/verify-details" element={<ConfirmMail />} />
        <Route path="/verified" element={<VerifiedPage />} />
        <Route path="/dues" element={<DuesPage />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/account-disable" element={<DisableWarningPage />} /> */}
        {/* <Route path="/payment-detail" element={<PaymentDetails />} /> */}
        {/* <Route path="/loginemployee" element={<EmployeeLogin />} />  */}
        {/* <Route path="/" element={<EmployeeLogin />} />  */}
        <Route path="/checkUser" element={<Delaypage />} />
        <Route path="/terms-of-use" element={<TermsOfUseForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyForm />} />
        <Route path="/identify" element={<EnterEmailPage />} />
        <Route path="/reset-password/:id" element={<EnterNewPassword />} />
        <Route path="*" element={<Login />} />
        {/* <Route path="*" element={<Unauthorized />} /> */}
        {/* <Route path="/requestSend" element={<EmailSentToUserPage />} />  */}

        {auth.user &&
        auth.user.role === "user" &&
        auth.user.disable === false ? (
          <Route
            path=""
            element={
              <EmployeeLayout>
                <Outlet />
              </EmployeeLayout>
            }
          >
            <Route path="/dashboard" element={<EmployeeHome />} />
            <Route path="/shift-scheduling" element={<EmployeeSchedule />} />
            <Route path="/attendance" element={<EmployeeAttendance />} />
            <Route path="/leaves" element={<EmployeeLeaves />} />
            <Route path="/training" element={<EmployeeTraining />} />
            <Route path="/activities" element={<EmployeeActivities />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<EmployeeSettings />} />
            <Route path="/calendar" element={<EmployeeCalendar />} />
            {/* <Route path="/timesheet" element={<EmployeeTimesheet />} /> */}
            <Route path="/overtime" element={<EmployeeOvertime />} />
            <Route path="/mail-view/:id" element={<MailView />} />
            <Route
              path="/location-view/:id"
              element={<EmployeeWorkLocation />}
            />
            <Route path="/file-manager" element={<EmployeeFileManager />} />
            <Route path="/chat" element={<AdminChatPage />} />
          </Route>
        ) : (
          <Route path="/account-disable" element={<DisableWarningPage />} />
        )}

        {auth.user && auth.user.role === "admin" && (
          <>
            <Route
              path=""
              element={
                <AdminLayout>
                  <Outlet />
                </AdminLayout>
              }
            >
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/employees" element={<AdminAllEmployees />} />
              <Route path="/employees/:id" element={<AdminAllEmployees />} />
              <Route path="/employees-add" element={<AddEmployeeFormPage />} />
              <Route path="/payroll-items" element={<AdminPIView />} />
              {/* <Route path="/scheduling-report" element={<AdminSchedulingReport />} /> */}
              <Route path="/shift-report" element={<AdminShiftReport />} />
              <Route
                path="/tracking"
                element={<AdminTrackingofEmployeePage />}
              />
              <Route
                path="/tracking/:id"
                element={<AdminTrackingofEmpIDPage />}
              />
              <Route
                path="/timeclk-report"
                element={<AdminTimeClockReport />}
              />
              <Route path="/attend-report" element={<AdminAttendReport />} />
              <Route
                path="/locations"
                element={<AdminLocationAdministration />}
              />
              <Route
                path="/active-locations"
                element={<AdminActiveLocations />}
              />
              <Route path="/policies" element={<AdminPoliciesView />} />
              <Route
                path="/location-view/:id"
                element={<AdminWorkLocation />}
              />
              <Route path="/users" element={<AdminUsersView />} />
              <Route path="/users/:id" element={<AdminUsersView />} />
              <Route
                path="/settings"
                element={<AdminAdministrationSettingView />}
              />
              <Route path="/salary-view/:id" element={<AdminSalaryView />} />
              <Route path="/leaves" element={<AdminLeaveMenu />} />
              <Route path="/time-clock" element={<AdminTClockView />} />
              <Route
                path="/shift-scheduling"
                element={<AdminSSchedulingView />}
              />
              <Route path="/training" element={<AdminTrainingView />} />
              <Route
                path="/subscriptions-company"
                element={<AdminAdministrationSubscription />}
              />
              <Route path="/admin-profile" element={<ProfileAtAdminAdmin />} />
              <Route path="/inbox" element={<ViewInbox />} />
              <Route path="/mail-view/:id" element={<ViewMailView />} />
              <Route path="/compose" element={<ViewComposeMsg />} />
              <Route path="/profile/:id" element={<ProfileAtAdmin />} />
              <Route path="/file-manager" element={<AdminFileManager />} />
              <Route path="/chat" element={<AdminChatPage />} />
            </Route>

            {/* <Route path='/activities' element={<ViewActivity />} /> */}
          </>
        )}

        {auth.user && auth.user.role === "superAdmin" && (
          <Route
            path=""
            element={
              <SuperAdminLayout>
                <Outlet />
              </SuperAdminLayout>
            }
          >
            <Route path="/dashboard" element={<SuperAdminDashboard />} />
            <Route path="/companies" element={<SuperAdminCompaniesPage />} />
            <Route
              path="/subscriptions"
              element={<SuperAdminSubCompanyPage />}
            />
            <Route
              path="/subscribed-companies"
              element={<SuperAdminSubscriptionPage />}
            />
            <Route
              path="/file-manager"
              element={<SuperAdminFileManagerPage />}
            />
            <Route path="/policies" element={<SuperAdminPoliciesPage />} />
            <Route path="/users" element={<SuperAdminUsersPage />} />
            <Route
              path="/subscriptions-company"
              element={<SuperAdminSubscriptionPageAdministration />}
            />
            <Route path="/settings" element={<SuperAdminSettingPage />} />
            <Route path="/admin-profile" element={<SuperAdminProfile />} />
            <Route
              path="/client-profile/:id"
              element={<SuperAdminClientProfile />}
            />
            <Route path="/all-invoices" element={<AllInvoices />} />
            <Route path="/mail-view/:id" element={<SuperAdminMailViewPage />} />
            <Route path="/inbox" element={<SuperAdminInbox />} />
            <Route
              path="/compose-message"
              element={<SuperAdminComposePage />}
            />
            <Route
              path="/compose-message-to-admin"
              element={<SuperAdminComposetoAdminPage />}
            />
            <Route
              path="/compose-message-to-users"
              element={<SuperAdminComposetoUserPage />}
            />
            <Route path="/admin-login-info" element={<AdminLoginInfo />} />
            <Route
              path="/contact-us-queries"
              element={<SuperAdminContactUsQueriesPage />}
            />
            <Route path="/chat" element={<SuperAdminChatPage />} />
          </Route>
        )}
        <Route path="/test" element={<MyComponent />} />
        <Route path="/test2/:id" element={<Test2 />} />
        {/* <Route path="/test3" element={<Test3 />}/>  */}
        {/* <Route path="/test2" element={<UploadImageToS3WithNativeSdk />}/> */}
        {/* <Route path="/companies" element={<SuperAdminCompaniesPage />}/> */}
      </Routes>
    </>
  );
};
export default App;

import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import mail from "../../assetsgeneral/images/undraw.png"

const MailConfirm = ({emailValue}) => {
    const myState = useSelector((state => state.changeTheTab))
    return (
        <>
            <section className="pricing-6-area pt-62 new-bg-color">
                <div className="container">
                    <div className="row contact-area justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                            <div className="pricing-6-item mt-30">
                                <div className="text-center">
                                    <img className="icom-email" src={mail} alt="" />
                                    <h3>Success !</h3>
                                    <p className="text-muted font-14 mt-2" > An email will be send to <b>{myState.newLeadEmail}</b>.

                                    <br />
                                    <br />
                                        Please check your email and verify it!</p>
                                        {/* <p className="text-muted font-14 mt-2"> A email has been send to <b>{emailValue}</b>.
                                        Please check for an email from <strong style={{ color: "#32895E" }}>RiyoWork</strong> and click on the included link to
                                        confirm your account.</p> */}
                                    <NavLink to="/" className="main-btn main-btn-2" style={{display:"inline-block"}}>Back to Home</NavLink> &nbsp;
                                    {/* <NavLink to="/payment-detail" className="ml-5 main-btn main-btn-2" style={{display:"inline-block"}}>Proceed to payment</NavLink> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MailConfirm
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import cake from "../../../assetsAdmin/img/cakeundraw_Birthday_cake_re_bsw5.png";
// import shift1 from "../../../assetsAdmin/img/undraw_Time_management_re_tk5w.png";
import tabel from "../../../assets/img/icons/tabel.png";
import { BUCKET_BASE_URL } from "../../../constants";
import AllAnnievesaryModal from "./AllAnnievesaryModal";
import AllLateComersModal from "./AllLateComersModal";
import { tabRecentClockin } from "../../../actions/tabActions";
import {
  // createComment,
  createMessageWall,
  getCommentById,
  getMessageWallById,
  updateMessageWall,
} from "../../../actions/messageWallActions";
import { allUsersWithoutPaginate } from "../../../actions/userActions";
import CommentComponent from "./CommentComponent";
import DeletePin from "./DeletePin";
import MessageWallRow from "./MessageWallRow";

const EmployeeDetails = () => {
  const [showCommentBox, setShowCommentBox] = useState(null); // Track comment input visibility
  const [showComments, setShowComments] = useState({}); // Track visibility of comments per message

  const auth = useSelector((state) => state.auth);
  const admin = useSelector((state) => state.admin);
  const user = useSelector((state) => state.user);
  const messageWall = useSelector((state) => state.messageWall);
  const messageWallData = async () => {
    dispatch(getMessageWallById({ companyId: auth.user.companyId }));
  };
  const allUsersDataWithoutPagination = async () => {
    dispatch(allUsersWithoutPaginate({ companyId: auth.user.companyId }));
  };

  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };

  const getAllComments = async (id) => {
    dispatch(getCommentById({ messageWallId: id }));
    setShowComments((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle comment visibility for the clicked message
    }));
  };

  useEffect(() => {
    messageWallData();
    allUsersDataWithoutPagination();
    getAllComments();
  }, []);
  let i = 0;
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString?.substring(11, 13));
    const minutes = timeString?.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  const formatDate = (date) => {
    const sp = date.split("T");
    const spDash = sp[0].split("-");
    return spDash[1] + "/" + spDash[2] + "/" + spDash[0];
  };
  const [showAnnieversaryDropDown, setshowAnnieversaryDropDown] =
    useState(false);
  const [showLateDropDown, setshowLateDropDown] = useState(false);
  const openAnnieversaryDropDown = () => {
    setshowAnnieversaryDropDown(
      (showAnnieversaryDropDown) => !showAnnieversaryDropDown
    );
  };
  const openLateDropDown = () => {
    setshowLateDropDown((showLateDropDown) => !showLateDropDown);
  };
  const dispatch = useDispatch();
  const [showMessageForm, setShowMessageForm] = useState(false);

  const toggleForm = () => {
    setShowMessageForm(!showMessageForm);
  };
  const [values, setValues] = useState({
    companyId: "",
    userId: "",
    messageOnWall: "",
    date: "",
    isPin: false,
  });

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currDate = moment().format();
  const arr = currDate?.toString()?.substring(0, 19);
  const newCurrDate = arr + ".000+00:00";

  const messageOnData = {
    companyId: auth.user.companyId,
    userId: auth.user.id,
    messageOnWall: values.messageOnWall,
    date: newCurrDate,
  };

  const handleSubmit = async (event) => {
    dispatch(createMessageWall(messageOnData));
    setValues({
      companyId: "",
      userId: "",
      messageOnWall: "",
      date: "",
    });
  };

  const foundDetails = (userId) => {
    return user?.allUsersWithoutPaginate?.find((x) => x.id == userId);
  };

  // const pinMessage = messageWall.getMessageWallById;
  const [sortedMessages, setSortedMessages] = useState([]);

  // Sorting function to move pinned messages on top
  const sortMessages = (messages) => {
    return messageWall?.getMessageWallById.sort((a, b) => {
      // If 'isPin' is true, it goes to the top
      if (a.isPin && !b.isPin) {
        return -1;
      } else if (!a.isPin && b.isPin) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const handlePin = async (id) => {
    dispatch(updateMessageWall({ _id: id, isPin: true }));
  };
  useEffect(() => {
    if (
      messageWall.getMessageWallById &&
      messageWall.getMessageWallById.length > 0
    ) {
      // Sort messages and set sorted state
      const sorted = sortMessages([...messageWall.getMessageWallById]);
      setSortedMessages(sorted);
    }
  }, [messageWall.getMessageWallById]);

  // console.log(admin, "AbsUsers");

  return (
    <>
      <div className="row">
        <div class="col-xl-5">
          <div class="card flex-fill card-table">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title">Annual Anniversaries</h5>
                <span className="badge bg-inverse-danger ml-2">
                  {admin.AnnualAnniversary &&
                  admin.AnnualAnniversary.length > 0 ? (
                    admin.AnnualAnniversary.map((it, ind) => {
                      if (it.user.length > 0) {
                        i += it.user.length;
                      }
                      return (
                        ind === admin.AnnualAnniversary.length - 1 && <>{i}</>
                      );
                    })
                  ) : (
                    <>{i}</>
                  )}
                </span>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    {admin.AnnualAnniversary &&
                    admin.AnnualAnniversary.length > 0 ? (
                      admin.AnnualAnniversary.map((current, i) => {
                        let hasMoreThanThreeUsers = current.user.length > 3;
                        return (
                          current.user.length > 0 && (
                            <>
                              {current.user.slice(0, 3).map((e, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <h2 class="table-avatar">
                                        <NavLink
                                          to={`/profile/${e.id}`}
                                          className="avatar"
                                        >
                                          {e.profilePic &&
                                          e.profilePic.fileUrl ? (
                                            <img
                                              alt="profilePic"
                                              src={
                                                BUCKET_BASE_URL +
                                                e.profilePic.fileUrl
                                              }
                                              style={{
                                                objectFit: "fill",
                                                height: "100%",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              alt="profilePic"
                                              src={defaultImg}
                                            />
                                          )}
                                        </NavLink>
                                        <a href="#">
                                          {e?.firstName} {e?.lastName}{" "}
                                          <span>
                                            {parseInt(
                                              e?.startingDate?.substring(0, 4)
                                            ) ===
                                            parseInt(
                                              moment().format().substring(0, 4)
                                            )
                                              ? "Recently Joined"
                                              : parseInt(
                                                  moment()
                                                    .format()
                                                    .substring(0, 4)
                                                ) -
                                                parseInt(
                                                  e?.startingDate?.substring(
                                                    0,
                                                    4
                                                  )
                                                ) +
                                                " Year Completed"}
                                          </span>
                                        </a>
                                      </h2>
                                    </td>
                                    <td class="text-end">
                                      <span class="badge bg-success-dark">
                                        {i === 0
                                          ? "Today"
                                          : i === 1
                                          ? " Tomorrow"
                                          : " In " + (i + 1) + " days"}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                              {hasMoreThanThreeUsers && (
                                <div class="card-footer text-center">
                                  <NavLink
                                    className="text-dark"
                                    onClick={openAnnieversaryDropDown}
                                  >
                                    View All Annual Anniversary
                                  </NavLink>
                                </div>
                              )}
                            </>
                          )
                        );
                      })
                    ) : (
                      <>
                        {/* <br /> */}
                        <br />
                        <div className="dash-stats-list">
                          <img
                            alt=""
                            src={cake}
                            style={{ width: "110px", opacity: "0.8" }}
                          />
                          {/* <br /> */}
                          <p style={{ opacity: "0.5" }}>
                            No Annual Anniversary
                          </p>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card flex-fill card-table">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title">Late Coming</h5>
                <span className="badge bg-inverse-danger ml-2">
                  {admin.LateComing && admin.LateComing.length}
                </span>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <tbody>
                    {admin.LateComing && admin.LateComing.length > 0 ? (
                      <>
                        {admin.LateComing.slice(0, 3).map((current, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <h2 className="table-avatar">
                                  <NavLink
                                    to={`/profile/${current.user.userId.id}`}
                                    className="avatar"
                                  >
                                    {current.user?.userId?.profilePic
                                      ?.fileUrl ? (
                                      <img
                                        alt="profilePic"
                                        src={
                                          BUCKET_BASE_URL +
                                          current.user.userId.profilePic.fileUrl
                                        }
                                        style={{
                                          objectFit: "fill",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      <img alt="profilePic" src={defaultImg} />
                                    )}
                                  </NavLink>
                                  <a href="#">
                                    {current.user.userId.firstName}{" "}
                                    {current.user.userId.lastName}{" "}
                                    <span>
                                      <h6 className="mb-0">
                                        {current.user?.location?.name || ""}
                                      </h6>
                                      <span>
                                        Timings :{" "}
                                        {current.user
                                          ? formatTime(current.user.startTime) +
                                            " - " +
                                            formatTime(current.user.endTime)
                                          : "without shift"}
                                      </span>
                                      {/* <div className="col-6 text-right">
                                          <span className="badge bg-danger text-white">
                                            {current.late} late
                                          </span>
                                        </div> */}
                                    </span>
                                  </a>
                                </h2>
                              </td>
                              <td class="text-end">
                                <span class="badge bg-danger">
                                  {current.late} Late
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        {admin.LateComing.length > 3 && (
                          <div className="card-footer text-center">
                            <NavLink
                              className="text-dark"
                              onClick={openLateDropDown}
                            >
                              View All Late Comers
                            </NavLink>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="dash-stats-list">
                        <img
                          alt=""
                          src={tabel}
                          style={{ width: "100px", opacity: "0.8" }}
                        />
                        <p style={{ opacity: "0.5" }}>No Late Comers</p>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card flex-fill card-table">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title">Today Absent</h5>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    {admin.AbsUsers && admin.AbsUsers.length > 0 ? (
                      <>
                        {admin.AbsUsers.map((current, i) => {
                          console.log(current, "abs user current");
                          const userDetail = foundDetails(current?.id);
                          return (
                            <tr key={i}>
                              <td>
                                <h2 class="table-avatar">
                                  <NavLink to="#" className="avatar">
                                    {/* {current.userId &&
                            current.userId.profilePic &&
                            current.userId.profilePic.fileUrl ? (
                              <img
                                alt="profilePic"
                                src={
                                  BUCKET_BASE_URL +
                                  current.userId.profilePic.fileUrl
                                }
                                style={{
                                  objectFit: "fill",
                                  height: "100%",
                                }}
                              />
                            ) : ( */}
                                    {userDetail?.profilePic?.fileUrl ? (
                                      <img
                                        alt="profilePic"
                                        src={
                                          BUCKET_BASE_URL +
                                          userDetail?.profilePic?.fileUrl
                                        }
                                        style={{
                                          objectFit: "fill",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      <img alt="profilePic" src={defaultImg} />
                                    )}
                                    {/* <img alt="profilePic" src={defaultImg} /> */}
                                    {/* )} */}
                                  </NavLink>
                                  <a href="#">
                                    {current.firstName} {current.lastName}{" "}
                                    <span>{current.jobTitle}</span>
                                  </a>
                                </h2>
                              </td>
                              <td class="text-end">
                                <span class="badge bg-danger">Absent</span>
                              </td>
                            </tr>

                            //   <td class="text-end">
                            //     <span class="badge bg-success-dark">Approved</span>
                            //   </td>
                          );
                        })}
                      </>
                    ) : (
                      <div className="dash-stats-list">
                        <img
                          alt=""
                          src={tabel}
                          style={{ width: "100px", opacity: "0.8" }}
                        />
                        <p style={{ opacity: "0.5" }}>No Late Comers</p>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-7">
          <div class="card flex-fill">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="card-title">
                  Who's On Now{" "}
                  <span className="badge badge-pill bg-success">
                    {admin.ActiveUser && admin.ActiveUser.length}
                  </span>
                </h3>
              </div>
            </div>
            <div class="card-body p-2">
              <div class="table-responsive">
                <table class="table table-hover">
                  {/* <thead class="thead-light">
                    <tr>
                      <th>Name</th>
                      <th>Time-In</th>
                      <th>Shift Timing</th>
                      <th>Location</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {admin.ActiveUser &&
                      admin.ActiveUser.length > 0 &&
                      // admin.ActiveUser.map((current, i) => {
                      admin.ActiveUser.slice(0, 4).map((current, i) => {
                        // console.log(current, "current admin.ActiveUser");
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                <h2 className="table-avatar">
                                  <NavLink to="#" className="avatar">
                                    {current.userId &&
                                    current?.userId?.profilePic &&
                                    current?.userId?.profilePic?.fileUrl ? (
                                      <img
                                        alt="profilePic"
                                        src={
                                          BUCKET_BASE_URL +
                                          current?.userId?.profilePic?.fileUrl
                                        }
                                        style={{
                                          objectFit: "fill",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      <img alt="profilePic" src={defaultImg} />
                                    )}
                                  </NavLink>
                                  <NavLink to="/profile">
                                    {current?.userId?.firstName}{" "}
                                    {current?.userId?.lastName}
                                    <span>
                                      {current.shift
                                        ? formatTime(
                                            current?.shift?.startTime
                                          ) +
                                          " - " +
                                          formatTime(current?.shift?.endTime)
                                        : "without shift"}
                                    </span>
                                    <span>{current?.userId?.jobTitle}</span>
                                  </NavLink>
                                </h2>
                              </td>
                              {/* <td>{current.dayClockIn.substring(11, 16)}</td> */}
                              {/* <td>{current.dayClockIn.substring(11, 16)}</td> */}
                              {/* <td>{formatTime(current.dayClockIn)}</td> */}
                              {/* <td>
                              {current.shift
                                ? current.shift.startTime.substring(11, 16) +
                                  " - " +
                                  current.shift.endTime.substring(11, 16)
                                : "without shift"}
                            </td> */}
                              <td>
                                {/* {current.shift
                                  ? formatTime(current.shift.startTime) +
                                    " - " +
                                    formatTime(current.shift.endTime)
                                  : "without shift"} */}
                              </td>
                              <td>
                                {/* <span className="badge bg-inverse-warning">
                                  {current.shift
                                    ? current.shift?.location?.name
                                    : ""}
                                </span> */}
                              </td>
                              <td>
                                {
                                  formatTime(current.dayClockIn)
                                  //  +
                                  //   " - " +
                                  //   (current.dayClockOut
                                  //     ? formatTime(current.dayClockOut)
                                  //     : "")
                                }
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer text-center">
              <NavLink
                to="/time-clock"
                className="text-dark"
                onClick={() => dispatch(tabRecentClockin())}
              >
                View All Active Employees
              </NavLink>
            </div>
          </div>
          <div class="card flex-fill">
            <div class="card-header">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title mb-4">Message Wall</h5>
                {!showMessageForm && (
                  <button
                    className="btn btn-success rounded-1"
                    onClick={toggleForm}
                  >
                    New Message
                  </button>
                )}
              </div>
              {showMessageForm && (
                <div className="row">
                  <div className="col-md-12">
                    <textarea
                      placeholder="Write your message"
                      className="form-control bg-light mb-3"
                      rows="4"
                      autoComplete="false"
                      value={values.messageOnWall}
                      onChange={handleChange("messageOnWall")}
                    ></textarea>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                      <div>
                        <button
                          className="btn btn-success rounded-1 me-4"
                          onClick={() => {
                            handleSubmit();
                            toggleForm(); // Hide form after posting
                          }}
                        >
                          Post Message
                        </button>
                        <button
                          className="btn btn-success rounded-1 me-4"
                          onClick={toggleForm}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {
              // messageWall.getMessageWallById &&
              sortedMessages.length > 0 &&
                sortedMessages.map((current2, i) => {
                  const userDetail = foundDetails(current2?.userId);
                  return (
                    current2.delete === false && (
                      <>
                        <MessageWallRow
                          current2={current2}
                          userDetail={userDetail}
                        />
                      </>
                    )
                  );
                })
            }
          </div>
        </div>
      </div>
      <AllAnnievesaryModal
        // current={current}
        showAnnieversaryDropDown={showAnnieversaryDropDown}
        openAnnieversaryDropDown={openAnnieversaryDropDown}
      />
      <AllLateComersModal
        // current={current}
        showLateDropDown={showLateDropDown}
        openLateDropDown={openLateDropDown}
      />
    </>
  );
};
export default EmployeeDetails;

{
  /* <div className="col-md-12 col-lg-12 col-xl-4 d-flex"> */
}
{
  /* style={{maxHeight: "415px"}}  neeche for fixed size */
}
{
  /* <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Annual Anniversaries
                <span className="badge bg-inverse-danger ml-2">
                  {admin.AnnualAnniversary &&
                  admin.AnnualAnniversary.length > 0 ? (
                    admin.AnnualAnniversary.map((it, ind) => {
                      if (it.user.length > 0) {
                        i += it.user.length;
                      }
                      return (
                        ind === admin.AnnualAnniversary.length - 1 && <>{i}</>
                      );
                    })
                  ) : (
                    <>{i}</>
                  )}
                </span>
              </h4>
              {admin.AnnualAnniversary && admin.AnnualAnniversary.length > 0 ? (
                admin.AnnualAnniversary.map((current, i) => {
                  return (
                    current.user.length > 0 && (
                      <>
                        {current.user.slice(0, 3).map((e, index) => {
                          return (
                            <div className="leave-info-box py-2">
                              <div className="media align-items-center">
                                <NavLink
                                  to={`/profile/${e.id}`}
                                  className="avatar"
                                >
                                  {e.profilePic && e.profilePic.fileUrl ? (
                                    <img
                                      alt="profilePic"
                                      src={
                                        BUCKET_BASE_URL + e.profilePic.fileUrl
                                      }
                                      style={{
                                        objectFit: "fill",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img alt="profilePic" src={defaultImg} />
                                  )}
                                </NavLink>
                                <div className="media-body">
                                  <div className="text-sm my-0">
                                    {e.firstName}, {e.lastName}
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center mt-2">
                                <div className="col-6">
                                  <h6 className="mb-0">
                                    {parseInt(
                                      e.startingDate.substring(0, 4)
                                    ) ===
                                    parseInt(moment().format().substring(0, 4))
                                      ? "Recently Joined"
                                      : parseInt(
                                          moment().format().substring(0, 4)
                                        ) -
                                        parseInt(
                                          e.startingDate.substring(0, 4)
                                        ) +
                                        " Year Completed"}
                                  </h6>
                                  <span className="text-sm text-muted"></span>
                                </div>
                                <div className="col-6 text-right">
                                  <span className="badge bg-primary text-white">
                                    {i === 0
                                      ? "Today"
                                      : i === 1
                                      ? " Tomorrow"
                                      : " In " + (i + 1) + " days"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )
                  );
                })
              ) : (
                <>
                  <br />
                  <br />
                  <div className="dash-stats-list">
                    <img
                      alt=""
                      src={cake}
                      style={{ width: "110px", opacity: "0.8" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Annual Anniversary</p>
                  </div>
                </>
              )}
            </div>
            <div
              className="card-footer"
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={openAnnieversaryDropDown}
            >
              <div>View All Annual Anniversary</div>
            </div>
          </div> */
}
{
  /* </div>; */
}

// {
//   admin.LateComing && admin.LateComing.length > 0 ? (
//     <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
//       <div className="card flex-fill">
//         <div className="card-body">
//           <h4 className="card-title">
//             Late Coming
//             <span className="badge bg-inverse-danger ml-2">
//               {admin.LateComing && admin.LateComing.length}
//             </span>
//           </h4>
//           {admin.LateComing &&
//             admin.LateComing.length > 0 &&
//             admin.LateComing.slice(0, 3).map((current, i) => {
//               return (
//                 <>
//                   <div className="leave-info-box">
//                     <div className="media align-items-center">
//                       <NavLink to="/profile" className="avatar">
//                         {current.user &&
//                         current.user.userId &&
//                         current.user.userId.profilePic &&
//                         current.user.userId.profilePic.fileUrl ? (
//                           <img
//                             alt="profilePic"
//                             src={
//                               BUCKET_BASE_URL +
//                               current.user.userId.profilePic.fileUrl
//                             }
//                             style={{ objectFit: "fill", height: "100%" }}
//                           />
//                         ) : (
//                           <img alt="profilePic" src={defaultImg} />
//                         )}
//                       </NavLink>
//                       <div className="media-body">
//                         <div className="text-sm my-0">
//                           {current.user.userId.firstName}{" "}
//                           {current.user.userId.lastName}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row align-items-center mt-3">
//                       <div className="col-6">
//                         <h6 className="mb-0">
//                           {current.user ? current.user?.location?.name : ""}
//                         </h6>
//                         <span className="text-sm text-muted">
//                           Timings :{" "}
//                           {current.user
//                             ? formatTime(current.user.startTime) +
//                               " - " +
//                               formatTime(current.user.endTime)
//                             : "without shift"}
//                         </span>
//                       </div>
//                       <div className="col-6 text-right">
//                         <span className="badge bg-danger text-white">
//                           {current.late} late
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                   <div
//                     className="card-footer"
//                     style={{ textAlign: "center", cursor: "pointer" }}
//                     onClick={openLateDropDown}
//                   >
//                     <div>View All Late Coming</div>
//                   </div>
//                 </>
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   ) : (
//     <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
//       <div className="card flex-fill">
//         <div className="card-body">
//           <h4 className="card-title">
//             Late Coming
//             <span className="badge bg-inverse-danger ml-2">
//               {admin.LateComing && admin.LateComing.length}
//             </span>
//           </h4>
//           <br />
//           <div className="dash-stats-list">
//             <img
//               alt=""
//               src={shift1}
//               style={{ width: "110px", opacity: "0.8" }}
//             />
//             <br />
//             <p style={{ opacity: "0.5" }}>No Late Comers</p>
//           </div>
//         </div>
//         <div
//           className="card-footer"
//           style={{ textAlign: "center", cursor: "pointer" }}
//           onClick={openLateDropDown}
//         >
//           <div>View All Late Coming</div>
//         </div>
//       </div>
//     </div>
//   );
// }

{
  /* <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
  <div className="card flex-fill">
    <div className="card-body">
      <h4 className="card-title">
        Today Absent <span className="badge bg-inverse-danger ml-2">5</span>
      </h4>
      <div className="leave-info-box">
        <div className="media align-items-center">
          <NavLink to="/profile" className="avatar">
            <img alt="" src={defaultImg} />
          </NavLink>
          <div className="media-body">
            <div className="text-sm my-0">Martin Lewis</div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-6">
            <h6 className="mb-0">9/22/2021</h6>
            <span className="text-sm text-muted">Leave Date</span>
          </div>
          <div className="col-6 text-right">
            <span className="badge bg-inverse-danger">Pending</span>
          </div>
        </div>
      </div>
      <div className="leave-info-box">
        <div className="media align-items-center">
          <NavLink to="/profile" className="avatar">
            <img alt="" src={defaultImg} />
          </NavLink>
          <div className="media-body">
            <div className="text-sm my-0">Martin Lewis</div>
          </div>
        </div>
        <div className="row align-items-center mt-3">
          <div className="col-6">
            <h6 className="mb-0">9/22/2021</h6>
            <span className="text-sm text-muted">Leave Date</span>
          </div>
          <div className="col-6 text-right">
            <span className="badge bg-inverse-success">Approved</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>; */
}

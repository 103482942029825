import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AddLeaveModal from "./employeeLeavesComponents/AddLeaveModal";
const EmployeeTitle = ({
  title,
  schedule,
  attendance,
  leaves,
  timesheet,
  overtime,
}) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  let navigate = useNavigate();
  const navDashboard = () => {
    navigate("/dashboard");
  };
  // console.log(showDropDown)
  return (
    <>
      <div className="page-header">
        <div className="row">
          <div className="col">
            <h3 className="page-title">{title}</h3>
            <ul className="breadcrumb2">
              {/* <li className="breadcrumb-item"><NavLink to="/dashbord">Dashboard</NavLink></li> */}
              <li className="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={navDashboard}>
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ul>
          </div>
          {/* {schedule &&
                        <div className="col-auto float-right ml-auto">
                            <NavLink to="/calendar" className="btn add-btn"><i className="la la-calendar"></i> Calender</NavLink>
                        </div>
                        } */}
          {attendance && (
            <div className="col-6 float-right ml-auto">
              {/* <NavLink to="/timesheet" className="btn add-btn mx-2"><i className="la la-table"></i> Timesheet</NavLink> */}
              <NavLink to="/overtime" className="btn add-btn">
                <i className="la la-briefcase"></i>Overtime
              </NavLink>
            </div>
          )}
          {leaves && (
            <div className="col-auto float-right ml-auto">
              <NavLink
                to="#"
                className="btn add-btn"
                data-toggle="modal"
                data-target="#add_leave"
                onClick={openDropDown}
              >
                <i className="fa fa-plus"></i>Request Leave
              </NavLink>
              <AddLeaveModal
                showDropDown={showDropDown}
                openDropDown={openDropDown}
              />
            </div>
          )}
          {/* {timesheet &&
                        <div className="col-6 float-right ml-auto">
                            <NavLink to="/attendance" className="btn add-btn mx-2"><i className="la la-clock-o"></i> Attendance</NavLink>
                            <NavLink to="/overtime" className="btn add-btn"><i className="la la-briefcase"></i> Overtime</NavLink>
                        </div>} */}
          {overtime && (
            <div className="col-6 float-right ml-auto">
              {/* <NavLink to="/attendance" className="btn add-btn mx-2"><i className="la la-clock-o"></i> Attendance</NavLink> */}
              <NavLink to="/attendance" className="btn add-btn">
                <i className="la la-table"></i>Attendance
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EmployeeTitle;

import {
    LEAVE_ANNUAL_STATUS_REQUEST,
    LEAVE_ANNUAL_STATUS_SUCCESS,
    LEAVE_ANNUAL_STATUS_FAIL,
    LEAVE_REQUEST,
    LEAVE_SUCCESS,
    LEAVE_FAIL,
    LEAVE_CREATE_REQUEST,
    LEAVE_CREATE_SUCCESS,
    LEAVE_CREATE_FAIL,
    LEAVE_CATEGORY_CREATE_REQUEST,
    LEAVE_CATEGORY_CREATE_SUCCESS,
    LEAVE_CATEGORY_CREATE_FAIL,
    ALL_LEAVE_QUERY_REQUEST,
    ALL_LEAVE_QUERY_SUCCESS,
    ALL_LEAVE_QUERY_FAIL,
    LEAVE_REPORT_REQUEST,
    LEAVE_REPORT_SUCCESS,
    LEAVE_REPORT_FAIL,
    LEAVE_SETTING_REQUEST,
    LEAVE_SETTING_SUCCESS,
    LEAVE_SETTING_FAIL,
    ADMIN_LEAVE_CARDS_DATA_REQUEST,
    ADMIN_LEAVE_CARDS_DATA_SUCCESS,
    ADMIN_LEAVE_CARDS_DATA_FAIL,
    LEAVE_UPDATE_REQUEST,
    LEAVE_UPDATE_SUCCESS,
    LEAVE_UPDATE_FAIL,
    REMOVE_LEAVE_REQUEST,
    REMOVE_LEAVE_SUCCESS,
    REMOVE_LEAVE_FAIL,
    LEAVE_SEARCH_REQUEST,
    LEAVE_SEARCH_SUCCESS,
    LEAVE_SEARCH_FAIL,
    ADMIN_CREATED_LEAVE_UPDATE_REQUEST,
    ADMIN_CREATED_LEAVE_UPDATE_SUCCESS,
    ADMIN_CREATED_LEAVE_UPDATE_FAIL,
    LEAVE_REPORT_USER_REQUEST,
    LEAVE_REPORT_USER_SUCCESS,
    LEAVE_REPORT_USER_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const leaveReducer = (
    state = {
        leave: {
            leaveAnnualStatus: null,
            leaveList: null,
            leaveDisplay: null,
            leaveReport: null,
            leaveSetting: null,
            leaveCard: null,
            leaveSearch: null,
            leaveReportUsers: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                leaveAnnualStatus: null,
                leaveList: null,
                leaveDisplay: null,
                leaveReport: null,
                leaveSetting: null,
                leaveCard: null,
                leaveSearch: null,
            };

        // USER ANNUAL LEAVE STATUS
        case LEAVE_ANNUAL_STATUS_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_ANNUAL_STATUS_SUCCESS :
            return {
                ...state,
                leaveAnnualStatus: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LEAVE_ANNUAL_STATUS_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        // USER LEAVE LIST
        case LEAVE_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_SUCCESS :
            return {
                ...state,
                leaveList: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case LEAVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
            
        // USER LEAVE LIST
        case LEAVE_CREATE_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_CREATE_SUCCESS :
            return {
                ...state,
                leaveList: action.payload,
            };
        case LEAVE_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            
        case LEAVE_CATEGORY_CREATE_REQUEST :
            return {
                ...state,
            }
        case LEAVE_CATEGORY_CREATE_SUCCESS :
            return {
                ...state,
            }
        case LEAVE_CATEGORY_CREATE_FAIL :
            return {
                ...state,

                error: action.payload,
            }

        case ALL_LEAVE_QUERY_REQUEST : 
            return {
                ...state,
            };
        case ALL_LEAVE_QUERY_SUCCESS :
            return {
                ...state,
                leaveDisplay: action.payload,
            };
        case ALL_LEAVE_QUERY_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case LEAVE_REPORT_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_REPORT_SUCCESS :
            return {
                ...state,
                leaveReport: action.payload,
            };
        case LEAVE_REPORT_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        
        // USER LEAVE REPORT
        case LEAVE_REPORT_USER_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_REPORT_USER_SUCCESS :
            return {
                ...state,
                leaveReportUsers: action.payload,
            };
        case LEAVE_REPORT_USER_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case LEAVE_SETTING_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_SETTING_SUCCESS :
            return {
                ...state,
                leaveSetting: action.payload,
            };
        case LEAVE_SETTING_FAIL :
            return {
                ...state,
                error: action.payload,
            };



        case ADMIN_LEAVE_CARDS_DATA_REQUEST : 
            return {
                ...state,
            };
        case ADMIN_LEAVE_CARDS_DATA_SUCCESS :
            return {
                ...state,
                leaveCard: action.payload,
            };
        case ADMIN_LEAVE_CARDS_DATA_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // UPDATE LEAVE
        case LEAVE_UPDATE_REQUEST :
            return {
                ...state,
            };
        case LEAVE_UPDATE_SUCCESS :
            return {
                ...state,
                leaveSetting: state.leaveSetting.map((x) =>
                x.id === action.payload.id ? action.payload : x
              ),
            };
        case LEAVE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };



        case REMOVE_LEAVE_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_LEAVE_SUCCESS :
            return {
                ...state,
                leaveSetting: state.leaveSetting.filter((x) => x.id !== action.payload.id),
            };
        case REMOVE_LEAVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // ADMIN UPDATE LEAVE CREATE BY EMPLOYEE
        case ADMIN_CREATED_LEAVE_UPDATE_REQUEST :
            return {
                ...state,
            };
        case ADMIN_CREATED_LEAVE_UPDATE_SUCCESS :
            return {
                ...state,
                leaveDisplay: {...state.leaveDisplay, results:state.leaveDisplay.results.map((x) =>
                x.e.id === action.payload.e.id ? action.payload : x
              )},
            };
        case ADMIN_CREATED_LEAVE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };
            
        case LEAVE_SEARCH_REQUEST : 
            return {
                ...state,
            };
        case LEAVE_SEARCH_SUCCESS :
            return {
                ...state,
                leaveSearch: action.payload,
            };
        case LEAVE_SEARCH_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        

        default:
            return state;
    }
}
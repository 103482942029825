import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_BASE_URL,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_REGION,
  BUCKET_SECRET_KEY,
  SERVERADDRESS,
} from "../../../constants";
import AWS from "aws-sdk";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import moment from "moment";
import { updateUserById } from "../../../actions/authActions";
import { forwardRef } from "react";
// import { useParams } from 'react-router-dom'
// import { updateUserByAdmin } from '../../../actions/userActions'
// import DatePicker from "react-datepicker"

// const S3_BUCKET = "riyoworkportal";
// // const REGION ='YOUR_DESIRED_REGION_HERE';

// AWS.config.update({
//   accessKeyId: "AKIAWVZVUJ2JJRJGBNPC",
//   secretAccessKey: "FYAbfPHSd6pJJQ9Rnxe5+gLihOJx8lxHs6gzDwkl",
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   // region: REGION,
// });

const s3 = new AWS.S3({
  // accessKeyId: "42517f1906a1e312810ee9ed7a0307ab",
  // secretAccessKey: "5dcddf9ff5cb2705f6ac2fbf65712c825b9969278e4a1efb551bd8f98e79f2ee",
  region: BUCKET_REGION,
  endpoint: BUCKET_ENDPOINT,
  credentials: {
    accessKeyId: BUCKET_ACCESS_KEY_ID,
    secretAccessKey: BUCKET_SECRET_KEY,
  },
});
const AdminEditModalProfileCover = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    address: "",
    state: "",
    pinCode: "",
    phone: "",
  });

  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  useEffect(() => {
    if (auth.user !== null) {
      setValues({
        address: auth.user.address,
        state: auth.user.state,
        pinCode: auth.user.pinCode,
        phone: auth.user.phone,
      });
      if (auth.user.profilePic) {
        setSelectedImage(auth.user.profilePic.fileUrl);
      }
    }
  }, [auth.user]);

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const userData = {
    userId: auth.user.id,
    address: values.address,
    state: values.state,
    pinCode: values.pinCode,
    phone: values.phone,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateUserById(userData));
  };
  const [progress, setProgress] = useState(0);
  const [awsURL, setAwsURL] = useState(null);
  const [selectedImage, setSelectedImage] = useState(defaultImg);
  const handleChangePic = async (event) => {
    // const params = {
    //     Bucket: S3_BUCKET,
    //     Key: "data/"+auth.company.companyName+"/ProfilePic/"+auth.user.id+"/"+event.target.files[0].name,
    //     Body: event.target.files[0],
    //     ACL: 'public-read'
    // };
    // var upload = myBucket.upload(params)
    // .on('httpUploadProgress',(evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .promise();
    // let url;
    // await upload.then(function(data, err) {
    //     try {
    //         url = data.Location
    //         setAwsURL(url)
    //         console.log(url)
    //     } catch (error) {
    //         console.log(error, err, "ee");
    //     }
    // });

    const today = new Date();
    const tsForUnique = today.getTime();
    //   const { name } = req.body;
    const filePath =
      "data/" +
      auth.company.companyName +
      "/ProfilePic/" +
      auth.user.id +
      "/" +
      event.target.files[0].name;

    const params = {
      Bucket: BUCKET_NAME,
      Key: filePath,
      Body: event.target.files[0],

      // ACL: 'public-read',
    };

    await s3
      .upload(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        //   console.log("pro==>", Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
    // let url="";
    // await multipartUpload();
    const url = filePath;
    // console.log(url);

    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/mail/profilePicUpload`,
      {
        url: url,
        file: event.target.files[0].name,
        type: event.target.files[0].type,
        userId: auth.user.id,
        companyId: auth.company.id,
      }
    );
    setSelectedImage(url);
    dispatch(updateUserById({ userId: auth.user.id, profilePic: data.id }));
  };
  // console.log(values)
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="profile_info "
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Basic Information</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {auth.user && (
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-img-wrap edit-img">
                        {selectedImage && (
                          <img
                            className="inline-block"
                            src={BUCKET_BASE_URL + selectedImage}
                            alt="Profile Pic"
                          />
                        )}
                        <div className="fileupload btn">
                          <span className="btn-text">edit</span>
                          <input
                            className="upload"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleChangePic}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={auth.user.firstName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={auth.user.lastName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Birth Date</label>
                            <div className="cal-icon">
                              <input
                                className="form-control datetimepicker"
                                type="text"
                                value={moment(auth.user.dateOfBirth).format(
                                  "MM/DD/YYYY"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Gender</label>
                            <input
                              type="text"
                              className="form-control"
                              value={auth.user.gender}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.address}
                          onChange={handleChange("address")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>State</label>
                        <input
                          type="text"
                          className="form-control"
                          value={values.state}
                          onChange={handleChange("state")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Pin Code</label>
                        <input
                          type="number"
                          onKeyDown={(e) => {
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          className="form-control"
                          value={values.pinCode}
                          onChange={handleChange("pinCode")}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          className="form-control"
                          minLength="14"
                          maxLength="14"
                          required
                          // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                          onKeyDown={(e) => {
                            if (alphabets.includes(e.key)) {
                              e.preventDefault();
                            }
                            if (
                              e.code === "Backspace" &&
                              values.phone.length === 6
                            ) {
                              setValues({
                                ...values,
                                phone: values.phone.substring(0, 4),
                              });
                            }
                            if (
                              e.code === "Backspace" &&
                              values.phone.length === 2
                            ) {
                              setValues({
                                ...values,
                                phone: "",
                              });
                            }
                            if (
                              e.code === "Backspace" &&
                              values.phone.length === 10
                            ) {
                              setValues({
                                ...values,
                                phone: values.phone.substring(0, 9),
                              });
                            }
                          }}
                          name="phone"
                          value={values.phone}
                          onChange={handlePhone("phone")}
                          placeholder="(123) 456-7890"
                          autocomplete="new-mobileNunmber"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      className="btn btn-primary submit-btn"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminEditModalProfileCover;

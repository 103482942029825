import axios from "axios";
import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAIL,
  READ_NOTIFICATIONS_REQUEST,
  READ_NOTIFICATIONS_SUCCESS,
  SERVERADDRESS,
} from "../constants";

// Get All Notifications
export const getNotificationsAction = (userId) => async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS_REQUEST,
  });
  try {
    const { data } = await axios.get(
      `${SERVERADDRESS}/v1/notifications/${userId}`
    );
    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
      payload: err,
    });
  }
};

// Read Notification Action
export const readNotificationAction =
  ({ notificationId, readerId }, successHandler) =>
  async (dispatch) => {
    dispatch({
      type: READ_NOTIFICATIONS_REQUEST,
    });

    try {
      const { data } = await axios.patch(
        `${SERVERADDRESS}/v1/notifications/${notificationId}`,
        {
          readerId,
        }
      );
      dispatch({
        type: READ_NOTIFICATIONS_SUCCESS,
        payload: data,
      });
      // toast.success("Group Created Successfully!");
      // dispatch(getChatsAction(obj));
      // dispatch({ type: CHAT_SELECT_SUCCESS, payload: data });
      // successHandler();
    } catch (err) {
      console.log(err);
      // toast.error(err.response.data.message);

      dispatch({
        type: READ_NOTIFICATIONS_FAIL,
        payload: err,
      });
    }
  };

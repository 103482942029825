import {
  CHATS_GET_FAIL,
  CHATS_GET_REQUEST,
  CHATS_GET_SUCCESS,
  CHAT_SELECT_FAIL,
  CHAT_SELECT_REQUEST,
  CHAT_SELECT_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  MESSAGE_GET_FAIL,
  MESSAGE_GET_REQUEST,
  MESSAGE_GET_SUCCESS,
  MESSAGE_RECEIVE_SUCCESS,
  MESSAGE_SEND_FAIL,
  MESSAGE_SEND_REQUEST,
  MESSAGE_SEND_SUCCESS,
} from "../constants";

export const chatReducer = (
  state = {
    list: [],
    selectedChat: null,
    messages: { list: [] },
  },
  action
) => {
  switch (action.type) {
    case CHATS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHATS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case CHATS_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CHAT_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CHAT_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedChat: action.payload,
      };
    case CHAT_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case MESSAGE_GET_REQUEST:
      return {
        ...state,
        messages: {
          loading: true,
          list: [],
        },
      };
    case MESSAGE_GET_SUCCESS:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: false,
          list: action.payload,
        },
      };
    case MESSAGE_GET_FAIL:
      return {
        ...state,
        messages: {
          ...state.messages,
          list: [],
          error: action.payload,
        },
      };

    case MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.chatId.id
            ? {
                ...x,
                latestMessage: action.payload,
                readBy: [{ readerId: action.payload.sender.id }],
              }
            : x
        ),
        messages: {
          ...state.messages,
          list:
            state.selectedChat.id == action.payload.chatId.id
              ? [...state.messages.list, action.payload]
              : state.messages.list,
        },
      };
    case MESSAGE_RECEIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.chatId.id
            ? {
                ...x,
                latestMessage: action.payload,
                readBy: [{ readerId: action.payload.sender.id }],
              }
            : x
        ),
        messages: {
          ...state.messages,
          list:
            state.selectedChat.id == action.payload.chatId.id
              ? [...state.messages.list, action.payload]
              : state.messages.list,
        },
      };

    case GROUP_CREATE_SUCCESS:
      return { ...state, selectedChat: action.payload };
    default:
      return state;
  }
};

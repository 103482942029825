import React from "react";
import { clockedOutByAdmin } from "../../../actions/clockActions";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const AdminRecentClockInRow = ({ current, i, tracking }) => {
  const user = useSelector((state) => state.user);
  const admin = useSelector((state) => state.admin);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickUser = async (id) => {
    dispatch(clockedOutByAdmin({ _id: id, clockedOutBy: auth.user.id }));
  };
  const navigateUser = (id) => {
    navigate(`/tracking/${id}`);
  };
  const formatTime = (timeString) => {
    const hours24 = parseInt(timeString.substring(11, 13));
    const minutes = timeString.substring(14, 16);

    const period = hours24 >= 12 ? "PM" : "AM";
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, with 12 as noon/midnight

    return `${hours12}:${minutes} ${period}`;
  };
  return (
    <>
      <tr key={i}>
        <td>
          <h2 className="table-avatar">
            <NavLink to={`/profile/${current.userId.id}`} className="avatar">
              {current.userId &&
              current.userId.profilePic &&
              current.userId.profilePic.fileUrl ? (
                <img
                  alt="profilePic"
                  src={BUCKET_BASE_URL + current.userId.profilePic.fileUrl}
                  style={{ objectFit: "fill", height: "100%" }}
                />
              ) : (
                <img alt="profilePic" src={defaultImg} />
              )}
            </NavLink>
            <NavLink to={`/profile/${current.userId.id}`}>
              {current.userId.firstName} {current.userId.lastName}
              <span>{current.userId.jobTitle}</span>
            </NavLink>
          </h2>
        </td>
        <td>{moment(current.date).format("MM/DD/YYYY")}</td>
        {/* <td>{current.dayClockIn.toString().substring(11, 16)}</td> */}
        <td>{formatTime(current.dayClockIn)}</td>
        <td>
          {current.shift ? (
            <>
              {formatTime(current.shift.startTime)} {"-"}
              <br />
              {formatTime(current.shift.endTime)}
            </>
          ) : (
            "without shift"
          )}
        </td>
        <td>{current.shift ? current?.shift?.location?.name : ""}</td>
        {tracking ? (
          <td className="text-center">
            <span
              className="btn btn-success"
              onClick={() => navigateUser(current.userId.id)}
            >
              TRACK
            </span>
          </td>
        ) : (
          <td>{current.hoursUsed}</td>
        )}
        {/* <td>{current.hoursUsed}</td> */}
        <td className="text-center">{current.userId.jobTitle}</td>
        <td className="text-center">
          <span
            className="btn btn-success"
            onClick={() => {
              onClickUser(current.id);
            }}
          >
            Clock Out
          </span>
        </td>
      </tr>
    </>
  );
};
export default AdminRecentClockInRow;

import {
    SALARY_REQUEST,
    SALARY_SUCCESS,
    SALARY_FAIL,
    SALARY_CREATE_REQUEST,
    SALARY_CREATE_SUCCESS,
    SALARY_CREATE_FAIL,
    SALARY_STRUCTURE_UPDATE_REQUEST,
    SALARY_STRUCTURE_UPDATE_SUCCESS,
    SALARY_STRUCTURE_UPDATE_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const salaryReducer = (
    state = {
        salary: {
            salary: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                salary: null,
            };

        case SALARY_REQUEST : 
            return {
                ...state,
            };
        case SALARY_SUCCESS :
            return {
                ...state,
                salary: action.payload,
            };
        case SALARY_FAIL :
            return {
                ...state,
                error: action.payload,
            };

            case SALARY_CREATE_REQUEST : 
            return {
                ...state,
            };
        case SALARY_CREATE_SUCCESS :
            return {
                ...state,
                // : action.payload,
            };
        case SALARY_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // UPDATE SALARY STRUCTURE
        case SALARY_STRUCTURE_UPDATE_REQUEST :
            return {
                ...state,
            };
        case SALARY_STRUCTURE_UPDATE_SUCCESS :
            return {
                ...state,
                // salary: state.salary.map((x) =>
                // x.id === action.payload.id ? action.payload : x
                salary: action.payload,
            //   ),
            };
        case SALARY_STRUCTURE_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };





        default:
            return state;
    }
}
import axios from "axios";
import {
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    LOCATION_FAIL,
    LOCATION_REQUEST_NEW,
    LOCATION_SUCCESS_NEW,
    LOCATION_FAIL_NEW,
    LOCATION_CREATE_REQUEST,
    LOCATION_CREATE_SUCCESS,
    LOCATION_CREATE_FAIL,
    LOCATION_WITH_USERS_REQUEST,
    LOCATION_WITH_USERS_SUCCESS,
    LOCATION_WITH_USERS_FAIL,
    LOCATION_UPDATE_REQUEST,
    LOCATION_UPDATE_SUCCESS,
    LOCATION_UPDATE_FAIL,
    LOCATION_REMOVE_REQUEST,
    LOCATION_REMOVE_SUCCESS,
    LOCATION_REMOVE_FAIL,
    LOCATION_BY_ID_REQUEST,
    LOCATION_BY_ID_SUCCESS,
    LOCATION_BY_ID_FAIL,
    SERVERADDRESS,
} from "../constants"

// COMPANY LOCATION
export const getCompanyLocations = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getAllLocations`, obj);
        localStorage.setItem("location", JSON.stringify(data));
        dispatch({
            type: LOCATION_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_FAIL,
            payload: err,
        });
    }
};
// COMPANY LOCATION
export const getAllLocationsWithPagination = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_REQUEST_NEW,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getAllLocationsWithPagination`, obj);
        // localStorage.setItem("location", JSON.stringify(data));
        // localStorage.setItem("getAllLocationsWithoutPagination", JSON.stringify(data));
        dispatch({
            type: LOCATION_SUCCESS_NEW,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_FAIL_NEW,
            payload: err,
        });
    }
};


// ADMIN CREATE LOCATION
export const addLocation = (obj) => async ( dispatch ) => {
    dispatch({
        type: LOCATION_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/createLocation`, obj)
        dispatch({
            type: LOCATION_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_CREATE_FAIL,
            payload: err,
        })
    }
}



// LOCATION WITH USER
export const getAllLocationsWithUsers = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_WITH_USERS_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getAllLocationsWithUsers`, obj);
        localStorage.setItem("locationWithUsers", JSON.stringify(data));
        dispatch({
            type: LOCATION_WITH_USERS_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_WITH_USERS_FAIL,
            payload: err,
        });
    }
};


// LOCATION UPDATE
export const updateLocation = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/location/updateLocation`, obj);
        // localStorage.setItem("locationWithUsers", JSON.stringify(data));
        dispatch({
            type: LOCATION_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log("location update", err)
        dispatch({
            type: LOCATION_UPDATE_FAIL,
            payload: err,
        });
    }
};

// LOCATION REMOVE
export const removeLocation = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/location/updateLocation`, obj);
        // localStorage.setItem("locationWithUsers", JSON.stringify(data));
        dispatch({
            type: LOCATION_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log("location update", err)
        dispatch({
            type: LOCATION_REMOVE_FAIL,
            payload: err,
        });
    }
};


// //USER LOGOUT
// export const userLogout = (obj, navigateUser) => async (dispatch) => {
//     try {
//       await axios.post(`${SERVERADDRESS}v1/auth/logout`, obj);
//       localStorage.removeItem("tokens");
//       localStorage.removeItem("user");
//     //   localStorage.removeItem("selectedPackage");
//       dispatch({
//         type: USER_LOGOUT_SUCCESS,
//       });
//       navigateUser()
//     } catch (err) {
//       dispatch({
//         type: USER_LOGOUT_FAIL,
//         payload: "err.response.data.message",
//       });
//     }
// };

// export const getUserByToken = (token, navigate) => async (dispatch) => {
//   navigate('/checkUser')
//   dispatch({
//     type: GET_USER_REQUEST,
    
//   });
//   try {
//     const { data } = await axios.post(`${SERVERADDRESS}v1/auth/refresh-tokens`, {
//       refreshToken: token,
//     });
//     localStorage.setItem("tokens", JSON.stringify(data));
//     dispatch({
//       type: GET_USER_SUCCESS,
//       payload: data,
//     });
//     navigate('/dashboard')
//   } catch (err) {
//     localStorage.clear()
//     console.log("err=>", err);
//     dispatch({
//       type: GET_USER_FAIL,
//       payload: err.response.data.message,
//     });
//     navigate('/')
//     console.log(localStorage)
//   }
// };


export const getLocationByLocationId = (obj) => async (dispatch) => {
    dispatch({
        type: LOCATION_BY_ID_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/location/getLocationByLocationId`, obj);
        localStorage.setItem("locationById", JSON.stringify(data));
        dispatch({
            type: LOCATION_BY_ID_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: LOCATION_BY_ID_FAIL,
            payload: err,
        });
    }
};



export const clickLatLng = (lat, lng) => {
    return {
        type: "CLICK_LATLNG",
        lat: lat,
        lng: lng
    }
}
export const clickMap = (map) => {
    return {
        type: "CLICK_MAP",
        map: map
    }
}
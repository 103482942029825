import React from "react";
import CompaniesSearch from "../../components/superAdminCompoents/superAdminCompanies/CompaniesSearch";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import CompaniesCards from "../../components/superAdminCompoents/superAdminCompanies/CompaniesCards";
import { useSelector } from "react-redux";
import SuperAdminMenu from "../../components/superAdminCompoents/superAdminSubscription/SuperAdminMenu";
import SubsCards from "../../components/superAdminCompoents/superAdminSubsCompany/SubsCards";
import SubsTable from "../../components/superAdminCompoents/superAdminSubsCompany/SubsTable";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
const SuperAdminSubscriptionPageAdministration = () => {
  const myState = useSelector((state) => state.changeTheTab);
  return (
    <>
      {/* <SuperAdminHeader />
      <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {myState.viewCompanyAndSubscription === "company" ? (
              <>
                <AdminTittle title="Companies" CompAndSubsNav />
                <CompaniesSearch />
                <CompaniesCards />
              </>
            ) : myState.viewCompanyAndSubscription === "subscription" ? (
              <>
                <AdminTittle title="Subscription" CompAndSubsNav />
                <SuperAdminMenu />
              </>
            ) : myState.viewCompanyAndSubscription === "scomp" ? (
              <>
                <AdminTittle title="Subscribed Companies" CompAndSubsNav />
                <SubsCards />
                <SubsTable />
              </>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminSubscriptionPageAdministration;

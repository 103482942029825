import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom'
import ProfileLocations from './ProfileLocations'
import ProfileMain from './ProfileMain'
import SalaryInfo from './SalaryInfo'

const ProfileBar = () => {
    const [ viewBarItems, setViewBarItems ] = useState("profile")
    const handleProfile = (e) => {
        setViewBarItems("profile")
    }
    const handleLocations = (e) => {
        setViewBarItems("locations")
    }
    const handleBank = (e) => {
        setViewBarItems("bank")
    }

    return (
        <>
        { 
        viewBarItems === "profile" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link active">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            // data-toggle="tab" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link">Locations</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#bank_statutory" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</a ></li>
                    </ul>
                </div>
            </div>
        </div>
        <ProfileMain />
        </>
        :
        viewBarItems === "locations" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            // data-toggle="tab" 
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link active">Locations</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#bank_statutory" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link">Bank &amp; Statutory</a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <ProfileLocations />
        </>
        :
        viewBarItems === "bank" ?
        <>
        <div className="card tab-box">
            <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                        <li className="nav-item">
                            <a to="#emp_profile" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleProfile} 
                            className="nav-link">Profile</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#emp_Locations" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleLocations}
                            className="nav-link">Locations</a>
                        </li>
                        <li className="nav-item">
                            <a  to="#bank_statutory" 
                            // data-toggle="tab"
                            style={{cursor: "pointer"}}
                            onClick={handleBank}
                            className="nav-link active">Bank &amp; Statutory</a >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <SalaryInfo />
        </>
        :
        <div></div>
        }  
        </> 
    )
}

export default ProfileBar






// { viewBarItems === "profile" ?
//     <ProfileMain />
//     : viewBarItems === "locations" ?
//     <ProfileLocations />
//     : viewBarItems === "bank" ?
//     <SalaryInfo />
//     : <div></div>
// }
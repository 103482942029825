import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import Settings from '../../components/employeeComponents/employeeSettingsComponents/Settings'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'

const EmployeeSettings = () => {
  return (
    <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <div className="main-wrapper">
          <div className="page-wrapper">
              <div className="content container-fluid">
                  <Settings />
              </div>
          </div>
      </div>
    </>
  )
}

export default EmployeeSettings
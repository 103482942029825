import {
    HOLIDAY_REQUEST,
    HOLIDAY_SUCCESS,
    HOLIDAY_FAIL,
    HOLIDAY_CREATE_REQUEST,
    HOLIDAY_CREATE_SUCCESS,
    HOLIDAY_CREATE_FAIL,
    HOLIDAY_TABLE_REQUEST,
    HOLIDAY_TABLE_SUCCESS,
    HOLIDAY_TABLE_FAIL,
    HOLIDAY_UPDATE_REQUEST,
    HOLIDAY_UPDATE_SUCCESS,
    HOLIDAY_UPDATE_FAIL,
    REMOVE_HOLIDAY_REQUEST,
    REMOVE_HOLIDAY_SUCCESS,
    REMOVE_HOLIDAY_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const holidayReducer = (
    state = {
        holiday: {
            holiday: null,
            holidayTable: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                holiday: null,
                holidayTable: null,
            };

        // USER AUTH
        case HOLIDAY_REQUEST : 
            return {
                ...state,
            };
        case HOLIDAY_SUCCESS :
            return {
                ...state,
                holiday: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case HOLIDAY_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case HOLIDAY_CREATE_REQUEST :
            return {
                ...state,
            };
        case HOLIDAY_CREATE_SUCCESS :
            return {
                ...state,
                holidayTable: [...state.holidayTable, action.payload],

            };
        case HOLIDAY_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case HOLIDAY_TABLE_REQUEST : 
            return {
                ...state,
            };
        case HOLIDAY_TABLE_SUCCESS :
            return {
                ...state,
                holidayTable: action.payload,
            };
        case HOLIDAY_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case HOLIDAY_UPDATE_REQUEST : 
            return {
                ...state,
            };
        case HOLIDAY_UPDATE_SUCCESS :
            return {
                ...state,
                // holidayTable: [...state.holidayTable, action.payload],
                holidayTable: state.holidayTable.map((x) =>
                x.id == action.payload.id ? action.payload : x
              ),
            };
        case HOLIDAY_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case REMOVE_HOLIDAY_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_HOLIDAY_SUCCESS :
            return {
                ...state,
                holidayTable: state.holidayTable.filter((x) => x.id !== action.payload.id),
            };
        case REMOVE_HOLIDAY_FAIL :
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}
import React from 'react'
import s1 from '../../assetsgeneral/images/s1.png'
import s2 from '../../assetsgeneral/images/s2.png'
import s3 from '../../assetsgeneral/images/s3.png'
import s4 from '../../assetsgeneral/images/s4.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CountUp from 'react-countup';

const OurUsers = () => {
    return (
        <>
            <div className="our-solutin-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-iconss">
                                <img src={s1} id="img1" />
                            </div>
                            <div className="ousoltext1">
                            <CountUp start={0} end={75} delay={1} duration={2} enableScrollSpy="true">
                                            {({ countUpRef }) => (
                                <h3>
                                    <span ref={countUpRef} /><span>K</span>
                                </h3>
                                )}
                                </CountUp>
                                <h2>Overall Users </h2>
                            </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-md-3">
                            <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-iconss">
                                <img src={s2} id="img1" />
                            </div>
                            <div className="ousoltext1">
                            <CountUp start={0} end={62} delay={1} duration={2} enableScrollSpy="true">
                                            {({ countUpRef }) => (
                                <h3>
                                    <span ref={countUpRef} /><span>K</span>
                                </h3>
                                )}
                                </CountUp>
                                <h2>Happy Managers </h2>
                            </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-md-3">
                            <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-iconss">
                                <img src={s3} id="img1" />
                            </div>
                            <div className="ousoltext1">
                            <CountUp start={0} end={10} delay={1} duration={2} enableScrollSpy="true">
                                            {({ countUpRef }) => (
                                <h3>
                                    <span ref={countUpRef} /><span>K</span>
                                </h3>
                                )}
                                </CountUp>
                                <h2>Support Our Team </h2>
                            </div>
                            </AnimationOnScroll>
                        </div>
                        <div className="col-md-3">
                            <AnimationOnScroll animateIn="fadeInUp" duration="1.5" animateOnce="true">
                            <div className="oursolut-iconss">
                                <img src={s4} id="img1" />
                            </div>
                            <div className="ousoltext1">
                            <CountUp start={0} end={238} delay={1} duration={2} enableScrollSpy="true">
                                            {({ countUpRef }) => (
                                <h3>
                                    <span ref={countUpRef} /><span></span>
                                </h3>
                                )}
                                </CountUp>
                                <h2>Companies </h2>
                            </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurUsers
// import { useEffect } from 'react'
// import { NavLink } from 'react-router-dom'
import React, { forwardRef, useState } from 'react'
import { shiftSearchFilter } from "../../../actions/shiftActions"
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { tabShiftSearch } from '../../../actions/tabActions';

const EmployeeScheduleSearch = () => {
    const auth = useSelector((state) => state.auth);
    const locationTemp = useSelector((state) => state.location);
    let location = locationTemp.location
    const dispatch = useDispatch()
    // location.forEach(element => {
    //     console.log(element.name)
    // });
    const [values, setValues] = useState({
        location: '',
        name: '',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const [fromDate, setFromDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">From</label>
        </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">To</label>
        </div>
    ));
    // console.log(fromDate, toDate, values)
    let searchData;
    // if (values.location !== ''){
    searchData = {
        companyId: auth.user.companyId,
        userId: auth.user.id,
        name: auth.user.firstName + " " + auth.user.lastName,
        date: moment().format('YYYY-MM-DD'),
        location: values.location,
        from: fromDate.toISOString().substring(0, 10),
        to: toDate.toISOString().substring(0, 10)
    }
    // }
    // console.log(searchData)
    // console.log(toDate.toISOString(), "toDate.toISOString()")
    const handleClick = async (event) => {
        event.preventDefault()
        // if (searchData.name === ''){
        //     delete searchData.name
        // }
        // if (searchData.location === ''){
        //     delete searchData.location
        // }
        // if (searchData.from === ''){
        //     delete searchData.from
        // }
        // if (searchData.to === ''){
        //     delete searchData.to
        // }
        for (let key of Object.keys(searchData)) {
            // console.log(key,"54121421521", searchData[key])
            if(searchData[key] === '' ){
                delete searchData[key]
            }
            // console.log(key,"54121421521", searchData[key])
        }
        // console.log("first")
        dispatch(tabShiftSearch())
        dispatch(shiftSearchFilter(searchData))
        // console.log("first2")
    }
    return (
        <>
            {/* <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid"> */}
            {/* {location.map((current, i) => {
            <h1>{current.name}</h1>
            })} */}
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus">
                        <input type="text" className="form-control floating"  disabled />
                        <label className="focus-label">{searchData.name.length > 26 ? searchData.name.substring(0,26)+"..." : searchData.name}</label>
                        {/* {auth.user.firstName} {auth.user.lastName} */}
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px", paddingBottom: "0.01rem" }}
                        type="text"
                        autoComplete="false" value={values.location}
                        onChange={handleChange('location')}>
                            <option value=''>All Locations</option>
                            {
                                location &&
                                location.length > 0 &&
                                location.map((current, i) => {
                                    return (
                                        <option value={current.id} key={i+1}>{current.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label className="focus-label">Locations</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <div className="form-group form-focus select-focus">
                        <DatePicker
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                        customInput={<From />}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <div className="form-group form-focus select-focus">
                    <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        customInput={<To />}
                        />
                        {/* <div className="form-group form-focus focused">
                            <div className="cal-icon">
                                <input className="form-control floating datetimepicker" type="text" />
                            </div>
                            <label className="focus-label">To</label>
                        </div> */}
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <a onClick={handleClick} className="btn btn-success btn-block"> Search </a>
                </div>
            </div>
        </>
    )
}
export default EmployeeScheduleSearch


// import  { axios } from 'axios'
    // import { useSelector } from 'react-redux'
    // import  { SERVERADDRESS } from '../../../constants'
    
    
    // const [ location, setLoction ] = useState([]);
    // const [ error, setError ] = useState("");
    // const auth = useSelector((state) => state.auth);
    // const locationApi = async () => {
    //     try {
    //         const res = await axios.post(`${SERVERADDRESS}v1/location/getAllLocations`, 
    //         { companyId : auth.user.companyId}
    //         )
    //         setLoction(res.data);
    //     } catch (error) {
    //         setError(error.message)
    //     }
    // }
    // useEffect(() => {
    //     locationApi()
    // }, [])
    // const dispatch = useDispatch()
    // const locationData = async (e) => {
    //     await dispatch(getCompanyLocations({companyId: auth.user.companyId}))
    // }
    // useEffect(() => {
    //     locationData()
    // },[])
import React from 'react'
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import OvertimeCard from '../../components/employeeComponents/employeeOvertime/OvertimeCard'
import OvertimeTable from '../../components/employeeComponents/employeeOvertime/OvertimeTable'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeTitle from '../../components/employeeComponents/EmployeeTitle'

const EmployeeOvertime = () => {
    return (
        <>
            {/* <EmployeeHeader />
            <EmployeeSidebar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <EmployeeTitle title="Overtime" overtime />
                        <OvertimeCard />
                        <OvertimeTable />
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmployeeOvertime
import React, { useState } from "react";
import { addOvertime } from "../../../actions/payrollAction";
import { useDispatch, useSelector } from "react-redux";
const AddOvertimeModal = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    companyId: "",
    name: "",
    rateType: "",
    rateMultiplier: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const dispatch = useDispatch();
  const overtimeData = {
    companyId: auth.user.companyId,
    name: values.name,
    rateType: values.rateType,
    rateMultiplier: parseInt(values.rateMultiplier),
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(addOvertime(overtimeData));
    // openDropDown()
    setValues({
      companyId: "",
      name: "",
      rateType: "",
      rateMultiplier: "",
    });
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="edit_overtime"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Overtime</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.name}
                    onChange={handleChange("name")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Rate Type <span className="text-danger">*</span>
                  </label>
                  {values.rateType === "" ? (
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      value={(values.rateType = "Daily Rate")}
                      onChange={handleChange("rateType")}
                    >
                      {/* <option>-</option> */}
                      <option>Daily Rate</option>
                      <option>Hourly Rate</option>
                    </select>
                  ) : (
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      value={values.rateType}
                      onChange={handleChange("rateType")}
                    >
                      {/* <option>-</option> */}
                      <option>Daily Rate</option>
                      <option>Hourly Rate</option>
                    </select>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Rate <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.rateMultiplier}
                    onChange={handleChange("rateMultiplier")}
                  />
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddOvertimeModal;

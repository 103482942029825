import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { userLogout } from "../../actions/authActions";

const SuperAdminSidebar = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  const [showAdministratorDropDown, setshowAdministratorDropDown] =
    useState(false);
  const [showReportDropDown, setshowReportDropDown] = useState(false);
  const openReportDropDown = () => {
    setshowReportDropDown((showReportDropDown) => !showReportDropDown);
    setshowAdministratorDropDown(false);
  };
  // console.log(showReportDropDown)
  const openAdministratorDropDown = () => {
    setshowAdministratorDropDown(
      (showAdministratorDropDown) => !showAdministratorDropDown
    );
    setshowReportDropDown(false);
  };
  return (
    <>
      <div className="">
        <div className="sidebar" id="sidebar">
          <div className="slimScrollRail">
            {/* <div className="sidebar-inner slimscroll" > */}
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  <NavLink to="/dashboard">
                    <i className="la la-home"></i> <span> Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/companies">
                    <i className="la la-building"></i> <span> Companies</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i> <span>Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin-login-info">
                    <i className="la la-users"></i>{" "}
                    <span> Admin Login Info</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us-queries">
                    <i className="la la-comment"></i>{" "}
                    <span> Contact-Us Queries</span>
                  </NavLink>
                </li>
                <li className="submenu">
                  <NavLink to="#" onClick={openAdministratorDropDown}>
                    <i className="las la-toolbox"></i>
                    <span>Administration</span>{" "}
                    <span className="menu-arrow"></span>
                  </NavLink>
                  <ul
                    className={`${showAdministratorDropDown ? "show" : "hide"}`}
                  >
                    <li>
                      <NavLink to="/file-manager">
                        <i className="la la-folder"></i> <span>My Files</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/policies">
                        <i className="la la-file-pdf-o"></i>{" "}
                        <span>Policies</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="la la-user-plus"></i> <span>Users</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/subscriptions-company">
                        <i className="la la-hand-o-up"></i>{" "}
                        <span> Subscriptions </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/settings">
                        <i className="la la-cog"></i> <span> Settings </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#fff" }}
                    onClick={logout}
                  >
                    <i className="la la-power-off"></i> <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default SuperAdminSidebar;

import React, { forwardRef, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { addEmployee, allUsers, lastId } from '../../../actions/userActions'
import { addEmployee } from "../../../actions/userActions";
import moment from "moment";

const AdminAUModal = ({ showDropDown, openDropDown }) => {
  // const [showDropDown, setshowDropDown] = useState(false)
  // const openDropDown = () => {
  //     setshowDropDown((showDropDown) => !showDropDown)
  // }
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [theNewDate, setTheNewDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  //   function handleClick() {
  //   }
  const location = useSelector((state) => state.location);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  let employeeIdStr;
  let generatedPassword;
  let employeeIdInt = parseInt(user.lastEmployeeId);
  employeeIdInt += 1;
  employeeIdStr = employeeIdInt.toString();
  if (employeeIdStr.length === 1) {
    employeeIdStr = "00" + employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr")
  } else if (employeeIdStr.length === 2) {
    employeeIdStr = "0" + employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr2")
  } else {
    employeeIdStr = employeeIdStr;
    // console.log(employeeIdStr,"employeeIdStr3")
  }
  generatedPassword = Math.random().toString(36).substring(2, 9) + 1;
  // const DOB = forwardRef(({ value, onClick }, ref) => (
  //    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //       <div className="cal-icon">
  //          <input className="form-control floating datatimepicker" type="text" value={value}/>
  //       </div>
  //       <label className="focus-label">Date</label>
  //    </div>
  // ))
  // const SDate = forwardRef(({ value, onClick }, ref) => (
  //    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //       <div className="cal-icon">
  //          <input className="form-control floating datatimepicker" type="text" value={value}/>
  //       </div>
  //       <label className="focus-label">Date</label>
  //    </div>
  // ))
  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ") ",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };
  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const [values, setValues] = useState({
    companyId: "",
    firstName: "",
    lastName: "",
    employeeId: employeeIdStr,
    email: "",
    password: generatedPassword,
    phone: "",
    jobTitle: "",
    dateOfBirth: "",
    gender: "",
    hourlyWage: "",
    startingDate: "",
    employeeType: "",
    payType: "",
    address: "",
    state: "",
    pinCode: "",
    timeZone: "",
    location: [],
    role: "",
    availability: {
      monday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      tuesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      wednesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      thursday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      friday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      saturday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      sunday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
    },
  });
  const dispatch = useDispatch();
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [locObj, setLocObj] = useState([]);
  const handleLocationChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.location.includes(abc.id) === false) {
        // console.log(abc)
        setLocObj([...locObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.location, abc.id],
        });
      }
    }
  };
  const deleteHandler = (id) => {
    setLocObj(locObj.filter((x) => x.id !== id));
    const index = values.location.indexOf(id);
    let arr = values.location;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, location: arr });
    }
  };
  // console.log("moreLocationmoreLocationmoreLocationmoreLocationmoreLocationmoreLocation",locObj)
  // console.log(values)
  let [moreLocation, setMoreLocation] = useState([0]);
  const moreLocationFunc = () => {
    let abc = 0;
    setMoreLocation([...moreLocation, abc]);
  };
  const employeeData = {
    companyId: auth.user.companyId,
    firstName: values.firstName,
    lastName: values.lastName,
    employeeId: values.employeeId,
    email: values.email,
    password: values.password,
    phone: values.phone,
    jobTitle: values.jobTitle,
    dateOfBirth: theDate.toISOString().substring(0, 10),
    gender: values.gender,
    hourlyWage: values.hourlyWage,
    startingDate: theNewDate.toISOString().substring(0, 10),
    employeeType: values.employeeType,
    payType: values.payType,
    address: values.address,
    state: values.state,
    pinCode: values.pinCode,
    location: values.location,
    role: "user",
    availability: values.availability,
  };
  // console.log(employeeData)
  // let navigate = useNavigate()
  //  const navigateUser = () => {
  //      navigate('/users')
  //  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(addEmployee(employeeData));
    openDropDown();
    setTimeout(() => {
      document.location.reload();
      //  dispatch(allUsers({companyId: auth.user.companyId}))
      //  dispatch(lastId({companyId: auth.user.companyId}))
      //  idAndPasswordFunc()
      //      employeeIdInt = parseInt(user.lastEmployeeId)
      // employeeIdInt += 1
      //  employeeIdStr = employeeIdInt.toString()
      // if (employeeIdStr.length === 1){
      //     employeeIdStr = "00"+employeeIdStr
      //     console.log(employeeIdStr,"employeeIdStr")
      // }
      // else if (employeeIdStr.length === 2){
      //     employeeIdStr = "0"+employeeIdStr
      //     console.log(employeeIdStr,"employeeIdStr2")
      // }
      // else{
      //     employeeIdStr = employeeIdStr
      //     console.log(employeeIdStr,"employeeIdStr3")
      // }
      //  generatedPassword = Math.random().toString(36).substring(2,9)+1;
    }, 2000);
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="add_user"
        role="dialog"
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add User</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="false"
                        value={values.employeeId}
                        onChange={handleChange("employeeId")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        autoComplete="false"
                        value={values.email}
                        onChange={handleChange("email")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Phone </label>
                      <input
                        className="form-control"
                        minLength="14"
                        maxLength="14"
                        required
                        // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                        onKeyDown={(e) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault();
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 6
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 4),
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 2
                          ) {
                            setValues({
                              ...values,
                              phone: "",
                            });
                          }
                          if (
                            e.code === "Backspace" &&
                            values.phone.length === 10
                          ) {
                            setValues({
                              ...values,
                              phone: values.phone.substring(0, 9),
                            });
                          }
                        }}
                        name="phone"
                        value={values.phone}
                        onChange={handlePhone("phone")}
                        placeholder="(123) 456-7890"
                        autocomplete="new-mobileNunmber"
                      />
                    </div>
                    {/* <input className="form-control" type="text"
                                    autoComplete="false" value={values.phone}
                                    onChange={handleChange('phone')}/> */}
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="type"
                        autoComplete="false"
                        value={values.password}
                        onChange={handleChange("password")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Job Title</label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.jobTitle}
                        onChange={handleChange("jobTitle")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={theDate}
                        onChange={(date) => setTheDate(date)}
                        className="colo-icon form-control datetimepicker3"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Gender</label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.gender}
                        onChange={handleChange("gender")}
                      >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Hourly Wage</label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.hourlyWage}
                        onChange={handleChange("hourlyWage")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Starting Date <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        selected={theNewDate}
                        onChange={(date) => setTheNewDate(date)}
                        className="colo-icon form-control datetimepicker3"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Employee Type </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.employeeType}
                        onChange={handleChange("employeeType")}
                      >
                        <option value="null">None</option>
                        <option value="Full time">Full time</option>
                        <option value="Part time">Part time</option>
                        <option value="Seasonal">Seasonal</option>
                        <option value="Temporary">Temporary</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Volunteer">Volunteer</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Pay Type </label>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        value={values.payType}
                        onChange={handleChange("payType")}
                      >
                        <option value="null">None</option>
                        <option value="Hourly">Hourly</option>
                        <option value="Salary">Salary</option>
                        <option value="Commission">Commission</option>
                        <option value="Daily">Daily</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Contract">Contract</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="col-form-label">Address </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.address}
                        onChange={handleChange("address")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">State </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.state}
                        onChange={handleChange("state")}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">Postal/Zip Code </label>
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="false"
                        value={values.pinCode}
                        onChange={handleChange("pinCode")}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Primary Location{" "}
                      </label>
                      <a
                        onClick={openDropDown}
                        className="micoloc btn"
                        data-toggle="modal"
                        data-target="#add_designation"
                      >
                        <i
                          className="fa fa-plus"
                          onClick={moreLocationFunc}
                        ></i>
                      </a>
                      <select
                        className="form-select"
                        style={{ height: "44px", fontSize: "14px" }}
                        autoComplete="false"
                        onChange={handleLocationChange("location")}
                      >
                        <option value="null">Select</option>
                        {location.location &&
                          location.location.length > 0 &&
                          location.location.map((current, i) => {
                            return (
                              <option
                                value={JSON.stringify({
                                  id: current.id,
                                  name: current.name,
                                })}
                                key={i + 1}
                              >
                                {current.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Selected Location
                      </label>
                      <ul>
                        {locObj.map((elem, ind) => {
                          return (
                            <li key={ind}>
                              {elem.name}
                              <i
                                style={{ float: "right" }}
                                className="fa fa-trash"
                                onClick={() => deleteHandler(elem.id)}
                              ></i>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="table-responsive m-t-15">
                  <table className="table table-striped custom-table">
                    <thead>
                      <tr>
                        <th>Available Shifts</th>
                        <th className="text-center">Mon</th>
                        <th className="text-center">Tue</th>
                        <th className="text-center">Wed</th>
                        <th className="text-center">Thu</th>
                        <th className="text-center">Fri</th>
                        <th className="text-center">Sat</th>
                        <th className="text-center">Sun</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Morning Shift</td>

                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  monday: {
                                    ...values.availability.monday,
                                    morningShift:
                                      !values.availability.monday.morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  tuesday: {
                                    ...values.availability.tuesday,
                                    morningShift:
                                      !values.availability.tuesday.morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  wednesday: {
                                    ...values.availability.wednesday,
                                    morningShift:
                                      !values.availability.wednesday
                                        .morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  thursday: {
                                    ...values.availability.thursday,
                                    morningShift:
                                      !values.availability.thursday
                                        .morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  friday: {
                                    ...values.availability.friday,
                                    morningShift:
                                      !values.availability.friday.morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  saturday: {
                                    ...values.availability.saturday,
                                    morningShift:
                                      !values.availability.saturday
                                        .morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  sunday: {
                                    ...values.availability.sunday,
                                    morningShift:
                                      !values.availability.sunday.morningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Evening Shift</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  monday: {
                                    ...values.availability.monday,
                                    eveningShift:
                                      !values.availability.monday.eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  tuesday: {
                                    ...values.availability.tuesday,
                                    eveningShift:
                                      !values.availability.tuesday.eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  wednesday: {
                                    ...values.availability.wednesday,
                                    eveningShift:
                                      !values.availability.wednesday
                                        .eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  thursday: {
                                    ...values.availability.thursday,
                                    eveningShift:
                                      !values.availability.thursday
                                        .eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  friday: {
                                    ...values.availability.friday,
                                    eveningShift:
                                      !values.availability.friday.eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  saturday: {
                                    ...values.availability.saturday,
                                    eveningShift:
                                      !values.availability.saturday
                                        .eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  sunday: {
                                    ...values.availability.sunday,
                                    eveningShift:
                                      !values.availability.sunday.eveningShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Night Shift</td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  monday: {
                                    ...values.availability.monday,
                                    nightShift:
                                      !values.availability.monday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  tuesday: {
                                    ...values.availability.tuesday,
                                    nightShift:
                                      !values.availability.tuesday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  wednesday: {
                                    ...values.availability.wednesday,
                                    nightShift:
                                      !values.availability.wednesday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  thursday: {
                                    ...values.availability.thursday,
                                    nightShift:
                                      !values.availability.thursday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  friday: {
                                    ...values.availability.friday,
                                    nightShift:
                                      !values.availability.friday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  saturday: {
                                    ...values.availability.saturday,
                                    nightShift:
                                      !values.availability.saturday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setValues({
                                ...values,
                                availability: {
                                  ...values.availability,
                                  sunday: {
                                    ...values.availability.sunday,
                                    nightShift:
                                      !values.availability.sunday.nightShift,
                                  },
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminAUModal;

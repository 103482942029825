import React, { forwardRef, useEffect, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch } from "react-redux";
import { updateHoliday } from "../../../actions/holidayActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const EditModalHolidays = ({
  showDropDown,
  openDropDown,
  current,
  initialFromDate,
}) => {
  const [fromDate, setFromDate] = useState(
    initialFromDate
      ? new Date(initialFromDate)
      : new Date(moment().format("YYYY-MM-DD"))
  );
  const From = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      <div className="cal-icon">
        <input
          className="form-control floating datatimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">From</label>
    </div>
  ));

  //   const [theDate, setTheDate] = useState(
  //     new Date(moment().format("YYYY-MM-DD"))
  //   );
  //   const HDate = forwardRef(({ value, onClick }, ref) => (
  //     <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //       {/* {console.log("value=>",value)} */}
  //       <div className="cal-icon">
  //         <input
  //           className="form-control floating datatimepicker"
  //           type="text"
  //           value={value}
  //         />
  //       </div>
  //       <label className="focus-label">Date</label>
  //     </div>
  //   ));
  const [values, setValues] = useState({
    id: "",
    holidayName: "",
    date: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const currentHoliday = async () => {
    try {
      setValues({
        id: "",
        holidayName: "",
        date: "",
      });
      setValues({
        id: current.id,
        holidayName: current.holidayName,
        date: current.date,
      });
      //   setTheDate(
      //     new Date(
      //       current.date.substring(0, 4),
      //       current.date.substring(5, 7),
      //       current.date.substring(8, 10)
      //     )
      //   );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    currentHoliday();
  }, []);
  const dispatch = useDispatch();
  const updateHolidayData = {
    _id: values.id,
    holidayName: values.holidayName,
    // date: theDate.toISOString().substring(0, 10),
    date: fromDate.toISOString(),
    // date: toDate.toISOString(),
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(updateHoliday(updateHolidayData));
    setValues({
      id: "",
      holidayName: "",
      //   date: "",
      date: "",
    });
    openDropDown();
  };
  console.log(values, "holiday values tak chal rha hai");
  console.log(current, "current values tak chal rha hai");
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="edit_holiday"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Holiday</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div className="form-group">
                  <label>
                    Holiday Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={values.holidayName}
                    onChange={handleChange("holidayName")}
                  />
                </div>
                <div className="form-group form-focus select-focus">
                  <label>
                    Holiday Date <span className="text-danger">*</span>
                  </label>
                  <br />
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    customInput={<From />}
                  />
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalHolidays;

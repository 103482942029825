import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../actions/authActions";

const EmployeeSidebar = () => {
  const auth = useSelector((state) => state.auth);
  // const leave = useSelector((state) => state.leave);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };

  const location = useLocation();
  const currentPath = location.pathname;
  const isActive = (path) => (currentPath === path ? "active" : "");

  return (
    <>
      <div className="">
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li className={isActive("/dashboard")}>
                  <NavLink to="/dashboard">
                    <i className="la la-home"></i> <span>Dashboard</span>
                  </NavLink>
                </li>
                <li className={isActive("/shift-scheduling")}>
                  <NavLink to="/shift-scheduling">
                    <i className="la la-calendar"></i>
                    <span>Shift &amp; Schedule</span>
                  </NavLink>
                </li>

                <li className={isActive("/chat")}>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i> <span>Chat</span>
                  </NavLink>
                </li>

                <li className={isActive("/attendance")}>
                  <NavLink to="/attendance">
                    <i className="la la-clock-o"></i>
                    <span>Time Clock</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/leaves">
                    <i className="la la-user-times"></i>
                    <span>Leaves </span>
                    {/* <span className="badge badge-pill bg-primary float-right">{leave.leaveList && leave.leaveList.length }</span> */}
                  </NavLink>
                </li>
                <li className={isActive("/training")}>
                  <NavLink to="/training">
                    <i className="la la-laptop"></i> <span>Training</span>
                  </NavLink>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer", color: "#fff" }}
                    onClick={logout}
                  >
                    <i className="la la-sign-out"></i> <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeSidebar;
